import classes from "./SwitchButton.module.css";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

const SwitchButton = (props) => {
  const { inputName, labelText, switchHandler  = () => {}, checked, ...rest } = props;
  return (
      <FormControlLabel
        control={<Switch onChange={switchHandler} checked={checked || false} {...rest}/>}
        label={props.label || ''}
      />
  );
};

export default SwitchButton;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMicrosite } from "src/store/actions/shareLink";
import MicrositeIframe from "src/pages/microsites/MicrositeIframe";
const ViewMicrosite = ({ UID, id }) => {
  const dispatch = useDispatch();
  const [landingpageHtml, setLandingpageHtml] = useState(null);
  useEffect(() => {
    dispatch(getMicrosite({ UID, id, type: "html" })).then((res) => {
      if (res.payload) {
        console.log(res.payload);
        setLandingpageHtml(res.payload.html);
        // setHashDetails(res.payload);
      }
    });
  }, []);

  return (
    <div className="viewMicrosite">
      {landingpageHtml && <MicrositeIframe landingpageHtml={landingpageHtml} />}
    </div>
  );
};

export default ViewMicrosite;

import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  FormGroup,
  Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { IoCloudUploadOutline, IoCloudDoneOutline } from "react-icons/io5";
import {
  MdUndo,
  MdRedo,
  MdOutlineFitScreen,
  MdFitScreen,
  MdGridOff,
  MdGridOn,
} from "react-icons/md";
import { BsTablet } from "react-icons/bs";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import SelectDevice from "src/components/common/SelectDevice";
import { renderEditableElements } from "../renderPresentation";
import { useEditorHistory } from "src/hooks/editor/history";
import SelectBox from "src/components/ui/formElements/SelectBox";
import { useSelector, useDispatch } from "react-redux";
import { getMasterTemplate, updateSlideSetting } from "src/store/actions/presentations";
import SwitchButton from "src/components/ui/formElements/SwitchButton";
import { setSelectedMasterTemplate } from "src/store/reducers/presentations-slice";
import { findSlideIndxes, masterTemplateUi } from "src/services/editor.service";
import { useParams } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import { editorActions } from "src/store/reducers/editor-slice";
const TopBar = (props) => {
  const dispatch = useDispatch();
  let editMasterTemplateOnSlide = props.editMasterTemplateOnSlide
  
  const [isGrid, setIsGrid] = useState(false);
  const { undo, redo, disabledButtons } = useEditorHistory({
    csRenderEditable: renderEditableElements,
  });
  const { slideId, masterTemplateId } = useParams();
  const {
    presentation: { masterTemplates, _id: presentationId, chapters },
    selectedMasterTemplate,
  } = useSelector((state) => state.presentations);  
  
  let chapterIndex, slideIndex;
 
  if(slideId && chapters){
    let getIndex = findSlideIndxes(chapters, slideId);
    chapterIndex = getIndex.chapterIndex
    slideIndex = getIndex.slideIndex
  }

  useEffect(() => {
    const gridOverylay = document.getElementById("gridOverylay");
    if (isGrid) {
      gridOverylay.style.display = "block";
    } else {
      gridOverylay.style.display = "none";
    }
  }, [isGrid]);
  
  const fetchMasterTemplate = async (presentationId, masterTemplateId) => {
    await dispatch(getMasterTemplate({ presentationId, masterTemplateId }));
  };
 
  const deActiveAll = ()=>{
    dispatch(editorActions.deActiveElement());
    document.querySelector('.editable.active')?.classList.remove('active')
  }

  const handleChangeMasterTemplate = async (e) => {
    const masterTemplate = e.target.value;
    deActiveAll()
    if (masterTemplate) {
      await fetchMasterTemplate(presentationId, masterTemplate._id);
      await dispatch(setSelectedMasterTemplate(masterTemplate));
      await dispatch(updateSlideSetting({
          id: { presentationId, chapterIndex, slideIndex },
          data: { masterTemplateFileId: masterTemplate.fileId },
          connectMasterTemplate:'connectTamplate'
        })
      );
    }
  };

  const handleRemoveMasterTemplate = (e) => {
    deActiveAll()
    dispatch(updateSlideSetting({
        id: { presentationId, chapterIndex, slideIndex },
        data: { masterTemplateFileId: null },
        connectMasterTemplate:'disconnectTamplate'
      })
    ).then(()=>{
      dispatch(setSelectedMasterTemplate(null));
      masterTemplateUi.removeTemplate()
    })
  };

  
  return (
    <Box className="editorHead">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={5}>
          <Breadcrumb />
        </Grid>
        <Grid item md={2}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {props.component === "presentation" && props.dimensions && (
              <>
                <Grid>
                  <Typography variant="body1" component="span" className="text">
                    Target Device :
                    <Typography
                      variant="body1"
                      component="span"
                      ml={1}
                      sx={{ verticalAlign: "middle" }}
                    >
                      <BsTablet />
                    </Typography>
                  </Typography>
                  {/* SelectBox */}
                </Grid>
                <Grid>
                  <SelectDevice
                    value={props.dimensions.deviceId}
                    disabled={true}
                  />
                </Grid>
              </>
            )}

            {/* Zoom */}
            <Grid>
              <Box className="zoom-selectbox">
                <Tooltip title="Zoom-Out" arrow>
                  <IconButton
                    onClick={() => props.handleZoomChange(props.zoom - 1)}
                  >
                    <BiZoomOut />
                  </IconButton>
                </Tooltip>
                <TextField
                  type="number"
                  value={props.zoom}
                  onChange={(e) => props.handleZoomChange(e.target.value)}
                />
                <Tooltip title="Zoom-In" arrow>
                  <IconButton
                    onClick={() => props.handleZoomChange(props.zoom + 1)}
                  >
                    <BiZoomIn />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Scale to Fit" arrow>
                  <IconButton
                    variant="contained"
                    onClick={() => props.handleZoomChange("scaleToFit")}
                  >
                    {props.scaledToFit ? (
                      <MdFitScreen />
                    ) : (
                      <MdOutlineFitScreen />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
            <Grid></Grid>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <Grid
            container
            alignItems="center"
            justifyContent="end"
            textAlign="end"
          >
            {!props.isMasterTemplatePage && (
              <Grid item>
                <Stack direction="row">
                  {(selectedMasterTemplate && !props.isAnyChange) && (
                    <Tooltip 
                    key={editMasterTemplateOnSlide} 
                      title={
                        <>
                        <strong>Warning:</strong>
                        {editMasterTemplateOnSlide 
                          ? ' Switching to the Slide will erase your slide history.'
                          : ' Switching to the master template will erase your slide history.'
                        }
                       </>
                      }
                    arrow>
                    <Box sx={{ display: 'flex' }}>
                    <SwitchButton
                      size="small"
                      label={"Edit"}
                      checked={editMasterTemplateOnSlide}
                      switchHandler={()=>{
                        dispatch(editorActions.setEditMasterTemplateOnSlide(!editMasterTemplateOnSlide))
                        dispatch(editorActions.clearHistory())
                      }}
                    />
                    </Box>
                    </Tooltip>
                  )}
                  <SelectBox
                    placeholder="Master Templates"
                    value={selectedMasterTemplate}
                    autoWidth
                    name="masterTemplate"
                    onChange={handleChangeMasterTemplate}
                    handleClear={handleRemoveMasterTemplate}
                    options={masterTemplates}
                    optionValueKey="id"
                    optionLabelKey="name"
                    disabled={editMasterTemplateOnSlide || props.isAnyChange}
                  ></SelectBox>
                </Stack>
              </Grid>
            )}

            <Grid item>
              <Tooltip title="Grid" arrow>
                <IconButton
                  variant="contained"
                  onClick={() => setIsGrid(!isGrid)}
                >
                  {!isGrid ? <MdGridOff /> : <MdGridOn />}
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  disabledButtons.undo
                    ? "No Previous History"
                    : "Undo (ctrl + z)"
                }
                arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={undo}
                    disabled={disabledButtons.undo}
                    id="undoBtn"
                  >
                    <MdUndo />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip
                title={
                  disabledButtons.redo ? "No Next History" : "Redo (ctrl + y)"
                }
                arrow
              >
                <span>
                  <IconButton
                    className="iconColor"
                    onClick={redo}
                    disabled={disabledButtons.redo}
                    id="redoBtn"
                  >
                    <MdRedo />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                sx={{ minWidth: "100px" }}
                variant="text"
                onClick={props.handleSlideExport}
                startIcon={
                  props.isAnyChange ? (
                    <IoCloudUploadOutline />
                  ) : (
                    <IoCloudDoneOutline />
                  )
                }
                disabled={!props.isAnyChange}
              >
                
                {props.isAnyChange ? "Save All Changes" : "All Changes saved"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopBar;

import React, { useState, useEffect } from "react";
import Main from "../dialogs/Main";
import Grid from "@mui/material/Grid2";
import Content from "src/layouts/Content";
import { Button, IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";
import MoveFolderBreadcumbs from "./MoveFolderBreadcumbs";
import { useDispatch, useSelector } from "react-redux";
import {
  getMoveFoldersHierarchy,
  getMoveableFoldersList,
} from "src/store/actions/folder";
import { getModuleType } from "src/utils/Utils";
import { useSearchParams } from "react-router-dom";
import Folder from "./Folder";
import Divider from "../ui/Divider";
import "./MoveDialog.scss";


const MoveDialog = ({ show, close, moveableItem, onSubmit }) => {
  const { updateLoading, moveableFoldersList, getmoveableListLoading } = useSelector((state) => state.folders);
 
  const { updatePrLoading } = useSelector((state) => state.presentations);
  const { updateEmLoading } = useSelector((state) => state.emails);
  const { updateMiLoading } = useSelector((state) => state.microsites);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const moduleType = getModuleType();
  const folderId = searchParams.get("folderId");
  const handleFolderClick = (folder) => {
    if (folder._id !== moveableItem?._id) {
      setSelectedFolder(folder._id);
    }
  };
  useEffect(() => {
    if (selectedFolder || selectedFolder == null || selectedFolder == null) {
      dispatch(
        getMoveableFoldersList({
          parentId: selectedFolder || null,
          type: moduleType,
        })
      );
    }
    if (selectedFolder) {
      dispatch(getMoveFoldersHierarchy(selectedFolder));
    }
  }, [selectedFolder]);

  useEffect(() => {
    if (moveableItem) {
      fetchDetails();
      setSelectedFolder(null);
    }
  }, [moveableItem]);

  const fetchDetails = () => {
    if (folderId || folderId == null) {
      dispatch(
        getMoveableFoldersList({ parentId: folderId, type: moduleType })
      );
    }
    if (folderId) {
      dispatch(getMoveFoldersHierarchy(folderId));
    }
  };

  const handleCancel = () => {
    setSelectedFolder(null);
    close();
  };
    // const handleSubmit = (e) => {
  //     dispatch(updateFolder({ data: { parentId: selectedFolder || null }, id: moveableItem?._id })).then(() => {
  //         handleCancel()
  //         dispatch(getFoldersList({ parentId: folderId, type: moduleType }));
  //     })
  // }

  const getFolderClassName = (folder) => {
    let className = "movefolder-box";

    if (selectedFolder === folder._id) {
      className += " selected";
    }

    if (moveableItem?._id === folder._id) {
      className += " disabled";
    }

    return className;
  };

  const isEmptyMoveableList = moveableFoldersList.length === 0 && !getmoveableListLoading;

  return (
    <Main className="move-dialog" isOpen={show} handleClose={close}>
      <Content title="Move To">
        <MoveFolderBreadcumbs setSelectedFolder={setSelectedFolder} />
        <Divider sx={{ my: 2 }} />
        <div className={`move-box ${isEmptyMoveableList ? 'vCenter' : ''}`}>
          {!getmoveableListLoading ? (
            <div>
              {moveableFoldersList.map((folder) => (
                <Folder
                className={getFolderClassName(folder)}
                  name={folder.name}
                  onClick={() => handleFolderClick(folder)}
                  controls={<IconButton size="small" onClick={() => handleFolderClick(folder)}><ArrowForwardIcon fontSize="small" /></IconButton>}
                />
              ))}
            </div>
          ) : (
            <h3>Loading...</h3>
          )}
        </div>
        <div className="button-div">
          <Grid container mt={2}>
            <div className="custom-btn">
              <Button
                sx={{ mr: 2 }}
                variant="outlined"
                className="capitalize"
                onClick={handleCancel}
                startIcon={<AiOutlineClose size={13} />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="capitalize"
                startIcon={<BsCheck2 size={13} />}
                disabled={updateMiLoading || updatePrLoading || updateEmLoading ||updateLoading || moveableItem?._id === selectedFolder || selectedFolder === moveableItem?.folderId}
                onClick={() => onSubmit(selectedFolder)}
              >
                Move Here
              </Button>
            </div>
          </Grid>
        </div>
      </Content>
    </Main>
  );
};

export default MoveDialog;

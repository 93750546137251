import React from "react";
import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import './InputFields.scss'
const SelectFields = ({
  label,
  fieldName,
  control,
  options,
  rules,
  error,
  disabled,
  withSearch,
  labelTag,
  gap, 
  inputFieldSx,
  placeholder
}) => {
  return (
    <Box className={`InputField ${gap? "gap":''}`} sx={inputFieldSx}>
      {labelTag && (
        <Typography variant="body1" component="label" className="labelTag">
          {label} {rules?.required && <span className="required">*</span>}
        </Typography>
      )}
      {withSearch ? (
        <>
          <Controller
            name={fieldName}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disabled={disabled}
                options={options}
                onChange={(_, data) => onChange(data ? data.value : "")}
                value={value ? value : null}
                getOptionLabel={(option) => {
                  if (option.label) {
                    return option.label;
                  } else if (option) {
                    return (
                      options.filter((e) => e.value == option)[0]?.label || ""
                    );
                  }
                  return "";
                }}
                renderInput={(params) => (
                  <TextField
                    className="outlined"
                    required={rules?.required && true}
                    {...params}
                    label={(labelTag && value?.length) || placeholder? null : label}
                    placeholder={placeholder}
                    error={error && true}
                    helperText={error ? error.message : null}
                    autoComplete="off"
                  />
                )}
                isOptionEqualToValue={(option, value) => {
                  if (value.value) {
                    return option.value == value.value;
                  } else if (value) {
                    return option.value == value;
                  }
                }}
              />
            )}
          />
        </>
      ) : (
        <Controller
          name={fieldName}
          control={control}
          rules={rules}
          render={({ field }) => (
            <FormControl
              required={rules?.required && true}
              fullWidth
              error={error && true}
            >
              <InputLabel sx={{ textTransform: "capitalize" }}>
                {label}
              </InputLabel>
              <Select
                disabled={disabled}
                inputRef={field.ref}
                value={field.value}
                onChange={field.onChange}
                fullWidth
              >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error ? error.message : " "}</FormHelperText>
            </FormControl>
          )}
        />
      )}
    </Box>
  );
};

export default SelectFields;

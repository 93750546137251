import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TextLimit from "src/components/ui/TextLimit";

const Breadcrumb = () => {
  const { masterTemplateId, slideId } = useParams();
  const { presentation, presentationSlides } = useSelector(
    (state) => state.presentations
  );
  const { name, masterTemplates } = presentation;

  const activeMasterTemplate = masterTemplates?.find(
    (temp) => temp._id === masterTemplateId
  );
  const activeSlide = presentationSlides?.find((slide) => slide.id === slideId);

  return (
    <Breadcrumbs>
      <Typography>
        <TextLimit text={name} width="160px" />
      </Typography>
      <Typography>{masterTemplateId ? "Master Template" : "slide"}</Typography>
      <Typography color="text.primary" id="breadCrumb">
        <TextLimit
          text={activeSlide ? activeSlide?.name : activeMasterTemplate?.name}
          width="160px"
        />
      </Typography>
    </Breadcrumbs>
  );
};

export default Breadcrumb;

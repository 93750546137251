import { createTheme } from "@mui/material/styles";
// const font = "'Lato', sans-serif";
const font = "'Montserrat', sans-serif";
const BodyTextColor = "#939393";
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#2E3A94",
    },
    secondary: {
      main: "#0AB1BD",
      contrastText: "#fff",
    },
    neutral: {
      main: "#ff0",
      contrastText: "#ddd",
    },
    warning: {
      main: "#d80000",
    },
    btnColor: {
      main: "#1360EF",
      contrastText: "#fff",
    }
  },

  typography: {
    fontFamily: font,
    body1: {
      fontSize: 12,
      fontWeight: 400,
      color: BodyTextColor,
    },
    body2: {
      fontSize: 12,
      fontWeight: 500,
      color: BodyTextColor,
    },
    button: {
      fontSize: 10,
      lineHeight: "27px",
      fontWeight: 700,
      borderRadius: 4
    },
  },
  shape: {
    borderRadius: 4,
  },
});

theme.typography.h1 = {
  fontSize: 18,
  fontWeight: 700,
  lineHeight: "25.2px",
  [theme.breakpoints.down('md')]: {
    fontSize: 15,
  },
};

theme.typography.h2 = {
  fontSize: 15,
  fontWeight: 700,
  lineHeight: "25.2px",
  [theme.breakpoints.down('md')]: {
    fontSize: 15,
  },
};

theme.typography.h3 = {
  fontSize: 13,
  fontWeight: 700,
  lineHeight: "25.2px",
  [theme.breakpoints.down('md')]: {
    fontSize: 13,
  },
};

theme.typography.h4 = {
  fontSize: 11,
  fontWeight: 700,
  lineHeight: "25.2px",
  [theme.breakpoints.down('md')]: {
    fontSize: 11,
  },
};

theme.typography.h5 = {
  fontSize: 9,
  fontWeight: 700,
  lineHeight: "25.2px",
  [theme.breakpoints.down('md')]: {
    fontSize: 9,
  },
};

export default theme;

import {
  IoImage,
  IoTextOutline,
  IoNewspaper,
  IoCreateSharp,
  IoCheckbox,
  IoAppsSharp,
  IoRadioButtonOn,
  IoBarChartSharp,
  IoPencilOutline,
  IoVideocam,
  IoListSharp,
  IoGrid,
} from "react-icons/io5";
import { MdAudiotrack, MdSocialDistance } from "react-icons/md";
import { BsLink45Deg, BsSliders } from "react-icons/bs";
import {
  AiOutlineGroup,
  AiOutlineCreditCard,
  AiFillStar,
} from "react-icons/ai";
import { MdOutlineViewCarousel } from "react-icons/md";
import { IoMdRemove } from "react-icons/io";
import { GridIcons } from "./ToolbarIcons";
import { ReactComponent as ParaIcon } from "src/assets/images/ParagraphIcon1.svg";
import { ReactComponent as TitleIcon } from "src/assets/images/Title.svg";


export const microsite_toolbar_items = {
  components: [
    {
      type: "cs-input",
      caption: "Title",
      icon: <TitleIcon />,
      permissionPath: 'microsites.components.input'
    },
    {
      type: "cs-text",
      caption: "Paragraph",
      icon: <ParaIcon />,
      permissionPath: 'microsites.components.text'
    },
    {
      type: "cs-image",
      caption: "Image",
      icon: <IoImage />,
      permissionPath: 'microsites.components.image'
    },
    {
      type: "cs-link",
      caption: "Button",
      icon: <BsLink45Deg />,
      permissionPath: 'microsites.components.button'
    },
    {
      type: "cs-card",
      caption: "Card",
      icon: <IoNewspaper />,
      permissionPath: 'microsites.components.card'
    },
    {
      type: "cs-table",
      caption: "Table",
      icon: <IoAppsSharp />,
      permissionPath: 'microsites.components.table'
    },
    {
      type: "cs-list",
      caption: "List",
      icon: <IoListSharp />,
      permissionPath: 'microsites.components.list'
    },
    {
      type: "cs-rating",
      caption: "Rating",
      icon: <AiFillStar />,
      permissionPath: 'microsites.components.ratting'
    },
    {
      type: "cs-social",
      caption: "Social",
      icon: <MdSocialDistance />,
      permissionPath: 'microsites.components.social'
    },
    {
      type: "cs-divider",
      caption: "Divider",
      icon: <IoMdRemove />,
      permissionPath: 'microsites.components.divider'
    },
    {
      type: "cs-video",
      caption: "Video",
      icon: <IoVideocam />,
      permissionPath: 'microsites.components.video'
    },
    // {
    //   type: "cs-audio",
    //   caption: "Audio",
    //   icon: <MdAudiotrack />,
    //   permissionPath: 'microsites.components.audio'
    // },
    // {
    //   type: "cs-radio",
    //   caption: "Radio",
    //   icon: <IoRadioButtonOn />,
    //   permissionPath: 'microsites.components.radio'
    // },
    // {
    //   type: "cs-checkboxes",
    //   caption: "Checkbox",
    //   icon: <IoCheckbox />,
    //   permissionPath: 'microsites.components.checkbox'
    // },
    // {
    //   type: "cs-flipCard",
    //   caption: "FlipCard",
    //   icon: <IoNewspaper />,
    //   permissionPath: 'microsites.components.flipCard'
    // },
    // {
    //   type: "cs-graph",
    //   caption: "Graph",
    //   icon: <IoBarChartSharp />,
    //   permissionPath: 'microsites.components.graph'
    // },
    // {
    //   type: "cs-coverflow",
    //   caption: "Coverflow",
    //   icon: <MdOutlineViewCarousel />,
    //   permissionPath: 'microsites.components.coverFlow'
    // },
    // {
    //   type: "cs-carousel",
    //   caption: "Carousel",
    //   icon: <MdOutlineViewCarousel />,
    //   permissionPath: 'microsites.components.carousel'
    // },
    // {
    //   type: "cs-extendedCard",
    //   caption: "Extended Card",
    //   icon: <AiOutlineCreditCard />,
    //   permissionPath: 'microsites.components.extendedCard'
    // },
    // {
    //   type: "cs-tabGroup",
    //   caption: "Tab Group",
    //   icon: <AiOutlineGroup />,
    //   permissionPath: 'microsites.components.tabs'
    // },
  ],
  layouts: [
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-single" />,
      layout: "layout-single",
    },
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-1" />,
      layout: "layout-1",
    },
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-2" />,
      layout: "layout-2",
    },
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-3" />,
      layout: "layout-3",
    },
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-4" />,
      layout: "layout-4",
    },
    {
      type: "cs-column",
      caption: "",
      renderType: "microsite",
      icon: <GridIcons icon="layout-5" />,
      layout: "layout-5",
    },
  ],
};

import { createSlice, current } from "@reduxjs/toolkit";

import { findELementIndex } from "src/services/editor.service";
import { saveMasterTemplate, saveSlide } from "../actions/presentations";
import { saveEmail } from "../actions/emails";
import { saveMicrosite } from "../actions/microsites";
import { populateEntry } from "src/hooks/editor/shared-logic";
import { selectLast } from "src/services/utils";
import { HISTORY_LIMIT } from "src/data/contants";

const initialState = {
  slideName: "slide1",
  activeEditable: { element: "", elementId: "", elementDetails: {} },
  clickedEle: "",
  history: { backStack: [], forwardStack: [], savedActionId: null },
  dialogs : {deleteLayer: false},
  layers: [],
  hierarchy: [],
  editMasterTemplateOnSlide:false
};

const editorSlice = createSlice({
  name: "editor",
  initialState: initialState,
  reducers: {
    // EDITOR
    activeElement(state, action) {
      const { elementType, elementId, elementDetails, clickedEle } = action.payload;

      state.activeEditable = {
        elementType,
        elementId,
        elementDetails,
      };

      if(clickedEle){
        state.clickedEle = clickedEle
      }
      
    },
    deActiveElement(state) {
      state.activeEditable.elementType = "";
      state.activeEditable.elementId = "";
    },
    clearEditorData(state) {
      state.slideName = initialState.slideName;
      state.activeEditable = initialState.activeEditable;
      state.history = initialState.history;
      state.layers = initialState.layers;
      state.hierarchy = initialState.hierarchy;
      state.editMasterTemplateOnSlide = false
    },
    clearHistory(state) {
      state.history = { backStack: [], forwardStack: [], savedActionId: null };
    },
    // Hierachy
    updateHierachy(state, action) {
      state.hierarchy.length = 0;
      state.hierarchy = action.payload;
    },
    emptyHirarchy(state, action) {
      state.hierarchy.length = 0;
    },
    clearLayers(state) {
      state.layers = [];
    },
    deleteHierarchy(state, action) {
      const layerIndex = findELementIndex(
        state.hierarchy,
        "parentId",
        action.payload
      );
      if (layerIndex != -1) {
        state.hierarchy.splice(layerIndex, 1);
      }
    },
    replaceHierarchy(state, action) {
      const layerIndex = findELementIndex(
        state.hierarchy,
        "parentId",
        action.payload.id
      );

      if (layerIndex != -1) {
        state.hierarchy.splice(layerIndex, 1, action.payload.root);
      } else {
        state.hierarchy.push(action.payload.root);
      }
    },

    // History
    updateHistory(state, action) {
      const { actionEntry, backupDetails } = action.payload;
      state.history.backStack.push(actionEntry);
      state.history.forwardStack.length = 0;
      // keeping track for latest updated element
      state.activeEditable.elementDetails = backupDetails;
      // deletting History after limit exceeds
      limitHistoryStack(state);
    },
    updateUndoRedo(state, action) {
      const { type, backupDetails } = action.payload;
      const history = state.history;

      if (type === "undo") {
        const backLastele = history.backStack.pop();
        state.activeEditable.elementDetails = backupDetails;
        history.forwardStack.unshift(backLastele);
      } else {
        // REDO (Forward)
        const forwardFirstEle = history.forwardStack.shift();
        state.activeEditable.elementDetails = backupDetails;
        history.backStack.push(forwardFirstEle);
      }
    },
    setEditMasterTemplateOnSlide(state, action){
      state.editMasterTemplateOnSlide = action.payload
    },
    setEditorDialog(state, action){
      state.dialogs[action.payload.dialog] = action.payload.value; 
    }
  },
  extraReducers: (builder) => {
    builder.addCase(saveSlide.fulfilled, updateSavedRecordId);
    builder.addCase(saveMasterTemplate.fulfilled, updateSavedRecordId);
    builder.addCase(saveEmail.fulfilled, updateSavedRecordId);
    builder.addCase(saveMicrosite.fulfilled, updateSavedRecordId);
  },
});

// <--------Helpers--------->
const updateSavedRecordId = (state) => {
  const lastAction = selectLast(state.history.backStack);
  const lastActionRecordId = lastAction?._recordId;
  if(lastActionRecordId){
    state.history.savedActionId = lastActionRecordId;
  }
};

const limitHistoryStack = (state) => {
  const backStack = state.history.backStack;
  if (backStack.length > HISTORY_LIMIT) backStack.shift();
};

export const editorActions = editorSlice.actions;

export default editorSlice.reducer;

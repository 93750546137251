import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activateAccount } from "src/store/actions/auth";
import CreatePassword from "src/components/login/CreatePassword";
import { uiActions } from "src/store/reducers/ui-slice";
import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthLayout from "src/layouts/AuthLayout";
import "../styles/ActivateAccount.scss";

const ActivateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status, tokenStatus } = useSelector(
    (state) => state.auth.accountActive
  );
  const [param] = useSearchParams(useLocation().pathname);
  const token = param.get("token");

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   const [pass, confirmPass] = new FormData(e.target);
  //   const [password, confirmPassword] = [pass[1], confirmPass[1]];
  //   if (password == confirmPassword) {
  //     if (password.length >= 8) {
  //       if (token) {
  //         dispatch(activateAccount({ password, confirmPassword, token }));
  //       }
  //     } else {
  //       dispatch(
  //         uiActions.showNotification({
  //           status: "info",
  //           title: "short password",
  //           message: "Password must have 8 or more digits in it",
  //         })
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       uiActions.showNotification({
  //         status: "info",
  //         title: "Password not same",
  //         message: "Password must be same as confirm password",
  //       })
  //     );
  //   }
  // };

  useEffect(() => {
    if (status && token) {
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Your Account has been activated",
        })
      );
      navigate("/login", { replace: true });
    }
  }, [status]);

  useEffect(() => {
    if (tokenStatus) {
      dispatch(
        uiActions.showNotification({
            status: "warning",
          title: "Your Token has been Expired",
        })
      );
      navigate("/generate-token", { replace: true });
    }
  }, [tokenStatus]);

  return (
    <AuthLayout heading="Activate your Account">
      <CreatePassword token={token} />
    </AuthLayout>
  );
};
export default ActivateAccount;

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

import { uploadAsset } from "../../store/actions/assets";

import upload from "../../assets/images/upload.png";

import { uiActions } from "../../store/reducers/ui-slice";
import Main from "./Main";
import { useConvertFileToBase64 } from "src/hooks/useUrlToBase64";
import { assetsFormats } from "src/config";
import "../../styles/Upload.scss";
import { convertMBToBytes, getUnitBasedOnMB } from "./upload.helper";

const Upload = (props) => {
  const { show, title, type, uploadHandler, uploadLimit = 10 } = props;
  const dispatch = useDispatch();
  const [update, setUpdate] = useState(0);
  const uploadAssetRef = useRef();

  const closeDialog = () => {
    if(props.close){
      props.close();
    }
  };

  const sendFormData = (file) => {
    const ext = file.name.split(".").at(-1);
    if (!validateFormat(type, ext)) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Upload Failed",
          message: `Invalid File Format`,
        })
      );
    }

    // Check file size (1MB = 1048576 bytes)
    if (file.size > convertMBToBytes(uploadLimit)) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Upload Failed",
          message: `File size exceeds ${getUnitBasedOnMB(uploadLimit)}`,
        })
      );
    }

    const formData = new FormData();

    if (file.type.includes("image")) {
      const quality = 1;
      useConvertFileToBase64(file, quality).then((imageDetails) => {
        if(uploadHandler){
          uploadHandler(imageDetails)
        }else{
          dispatch(uploadAsset(imageDetails));
        }
        closeDialog();
      });

      setUpdate(update + 1);
    } else {
      formData.append("file", file);
      formData.append("type", file.type);
      dispatch(uploadAsset(formData));
      closeDialog();
    }
  };

  const browseFileHandler = () => {
    uploadAssetRef.current.click();
  };
  const uploadImage = (e) => {
    const file = e.target.files[0];
    sendFormData(file);
  };

  // Drag & Drop Functionality
  const uploadOnDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    sendFormData(file);
  };

  const DragOverHandler = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add("dragOver");
  };
  const DragLeaveHandler = (e) => {
    e.currentTarget.classList.remove("dragOver");
  };

  return (
    <Main className="uploadPresentaion" isOpen={show} handleClose={closeDialog}>
      <Box className="dialogBody">
        <Typography variant="h3" className="uploadHead">
          {title}
        </Typography>
        <div className="divider"></div>
        <div
          className="uploadArea"
          onClick={browseFileHandler}
          onDragOver={DragOverHandler}
          onDragLeave={DragLeaveHandler}
          onDrop={uploadOnDrop}
        >
          <input
            type="file"
            name="uploadAsset"
            id="uploadAsset"
            hidden
            onChange={uploadImage}
            ref={uploadAssetRef}
          />
          <img src={upload} className="uploadImage" alt="upload" />
          <Typography textAlign="center" className="heading">
           Drag &amp; Drop
          </Typography>
          <Typography textAlign="center" className="subHeading">
            Select file from your computer here, Max file size is {getUnitBasedOnMB(uploadLimit)}
          </Typography>
        </div>
      </Box>
    </Main>
  );
};

export default Upload;

const validateFormat = (type, ext) => {
  const formats = assetsFormats[type]?.format;
  // console.log(type)
  // console.log(assetsFormats)
  // console.log(assetsFormats[type])
  if (!formats) {
    return false;
  }

  let shouldUpload = false;

  for (let i = 1; i < formats.length; i++) {
    if (formats[i].value.toLowerCase() === ext.toLowerCase()) {
      shouldUpload = true;
      break;
    }
  }

  return shouldUpload;
};

import React from 'react'
import './Textlimit.scss'
import TooltipWrapper from './Tooltip'
const TextLimit = ({text, width}) => {

  return (
    <TooltipWrapper title={text}><span className='textLimit' style={{maxWidth:width}}>{text}</span></TooltipWrapper>
  )
}

export default TextLimit
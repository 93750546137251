import React from "react";
import { useDispatch } from "react-redux";
import AccessControl from "src/components/presentations/AccessControl";
import { getSlide } from "src/store/actions/presentations";
import PreviewPage from "./PreviewPage";
import { useParams } from "react-router-dom";

const PresentationPreview = () => {
  return (
    <>
      <AccessControl component="view">
        <PreviewPage
          viewType="presentationView"
        />
      </AccessControl>
    </>
  );
};

export default PresentationPreview;

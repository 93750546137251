import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/sharing-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";

import {
  exportLandingPage,
  importLandingPage,
  uploadNewVersion,
  deleteLandingPage,
  cloneLandingPage,
  updateLandingPage,
} from "src/store/actions/microsites";

import { downloadPdfByImages, getImageSize } from "src/services/utils";

import { share } from "src/store/actions/sharing";

//Dialogs
import AddNew from "./AddNew";
import ExportLandingPage from "./Export";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import UploadNew from "../UploadNew";
import Confirm from "../Confirm";
import ReviewDelete from "../ReviewDelete";
import RenameDialog from "../RenameDialog";
import { setLandingpage } from "src/store/reducers/microsite-slice";
import { toDataURL } from "src/hooks/useUrlToBase64";
import CreateFolder from "../../folder/CreateFolder";
import ShareLinkDialog from "./ShareLinkDialog";
const MicrositesMyLibraryDialogs = (props) => {
  const dispatch = useDispatch();
  const type = "landingpage";
  const { company, _id: userId } = useSelector((state) => state.auth.userData);

  const { onClose, dialog, micrositeId, microsite } = props;
  const microsites = useSelector((state) => state.microsites.list);
  const micrositeIndex = microsites.findIndex(
    (microsite) => microsite._id === micrositeId
  );
  //on close function for closing dialogs

  //upload new microsite function
  const onUploadNewVersion = useCallback((data) => {
    dispatch(uploadNewVersion(data));
  }, []);

  //import new microsite
  const onUploadNew = useCallback((data) => {
    dispatch(importLandingPage(data));
  }, []);

  //export microsite
  const onExport = useCallback(async () => {
    const templateImageUrl = `${microsite.bucketURL}/${microsite.image}`;
    const imageSize = await getImageSize(templateImageUrl);
    const dataImage = await toDataURL({ src: templateImageUrl, type: "image" });
    downloadPdfByImages([dataImage], imageSize, microsite.name);
  }, [microsite]);

  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const cancelReviewEmail = useCallback(() => {
    dispatch(cancelReview({ id: micrositeId, type }));
    onClose();
  }, [micrositeId]);

  const micrositeShare = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: micrositeId,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      ).then((res) => {
        if (res.payload) {
          dispatch(
            setLandingpage({
              micrositeIndex,
              data: {
                isShared: res.payload.sharedWith.length || userIds.length,
              },
            })
          );
        }
      });
    },
    [micrositeId]
  );

  //assign microsite fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: micrositeId, data }));
    },
    [micrositeId]
  );

  const micrositeDelete = useCallback(async () => {
    await dispatch(deleteLandingPage(micrositeId));
    onClose();
  }, [micrositeId]);

  const handleClone = useCallback(async () => {
    await dispatch(cloneLandingPage(micrositeId));
    onClose();
  }, [micrositeId]);

  //rename presentation dialog
  const [renameLoading, setRenameLoading] = useState(false);
  const handleRenameMicrosite = (name) => {
    setRenameLoading(true);
    dispatch(
      updateLandingPage({ id: micrositeId, data: { name: name } })
    ).finally(() => {
      setRenameLoading(false);
      if (micrositeIndex > -1) {
        dispatch(
          setLandingpage({
            micrositeIndex: micrositeIndex,
            data: { name: name },
          })
        );
      }
      onClose();
    });
  };

  return (
    <>
      <CreateFolder show={dialog.createfolder} close={onClose} />
      <AddNew show={dialog.createNew} close={onClose} type={type} />
      <ExportLandingPage
        isOpen={dialog.export}
        exportAs="pdf"
        handleExport={onExport}
        handleClose={onClose}
        id={micrositeId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={micrositeId}
      />
      <DetailsDrawer
        type="microsite"
        open={props.dialog.info}
        onClose={onClose}
        details={microsite || {}}
      />
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={micrositeShare}
        shareType={type}
      />
      <ShareLinkDialog
        type={type}
        shareObjectId={micrositeId}
        version={microsite?.version}
        userId={userId}
        isOpen={dialog.shareLink}
        handleClose={onClose}
      />
      <SendForReview
        type={type}
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <UploadNew
        show={dialog.uploadNew}
        close={onClose}
        title="Upload From"
        type="Microsite"
        onUpload={onUploadNew}
        fieldsWidth={4}
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this landing page?"
        handleClose={onClose}
        handleConfirm={micrositeDelete}
      />
      <ReviewDelete
        isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}
      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel this review?"
        handleClose={onClose}
        handleConfirm={cancelReviewEmail}
      />
      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this microsite?"
        handleClose={onClose}
        handleConfirm={handleClone}
      />
      <RenameDialog
        isOpen={dialog.renameMicrosite}
        title="Microsite"
        handleClose={onClose}
        handleConfirm={handleRenameMicrosite}
        name={microsite && microsite.name}
        loading={renameLoading}
      />
    </>
  );
};

export default MicrositesMyLibraryDialogs;

import { createSlice } from "@reduxjs/toolkit";
import { getFoldersList, createFolder, deleteFolder, updateFolder, getFoldersHierarchy, getMoveFoldersHierarchy, getMoveableFoldersList } from "../actions/folder";

const initialState = {
  foldersList: [],
  moveableFoldersList: [],
  createLoading: false,
  getListLoading: false,
  getmoveableListLoading: false,
  deleteLoading: false,
  updateLoading: false,
  getHierarchyLoading: false,
  getMoveHierarchyLoading: false,
  folderbreadcrumbs: [],
  moveFolderBreadcrumbs: []
};
const folderSlice = createSlice({
  name: "Folder slice",
  initialState,
  extraReducers: {
    // Create Folder
    [createFolder.pending]: (state) => {
      state.createLoading = true;
    },
    [createFolder.fulfilled]: (state, action) => {
      state.createLoading = false;
      state.foldersList.unshift(action.payload)
    },
    [createFolder.rejected]: (state, action) => {
      state.createLoading = false;
    },

    // Get Folders List
    [getFoldersList.pending]: (state) => {
      state.getListLoading = true;
    },
    [getFoldersList.fulfilled]: (state, action) => {
      state.getListLoading = false;
      state.foldersList = action.payload || []
    },
    [getFoldersList.rejected]: (state, action) => {
      state.getListLoading = false;
    },

    // Get moveable Folders List
    [getMoveableFoldersList.pending]: (state) => {
      state.getmoveableListLoading = true;
    },
    [getMoveableFoldersList.fulfilled]: (state, action) => {
      state.getmoveableListLoading = false;
      state.moveableFoldersList = action.payload || []
    },
    [getMoveableFoldersList.rejected]: (state, action) => {
      state.getmoveableListLoading = false;
    },

    // Get Folders Hierarchy
    [getFoldersHierarchy.pending]: (state) => {
      state.getHierarchyLoading = true;
    },
    [getFoldersHierarchy.fulfilled]: (state, action) => {
      state.getHierarchyLoading = false;
      state.folderbreadcrumbs = action.payload || []
    },
    [getFoldersHierarchy.rejected]: (state, action) => {
      state.getHierarchyLoading = false;
    },
    // Get Move Folders Hierarchy
    [getMoveFoldersHierarchy.pending]: (state) => {
      state.getMoveHierarchyLoading = true;
    },
    [getMoveFoldersHierarchy.fulfilled]: (state, action) => {
      console.log('action.payload =>', action.payload);

      state.getHierarchyLoading = false;
      state.moveFolderBreadcrumbs = action.payload || []
    },
    [getMoveFoldersHierarchy.rejected]: (state, action) => {
      state.getMoveHierarchyLoading = false;
    },
    // Delete Folders
    [deleteFolder.pending]: (state) => {
      state.deleteLoading = true;
    },
    [deleteFolder.fulfilled]: (state, action) => {
      state.deleteLoading = false;
      state.foldersList = state.foldersList.filter(item => item._id != action.payload)
    },
    [deleteFolder.rejected]: (state, action) => {
      state.deleteLoading = false;
    },


    // Update Folders
    [updateFolder.pending]: (state) => {
      state.updateLoading = true;
    },
    [updateFolder.fulfilled]: (state, action) => {
      state.updateLoading = false;
      const index = state.foldersList.findIndex(item => item._id == action.payload?._id)
      if (index > -1) {
        state.foldersList[index] = action.payload;
      }
    },
    [updateFolder.rejected]: (state, action) => {
      state.updateLoading = false;
    },



  },
  reducers: {
    resetFolderbreadcrumbs: (state, action) => {
      state.folderbreadcrumbs = [];
    },
    resetMoveFolderbreadcrumbs: (state, action) => {
      state.moveFolderBreadcrumbs = [];
    },
    clearFolder: (state, action) => {
      state.foldersList = [];
    },
  }
});

export default folderSlice.reducer;
export const { resetFolderbreadcrumbs, resetMoveFolderbreadcrumbs, clearFolder } = folderSlice.actions;


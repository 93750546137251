import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/sharing-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { getSettings, removeSharingUser } from "src/store/actions/sharing";
import { sharingData, roles } from "src/services/utils";
import { share } from "src/store/actions/sharing";
import {
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

import SharingDialog from "src/components/dialogs/SharingSettings";
import DropDown from "../ui/DropDown";
import { IoAdd, IoEllipsisVerticalSharp, IoPencil, IoPulse, IoTrash } from "react-icons/io5";
import { useLocation } from "react-router";

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const SharingSettings = (props) => {

  const getPath = () => {
    if (window.location.pathname.includes('presentation')) {
      return "presentation"
    }
    if (window.location.pathname.includes('email')) {
      return "email"
    }
    if (window.location.pathname.includes('microsite')) {
      return "microsite"
    }
  }
  const path = getPath()
  const dispatch = useDispatch();
  const [sharingSettingsUserList, setSharingSettingsUserList] = useState([]);
  const [sharingDialog, setsharingDialog] = useState(false);
  const [userRoleEdit, setUserRoleEdit] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(props.initialRows);

  const { sharingSettings } = useSelector((state) => state.sharing);
  const { company, _id: userId } = useSelector((state) => state.auth.userData);
  const data = useSelector((state) => state[`${path}s`]);
  const { type, id } = props;
  const createdBy = data[`${path == "microsite" ? "landingPage" : path}`].createdBy
  const createdById = createdBy?._id


  useEffect(() => {
    if (sharingSettings && createdById) {
      const presentationOwnerDetails = { _id: createdById, role: 'owner', userId: createdBy }
      setSharingSettingsUserList([...sharingSettings, presentationOwnerDetails])
    }
  }, [sharingSettings, createdById]);

  useEffect(() => {
    dispatch(getSettings({ type, id }));
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    if (isAsc) {
      setOrder("desc");
    } else if (order === "desc") {
      setOrderBy("");
      setOrder("asc");
    } else {
      setOrderBy(property);
      setOrder("asc");
    }
  };

  const sharePresentation = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });
      dispatch(
        share({
          id: props.id,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      )
    },
    [props.id]
  );

  const getCompanyUsers = () => {
    dispatch(getAllCompanyUsers(company.companyId));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - sharingSettingsUserList.length)
      : 0;

  const dialogClose = () => {
    setsharingDialog(false);
    setUserRoleEdit({})
  };

  const openDialog = () => {
    setsharingDialog(true);
    setUserRoleEdit({})
  };

  const removeUser = async (user) => {
    await dispatch(removeSharingUser({ id: props.id, userId: user._id, type }))
    dispatch(getSettings({ type, id }))
  }
  const dropDownData = (user) => {
    return [
      {
        label: "Edit",
        icon: <IoPencil />,
        onClick: () => {
          setsharingDialog(true);
          setUserRoleEdit(user)
        },
      },
      {
        label: "Delete",
        icon: <IoTrash />,
        onClick: () => {
          removeUser(user)
        },
      },
    ];
  };
  return (
    <>
      <SharingDialog
        isOpen={sharingDialog}
        editUserRole={userRoleEdit}
        handleClose={dialogClose}
        userId={userId}
        onInput={getCompanyUsers}
        onShare={sharePresentation}
      />
      <Grid container className="padding" alignItems="center">
        <Grid item xs={11}>
          <p>Users</p>
        </Grid>
        {(userId == createdById || false) && <Grid item xs={1} className="text-right">
          <IconButton onClick={openDialog}> <IoAdd></IoAdd> </IconButton>
        </Grid>}
      </Grid>
      <TableContainer>
        <Table aria-labelledby="tableTitle">
          {/* Table Header Starts*/}
          <TableHead>
            <TableRow>
              {sharingData.map((headCell) => (
                <TableCell
                  sx={{ textOverflow: "ellipsis" }}
                  key={headCell.id}
                  align="left"
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.sort ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                      className="tableHead"
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
              <TableCell className="tableHead" sx={{ width: "10%" }} align="right">
                ACTIONS
              </TableCell>

            </TableRow>
          </TableHead>
          {/* Table Header Endes*/}

          {/* Table Body Starts*/}
          <TableBody>
            {stableSort(sharingSettingsUserList, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sharingSettingsUserList, index) => {
                return (
                  <TableRow padding="normal" hover tabIndex={-1} key={index}>
                    <TableCell align="left" className="tableBody">
                      {sharingSettingsUserList.userId?.firstName}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {sharingSettingsUserList.userId?.lastName}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {roles[sharingSettingsUserList.role]}
                    </TableCell>
                    <TableCell align="left" className="tableBody">
                      {sharingSettingsUserList.userId?.email}
                    </TableCell>
                    <TableCell align="right">
                      {(sharingSettingsUserList._id !== createdById) &&
                        <DropDown
                          dropIcon={<IoEllipsisVerticalSharp size={16} />}
                          tooltip="options"
                          items={dropDownData(sharingSettingsUserList)}
                        />
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {/* Table Body Starts*/}
        </Table>
      </TableContainer>
      {/* Table Pagination*/}
      <TablePagination
        rowsPerPageOptions={[
          5,
          10,
          25,
          { label: "All", value: sharingSettingsUserList.length },
        ]}
        component="div"
        count={sharingSettingsUserList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default React.memo(SharingSettings);

SharingSettings.defaultProps = {
  initialRows: 10,
};

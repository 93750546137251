import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Tooltip, Box, Stack, IconButton, CircularProgress } from "@mui/material";
import {
  IoEye,
  IoPencil,
  IoDocument,
  IoShare,
  IoTrash,
  IoEllipsisVerticalSharp,
  IoCopyOutline,
} from "react-icons/io5";
import Confirm from "src/components/dialogs/Confirm";
import DropDown from "src/components/ui/DropDown";
import { useDispatch } from "react-redux";
import { cloneSlide, deleteSlide } from "src/store/actions/presentations";
import { hideOnStatus } from "src/services/utils";
import { toDataURL } from "src/hooks/useUrlToBase64";
import PermissionComp from "src/components/Permission";

const SlideControls = (props) => {
  //dropdown handler
  //to close dropdown on click we need to return true on each dropdown handler
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCloneLoading, setIsCloneLoading] = useState(false);

  const {
    presentationId,
    chapterId,
    chapterIndex,
    status,
    slide,
    slideIndex,
    imageURL,
    thumbnailURL,
    isLock,
    slidesCount
  } = props;

  const { _id: slideId, name: slideName, isEnabled } = slide;

  const [isOpen, setisOpen] = useState(false);
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);

  const handleClose = () => {
    setisOpen(false);
  };

  const handleCloneOpen = () => {
    setCloneDialogIsOpen(true);
  };

  const handleCloneClose = () => {
    setCloneDialogIsOpen(false);
  };

  const deleteHandle = () => {
    setisOpen(true);
  };

  const slideDelete = () => {
    dispatch(deleteSlide({ presentationId, slideId, chapterId }));
    return true;
  };

  const slideClone = async () => {
    // showing local loading
    setIsCloneLoading(true);
    const res = await Promise.allSettled([
      toDataURL({
        src: imageURL,
        type: 'image'
      }),
      toDataURL({
        src: thumbnailURL,
        type: 'image'
      }),
    ]);

    setIsCloneLoading(false);
    const [{ value: image }, { value: thumbnail }] = res;

    // global loader will show here
    dispatch(
      cloneSlide({
        presentationId,
        slideId,
        chapterIndex,
        slideIndex,
        slideName,
        image,
        thumbnail,
      })
    );

    return true;
  };

  const dropDownData = [
    // { label: "Share Presentation", icon: <IoShare />, onClick: shareHandler },
    { label: "Delete", icon: <IoTrash />, onClick: deleteHandle },
  ];

  //other handler will be create here
  return (
    <>
      <Confirm
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={slideDelete}
        title="Are you sure"
        subtitle="Are you sure you want to delete this slide of chapter?"
      />
      <Confirm
        isOpen={cloneDialogIsOpen}
        handleClose={handleCloneClose}
        handleConfirm={slideClone}
        variant="success"
        title="Are you sure"
        subtitle="Are you sure you make clone for this slide?"
      />
      <Grid item md={8}>
        <Stack direction="row" spacing="1">
          {isEnabled && (
          <Tooltip title="View" arrow>
            <IconButton
              variant="outlined"
              onClick={() =>
                navigate(`/presentation/${presentationId}/view/${slideId}`)
              }
            >
              <IoEye />
            </IconButton>
          </Tooltip>
          )}
          {!hideOnStatus?.[status]?.edit && (
            <Tooltip title="Edit" arrow>
            <IconButton
              variant="outlined"
              onClick={() =>
                navigate(`/presentation/${presentationId}/edit/${slideId}`)
              }
            >
              <IoPencil />
            </IconButton>
            </Tooltip>
          )}

          {/* for clone slide */}
          <PermissionComp permissionPath="presentation.create.clone">
            <Tooltip title="Clone" arrow>
              <IconButton variant="outlined" onClick={handleCloneOpen}>
                {isCloneLoading ? (
                  <Box sx={{ width: "100%" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <IoCopyOutline />
                )}
              </IconButton>
            </Tooltip>
          </PermissionComp>
          

          {/* <IconButton variant="outlined">
            <IoDocument />
          </IconButton> */}
        </Stack>
      </Grid>
      <Grid item md={4}>
      <Box style={{ textAlign: "right" }}>
          <DropDown
            tooltip="More"
            dropIcon={<IoEllipsisVerticalSharp color={isLock?"#ddd":""}/>}
            items={dropDownData}
            disabled={isLock}
          />
        </Box>
      </Grid>
    </>
  );
};
export default SlideControls;

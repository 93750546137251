import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import Main from "../dialogs/Main";
import Input from "src/components/ui/formElements/Input";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { createFolder, updateFolder } from "src/store/actions/folder";
import { getModuleType } from "src/utils/Utils";
import { useSearchParams } from 'react-router-dom';

const CreateFolder = (props) => {
  const { show, close, editing } = props;
  const [name, setName] = useState('');
  const [error, setError] = useState(''); 
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { createLoading } = useSelector((state) => state.folders);

  const handleSubmit = (e) => {
    e?.preventDefault();

    
    if (!name.trim()) {
      setError('Folder name is required');
      return;
    }
    setError('');

    if (editing) {
      dispatch(updateFolder({ data: { name }, id: editing._id })).then(() => closeDialog());
    } else {
      const moduleType = getModuleType();
      const parentId = searchParams.get('folderId');
      dispatch(createFolder({ name, parentId, type: moduleType })).then(() => closeDialog());
    }
  };

  useEffect(() => {
    if (editing) {
      setName(editing.name);
    }
  }, [editing]);

  const onInputChange = (e) => {
    const value = e?.target?.value || '';
    setName(value);
    if (error && value.trim()) {
      setError(''); 
    }
  };

  const closeDialog = () => {
    setName('');
    setError(''); 
    close();
  };

  return (
    <Main
      isOpen={show}
      handleClose={closeDialog}
      background="#fff"
      modalWidth="xs"
      zIndex={1305}
    >
      <Box textAlign="center" p={3}>
        <Typography variant="h1" component="h1">
          {editing ? "Rename Folder" : "Create Folder"}
        </Typography>

        <Input
          value={name}
          onChange={onInputChange}
          placeholder="Enter folder name"
          sx={{ m: 1 }}
          name="name"
          className="border"
          limit="50"
          error={!!error} 
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={1}
        >
          <Button
            variant="outlined"
            startIcon={<IoCloseOutline />}
            onClick={closeDialog}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="success"
            endIcon={<IoCheckmarkOutline />}
            sx={{ mt: 5 }}
            onClick={handleSubmit} 
            loading={createLoading}
            disabled={createLoading}
          >
            {editing ? "Update" : "Create"}
          </Button>
        </Stack>
      </Box>
    </Main>
  );
};

export default React.memo(CreateFolder);

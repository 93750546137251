import React from "react";
import { Box, Button, IconButton } from "@mui/material";
import { BiChevronDown, BiMinus, BiPlus} from "react-icons/bi";
import DropDown from "src/components/ui/DropDown";
import "./ZoomControls.scss";
const ZooomControls = ({zoom, zoomIn, zoomOut, changeZoom}) => {
  return (
    <Box className="zoomControls">
      <DropDown dropText={`${zoom}%`} className="variant1" dropIcon={<BiChevronDown />} id="zoomDropdown">
        <Box className="zoomElements">
          <Box className="item">
            <IconButton onClick={(e)=>{
               e.stopPropagation();
               zoomIn()
            }}>
              <BiMinus />
            </IconButton>
            <input type="number" value={zoom} onChange={(e)=>{changeZoom(e.target.value)}} />
            <IconButton onClick={zoomOut}>
               <BiPlus />
            </IconButton>
          </Box>
          <Box className="item">
            <Button size="medium" variant="text" onClick={()=> changeZoom('scaleToFit')}>
               Reset
            </Button>
          </Box>
        </Box>
      </DropDown>
      {/* button to click by js */}
      <Button size="medium" variant="text" onClick={()=> changeZoom('scaleToFit')} id="resetZoom" sx={{display:'none'}}>
        Reset
      </Button>
    </Box>
  );
};
export default React.memo(ZooomControls);

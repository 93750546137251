import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//MUI Componenets
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
//APIs
import { fetchLandingPage,updateLandingPage } from "src/store/actions/microsites";
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import LandingPageCard from "src/components/microsites/LandingPageCard";
import Permission from "src/components/Permission"

//Dialogs
import MicrositesMyLibraryDialogs from "src/components/dialogs/microsites/MicrositesMyLibrary-Dialogs";
import { clearFolder } from "src/store/reducers/folder-slice";
import { removeMicrositeFromUi } from "src/store/reducers/microsite-slice";
import FolderList from "src/components/folder";
import Breadcrumbs from "src/components/folder/FolderBreadcrumbs";
import MoveDialog from "src/components/folder/MoveDialog";

const MyLibrary = () => {
  const dispatch = useDispatch();
  const {
    list: microsites,
    count,
    loadingMsg,
  } = useSelector((state) => state.microsites);
  
  const [searchParams] = useSearchParams();
  const folderId = searchParams.get("folderId") || null;
  const [details, setDetails] = useState({});
  const [micrositeId, setMicrositeId] = useState("");
  const [moveableItem, setMoveableItem] = useState(null);
  const [fetchMicrositesLoading, setFetchMicrositesLoading] = useState(false);

  //Dialogs State
  const [dialog, setDialog] = useState({});

  const limit = 12;
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;

    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;

    setFetchMicrositesLoading(true)
    dispatch(fetchLandingPage(params)).then(()=>{
      setFetchMicrositesLoading(false)
    })

    return () => {
      dispatch(clearFolder())
    }
  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create Folder",
        icon: <IoCreate />,
        onClick: () => {
          openDialog("", "createfolder");
        },
      },
      {
        label: "Create New",
        icon: <IoCreate />,
        permissionPath: "microsites.create.createViaTemplate",
        onClick: () => {
          openDialog("", "createNew");
        },
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        permissionPath: "microsites.create.uploadZipFile",
        onClick: () => {
          openDialog("", "uploadNew");
        },
      },
    ];
  }, []);

  const handleMoveMicrosite = (selectedFolder = null) => {
    dispatch(
      updateLandingPage({
        data: { folderId: selectedFolder },
        id: moveableItem?._id,
      })
    ).then(() => {
      setMoveableItem();
      dispatch(removeMicrositeFromUi(moveableItem?._id))
    });
  };
  //microsite info
  const showDetails = useCallback((landingPage) => {
    setDialog({ info: true });
    setDetails(landingPage);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name, landingPage) => {
    setMicrositeId(id);
    setDialog({ [name]: true });
    setDetails(landingPage)
  }, []);

  return (
    <>
      {/* Dialogs */}
      <MicrositesMyLibraryDialogs
        dialog={dialog}
        microsite={details}
        micrositeId={micrositeId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}

     {/* Header */}
     <div className="headersection">
        <Breadcrumbs count={count} />
          <Permission permissionPath="microsites.create">
            <DropDown
              dropText="Add New"
              tooltip="options"
              startIcon={<IoAdd />}
              items={dropDownData}
            />
          </Permission>
          </div>
          <FolderList items={microsites.length} />

          {(!microsites.length && !fetchMicrositesLoading && !folderId) && (
        <Typography variant="h1" pl={3} pt={2}>
          No Microsite Found
        </Typography>
      )}
      {microsites.length > 0 && (
        <Grid container spacing={1.5} >
            {microsites.map((microsite) => {
              if (microsite.folderId == folderId)
              return (
                <LandingPageCard
                  key={microsite._id}
                  microsite={microsite}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role="owner"
                  id={microsite._id}
                  setMoveableItem={setMoveableItem}
                  />
                );
              return "";
            })}
          </Grid>
        )}

        {!fetchMicrositesLoading && (
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchLandingPage(value));
            }
          }}
        />
      )}
       <MoveDialog
        show={moveableItem}
        close={() => setMoveableItem()}
        moveableItem={moveableItem}
        onSubmit={handleMoveMicrosite}
      />
    </>
  );
};
export default React.memo(MyLibrary);

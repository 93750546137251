import React from 'react';
import { Breadcrumbs as MuiBreadcrumbs, Link, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetFolderbreadcrumbs } from 'src/store/reducers/folder-slice';

const FolderBreadcrumbs = ({count}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { folderbreadcrumbs } = useSelector((state) => state.folders);

  const openFolder = (_id) => {
    searchParams.set('folderId', _id);
    setSearchParams(searchParams);
  };
  const handleLibraryClick = () => {
    if (folderbreadcrumbs.length > 0) {
      searchParams.delete('folderId');
      setSearchParams(searchParams);
      dispatch(resetFolderbreadcrumbs());
    }
  };

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {folderbreadcrumbs.length > 0 ? (
        <Link component="button" color="inherit" onClick={handleLibraryClick}>
          My Library
        </Link>
      ) : (
        <Typography color="text.primary">My Library</Typography>
      )}
      {folderbreadcrumbs.map((folder, index) => {
        const isLast = index === folderbreadcrumbs.length - 1;

        return isLast ? (
          <Typography color="text.primary" key={folder._id}>
            {folder.name}
          </Typography>
        ) : (
          <Link
            component="button"
            color="inherit"
            onClick={() => openFolder(folder._id)}
            key={folder._id}
          >
            {folder.name}
          </Link>
        );
      })}

      <h4>Items ({count})</h4>
    </MuiBreadcrumbs>
  );
};

export default FolderBreadcrumbs;

import React from "react";
import Drawer from "./Drawer";
import { Link } from "react-router-dom";
import divider from "../../assets/images/logo-underline.png";
import { Typography, Grid, Button,Box } from "@mui/material";
import { dateFormat } from "src/services/utils";
import "../../styles/presentations/MyLibraryDetails.scss";

const DetailsDrawer = (props) => {
  const { open, onClose, details, className, type } = props;
  const { bucketURL, thumbnail } = details || {};

  return (
    <Drawer open={open} onClose={onClose} className={className}>
      <Grid container display="flex" height="100%">
        <Grid item md={12} flex={1}>
        <Box className="image-wrap-micro">
          <img src={`${bucketURL}/${thumbnail}`} alt={`${type} image`} />
          </Box>
          <Typography variant="h1" pt={"12px"} pb={"7px"}>
            {details?.name}
          </Typography>
          <img src={divider} alt="divide Image" />
          <Grid container mb={"10px"}>
            <Grid item md={6}>
              <Typography className="title">Created On</Typography>
              <Typography className="data">
                {dateFormat(details?.createdAt)}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="title">Last Updated</Typography>
              <Typography className="data">
                {dateFormat(details?.updatedAt)}
              </Typography>
            </Grid>
          </Grid>
          <Grid container mb={"10px"}>
            <Grid item md={6}>
              <Typography className="title">Version</Typography>
              <Typography className="data">{details?.version}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="title">Status</Typography>
              <Typography className="data">{details?.status}</Typography>
            </Grid>
          </Grid>
          <Grid container mb={"10px"}>
            <Grid item md={6}>
              <Typography className="title">Language</Typography>
              <Typography className="data">{details?.lang}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="title">Country</Typography>
              <Typography className="data">{details?.country}</Typography>
            </Grid>
          </Grid>

          <Grid container mb={"10px"}>
            <Grid item md={6}>
              <Typography className="title">Created By</Typography>
              <Typography className="data">{`${details?.createdBy?.firstName} ${details?.createdBy?.lastName}`}</Typography>
            </Grid>
            <Grid item md={6}>
              <Typography className="title">Target Device</Typography>
              <Typography className="data">{details?.targetDevice}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12} textAlign="end">
          <Button
            component={Link}
            to={`/${type}/${details?._id}/details`}
            variant="contained"
          >
            Show Details
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default React.memo(DetailsDrawer);

DetailsDrawer.defaultProps = {
  className: "presentationDetailPane",
};

import React from "react";
import { Box } from "@mui/material";
import underline from "../../assets/images/logo-underline.png";
import SidebarItems from "./SidebarItems";
import { IconButton } from "@mui/material";
import { IoReturnDownBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import CompanyLogo from "src/components/company/CompanyLogo";
import Divider from "src/components/ui/Divider";
import "./Sidebar.scss";
const Sidebar = ({ items, classes, logo, type, menuToggleHandler }) => {
  return (
    <div className={`sidebar ${classes}`}>
      <Box className="toggleButton">
        <IconButton
          onClick={() => {
            menuToggleHandler();
          }}
          size="small"
          sx={{ position: "absolute", top: "0px", right: "15px" }}
        >
          <IoReturnDownBack color="black"></IoReturnDownBack>
        </IconButton>
      </Box>
      <Box className="logo-container">
        <Link to={`/${type ? type : "presentations"}/my-library`}>
          <img src={logo} className="logo" />
        </Link>
        <Divider sx={{ my: 1 }} />
      </Box>
      <ul className="menu">
        <SidebarItems items={items} />
      </ul>
      <Box sx={{ marginTop: "auto" }}>
        <Divider sx={{ mb: 1.5 }} />
        <CompanyLogo sx={{ maxWidth: "70%", marginInline: "auto" }} />
      </Box>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Avatar,
  Stack,
  Button,
  Grid,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  Box,
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { uiActions } from "src/store/reducers/ui-slice";
import { changePassword } from "src/store/actions/auth";

import Main from "src/components/dialogs/Main";
import Input from "src/components/ui/formElements/Input";
import "../../../src/styles/profile.scss";
import { updateUserData } from "src/store/actions/auth";
import Card from "src/components/ui/Card";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import UsersForm from "../users/UsersForm";
import ViewProfile from "./ViewProfile";

const EditProfile = () => {
  const { userData } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const { email } = userData;
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [password, setPassword] = useState({
    new: "",
    confirm: "",
  });
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    reNew: false,
  });

  const userDataUpdate = () => {
    dispatch(updateUserData({ userInfo, userId: userData._id }));
  };

  const handleChangeInfo = (e) => {
    setUserInfo((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const handleClickShowPassword = (input) => {
    setShowPassword({ ...showPassword, [input]: !showPassword[input] });
  };

  const handleChangePassword = (e) => {
    setPassword((pre) => ({ ...pre, [e.target.name]: e.target.value }));
  };

  const passwordChange = () => {
    if (password.new.trim().length < 8) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Invalid New Password",
        })
      );
    } else if (password.confirm.trim().length < 8) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Invalid Confirm Password",
        })
      );
    }

    if (password.new !== password.confirm) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid",
          message: "Both password are different.",
        })
      );
    }

    dispatch(changePassword({ password: password.new }));
    setIsOpen(false);
  };
  return (
    <>
      <ViewProfile isEditProfile={true}></ViewProfile>
      <Box sx={{mt:3}}>
      <UsersForm cancelPath="/profile/view"  isEditProfile />
      </Box>
    </>
  )
};

export default EditProfile;

import { useCallback, useEffect, useState } from "react";

// Custom hook to debounce user input
export const useDebouncedCallback = (callback, delay=500) => {
   const [timer, setTimer] = useState(null);
 
   const debouncedCallback = useCallback(
     (value) => {
       if (timer) clearTimeout(timer);
       const newTimer = setTimeout(() => {
         callback(value);
       }, delay);
       setTimer(newTimer);
     },
     [callback, delay, timer]
   );
 
   // Cleanup the timer when component unmounts or delay changes
   useEffect(() => {
     return () => clearTimeout(timer);
   }, [timer]);
 
   return debouncedCallback;
 };
import { styled } from "@mui/material";
import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, width, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: `${width}px` || "300px", // Use width prop or default to 300px
  },
}));

const TooltipWrapper = ({ title, width, children }) => {
  return (
    <CustomWidthTooltip title={title} arrow width={width}>
      {children}
    </CustomWidthTooltip>
  );
};

export default TooltipWrapper;

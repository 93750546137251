import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { emailValidator } from "src/services/editor.service";
import Info from "../ui/Info";

const UserList = (props) => {
  const { onChange, selectedUsers, userList } = props;
  //data states
  const [options, setOptions] = useState([]);
  const [userInputValue, setUserInputValue] = useState("");
  //helper states
  const [open, setOpen] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const textFieldHandler = (userInputValue) => {
    setUserInputValue(userInputValue);
    setIsValidEmail(true);
  };

  return (
    <>
      <Autocomplete
        multiple
        disablePortal
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        // loading={loading}
        onChange={(_, val) => onChange(val)}
        onInputChange={(_, val) => textFieldHandler(val)}
        options={userList}
        value={selectedUsers}
        isOptionEqualToValue={(option, value) => value.email === option.email}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        renderOption={(props, option) => (
          <Box component="li" {...props} key={option._id}>
            <Box>
              <Typography className="option-label">
                {option.firstName} {option.lastName}
              </Typography>
              <Typography>{option.email}</Typography>
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            value={userInputValue}
            variant="standard"
            className="user-input"
            classes={{ input: "search-input" }}
            placeholder="Search for a user"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                const email = e.target.value;
                if (emailValidator(email)) {
                  const newCustomValue = {
                    email: email,
                    _id: Date.now().toString(), // Generate a unique ID for the custom entry
                    firstName: email,
                    lastName: "",
                    externalUser: true,
                  };
                  setOptions((prevOptions) => [...prevOptions, newCustomValue]);
                  onChange([...selectedUsers, newCustomValue]);
                  e.target.value = ""; // clear input
                  setIsValidEmail(true);
                } else {
                  setIsValidEmail(false);
                }
                setOpen(false);
              }
            }}
          />
        )}
        filterSelectedOptions
      />
      {!isValidEmail && <Info>Custom email is not valid</Info>}
    </>
  );
};

export default UserList;

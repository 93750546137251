import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { useForm } from "react-hook-form";
import InputFields from "../ui/formElements/InputFields";
import { useDispatch } from "react-redux";
import { sharedAuthenticate } from "src/store/actions/shareLink";
import Divider from "../ui/Divider";
import { useParams } from "react-router-dom";
import useAuthShareLink from "src/pages/sharedLink/useAuthSharedLink";
import { setLogin } from "src/store/reducers/sharelink-slice";

const AuthPasswordForm = () => {
  const { linkId } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    control,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: { password: "" },
  });

  const { addShareLinkId } = useAuthShareLink();
  const password = watch("password");
  const loginUserHandler = () => {
    setIsLoading(true);
    dispatch(sharedAuthenticate({ id: linkId, password }))
      .then((res) => {
        setIsLoading(false);
        const hashDetails = localStorage.getItem("sharedLinkAuthTempObj");
        addShareLinkId(JSON.parse(hashDetails));
        dispatch(setLogin());
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <form style={{ width: "clamp(250px, 30vw + -100px, 500px)" }}>
      <Box className="AuthPasswordForm">
        <Typography variant="h6" className="sign-in-text">
          Authenticate
        </Typography>
        <Typography variant="body2">
          Required password to view this page, check your email to get password
        </Typography>
        <Divider sx={{ m: 2 }}></Divider>
        <InputFields
          fieldName="password"
          label="Password"
          control={control}
          rules={{
            required: "Password is required",
          }}
          error={errors?.password}
          type="password"
        />

        <Box className="pt-4">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ letterSpacing: "1.2px" }}
            onClick={handleSubmit(loginUserHandler)}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ marginLeft: 1 }} />
            ) : (
              "Continue"
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default AuthPasswordForm;

import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Icons
import { IoShare, IoTrash, IoOpen, IoPencil } from "react-icons/io5";
import { MdCloudUpload, MdFileDownload } from "react-icons/md";
import { IoIosDownload } from "react-icons/io";
import { BsTablet } from "react-icons/bs";
import { AiFillLike } from "react-icons/ai";

//APIs
import {
  clonePresentation,
  downloadSource,
} from "src/store/actions/presentations";
import { setPreiewPresentation } from "src/store/reducers/presentations-slice";

//helpers
import { getFirstSlideId } from "src/services/utils";

import ProjectCard from "src/components/common/ProjectCard";
import PresentationCardControls from "./PresentationCardControls";
import { useNavigate } from "react-router-dom";
import { BiSolidEdit } from "react-icons/bi";
import OpenWithIcon from '@mui/icons-material/OpenWith';

const PresentationCard = (props) => {
  const { presentation, showDetails, openDialog, role, setMoveableItem } = props;

  let presentationImage = presentation.thumbnail;
  //if 1 side found in presentaiton then use that slide thumbnail
  if (presentation?.chapters?.length > 0 && presentation.chapters[0].slides[0]) {
    presentationImage = presentation.chapters[0].slides[0].thumbnail
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { roles } = useSelector((state) => state.roles);

  const openPreviewHandler = useCallback((presentationId) => {
    dispatch(setPreiewPresentation({ presentationId, isOpen: true }));
  }, []);

  const downloadHandler = useCallback((id, type) => {
    dispatch(downloadSource({ id, type }));
  }, []);

  const publishHandler = useCallback((id) => {
    navigate(`/presentation/${id}/publish`);
  }, []);

  const handleClone = useCallback((id) => {
    openDialog(id, "clone");
  }, []);

  //dropdown on card options
  const cardItemDropData = useCallback((role, status, device) => {
    const dropData = [
      {
        label: "Share Presentation",
        icon: <IoShare />,
        onClick: (id) => {
          openDialog(id, "share");
        },
        permissionPath: 'share'
      },
      {
        label: "Upload New Version",
        icon: <MdCloudUpload />,
        onClick: (id) => {
          openDialog(id, "uploadNewVersion");
        },
        permissionPath: 'presentation.versions'
      },
      {
        label: "Download Source",
        icon: <MdFileDownload />,
        onClick: (id) => {
          downloadHandler(id, "sourcecode");
        },
        permissionPath: 'presentation.export.downloadSourceCode'
      },
      {
        label: "Download Build",
        icon: <MdFileDownload />,
        onClick: (id) => {
          downloadHandler(id, "build");
        },
        permissionPath: 'presentation.export.downloadBuild'
      },
      {
        label: "Export",
        icon: <IoIosDownload />,
        onClick: (id) => {
          openDialog(id, "export", device);
        },
      },
      {
        label: "Edit Details",
        icon: <BiSolidEdit />,
        onClick: (id) => {
          openDialog(id, "updatePresentationDetails", null, presentation);
        },
      },
      {
        label: "Move To",
        icon: <OpenWithIcon />,
        onClick: () => {
          setMoveableItem(presentation);
        },
        disabled: presentation.isLock
      },
      {
        label: "Delete",
        icon: <IoTrash />,
        onClick: (id) => {
          if (status === "reviewed" || status === "inReview") {
            openDialog(id, "reviewdelete");
          } else {
            openDialog(id, "delete");
          }
        },
      },
      {
        label: "Rename",
        icon: <IoPencil />,
        onClick: (id) => {
          openDialog(id, "renamePresentation", null, presentation);
        },
      },

    ];

    const sendReview = {
      label: "Send for Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "review");
      },
      permissionPath: "approvalProcess"
    };

    const cancelReview = {
      label: "Cancel Review",
      icon: <AiFillLike />,
      onClick: (id) => {
        openDialog(id, "cancelReview");
      },
      permissionPath: "approvalProcess"
    };

    const publish = {
      label: "Publish",
      icon: <IoOpen />,
      onClick: publishHandler,
    };

    if (status !== "reviewed") {
      if (status === "inReview") {
        dropData.splice(1, 1);
        dropData.unshift(cancelReview);
      } else {
        dropData.unshift(sendReview);
      }
    }

    if (status == "reviewed") {
      dropData.splice(1, 1);

    }

    //Publish Option Code
    // if (roles[role]?.publish) {
    //   dropData.push(publish);
    // }

    return dropData;
  }, []);

  let masterTemplateThumbnailURL = presentation.masterTemplateUrl ? `${presentation.bucketURL}/${presentation.masterTemplateUrl}` : null
  return (
    <ProjectCard
      key={presentation._id}
      id={presentation._id}
      image={`${presentation.bucketURL}/${presentationImage}`}
      masterTemplateThumbnailURL={masterTemplateThumbnailURL}
      title={presentation.name}
      subtitle={`v${presentation.version} ${presentation.lang}`}
      description={presentation.description}
      status={presentation.status}
      targetDevice={presentation.targetDevice}
      onDoubleClick={() =>
        navigate(`/presentation/${presentation._id}/structure`)
      }
      isShared={presentation.isShared}
      classes="presentationCard"
      Controls={
        <PresentationCardControls
          id={presentation._id}
          status={presentation.status}
          clonePresentation={() => handleClone(presentation._id)}
          toggleDetails={() => showDetails(presentation)}
          slideId={() => getFirstSlideId(presentation?.chapters)} //to open first slidee
          dropDownData={cardItemDropData(role, presentation.status, presentation.targetDevice)}
          previewPresentation={() => openPreviewHandler(presentation._id)}
          role={role}
        />
      }
    />
  );
};
export default React.memo(PresentationCard);

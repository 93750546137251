import React from "react";
import ViewMicrosite from "./ViewMicrosite";
import { useNavigate, useParams } from "react-router-dom";

export const View = () => {
  const { linkId } = useParams();
  let sharedAuth = localStorage.getItem("sharedAuth");
  sharedAuth = JSON.parse(sharedAuth);
  const hashDetails = sharedAuth.find((auth) => auth.SharedLinkId === linkId);
  return (
    <div className="sharedPreview">
      {hashDetails && hashDetails.type === "landingpage" && (
        <ViewMicrosite id={hashDetails.id} UID={hashDetails.UID} />
      )}
    </div>
  );
};

import { Typography } from "@mui/material";
import React from "react";
import { FcExpired } from "react-icons/fc";
import Centered from "src/layouts/Centered";
const ExpiredLink = () => {
  return (
    <Centered>
      <div className="content" style={{textAlign:'center'}}>
        <FcExpired fontSize={100}></FcExpired>
        <Typography variant="h1" component="h1">
          Expired link
        </Typography>
        <Typography variant="body2" component="p">
          The link is expired, please contact to your link provider
        </Typography>
      </div>
    </Centered>
  );
};

export default ExpiredLink;

import { toJpeg, toPng } from "html-to-image";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { csRenderFunctional } from "src/components/editor/editorLogics/components";
import { rotatable } from "src/components/editor/editorLogics/rotatable";
import { toDataURL } from "src/hooks/useUrlToBase64";
import { getRandomId, toCamelCase } from "./utils";
import { useSetLayerHeight } from "src/components/editor/editorLogics/components";
import { HISTORY_ACTIONS } from "src/data/contants";
import { getElementData } from "src/hooks/editor/shared-logic";
import { formatHTML, removePx } from "src/utils/Utils";
export const covertStyleStrToObject = (strStyle) => {
  //return true;
  if (strStyle != null) {
    const style = {};
    strStyle.split(";").forEach((el) => {
      const [property, value] = el.split(":");
      if (!property) return;
      //helper function use in this file
      const formatStringToCamelCase = (str) => {
        const splitted = str.split("-");
        if (splitted.length === 1) return splitted[0];
        return (
          splitted[0] +
          splitted
            .slice(1)
            .map((word) => word[0].toUpperCase() + word.slice(1))
            .join("")
        );
      };
      const formattedProperty = property.trim();
      style[formattedProperty] = value.trim();
    });

    return style;
  }
};

// export const useShiftKey = () => {
//   document.addEventListener("keydown", function (e) {
//     if (e.key == "Shift") {
//       return false;
//     } else {
//       return true;
//     }
//   });
// };

export const findELementIndex = (array, attr, value) => {
  for (var i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i;
    }
  }
  return -1;
};
export const converObjectToStyleStr = (cssObject) => {
  let style = "";
  Object.keys(cssObject).map(function (key, index) {
    style += key + ":" + cssObject[key] + ";";
  });
  return style;
};
function capitalize(s)
{
    return s && s[0].toUpperCase() + s.slice(1);
}
//avoid this as much as possible because of loop
export const inlineStyleToObject = (inlineStyle) => {
  if (!inlineStyle) {
    return null;
  }

  const styleObject = {};

  inlineStyle.split(';').forEach(declaration => {
    const [property, value] = declaration.split(': ').map(part => part.trim());
    if (property && value) {
      styleObject[toCamelCase(property)] = value;
    }
  });

  return styleObject;
}

export const findElementType = (classes) => {
  function findObjectType(classes) {
    if (classes.includes("cs-")) {
      return classes;
    }
  }
  if (classes.includes(" ")) {
    return classes.split(" ").filter(findObjectType).toString();
  }
};

export const activeElement = (element, callback = () => {}) => {
  //make active to clicked element
  $(".editable").removeClass("active hover");
  element.addClass("active");
  callback();
};

export const addActiveClassToElement = (element, addClass = true) => {
  if(!element) return;
  const removeClass = (element) => {
    element.classList.remove("active");
    element.classList.remove("hover");
    element.classList.remove("reset");
  };

  if (element.classList.contains("cs-slide")) {
    removeClass(element);
    element.querySelectorAll(".editable.active").forEach((el) => {
      removeClass(el);
    });
    element.querySelectorAll(".editable.hover").forEach((el) => {
      removeClass(el);
    });
  } else {
    const dropzone = element.closest(".cs-slide");
    removeClass(dropzone);
    dropzone.querySelectorAll(".editable.active").forEach((el) => {
      removeClass(el);
    });
    dropzone.querySelectorAll(".editable.hover").forEach((el) => {
      removeClass(el);
    });
  }

  element.classList.remove("reset");
  if (addClass) {
    element.classList.add("active");
  }
};

// export const useFormateCssProperty = (propertyVal, inputType) => {
//   if (inputType == "number") {
//     return propertyVal + "px";
//   } else {
//     return propertyVal;
//   }
// };

export const convertHexToRgba = (hex, aplha = 1) => {
  hex = hex.replace("#", "");
  // return hex;
  var aRgbHex = hex.match(/.{1,2}/g);
  let r = parseInt(aRgbHex[0], 16);
  let g = parseInt(aRgbHex[1], 16);
  let b = parseInt(aRgbHex[2], 16);
  let a = aplha;
  let aRgb = `rgba(${r},${g},${b},${a})`;
  return aRgb;
};

export const convertRgbAToHexAplha = (rgbaColor) => {
  const rgba = rgbaColor.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
  const hex = `#${(
    (1 << 24) +
    (parseInt(rgba[0]) << 16) +
    (parseInt(rgba[1]) << 8) +
    parseInt(rgba[2])
  )
    .toString(16)
    .slice(1)}`;

  const alpha = rgba[3];
  return {
    hex: hex,
    aplha: alpha,
  };
};

/*this function will set the hight of layer dynamically when layer
will be create or update layer height and width*/

export const setLayerPositionToCenter = (el) => {
  const layer = $(el);
  const mainWrapper = $("#mainWrapper");
  let mainWrapperTop = mainWrapper.scrollTop() / 2;

  const dropzone = $("#dropzone");
  let dropzoneWidth = dropzone.outerWidth() / 2;
  let dropzoneHeight = dropzone.outerHeight() / 2;

  let layerWidth = layer.outerWidth() / 2;
  let layerHeight = layer.outerHeight() / 2;

  let left = dropzoneWidth - layerWidth;
  let top = dropzoneHeight - layerHeight;

  layer.css({ left, top });
};

export const setLayerElementIds = (layer) => {
  layer.querySelectorAll("[class*=cs-], img, video, audio").forEach((element) => {
    if (!element.id) {
      element.id = getRandomId();
    }
  });
};

export const setDuplicateLayerId = (layer) => {
  // FUNCTION TO GENERATE DUPLICATED UNIQUE ID
  const applyUniqueId = (el) => {
    const elementId = $(el).attr("id");
    const randmNum = () => Math.ceil(Math.random() * 10000);
    let generatedId = elementId + "-duplicate-" + randmNum();
    // updating only number if id is already duplicated
    if (elementId && elementId?.includes("duplicate")) {
      const duplicatedId = elementId.split("-");
      duplicatedId[duplicatedId.length - 1] = randmNum();
      generatedId = duplicatedId.join("-");
    }
    $(el).attr("id", generatedId);
  };

  // UPDATING LAYER'S ID
  applyUniqueId(layer);

  // UPDATING EDITABLE CHILDREN'S ID'S
  const editableChilds = layer.find(".editable");
  editableChilds.each((i, el) => {
    applyUniqueId(el);
  });
};

// Test function

const test = (callback = () => {}) => {
  callback();
  console.log("hello test");
};

export const getLayersEditableChildren = (layer) => {
  return layer.querySelectorAll('[class*="cs-"]');
};

//return layer styles for state update
export const getLayerData = (csLayer) => {
  const csLayerStyle = csLayer.attr("style");
  const layerId = csLayer.attr("id");
  //get current layer childern
  const layerElements = [];
  csLayer.find("[class*=cs-]").each(function () {
    const element = $(this);
    //check if elment has style then read it else blank
    let elementStyle = element.attr("style");
    if (typeof elementStyle !== "undefined" && elementStyle !== false) {
      elementStyle = element.attr("style");
    } else {
      elementStyle = "";
    }

    const elementType = findElementType(element.attr("class"));
    const elementId = element.attr("id");
    layerElements.push({
      id: elementId,
      type: elementType,
      style: covertStyleStrToObject(elementStyle),
    });
  });
  return {
    layerId: layerId,
    layerStyle: covertStyleStrToObject(csLayerStyle),
    layerElements: layerElements,
  };
};
export const getFormattedValue = (key, value) => {
  if (key == "transform") {
    value = "rotate(" + value + "deg)";
  } else if (key == "background") {
    value = value;
  } else if (
    key == "z-index" ||
    key == "border" ||
    key == "borderColor" ||
    key == "borderRadius"
  ) {
    value = value;
  } else {
    value = value + "px";
  }
  return value;
};
export const useDeformattedValue = (key, value) => {
  if (key == "transform") {
    if (value.includes("deg")) {
      //if value in deg
      const start = value.indexOf("(") + 1;
      const end = value.indexOf("deg");
      value = value.slice(start, end);
    } else {
      //if value in deg
      const start = value.indexOf("(") + 1;
      const end = value.indexOf("rad");
      value = value.slice(start, end);
    }
  } else if (key == "border") {
    value = value;
  } else {
    value = value.replace("px", "");
  }
  return value;
};

// export const useFormattedStyle = (stylesObject) => {
//   let formattedStyle = {};
//   Object.keys(stylesObject).map(function (key, index) {
//     let value = stylesObject[key];
//     //formattedStyle[key] = value;
//     formattedStyle[key] = getFormattedValue(key, value);
//     //console.log(key, value);
//   });
//   return formattedStyle;
// };

export const useDeFormattedStyle = (stylesObject) => {
  let deFormattedStyle = {};
  if (stylesObject && Object.keys(stylesObject).length !== 0) {
    Object.keys(stylesObject).map(function (key, index) {
      let value = stylesObject[key];
      deFormattedStyle[key] = useDeformattedValue(key, value);
    });
  }
  return deFormattedStyle;
};
export const applyControlCss = (elementId, name, value) => {
  if (elementId) {
    const element = document.getElementById(elementId);
    element.style[name] = getFormattedValue(name, value);
    //console.log(name, getFormattedValue(name, value));
  }
};

const isCanDrop = (ctrlKeyPress, layer) => {
  if(!layer) return false
  if (ctrlKeyPress) {
    layer.classList.add('canDrop')
    $(".container-component").addClass("highlight-drppable");
    activeElement($(layer))
  } else {
    layer.classList.remove("canDrop");
    $(".container-component").removeClass("highlight-drppable");
  }
};

//element droppable

let droppedEle;
export const makeElementDropable = (dropArea, callback = () => {}) => {
  dropArea.droppable({
    accept: ".canDrop",
    drop: function (event, ui) {
      var zoom = $("#dropzone").css("zoom");
      droppedEle = ui.draggable;
      dropArea = $(event.target);
      const droppedEleTop = droppedEle.offset().top;
      const droppedEleLeft = droppedEle.offset().left;

      const dropAreaTop = dropArea.offset().top;
      const dropAreaLeft = dropArea.offset().left;

      // Adjust the top and left positions by the zoom factor
      const setDropTop = (droppedEleTop - dropAreaTop) / zoom + "px";
      const setDropLeft = (droppedEleLeft - dropAreaLeft) / zoom + "px";

      dropArea.append(droppedEle);
      droppedEle.css({ top: setDropTop, left: setDropLeft });

      const containerLayerId = dropArea.closest(".cs-layer").attr("id");
      droppedEle.attr("data-container-layer", containerLayerId);
      callback();
      setTimeout(() => {
        $(".ui-droppable-hover").removeClass("ui-droppable-hover");
        $('.cs-layer').removeClass("canDrop")
      }, 100);
    },
  });
};
// const convertPixelToPer = (csLayer) => {
//   const dropzone = $("#dropzone");
//   const dropzoneHeight = dropzone.height();
//   const dropzoneWidth = dropzone.width();
//   const dropzoneTop = dropzone.offset().top;
//   const dropzoneLeft = dropzone.offset().left;

//   const csLayerTop = csLayer.offset().top;
//   const csLayerLeft = csLayer.offset().left;

//   const finalLeft = csLayerLeft - dropzoneLeft;
//   const finalTop = csLayerTop - dropzoneTop;

//   let leftPer = (finalLeft / dropzoneWidth) * 100;
//   let topPer = (finalTop / dropzoneHeight) * 100;

//   leftPer = leftPer.toFixed() + "%";
//   topPer = topPer.toFixed() + "%";

//   csLayer.css({ top: topPer, left: leftPer });
// };
//layer draggable and resizeable
const isJQueryObject = (element) => element instanceof $;
export const dragUiHelper = (element)=>{
  
  // if element 
  const positionClass = 'ui-position';
  const position = document.createElement('div')
  position.className = positionClass;
  
  const sizeClass = 'ui-size'
  const size = document.createElement('div')
  size.className = sizeClass;
  
  const removeAll = function(){
    document.querySelectorAll(`.${positionClass}`)?.forEach((position)=> position.remove())
  }
 
  if(!element){
    removeAll()
    return
  }

  let selectedElement = isJQueryObject(element) ? element.get(0) : element
  let layer = selectedElement.classList.contains('cs-layer')? selectedElement : element.closest('.cs-layer');

  const setWidth = function(layer, element){
    if(layer.offsetWidth < 200 ){
      element.classList.add("small")
    }else{
      element.classList.remove("small")
    }
         
  }
  const controller = {
    position: {
      
      init(){
        //first remove from all layers
        removeAll()
        //append 
        if (layer && !layer.querySelector(`.${positionClass}`)) {
          layer.appendChild(position)
        }
      },
      show(){
        controller.size.hide()
        const positionElement = layer?.querySelector(`.${positionClass}`);
        if (positionElement) {
          const top = layer.offsetTop;
          const left = layer.offsetLeft;
          positionElement.innerHTML = `<span><span> X: ${left}px, </span> <span> Y: ${top}px </span></span>`;
          if(document.getElementById('positionX')){
            document.getElementById('positionX').value = left
          }
          if(document.getElementById('positionY')){
            document.getElementById('positionY').value = top
          }
         
          setWidth(layer, positionElement)
        }else{
          this.init()
        }
      },
      hide(){
        const positionElement = layer?.querySelector(`.${positionClass}`);
        if (positionElement) {
          positionElement.remove();
        }
      },
    },
    size: {
      init() {
        if (!layer.querySelector(`.${sizeClass}`)) {
          layer.appendChild(size);
        }
      },
      show() {
        controller.position.hide()
        if(layer){
          const sizeElement = layer.querySelector(`.${sizeClass}`);
          if (sizeElement) {
            const height = layer.offsetHeight;
            const width = layer.offsetWidth;
            sizeElement.innerHTML = `<span><span> H: ${height}px,  </span> <span> W: ${width}px </span></span>`;
            setWidth(layer, sizeElement)
          }else{
            this.init()
          }
        }
      },
      hide() {
        if(layer){
          const sizeElement = layer.querySelector(`.${sizeClass}`);
          if (sizeElement) {
            sizeElement.remove();
          }
        }
      },
    }

    
  }

  return controller
}
export const csDragResizeable = (
  el,
  zoom,
  updateHistory = () => {},
  onDropCallback = () => {},
  activeElement
) => {
  const element = $(el);
  makeElementDropable($(".cs-slide"), () => {
    onDropCallback();
  });
  $(".cs-slide").droppable("disable");
  var pointerX;
  var pointerY;
  var zoom = $("#dropzone").css("zoom");
  let initialStyleAttr = "";
  let startingparent = null;
  let startingDetails = null;

  element.draggable({
    containment: "parent",
    cancel: ".no-drag, .masterTemplateOff .masterTemplate, .masterTemplateOn .cs-layer:not(.masterTemplate)",
    start: function (evt, ui) {
      // isDragging = true;
      element.addClass('dragging');      
      dragUiHelper(element).position.init()
      var zoom = $("#dropzone").css("zoom");
      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;

      var elementStyleTop = parseInt(element.css("top"));
      var elementStyleLeft = parseInt(element.css("left"));
      initialStyleAttr = element.attr("style");
      startingparent = element.get(0).parentElement;
      startingDetails = getElementData({
        id: element.attr("id"),
        styles: initialStyleAttr,
      });

      pointerY = (evt.pageY - canvasTop) / zoom - elementStyleTop;
      pointerX = (evt.pageX - canvasLeft) / zoom - elementStyleLeft;
    },
    drag: function (evt, ui) {
      
      var zoom = $("#dropzone").css("zoom");
      const parentElement = element.parent();
      //keeep element inside of parent
      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;

      // Fix for zoom
      ui.position.top = Math.round((evt.pageY - canvasTop) / zoom - pointerY);
      ui.position.left = Math.round((evt.pageX - canvasLeft) / zoom - pointerX);

      //left overflow restriction
      ui.position.top= ui.position.top < 0? 0 : ui.position.top 
      ui.position.left = ui.position.left < 0? 0 : ui.position.left 

      // Finally, make sure offset aligns with position
      ui.offset.top = Math.round(ui.position.top + canvasTop);
      ui.offset.left = Math.round(ui.position.left + canvasLeft);

      //if dragging inside container
      if (parentElement.hasClass("container-component")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside container");
      }

      //if dragging inside container
      if (parentElement.hasClass("cs-tabContent")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside tabGroup");
      }

      //if dragging inside popup
      if (parentElement.hasClass("cs-popup")) {
        parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside popup");
      }

      if (parentElement.hasClass("cs-grid-item")) {
        // parentElement.droppable("disable");
        $(".cs-slide").droppable("enable");
        // console.log("dragging inside grid", evt.pageX, evt.pageY);
      }

      //if dragging inside cs-slide
      if (parentElement.hasClass("cs-slide")) {
        //if slide has ui-droppable then disable droppable slide ,because its currently dragging area
        if (parentElement.hasClass("ui-droppable")) {
          parentElement.droppable("disable");
        }

        if (parentElement.find(".container-component").length > 0) {
          $(".container-component").droppable("enable");
        }

        if (parentElement.find(".tabGroup-component").length > 0) {
          $(".cs-tabContent").droppable("enable");
        }

        if (parentElement.find(".popup-component").length > 0) {
          $(".popup-component .cs-popup").droppable("enable");
        }

        // if (parentElement.find(".grid-component").length > 0) {
        //   $(".grid-component .cs-grid-item").droppable("enable");
        // }

        //console.log("dragging inside slide");
        let detectTolerance = 30;
        let elementCssTop = element.offset().top;
        let elementCssBottom = Number(elementCssTop) + element.height();

        let parentElementTop = parentElement.offset().top + detectTolerance;
        let parentElementBottom =
          parentElement.offset().top + parentElement.height() - detectTolerance;

        //check if dragg element is nearest on top of slide
        if (elementCssTop < parentElementTop) {
          element.addClass("nearTop");
        } else {
          element.removeClass("nearTop");
        }
        //check if dragg element is nearest on bottom of slide
        if (elementCssBottom > parentElementBottom) {
          element.addClass("nearBottom");
        } else {
          element.removeClass("nearBottom");
        }
        //let result = `element top, ${elementCssTop}, element bottom, ${elementCssBottom}, parent botton ${parentElementBottom}`;
        //element.find(".container").html(result);
      }
      //}
      dragUiHelper(element).position.show()
    },
    stop: function (evt, ui) {
      // isDragging = false;
      setTimeout(function(){
        element.removeClass('dragging')
      }, 1000)
      const csLayer = $(evt.target);
      if (csLayer.attr("data-state") !== "updated") {
        csLayer.attr("data-state", "updated");
      }

      //convertPixelToPer(csLayer)
      //console.log("dragba", convertPixelToPer(csLayer.css("top")));
      //create history
      const endedParent = csLayer.get(0).parentElement;
      const isContainerChange = startingparent !== endedParent;
      const actionType = isContainerChange
        ? HISTORY_ACTIONS.containerChanged
        : HISTORY_ACTIONS.STYLED_ACTIONS.dragged;

      const styledDetails = {
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      };

      if (isContainerChange) {
        startingDetails.action = HISTORY_ACTIONS.containerChanged;
      }

      const containerDetails = {
        startingparent,
        startingDetails,
        endedParent,
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      };

      const extraInfo = isContainerChange ? containerDetails : styledDetails;
      
      updateHistory(csLayer.attr("id"), actionType, extraInfo);
      dragUiHelper(element).position.hide();
      //on dragging stop active the cs-layer
      if(!element.hasClass('active')){
        activeElement(element[0]);
      }
    },
  });

  element.resizable({
    containment: "parent",
    autoHide: true,
    handles:
      element.hasClass("container-component") ||
      element.hasClass("popup-component") ||
      element.hasClass("carousel-component") ||
      element.hasClass("tabGroup-component")
        ? "e,s"
        : "e",
    aspectRatio:
      element.hasClass("image-component") ||
      element.hasClass("video-component") ||
      element.hasClass("card-component") ||
      element.hasClass("carousel-component") ||
      element.hasClass("flipCard-component") ||
      element.hasClass("audio-component") ||
      element.hasClass("graph-component") 
        ? true
        : false,
    minWidth: 100,
    start: function () {
      initialStyleAttr = element.attr("style");
      dragUiHelper(element).size.init()
      dragUiHelper(element).position.hide()
    },
    resize: function (evt, ui) {
      const csLayer = $(evt.target);
      zoom = $("#dropzone").css("zoom");
      var canvasTop = element.parent().offset().top;
      var canvasLeft = element.parent().offset().left;
      element[0].setAttribute("data-state", "updated");
      
      if (true) {
        //Fix resizeable zoom
        var changeWidth = ui.size.width - ui.originalSize.width; // find change in width
        var newWidth = ui.originalSize.width + changeWidth / zoom; // adjust new width by our zoomScale
        var changeHeight = ui.size.height - ui.originalSize.height; // find change in height
        var newHeight = ui.originalSize.height + changeHeight / zoom; // adjust new height by our zoomScale
        //ui.size.width = newWidth;
        ui.originalElement.width(newWidth);
        //set height on resize element
        if (!element.hasClass("cs-button")) {
          ui.originalElement.height(newHeight);
        }
      }
      dragUiHelper(element).size.show()
      useSetLayerHeight(csLayer);
    },

    stop: function (evt, ui) {
      dragUiHelper(element).size.hide()
      const csLayer = $(evt.target);
      const isCoverflowOrCarousel =
      csLayer.hasClass("coverflow-component") ||
      csLayer.hasClass("carousel-component");

      if (isCoverflowOrCarousel) {
        csRenderFunctional(csLayer.find(".editable"));
      }
      
      //create history
      const historyAction = isCoverflowOrCarousel
        ? HISTORY_ACTIONS.updated
        : HISTORY_ACTIONS.STYLED_ACTIONS.resized; // coverflow and carousel always rerender
     
      updateHistory(csLayer.attr("id"), historyAction, {
        initialStyleAttr,
        updatedStyleAttr: csLayer.attr("style"),
        itemId: csLayer.attr("id"),
      });
    },
  });

  const elementStyle = element.attr("style");
  rotatable(element, {
    start: function (csLayer) {
      initialStyleAttr = element.attr("style");
      $(csLayer).draggable("disable");
    },
    rotate: function (csLayer) {},
    stop: function (csLayer) {
      $(csLayer).draggable("enable");
      updateHistory(
        csLayer.attr("id"),
        HISTORY_ACTIONS.STYLED_ACTIONS.rotated,
        {
          initialStyleAttr,
          updatedStyleAttr: csLayer.attr("style"),
          itemId: csLayer.attr("id"),
        }
      );
    },
  });

  element.attr("style", elementStyle);
  if (element.hasClass("container-component")) {
    //const container = element.find(".container");
    makeElementDropable(element, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("tabGroup-component")) {
    const tabContents = element.find(".cs-tabContent");
    makeElementDropable(tabContents, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("popup-component")) {
    const popup = element.find(".cs-popup");
    makeElementDropable(popup, () => {
      onDropCallback();
    });
  }
  if (element.hasClass("grid-component")) {
    const gridItem = element.find(".cs-grid-item");
    makeElementDropable(gridItem, () => {
      onDropCallback();
    });
  }
};

export const findComponentType = (classs) => {
  return classs?.substring(classs.indexOf(" ") + 1, classs.indexOf("-com"));
};

const searchEditableEle = (element) => {
  if (element.hasClass("cs-layer")) {
    element.addClass("padding");
  }

  $(element)
    .children()
    .each(function () {
      const editable = $(this);
      if (editable.hasClass("editable")) {
        if (editable.find(".editable").length > 0) {
          editable.addClass("padding");
        }
      }
      searchEditableEle($(this));
    });
};

// export const usePaddingLayer = (target) => {
//   return;
//   if (!target.hasClass("container-component")) {
//     //now apply padding class on active element
//     searchEditableEle(target.closest(".cs-layer"));
//     setTimeout(() => {
//       useSetLayerHeight(target.closest(".cs-layer"));
//     }, 300);
//   }
// };

// grid action helper
const getGridItemsStyles = (gridItem) => {
  const items = [...gridItem.querySelectorAll(".cs-grid-item")];
  const itemsStyles = items.map((el) => ({
    id: el.id,
    eleStyles: el.getAttribute("style"),
  }));

  console.log(itemsStyles);
  return itemsStyles;
};

export const gridActions = {
  resizeable: function (elment, historyCallback) {
    if (historyCallback) {
      this.historyCallback = historyCallback;
    }
    // Main Resizer Function
    const applyResizersFunction = (resizer) => {
      // Query the element
      const root = document.documentElement;
      const gridItem = resizer.parentNode;
      const container = gridItem.closest(".cs-grid");
      let initialHTML = "";
      // The current position of mouse
      let x = 0;
      let y = 0;
      let minWidth = 20;
      // other Details
      let itemWidth = 0;
      let zoom = 1;

      let startingStyles;
      let startingDetails;

      // Handle the mousedown event
      // that's triggered when user drags the resizer
      const mouseDownHandler = function (e) {
        e.stopPropagation();
        // Get the current mouse position
        x = e.clientX;
        y = e.clientY;
        itemWidth = gridItem.getBoundingClientRect().width;
        zoom = 1 || document.getElementById("dropzone").style.zoom;
        const layer = gridItem.closest(".cs-layer");
        initialHTML = layer.outerHTML;

        startingStyles = getGridItemsStyles(container);
        startingDetails = getElementData({
          id: gridItem.id,
          type: HISTORY_ACTIONS.gridResized,
        });

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        // How far the mouse has been moved
        const dx = e.clientX - x;
        const nextSibling = gridItem.nextElementSibling;

        const newWidth =
          ((itemWidth + dx / zoom) * 100) /
          container.getBoundingClientRect().width;

        const widthToShare = 100 - newWidth;

        if (newWidth > minWidth && widthToShare > minWidth) {
          gridItem.style.width = newWidth + "%";

          nextSibling.style.width = widthToShare + "%";

          // to avoid the selection of other elements
          resizer.style.cursor = "col-resize";
          root.style.setProperty("--user-selection", "none");
          document.body.style.cursor = "col-resize";
        }
      };

      const mouseUpHandler = (e) => {
        e.preventDefault();
        resizer.style.cursor = "";
        root.style.setProperty("--user-selection", "auto");
        document.body.style.cursor = "";
        // Remove the handlers of `mousemove` and `mouseup`
        document.removeEventListener("mousemove", mouseMoveHandler);
        document.removeEventListener("mouseup", mouseUpHandler);

        const endingStyles = getGridItemsStyles(container);
        const historyPayload = {
          startingDetails,
          startingStyles,
          endingStyles,
        };

        this.historyCallback(
          container.id,
          HISTORY_ACTIONS.gridResized,
          historyPayload
        );
      };

      // Attach the handler
      resizer.addEventListener("pointerdown", mouseDownHandler);
    };

    // grid Items
    const items = elment.querySelectorAll(".cs-grid-item");
    // ALL Grid Items are calling resize funtion except last one
    items.forEach((item, index) => {
      const isLastItem = index == items.length - 1;
      // deleting old Resizers divs
      const oldResizer = item.querySelector(".grid-resizer");
      if (oldResizer) {
        oldResizer.remove();
      }
      if (!isLastItem) {
        const handle = document.createElement("div");
        handle.classList.add("grid-resizer");
        item.append(handle);
        applyResizersFunction(handle);
      }
    });
  },
  callback: () => {},
};

export const onLayerActiveHandler = (e) => {
  const target = e.target;

  if (!element.hasClass("cs-slide")) {
    element.on("click", function (e) {
      const target = e.target;
      // not click if element has not class
      if (
        target.hasAttribute("class") &&
        target.getAttribute("class").includes("cs-")
      ) {
        //not click on rotate handle
        if (!e.target.getAttribute("class").includes("ui-rotatable")) {
          activeElement($(target), () => {
            activeElementCallback();
          });
          //update state for active element
        } else {
          //if user click on rotate handle icon then
          activeElement($(target).closest(".cs-layer"), () => {
            activeElementCallback();
          });
        }
      } else {
        //if user click on (cs-text) of children then triggle cs-text
        $(target).parent().trigger("click");
      }
    });
  }
};

export const csRenderEditable = (
  id,
  updateHistory,
  activeElementCallback,
  zoom,
  actionState,
  callback = () => {},
  editorType,
  deviceDimensions
) => {
  // Adding listeners..
  const element = $(`#${id}`);
  if (!element.find(".status").length) {
    element.append("<div class='status'><span></span></div>");
  }

  const a = element[0].tagName === "A" ? element : element.find("a");
  if (a.length) {
    a.on("click", (e) => {
      e.preventDefault();
    });
  }

  if (!element.hasClass("cs-slide")) {
    element.on("click", function (e) {
      const target = e.target;
      // not click if element has not class
      if (
        target.hasAttribute("class") &&
        target.getAttribute("class").includes("cs-")
      ) {
        //not click on rotate handle
        if (!e.target.getAttribute("class").includes("ui-rotatable")) {
          activeElement($(target), () => {
            activeElementCallback();
          });
          //update state for active element
        } else {
          //if user click on rotate handle icon then
          activeElement($(target).closest(".cs-layer"), () => {
            activeElementCallback();
          });
        }
      } else {
        //if user click on (cs-text) of children then triggle cs-text
        $(target).parent().trigger("click");
      }
    });
  }
  if (element.hasClass("text-component")) {
    element.on("keypress", function (e) {
      const getLayer = $(this).parent().parent();
      useSetLayerHeight(getLayer);
    });
  }

  //editable status code start
  $("#dropzone .editable").on("mouseout", function (e) {
    $(".editable").removeClass("hover");
    const status = $(this).find(".status");
    if (status.length > 0) {
      status.stop().fadeOut("fast");
    }
  });

  $("#dropzone .editable").on("mouseover", function (e) {
    e.stopImmediatePropagation();
    let csElement = e.target;
    let classAttr = csElement.getAttribute("class");
    let type = "";
    if (
      typeof classAttr !== "undefined" &&
      classAttr !== null &&
      classAttr.includes("cs-")
    ) {
      type = findElementType(classAttr);
      csElement = $(csElement);
    } else {
      csElement = $(csElement).closest('[class*="cs-"]');
      type = findElementType(csElement.attr("class"));
    }
    $(csElement).addClass("hover");
    //finally we received actual hover element and its type
    //skip slide
    if (type != "cs-slide") {
      const csLayer = $(csElement).closest(".cs-layer");
      const status = csLayer.find(">.status");

      status.find("span").html(type);
      status.stop().fadeIn("fast");
    }
  });

  //make slide active
  $("#dropzone.cs-slide").on("dblclick", function (e) {
    e.stopImmediatePropagation();
    activeElement($(this), activeElementCallback);
  });
  //editable status code end

  const historyCallback = (id, action = "updated", extraInfo) => {
    // waiting for JQuery to remove dragging classes
    setTimeout(() => {
      updateHistory({
        action,
        actionState,
        id: typeof id === "string" ? id : "",
        extraInfo,
      });
    }, 30);
  };
  // making resizeable , draggable , including padding
  if (element.hasClass("grid-component")) {
    // makeElementDropable(element.find(".cs-grid-item"), () => {
    //   historyCallback();
    // });
    gridActions.resizeable(element.get(0), historyCallback);
  } else {
    if (editorType === "presentation") {
      csDragResizeable(
        element,
        zoom,
        historyCallback,
        () => {
          if (typeof callback === "function") {
            callback();
          }
        },
        activeElementCallback
      );
    }
  }

  // if component have slider JS functionality.
  const csComponent = element.find(".editable");

  if (csComponent.length) {
    csRenderFunctional(csComponent);

    // if not on Editor
    if (editorType === "email") {
      if (!csComponent.hasClass("cs-slide")) {
        sortable({
          item: csComponent.closest(".cs-layer").get(0),
          appendable: document.getElementById("dropzone"),
          onsort: (itemDetails) => {
            historyCallback(null, HISTORY_ACTIONS.sorted, itemDetails);
          },
          appendableGridClass: "cs-grid-item",
        });
      }
    } else if (editorType === "microsite") {
      if (!csComponent.hasClass("cs-slide")) {
        sortable({
          item: csComponent.closest(".cs-layer").get(0),
          appendable: document.getElementById("dropzone"),
          onsort: (itemDetails) => {
            historyCallback(null, HISTORY_ACTIONS.sorted, itemDetails);
          },
          appendableGridClass: "cs-col",
        });
      }
    }
  }
  if (element.attr("class").includes("flipCard-component")) {
    setLayerElementIds(element);
  }
  if (element.attr("class").includes("tabGroup-component")) {
    const tabLinks = element[0].querySelectorAll(".tabLinks");
    const tabContents = element[0].querySelectorAll(".cs-tabContent");
    setTimeout(() => {
      tabContents.forEach(
        (tab, index) => (tabLinks[index].dataset.target = tab.id)
      );
    }, 100);
  }
};

// Hierarchy starts here
export const removeNoClassTags = (id) => {
  let duplicateLayer = $(id).clone().wrap("div").parent();
  duplicateLayer.find(".status").remove();
  duplicateLayer.find(".ui-resizable-handle").remove();
  duplicateLayer.find(".ui-rotatable").remove();

  let selector = ":not([class*=cs-])";

  const selectorLen = $(duplicateLayer).find(selector).length;
  for (var a = 0; a < selectorLen; a++) {
    duplicateLayer.find(selector).each(function () {
      $(this).replaceWith($(this).html());
    });
  }

  const element = duplicateLayer.html();

  return $(element);
};

// Getting all cs-layers

const getCsLayers = (parent) => {
  const children = [];
  $(parent)
    .children()
    .each(function (index, item) {
      children.push(getCsLayers(item));
    });
  return {
    parent: $(parent).attr("class") || "",
    parentId: $(parent).attr("id") || "",
    type: getCsClass(
      $(parent).attr("class"),
      $(parent)
        .attr("id")
        ?.slice($(parent).attr("id").lastIndexOf("-") + 1)
    ),
    children: children,
  };
};

export const showLayers = (id) => {
  const parent = removeNoClassTags(id);

  return getCsLayers(parent);
};

// export const useImageToDataURL = (url, callback) => {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       if (url.includes(".jpg")) {
//         callback(
//           reader.result.replace(
//             "data:application/octet-stream",
//             "data:image/jpg"
//           )
//         );
//       } else {
//         callback(
//           reader.result.replace(
//             "data:application/octet-stream",
//             "data:image/png"
//           )
//         );
//       }
//     };
//     reader.readAsDataURL(xhr.response);
//   };

//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send();
// };

// export const useToDataURL = (url, callback) => {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function () {
//     var reader = new FileReader();
//     reader.onloadend = function () {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };

//   xhr.open("GET", url);
//   xhr.responseType = "blob";
//   xhr.send();
// };

export const setBodyinHTML = (html, link, script, email = false, microsite, isPreview = true) => {
  //adding break point based on preview or sending email
  const responsiveBreakPoint = isPreview ? '320px': '768px'
  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="ie=edge">
    ${link ? link : ""}
    <link href='http://fonts.googleapis.com/css?family=Lato:400,700' rel='stylesheet' type='text/css'>

    ${
      email
        ? `<style>
    *{
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'lato' , sans-serif;
    }

    #dropzone{
      overflow: hidden !important;
      margin: auto !important;
      background: white;
    }

    .cs-image{
      vertical-align: middle;
    }

    .cs-button {
      padding: 10px;
      border-radius: 6px;
      outline: none;
      border: none;
      background-color: rgb(0, 183, 255);
      color: white;
      border-radius: inherit;
    }

    .cs-button * {
    margin: 0px;
    }

  .cs-button p {
    word-break: break-word;
  }

    @media only screen and (max-width:768px)  {
      .desktop-view{display: none !important;}
      input{
        display:block  !important;
        width:100% !important;
      }
    }

   @media only screen and (min-width:769px) {
      .mobile-view{display: none !important;}
    }
   @media only screen and (max-width: ${responsiveBreakPoint}) {
      .cs-grid-item {
        width: 100% !important; 
        display: block;
      }
      .card-component .cs-text, .card-component .cs-image{
        width: 100% !important; 
        display: block;
      }
    } 
  </style>`
        : ""
    }
  </head>
  <body ${email ? "style='padding: 50px 0px; background:#f0f0f0'" : ""}>

  ${
    microsite
      ? `<div id="landing-page" data-content="landingpage">${microsite}</div>`
      : html
      ? html
      : '<div class="item-loading">No Data Available</div>'
  }
  ${script ? script : ""}
  </body>
</html>
  `;
};
export const parseHTMLString = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.body.firstChild;
}
export const getHTMLFromBody = (html, eleTopAppend) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  const dropzone = doc.getElementById("dropzone");
  if (
    dropzone &&
    (dropzone.innerHTML ||
      dropzone.getAttribute("style").includes("background"))
  ) {
    const div = document.createElement("div");
    div.append(dropzone);
    if (eleTopAppend) {
      div.innerHTML += eleTopAppend;
    }
    return div.innerHTML;
  }
};

export const masterTemplateUi = {
  templateClassName:'data-template-style',
  removeHelperAttr:function(slide){
    slide.classList.remove('masterTemplateOn')
    slide.classList.remove('masterTemplateOff')
    slide.removeAttribute(this.templateClassName)
    return slide
  },
  mergeMasterTemplateIntoSlide: function(slide, masterTEmplate){
    let prarseSlide = parseHTMLString(slide);
    let prarseMasterTemplate = parseHTMLString(masterTEmplate);
    //first remove master template helper classes
    prarseSlide.classList.remove('masterTemplateOn')
    prarseSlide.classList.remove('masterTemplateOff')
    prarseSlide.removeAttribute(this.templateClassName)

    prarseMasterTemplate.classList.remove('masterTemplateOff')
    prarseMasterTemplate.classList.remove('masterTemplateOff')
    prarseMasterTemplate.removeAttribute(this.templateClassName)
    
    // Append master template element into slide
    while (prarseMasterTemplate.childNodes.length > 0) {
      prarseSlide.appendChild(prarseMasterTemplate.childNodes[0]);
    }
    

    if(prarseMasterTemplate !== null && prarseSlide){
      // console.log('prarseSlide', prarseSlide)
      // console.log('prarseMasterTemplate', prarseMasterTemplate)
      if(prarseMasterTemplate.getAttribute('style')){
        //remove height width from template style becuase slide alreadu have this
        prarseMasterTemplate.style.height=""
        prarseMasterTemplate.style.width=""

        let checkProperties = null
        let masterTemplateStyle = prarseMasterTemplate.getAttribute('style');
        if(Boolean(masterTemplateStyle)){
          //backup master template style for validate to check slide has own style or master template style
          prarseSlide.setAttribute(this.templateClassName, masterTemplateStyle)
          checkProperties = Object.keys(inlineStyleToObject(masterTemplateStyle))
        }
        /*if slide has own style property like background then master template background will not apply,
        slide background is high priorityly*/
        if(!prarseSlide?.style.backgroundColor && !prarseSlide?.style.background  && !prarseSlide?.style.backgroundImage){
          if(checkProperties){
            checkProperties.forEach((property) =>{
              prarseSlide.style[property] = prarseMasterTemplate?.style[property]
            })
          }
        }
      }
    }
  
    return formatHTML(prarseSlide.outerHTML)
  },
  appendTemplateStyle: function(masterTEmplate){
    let prarseMasterTemplate = parseHTMLString(masterTEmplate);
    if(prarseMasterTemplate.getAttribute('style')){
      prarseMasterTemplate.setAttribute(this.templateClassName, prarseMasterTemplate.getAttribute('style'))
    }
    return formatHTML(prarseMasterTemplate.outerHTML)
  },
  removeTemplate: function(){
    const dropzone = document.getElementById('dropzone')
    dropzone.querySelectorAll('.masterTemplate').forEach(element => element.remove());
    this.removeTemplateStyle();
    dropzone.removeAttribute(this.templateClassName)
  },
  getTemplateStyle: function(){
    const dropzone = document.getElementById('dropzone');
    const getMasterTemplateStyle = dropzone.getAttribute(this.templateClassName)? inlineStyleToObject(dropzone.getAttribute(this.templateClassName)): null
    
    delete getMasterTemplateStyle?.height;
    delete getMasterTemplateStyle?.width;

    return getMasterTemplateStyle;
  },
  removeTemplateStyle: function(){
    const dropzone = document.getElementById('dropzone');
    const getMasterTemplateStyle = this.getTemplateStyle();
    let checkProperties = null
    if(getMasterTemplateStyle){
      checkProperties = Object.keys(getMasterTemplateStyle)
      checkProperties.forEach((property)=>{
        if(dropzone?.style[property] && getMasterTemplateStyle[property]){
          dropzone.style[property] = ""
        }
      })
    }
  },
  isPropertySame: function(property){
    const dropzone = document.getElementById('dropzone');
    const getMasterTemplateStyle = this.getTemplateStyle();

    if(!getMasterTemplateStyle) {
      return false;
    }

    const dropzoneStyle = dropzone.style;
    if(dropzoneStyle[property] === getMasterTemplateStyle[property]){
      return true;
    }else{
      return false
    }
  },
  revertProperty: function(property){
    const dropzone = document.getElementById('dropzone');
    const getMasterTemplateStyle = this.getTemplateStyle();
    const isMasterTemplateOFF = dropzone.classList.contains('masterTemplateOff')
    if(!window.location.href.includes('template') && getMasterTemplateStyle){
      if(property){
        if(getMasterTemplateStyle && getMasterTemplateStyle[property] && isMasterTemplateOFF) {
          dropzone.style[property] = getMasterTemplateStyle[property]
        }
      }else{
        let allProperties = Object.keys(getMasterTemplateStyle);
        allProperties.forEach((property)=>{
          dropzone.style[property] = getMasterTemplateStyle[property]
        })
      }
    }
  },
  isSlideBackgroundChanged:function(saveDropzone){
    //only for slide save
    //this funtion is responsible to validate that slide has template background or slide has own background
    let status = false
    const getMasterTemplateStyle = this.getTemplateStyle();
    //if template has not background or style then its mean slide background is not override on master tempalte 
    if(!getMasterTemplateStyle){
      return false
    }

    if(!window.location.href.includes('template') && getMasterTemplateStyle){
      
      if(getMasterTemplateStyle.background && getMasterTemplateStyle.background !== saveDropzone.style.background){
        // console.log('background')
        status = true
      }
      if(getMasterTemplateStyle.backgroundColor && getMasterTemplateStyle.backgroundColor !== saveDropzone.style.backgroundColor){
        // console.log('backgroundColor')
        status = true
      }
      if(getMasterTemplateStyle.backgroundImage && getMasterTemplateStyle.backgroundImage !== saveDropzone.style.backgroundImage){
        // console.log('backgroundImage')
        // console.log(getMasterTemplateStyle.backgroundImage)
        // console.log(saveDropzone.style.backgroundImage)
        status = true
      }

      return status;
    }
  },
  removeTemplateBGBeforeSaveSlide: function(saveDropzone){
    if(!window.location.href.includes('template')){
      const isSlideSaving = saveDropzone.classList.contains('masterTemplateOff')
      const getMasterTemplateStyle = this.getTemplateStyle();
      //if user saving slide then remove template styles from slide style
      if(getMasterTemplateStyle && isSlideSaving){
        console.log('removing ')
        if(saveDropzone?.style.background === getMasterTemplateStyle['background']){
          saveDropzone.style.background = ""
        }
        if(saveDropzone?.style.backgroundImage === getMasterTemplateStyle['backgroundImage'] || saveDropzone?.style.backgroundImage.includes('assets/masterTemplates')){
          console.log('removing bg')
          saveDropzone.style.backgroundImage = ""
        }
        if(saveDropzone?.style.backgroundColor === getMasterTemplateStyle['backgroundColor']){
          saveDropzone.style.backgroundColor = ""
        }

      }
    }
    return saveDropzone;
  }
}

export const createLink = (file) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href = `/css/${file}`;
  return link;
};
export const createScript = (file, src) => {
  const script = document.createElement("script");
  if (src) {
    script.src = src;
  } else {
    script.src = `/js/${file}`;
  }
  return script;
};

export const emailValidator = (email) => {
  if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  } else {
    return false;
  }
};

export const URLValidator = (URL) => {
  if (
    URL.toLowerCase().match(
      "^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"
    )
  ) {
    return true;
  } else {
    return false;
  }
};

export const cleanDropzone = (dropzone, isEmailOrMicrosite) => {
  let newDropzone = $(dropzone.outerHTML);
  //remove tags
  newDropzone.find(".status").remove();
  newDropzone.find(".ui-rotatable").remove();
  newDropzone.find(".ui-resizable-handle").remove();
  newDropzone.find(".grid-resizer").remove();
  newDropzone.find(".ui-position").remove();
  newDropzone.find(".ui-size").remove();
  newDropzone.find(".animate__animated").removeClass("animate__animated");
  newDropzone.removeClass("active");
  newDropzone.removeClass("ui-droppable-disabled");
  newDropzone.removeClass("ui-droppable");
  newDropzone.removeClass("saving-dropzone");
  
  //remove ui-clases
  newDropzone.find(".editable").each(function () {
    const ele = $(this);
    ele.removeClass(function (i, c) {
      //remove all classes contained ui-
      var matches = c.match(/\S*ui-\S*/g);
      return matches ? matches.join(" ") : false;
    });
    ele.removeClass(function (i, c) {
      // Check if the element has the class 'tab-content'
      if ($(this).hasClass('cs-tabContent')) {
        // If 'cs-tabContent' class is present, do not remove 'activeTab' class
        return false;
      }

      //remove all classes active
      var matches = c.match(/\S*active\S*/g);
      return matches ? matches.join(" ") : false;
    });
    ele.removeClass("editable");
    ele.removeClass("active");
    ele.removeClass("sortable-item");
    ele.removeClass("selected");
  });

  if (isEmailOrMicrosite) {
    newDropzone.attr("data-height", newDropzone.css("height"));

    newDropzone.css({
      overflow: "hidden",
      height: "auto",
    });
  }
  
  newDropzone.css({ zoom: "", padding: "" });
  return newDropzone.get(0);
};

export const insertHtmlToDropzone = (dropzone, html = "") => {
  
  if (html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    const fetchedDropzone = div.querySelector("#dropzone");
    // inserting attributes from fetch dropzone to real dropzone
    if (fetchedDropzone) {
      fetchedDropzone.style.overflow = ""
      Array.prototype.forEach.call(
        fetchedDropzone.attributes,
        ({ nodeName, value }) => {
          if (nodeName === "class") {
            const oldClass = dropzone.getAttribute("class");
            if (oldClass) {
              // merging old and fetched class attribute
              dropzone.setAttribute("class", `${oldClass} ${value}`);
            } else dropzone.setAttribute(nodeName, value);
          } else dropzone.setAttribute(nodeName, value);
        }
      );
      dropzone.innerHTML = fetchedDropzone.innerHTML;
    }
    return true;
  } else {
    dropzone.innerHTML = "";
    return false;
  }
};

export const getFirstCapitalized = (string) => {
  const capital = string
    .split("-")
    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
    .join("-");
  return capital;
};

export const getCsClass = (classList, index, withCs = true) => {
  const comp = findComponentType(classList)?.trim();
  const capitalized = getFirstCapitalized(
    comp?.includes("cs") ? comp : "layer-" + comp
  );
  if (capitalized.includes("Layer")) {
    const capLayer = capitalized.split("-");
    capLayer.splice(1, 0, index);
    return capLayer.join("-");
  } else if (capitalized.includes("Slide")) {
    return capitalized;
  } else {
    if (withCs) {
      return capitalized + `-${index}`;
    } else {
      return capitalized.split("-")[1] + `-${index}`;
    }
  }
};

export const getEditableElements = () => {
  const dropzoneWrapper = document.getElementById("mainWrapper");
  const active = dropzoneWrapper.querySelector(".active");
  let children = [active];
  if (active && active.classList.contains("cs-layer")) {
    children = [...children, ...active.querySelectorAll(".editable")];
  } else {
    const layer = active.closest(".cs-layer");
    if (layer) {
      children = [layer, ...layer.querySelectorAll(".editable")];
    } else {
      children = [active, ...active.querySelectorAll(".cs-layer")];
    }
  }

  const data = [];

  children.forEach((item) =>
    data.push({
      name: getCsClass(
        item.classList.value,
        item.id.slice(item.id.lastIndexOf("-") + 1),
        false
      ),
      id: item.id,
    })
  );

  return { options: data, activeId: active.id };
};

export const editableActions = {
  clickHandler: ({ element, activeElement, clickedEle }) => {
    //target only pass for microsite columns to save target colum in state
    if(clickedEle){
      activeElement(element, clickedEle);
    }else{
      activeElement(element)
    }
  },
  mouseOverHandler: ({ e, element }) => {
    e.stopImmediatePropagation();
    element.classList.add("hover");
    let csElement = e.target;
    let classAttr = csElement.getAttribute("class");
    let type = "";
    if (
      typeof classAttr !== "undefined" &&
      classAttr !== null &&
      classAttr.includes("cs-")
    ) {
      type = findElementType(classAttr);
      csElement = $(csElement);
    } else {
      csElement = $(csElement).closest('[class*="cs-"]');
      type = findElementType(csElement.attr("class"));
    }
    $(csElement).addClass("hover");
    //finally we received actual hover element and its type
    //skip slide
    if (type != "cs-slide") {
      const csLayer = $(csElement).closest(".cs-layer");
      const status = csLayer.find(">.status");
      status.find("span").html(type);
      status.stop().fadeIn("fast");
    }
  },
  mouseOutHandler: ({ element }) => {
    $(".editable").removeClass("hover");
    const status = $(element).find(".status");
    if (status.length > 0) {
      status.stop().fadeOut("fast");
    }
  },
  draggableAndResizeable({
    element,
    updateHistory,
    zoom = 1,
    onDragDropCallback = () => {},
    activeElement,
    actionState,
  } = {}) {
    const historyCallback = (id, action = "updated", extraInfo) => {
      // waiting for JQuery to remove dragging classes
      setTimeout(() => {
        updateHistory({
          action,
          actionState,
          id: typeof id === "string" ? id : "",
          extraInfo,
        });
      }, 30);
    };

    csDragResizeable(
      element,
      zoom,
      historyCallback,
      onDragDropCallback,
      activeElement
    );
  },
  renderSliderFunctional() {},
};
export const keybaordHandler = (updateHistory) => {
  return {
    moveElement: ()=>{
      if(document.body.classList.contains('keybaordHandler')){
        return false
      }
      let initialStyleAttr = null;
      let isInitialStyleStored = false;
      window.addEventListener("keydown", function (e) {
        const target = e.target;
        // Check if the target is the body
        if (target !== document.body) {
          return; // Exit if the target is not the body
        }

        e.stopPropagation();
        const element = document.querySelector('.editable.active');
        const layer = element?.classList.contains('cs-layer')? element : element?.closest('.cs-layer');

        if((e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight')){
          if (!isInitialStyleStored) {
            initialStyleAttr = layer.getAttribute('style');
            isInitialStyleStored = true; // Set the flag to true
          }

          const step = 1; // Movement step size in pixels
          let { top, left } = layer.style
          
          top = Number(removePx(top));
          left = Number(removePx(left));

          switch (e.key) {
            case "ArrowUp":
              top -= step;
              break;
            case "ArrowDown":
              top += step;
              break;
            case "ArrowLeft":
              left -= step;
              break;
            case "ArrowRight":
              left += step;
              break;
            default:
              return; // Exit if the key is not an arrow key
          }

          layer.style.top = `${top}px`;
          layer.style.left = `${left}px`;
          dragUiHelper(element).position.show()
        }
        
        if(e.key === 'Control'){
          console.log('ctrl')
          if(layer){
            isCanDrop(true, layer)
          }
        }
        
      },true)

      window.addEventListener("keyup", function (e) {
        const target = e.target;
        // Check if the target is the body
        if (target !== document.body) {
          return; // Exit if the target is not the body
        }
        e.stopPropagation();

        const element = document.querySelector('.editable.active');
        const layer = element?.classList.contains('cs-layer')? element : element?.closest('.cs-layer');
        
        if((e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight')){
          const styledDetails = {
            initialStyleAttr,
            updatedStyleAttr: layer.getAttribute("style"),
            itemId: layer.getAttribute("id"),
          };

          setTimeout(() => {
            updateHistory({id:layer.getAttribute("id"), action:HISTORY_ACTIONS.STYLED_ACTIONS.dragged, extraInfo:styledDetails});
            isInitialStyleStored = false; 
          }, 30);
        }
        
        if(e.key === 'Control'){
          isCanDrop(false, layer)
        }

        //undo redo for presentation by click on undo button
        if (e.ctrlKey) {
          // ctr + z
          console.log('ekey', e)
          if (e.code === 'KeyZ') {
            document.querySelector('#undoBtn').click()
          } else if (e.code === 'KeyY') {
            document.querySelector('#redoBtn').click()
          }
        }
        
      },true)
      document.body.classList.add('keybaordHandler')
    },
    deleteDialog: (callback)=>{
      window.addEventListener('keyup', function(e){
        const target = e.target;
        // Check if the target is the body
        if (target !== document.body) {
          return; // Exit if the target is not the body
        }
        
        if(e.key === 'Delete'){
          callback()
        }
      },true)
    }
  }
}
export const getNewLayerZIndex = (dropzone) => {
  return dropzone.querySelectorAll(".cs-layer").length + 1;
};

export const takeScreenShot = async (elementToCapture) => {
  const captureElement = (dropzone) => {
    const image = toPng(dropzone, {
      quality: 0.5,
    });
    const thumbnail = toPng(dropzone, {
      pixelRatio: 0.4,
      quality: 1,
    });
    return Promise.all([image, thumbnail]);
  };

  // Converting images to base64,to take screenshot properly
  const backgroundAndImages = elementToCapture.parentElement.querySelectorAll(
    "img[src] , [style*=background-image] , video[poster]"
  );
  const promises = [];
  backgroundAndImages.forEach((ele) => {
    const bgImage = ele.style.backgroundImage;
    if (bgImage) {
      ele.dataset.realUrl = bgImage;
      const base64Promise = toDataURL({
        src: bgImage.replace('url("', "").replace('")', "").trim(),
      }).then((base64) => {
        ele.style.backgroundImage = `url("${base64}")`;
      });
      promises.push(base64Promise);
    } else if (ele.poster) {
      ele.dataset.realUrl = ele.poster;
      const base64Promise = toDataURL({ src: ele.poster }).then((base64) => {
        ele.poster = base64;
      });
      promises.push(base64Promise);
    } else {
      ele.dataset.realUrl = ele.src;
      const base64Promise = toDataURL({ src: ele.src }).then((base64) => {
        ele.src = base64;
      });
      promises.push(base64Promise);
    }
  });

  await Promise.all(promises);

  // after all images and backgrounds are converted to base64 Take it's Capture
  return captureElement(elementToCapture).then((captureRes) => {
    // AND THEN AFTER SCREENSHOT PUTING ORIGNAL SRC'S TO IMAGES
    backgroundAndImages.forEach((ele) => {
      const bgImage = ele.style.backgroundImage;
      if (bgImage) {
        ele.style.backgroundImage = ele.dataset.realUrl;
      } else if (ele.poster) {
        ele.poster = ele.dataset.realUrl;
      } else {
        ele.src = ele.dataset.realUrl;
      }
      delete ele.dataset.realUrl;
    });
    return captureRes;
  });
};
export const activeLayer = () =>{
  const element = document.querySelector('.editable.active');
  if(element.classList.contains('cs-layer')){
   return element
  }else{
    return element.closest('.cs-layer')
  }
}
export const deActiveElementOnOtherSideClick = (deActiveElement) => {
  const validClasses = [
    "editorCard",
    "zoom-selectbox",
    "MuiDialog-container",
    "jodit-popup",
    "MuiButtonBase-root",
    "MuiSelect-select",
  ];

  const validTags = ["body"];

  const clickListener = (e) => {
    const target = e.target;
    const targetClasses = [...target.classList];
    let shouldDeActive = true;

    const activeElement = document.activeElement;
    const isValidActiveElement =
      activeElement.nodeName === "INPUT" ||
      activeElement.contentEditable === "true";

    if (isValidActiveElement) {
      shouldDeActive = false;
    } else {
      for (let index = 0; index < validClasses.length; index++) {
        const currentClass = validClasses[index];
        if (
          targetClasses.includes(currentClass) ||
          target.closest("." + currentClass) ||
          validTags.includes(target.tagName?.toLowerCase())
        ) {
          shouldDeActive = false;
          break;
        }
      }
    }

    if (
      // shouldDeActive ||
      (targetClasses.includes("editorCard") &&
        targetClasses.includes("main")) ||
      targetClasses.includes("mainWrapper")
    ) {
      deActiveElement();
    }
  };

  document.addEventListener("click", clickListener);
  return () => {
    document.removeEventListener("click", clickListener);
  };
};

export const getElementIndex = (element) => {
  const layerParent = element.parentElement;
  return Array.from(layerParent.children).indexOf(element);
};

export const areElementsHaveSameIndex = (element1, element2) => {
  return getElementIndex(element1) === getElementIndex(element2);
};

export const generateUniqueLetterId = () => {
  const randomString = Math.random().toString(36).substring(2, 8);
  const timestamp = Date.now();
  const uniqueId = randomString + timestamp.toString(36);
  return uniqueId;
};

export const isActiveComponent = (component)=>{
  return document.querySelector(".cs-layer.active")?.classList?.contains(component);
}

export const getNewAssetsAndHTML = (saveSlideDiv, moveToUrl, baseUrl, isMasterTemplateOn) => {
  const assets = [];
  const defaultAssets = [];
  const uploadedAssets = [];
  //step 1 - copy of orginial dom
  const saveDivNodeInstance = $(saveSlideDiv);

  //step 2 - delete layers based on save type slide/Master template
  if(isMasterTemplateOn){
    //if master template is on then delete all cs-layer from instance dom, only keep master Template elements
    saveDivNodeInstance.find('.cs-layer').not('.masterTemplate').remove();
  }else{
    //if master template is off then delete all master Template from instance dom, only keep slide elements
    saveDivNodeInstance.find('.masterTemplate').remove();
  }
  //step 3 - collect assets
  //select all elements that media is changed and need to upload on s3 , by the help of data-src
  //data-src contained the path of asset that should move to target slide, microsite, email folder
  saveDivNodeInstance.find('[data-src], [data-poster], div[style*="/assets/images/"]').each(function () {
    let element  = $(this)
    let elementId = element.attr("id");
    let elementType = "";
    let videoPoster = null;
    let elementSrc = element.attr("data-src");
    let elementPoster = element.attr("data-poster");
    
   
    const bgImg = element.css("background-image");
    if (bgImg && bgImg.includes("url")) {
      elementType = "background";
      elementSrc = bgImg.replace(/url\(['"](.+)['"]\)/, '$1');
    } else if (elementSrc && elementSrc.includes("pdf")) {
      elementType = "pdf";
      const fileName = elementSrc.split("/").pop();
      element.attr("data-src", moveToUrl(elementType, fileName));
    } else {
      if (element.is("audio")) {
        elementType = "audio";
      } else if (element.is("video")) {
        elementType = "video";
        videoPoster = element.attr("poster");
      } else {
        elementType = "images";
      }
    }
    elementType = $.trim(elementType);

    // Pushing founded assets into default or normal array
    const assetDetails = {
      elementId: elementId,
      elementSrc: elementSrc,
      elementType: elementType,
    };
    //if video poster found, then add poster into video object
    if (videoPoster) {
      assetDetails.videoPoster = videoPoster;
    }

    if (elementSrc && elementSrc.includes("createmart-seed-folder")) {
      defaultAssets.push(assetDetails);
    } else {
      assets.push(assetDetails);
    }

    // --Replacing URLs with moveToUrl inside html before save
    // For files Src img,audio,video,
    if (elementSrc && elementSrc.includes("http") && elementType != "background") {
      // if src is not relative then make it relative !
      const fileName = elementSrc.split("/").pop();
      if (element.is("video")) {
        const videoPosterFileName = videoPoster.split("/").pop();
        //only will work if video has data-src
        element.attr("src", moveToUrl(elementType, fileName));
        element.attr("poster", moveToUrl(elementType, videoPosterFileName));
        //collect assets for real dom change
        uploadedAssets.push({elementId, src: baseUrl+moveToUrl(elementType, fileName), poster: baseUrl+moveToUrl(elementType, videoPosterFileName)})
      }else{
        element.attr("src", moveToUrl(elementType, fileName));
        //collect new uploaded path of asset
        uploadedAssets.push({elementId, src: baseUrl+moveToUrl(elementType, fileName)})
      }
    }

    //if video poster found like poster or data-poster
    //check video src is not changed, and poster is changed then update poster url
    if (!elementSrc && elementPoster) {
      const videoPosterFileName = videoPoster.split("/").pop();
      element.attr("poster", moveToUrl(elementType, videoPosterFileName));
      uploadedAssets.push({elementId, poster: baseUrl+moveToUrl(elementType, videoPosterFileName)})
    }

    // FOR BACKGROUND IMAGES
    const bgImage = $(this).css("background-image");
    if (bgImage && bgImage.includes("http") && bgImage.includes("url")) {
      // if src is not relative then make it relative !
      let fileName = bgImage.split("/").pop();
      // removing ")" from file name because it was in b-g url("") format
      fileName = fileName.substring(0, fileName.length - 2);
      $(this).css("background-image", `url("${moveToUrl(elementType, fileName)}")`);
      uploadedAssets.push({elementId, bg: baseUrl+moveToUrl(elementType, fileName)})
    }
  });

  // As data-src only for new added asset, so once we finaizlisze assets and html,
  // then remove data-src from dropzone ele
  $("#dropzone [data-src], #dropzone [data-poster]").each(function () {
    $(this).removeAttr('data-src');
    $(this).removeAttr('data-poster');
  })
  
  
  //updated html with new paths
  //replace url only for presentation, prsentation html build require relative url, while microsite and email require absolute url 
  const replacedUrlHtml = baseUrl.includes('presentation') ? saveDivNodeInstance.html().replaceAll(baseUrl, '') : saveDivNodeInstance.html()
  return { assets, defaultAssets, uploadedAssets, HTML: replacedUrlHtml };
};
export const updateDomAssetsPath = (s3Assets)=>{
  s3Assets.forEach((asset)=>{
    const element = document.getElementById(asset?.elementId);
    if(asset.src){
      element.setAttribute('src', asset.src)
    }
    if(asset.poster){
      element.setAttribute('poster', asset.poster)
    }
    if(asset.bg){
      element.style.backgroundImage = `url(${asset.bg})`
    }
  })
}
export const removeFontSizeFromStr = (htmlString, tags = 'h1, h2, h3, h4, h5, h6')=>{
  // Create a DOMParser
  var parser = new DOMParser();

  // Parse the HTML string
  var doc = parser.parseFromString(htmlString, 'text/html');

  // Get all heading elements within the parsed document
  var headings = doc.querySelectorAll(tags);

  // Remove the font-size property from each heading
  headings.forEach(function(heading) {
    heading.style.fontSize = null;
  });

  return doc.body.innerHTML
}
export const findSlideIndxes = (chapters, slideId) => {
  for (let chapterIndex = 0; chapterIndex < chapters.length; chapterIndex++) {
    const slides = chapters[chapterIndex].slides;
    for (let slideIndex = 0; slideIndex < slides.length; slideIndex++) {
      if (slides[slideIndex]._id === slideId) {
        return { chapterIndex, slideIndex };
      }
    }
  }
  return null; // If not found
};

export const isEditor = {
  presentation: () => window.location.href.includes('/presentation/'),
  email: () => window.location.href.includes('/email/'),
  microsite: () => window.location.href.includes('/microsite/')
};
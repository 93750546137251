import React, { useState } from "react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { MdFileDownload } from "react-icons/md";

import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

import DropDown from "src/components/ui/DropDown";

// Mock Data for Exports
const mockExportData = [
  {
    id: "1",
    version: "Export 1",
    target: "Application",
    createdAt: "2024-10-01",
    status: "Completed",
  },
  {
    id: "2",
    version: "Export 2",
    target: "PDF",
    createdAt: "2024-10-02",
    status: "Pending",
  },

];

const ExportTableView = () => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("version");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...mockExportData].sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        {/* Table Header */}
        <TableHead>
          <TableRow>
            {exportHeaders.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="right">ACTION</TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {sortedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((exportItem) => (
              <TableRow hover tabIndex={-1} key={exportItem.id}>
                <TableCell align="left">{exportItem.version}</TableCell>
                <TableCell align="left">{exportItem.target}</TableCell>
                <TableCell align="left">{exportItem.createdAt}</TableCell>
                <TableCell align="left">{exportItem.status}</TableCell>
                <TableCell align="right">
                  {exportItem.status === "Completed" && (
                    <DropDown
                      dropIcon={<IoEllipsisVerticalSharp size={16} />}
                      tooltip="options"
                      items={[
                        {
                          label: "Download",
                          icon: <MdFileDownload />,
                          onClick: () => console.log("Download clicked"),
                        },
                      ]}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      {/* Table Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: mockExportData.length }]}
        component="div"
        count={mockExportData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default ExportTableView;

// Table Headers Configuration
const exportHeaders = [
  { id: "version", label: "VERSION" },
  { id: "target", label: "TARGET" },
  { id: "createdAt", label: "DATE" },
  { id: "status", label: "STATUS" },
];

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@mui/material";
import PreviewPage from "../../../pages/presentations/PreviewPage";
import {
  clearPresentationData,
  setPreiewPresentation,
} from "src/store/reducers/presentations-slice";

import { checkIfReviewed, review } from "src/store/actions/reviews";
import { getPresentaionById, getSlide } from "src/store/actions/presentations";
const PreviewPresentation = (props) => {
  const type = "presentation";
  const dispatch = useDispatch();
  const [isLoadingApprove, setIsLoadingApprove] = useState(false);
  const [isLoadingDeclined, setIsLoadingDeclined] = useState(false);

  const {
    preview: { presentationId, isOpen, reviewId, isReviewed, versionId },
  } = useSelector((state) => state.presentations);

  const approveOrDecline = (status) => {
    status ? setIsLoadingApprove(true) : setIsLoadingDeclined(true);
    if (reviewId) {
      const data = { status };
      dispatch(review({ id: reviewId, data, type })).then((res) =>{
        status ? setIsLoadingApprove(false) : setIsLoadingDeclined(false)
      })
    }
  };

  // To clear editor's data
  const clearData = () => {
    dispatch(clearPresentationData());
  };

  // To get presentationData
  const getPresenationData = (id) => {
    dispatch(getPresentaionById(id));
  };

  // To get slide's data
  const getSlideDispatch = (presentationId, slideId) => {
    dispatch(
      getSlide({
        presentationId,
        slideId,
      })
    );
  };

  const handleClose = () => {
    dispatch(setPreiewPresentation({ presentationId, isOpen: false }));
    clearData();
  };

  useEffect(() => {
    if (reviewId) {
      dispatch(checkIfReviewed({ type, id: reviewId }));
    }
  }, [reviewId]);

  useEffect(() => {
    if (isOpen) {
      getPresenationData(presentationId);
    }
  }, [isOpen]);

  useEffect(() => {
    return () => {
      dispatch(setPreiewPresentation({ isOpen: false }));
    };
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          height: "100%",
        },
      }}
      fullScreen
      fullWidth
    >
      <PreviewPage
        isReviewed={isReviewed}
        presentationId={presentationId}
        reviewPresentation={approveOrDecline}
        handleClose={handleClose}
        versionId={versionId}
        getSlideDispatch={getSlideDispatch}
        isLoadingApprove={isLoadingApprove}
        isLoadingDeclined={isLoadingDeclined}
      />
    </Dialog>
  );
};

export default PreviewPresentation;

import React from "react";
import { ReactComponent as FolderLogo } from "src/assets/images/FolderIcon.svg";
import TextLimit from "../ui/TextLimit";
import './Folder.scss'
const Folder = ({ onClick = () => {}, name = 'Folder', controls = null, className='' }) => {
  return (
    <div className={`folder-box ${className}`} onDoubleClick={onClick}>
      <div className="content">
        <div className="folder-icon">
          <FolderLogo width={35} height={35} />
        </div>
        <div className="foldername">
          <TextLimit width="100%" text={name} />
        </div>
      </div>
      <div className="controls">
        {controls && controls}
      </div>
    </div>
  );
};

export default Folder;

import { Box, Typography } from "@mui/material";
import React from "react";
import emptyfolder from "src/assets/images/empty-folder.png";
import './Empty.scss'
const EmptyPage = () => {
  return (
    <Box className="empty-folder">
      <img src={emptyfolder} alt="No Folders" style={{maxWidth:'150px', aspectRatio:0}} />
      <Box className="empty-text">
        <Typography className="empty-heading" variant="h2">
          Currently folder is empty
        </Typography>
        <Typography className="empty-subtext">
          Please 'Add New' or 'Move' files into this folder
        </Typography>
      </Box>
    </Box>
  );
};

export default EmptyPage;

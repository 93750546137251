import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormControl, Select, MenuItem } from "@mui/material";
import { getEditableElements } from "src/services/editor.service";
import Control from "./Control";
import "src/styles/properties.scss";
import { uiActions } from "src/store/reducers/ui-slice";

const ActiveDropDown = () => {
  const dispatch = useDispatch();
  const activeEditable = useSelector((state) => state.editor.activeEditable);
  const undoRedoChange = useSelector(
    (state) => state.editor.history.backStack.length
  );
  const [layer, setLayer] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [layersList, setLayersList] = useState([]);
  const activeRef = useRef();

  // Custom Events
  const dblClick = useRef(
    new MouseEvent("dblclick", {
      view: window,
    })
  );
  const mouseOver = useRef(
    new MouseEvent("mouseover", { bubbles: true, cancelable: true })
  );
  const mouseOut = useRef(
    new MouseEvent("mouseout", { bubbles: true, cancelable: true })
  );

  // Adding and removing eventlisteners on render/unrender
  useEffect(() => {
    document.addEventListener("keyup", keysHandler);
    document.body.addEventListener("click", closeHandler);
    return () => {
      document.removeEventListener("keyup", keysHandler);
      document.body.removeEventListener("click", closeHandler);
    };
  }, []);

  // Setting Layers List and handling dropdown open/close
  useEffect(() => {
    const data = getEditableElements();
    setIsOpen(false);
    setLayersList(data.options);
    setLayer(data.activeId);
  }, [activeEditable, undoRedoChange]);

  // Handling the event whenever dropdown value is changing
  const layerChange = useCallback((e) => {
    const active = document.getElementById(e.target.value);
    active.click();
    removeHoverStatuses();
  }, []);

  // Handling keybindings for Deleting Layer
  const deleteBtn = useCallback(
    (e) => {
      const deleteBtn = document.getElementById("layerDeleteBtn");
      if (e.key == "Delete") {
        if (deleteBtn) {
          deleteBtn.click();
        } else {
          dispatch(
            uiActions.showNotification({
              status: "warning",
              title: "Delete",
              message: "Only Main Layer Can Be Deleted",
            })
          );
        }
      }

      const confirmDeleteBtn = document.getElementById("confirmDeleteButton");
      if (e.key == "Enter") {
        if (confirmDeleteBtn) {
          confirmDeleteBtn.click();
        }
      }
    },
    [activeEditable]
  );

  // Closing Dropdown Handler
  const closeHandler = useCallback((e) => {
    if (e.target.id !== "layerElementSelect") {
      setIsOpen(false);
    }
  }, []);

  // Handling the movement of active layer from child to parent through short key
  //  SHORT-KEY : ---->>> ALT + O <<<-----
  const selectParent = useCallback((e) => {
    activeRef.current = document.getElementById(getEditableElements().activeId);
    const parent = activeRef.current.parentElement;
    if (e.altKey && e.key == "o") {
      if (
        parent &&
        parent.classList.contains("editable") &&
        !parent.classList.contains("cs-slide")
      ) {
        parent.click();
      } else if (
        parent.closest(".editable") &&
        !parent.classList.contains("cs-slide")
      ) {
        parent.closest(".editable").click();
      } else {
        parent.dispatchEvent(dblClick.current);
      }
    }
  }, []);

  // Handling the movement of active layer from parent to child through short key
  //  SHORT-KEY : ---->>> ALT + I <<<-----
  const selectChild = useCallback((e) => {
    activeRef.current = document.getElementById(getEditableElements().activeId);
    const child = activeRef.current.querySelector(".editable");
    if (e.altKey && e.key == "i") {
      if (child) {
        if (activeRef.current.classList.contains("cs-slide")) {
          const layer = activeRef.current.querySelector(
            ".cs-layer.editable:not(.popup-component)"
          );
          if (layer) {
            layer.click();
          }
        } else {
          child.click();
        }
      }
    }
  }, []);

  // This Function is just grouping all keybinding functions - So can be called in single listener
  const keysHandler = useCallback((e) => {
    selectParent(e);
    selectChild(e);
    deleteBtn(e);
  }, []);

  // This function is hovering the component that is under the mouse in dropdown list - layer element list
  const elementHoverHandler = useCallback(
    (e) => {
      const element = document.getElementById(e.target.dataset.value);
      element.dispatchEvent(mouseOver.current);
      if (!isOpen) {
        removeHoverStatuses();
      }
    },
    [isOpen]
  );

  // This function is unhovering the component that was previously under the mouse in dropdown list - layer element list
  const elementUnhoverHandler = useCallback((e) => {
    const element = document.getElementById(e.target.dataset.value);
    element.dispatchEvent(mouseOut.current);
  });

  // This is a reuseable function which is being used to remove hover and status from components
  const removeHoverStatuses = () => {
    const mainWrapper = document.getElementById("mainWrapper");
    const hoveredElements = mainWrapper.querySelectorAll(".hover");
    const statuses = mainWrapper.querySelectorAll(".status");
    hoveredElements.forEach((ele) => ele.classList.remove("hover"));
    statuses.forEach((ele) => (ele.style.display = "none"));
  };

  return (
    <div className="active_layer_dropdown">
      <FormControl fullWidth>
        <Select
          id="layerElementSelect"
          className="active_layer_select"
          classes={{
            select: "active_layer_input",
          }}
          value={layer}
          open={isOpen}
          onClick={(e) => {
            setIsOpen(true);
          }}
          onChange={layerChange}
        >
          {layersList.map((layer) => (
            <MenuItem
              key={layer?.id}
              value={layer?.id}
              onMouseOver={elementHoverHandler}
              onMouseOut={elementUnhoverHandler}
            >
              {layer?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ActiveDropDown;

export const useDateFormat = (time) => {
  const date = new Date(time);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const dateInSecond = (date, reset = false) => {
  const selectedDate = new Date(date);
  if (reset) {
    selectedDate.setHours(23);
    selectedDate.setMinutes(59);
    selectedDate.setSeconds(59);
  }
  return selectedDate;
};

// prevent user to select past date
export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  return yyyy + "-" + mm + "-" + dd;
};


export const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

//date input will be pass
export const isDateExpire = (dateInputValue) => {
  const dateTimestamp = new Date(dateInputValue).getTime(); // Convert to timestamp in milliseconds

  const currentTimestamp = Date.now(); // Get current timestamp in milliseconds

  if (dateTimestamp < currentTimestamp) {
    return true;
    console.log("The date has expired.");
  } else {
    return false;
    console.log("The date is still valid.");
  }
}

export const formatIsoDate = (inputDate)=>{
  // Format date to YYYY-MM-DD
  const date = new Date(inputDate);
const formattedDate = date.toISOString().split('T')[0];
return formattedDate
}
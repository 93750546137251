import { useSelector } from "react-redux";
import { useMemo } from "react";

const useFilteredUserList = () => {
   const { list: userList,  } = useSelector((state) => state.user);
   const loginUserEmail = useSelector((state) => state.auth.userData.email);

   const filteredUserList = useMemo(() => {
      if (!userList) return [];

      return userList
         .filter(
            (user) =>
               user.email !== loginUserEmail && user.role !== "developer"
         )
         .map((user) => ({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            _id: user._id,
         }));
   }, [userList, loginUserEmail]);

   return { filteredUserList };
};

export default useFilteredUserList;

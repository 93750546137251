import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { IoChevronDownOutline, IoTrash } from "react-icons/io5";
import { RiDeleteBin2Line } from "react-icons/ri";
import { removePx } from "src/utils/Utils";
import './Selectbox.scss';
const SelectBox = (props) => {
  return (
    <div className={`selectField ${props.className || ''}`}>
      {props.labelTag && (
        <Typography variant="body1" component="label" className="labelTag">
          {props.labelTag}
        </Typography>
      )}
      <FormControl
        className={props.classes}
        fullWidth={props.autoWidth ? false : true}
        disabled={props.disabled ? true : false}
      >
        <InputLabel>{props.inputLabel}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          value={props.value || ""}
          defaultValue={props.defaultValue}
          label={props.label}
          name={props.name}
          onChange={props.onChange}
          inputProps={props.inputProps}
          IconComponent={IoChevronDownOutline}
          autoWidth={props.autoWidth ? true : false}
          placeholder={props.placeholder}
          required={props.required}
          className={props.className}
          disabled={props.disabled}
          renderValue={props.value !== "" ? undefined : () => props.renderValue}
          displayEmpty={props.displayEmpty || props.placeholder}
          {...(props.variant ? { variant: props.variant } : {})}
        >
          {props.placeholder && (
            <MenuItem disabled value="">
              <b>{props.placeholder}</b>
              
            </MenuItem>
          )}
          {(props.options || []).map((option, index) => (
            <MenuItem
              key={index}
              value={option.value || option}
              disabled={option.disabled}
            >
              <div className="label">{option.label || option[props?.optionLabelKey]}</div>
              {props.name == "targetDevice" && <div className="otherValue">{removePx(option.width)}  X  {removePx(option.height)}</div>}
            </MenuItem>
          ))}
          {(props.value && props.handleClear) && (
            <MenuItem>
            <Button size="small" variant="contained" endIcon={<IoTrash fontSize="inherit"/>} onClick={props.handleClear}>Remove Template</Button>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBox;

import { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Switch,
  styled,
  FormGroup,
  FormControlLabel,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Input from "src/components/ui/formElements/Input";
import { Link } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import cookie from "react-cookies";
import { useForm } from "react-hook-form";
import InputFields from "../ui/formElements/InputFields";
import { useDispatch } from "react-redux";
import { login } from "src/store/actions/auth";
import { emailValidator } from "src/services/editor.service";

const SwitchBtn = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginLeft: 15,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const LoginForm = (props) => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();


  const defaultValues = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const email = watch("email");
  const password = watch("password");

  const loginUserHandler = () => {
    if (!emailValidator(email)) {
      setError("email", { type: "manual", message: "Email is Invalid" })
      return;
    }

    setIsLoading(true);
    dispatch(login({ email, password }))
      .then((res) => {
        console.log(res)
        setIsLoading(false)
      })
      .catch(() => setIsLoading(false));
  };

  const forgotHandler = () => { };
  const isValid = () => {
    return email && password;
  };

  useEffect(() => {
    cookie.save("rememberMe", { rememberMe: checked });
  }, [])

  const handleSwitchChange = () => {
    setChecked(!checked)
    cookie.save("rememberMe", { rememberMe: !checked });
  }

  return (
    <form>
      <Box className="loginForm">
        <Typography variant="h6" className="sign-in-text">
          Please Sign in with your account
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFields
              fieldName="email"
              type="email"
              label="Email"
              control={control}
              rules={{
                required: "Email is required",
              }}
              error={errors?.email}
              gap
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputFields
              fieldName="password"
              label="Password"
              control={control}
              rules={{
                required: "Password is required",
              }}
              error={errors?.password}
              type="password"
            />
          </Grid>
        </Grid>
        {/* <Box className="ControlWrap">
          <Input
            labelTag="Username or Email"
            label="Username or Email"
            type="email"
            name="email"
            value={email}
            placeholder="Enter text here..."
            onChange={(e) => setEmail(e.target.value)}
            className="login-input"
          />
        </Box>
        <Box className="ControlWrap">
          <Input
            labelTag="Password"
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            placeholder="Your Password"
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            inputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                </IconButton>
              ),
            }}
          />
        </Box> */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className="login-row-container"
        >
          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<SwitchBtn
                  checked={checked}
                  onChange={handleSwitchChange}
                />}
                label="Remember me"
                classes={{ label: "switch-label" }}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <Typography
              component="p"
              className="link-txt"
              onClick={forgotHandler}
            >
              <Link to="/forgot-password">Forgot Password?</Link>
            </Typography>
          </Grid>
        </Grid>
        <Box className="pt-4">
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ letterSpacing: "1.2px" }}
            disabled={!isValid() || isLoading}
            onClick={handleSubmit(loginUserHandler)}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ marginLeft: 1 }} />
            ) : (
              "SIGN IN"
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default LoginForm;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Tooltip, Box, Stack, IconButton, CircularProgress } from "@mui/material";
import {
  IoEye,
  IoPencil,
  IoTrash,
  IoEllipsisVerticalSharp,
  IoCopyOutline,
} from "react-icons/io5";
import Confirm from "src/components/dialogs/Confirm";
import DropDown from "src/components/ui/DropDown";
import { useDispatch } from "react-redux";
import { cloneMasterTemplate, deleteMasterTemplate } from "src/store/actions/presentations";
import { hideOnStatus } from "src/services/utils";
import { toDataURL } from "src/hooks/useUrlToBase64";
import PermissionComp from "src/components/Permission";

const MasterTemplateControls = (props) => {
  //to close dropdown on click we need to return true on each dropdown handler
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isCloneLoading, setIsCloneLoading] = useState(false);

  const {
    presentationId,
    status,
    template,
    templateIndex,
    showRenameTemplateDialog,
    imageURL,
    thumbnailURL
  } = props;

  const { _id: masterTemplateId, name: templateName } = template;

  const [isOpen, setisOpen] = useState(false);
  const [cloneDialogIsOpen, setCloneDialogIsOpen] = useState(false);

  const handleClose = () => {
    setisOpen(false);
  };

  const handleCloneOpen = () => {
    setCloneDialogIsOpen(true);
  };

  const handleCloneClose = () => {
    setCloneDialogIsOpen(false);
  };

  const deleteHandle = () => {
    setisOpen(true);
  };

  const templateDelete = () => {
    dispatch(deleteMasterTemplate({ presentationId, masterTemplateId })).then(() => {
      setisOpen(false)
    }).finally(() => {
      setisOpen(false)
    })
  };

  const templateClone = async () => {
    // showing local loading

    setIsCloneLoading(true);
    const res = await Promise.allSettled([
      toDataURL({
        src: imageURL,
        type: 'image'
      }),
      toDataURL({
        src: thumbnailURL,
        type: 'image'
      }),
    ]);

    setIsCloneLoading(false);
    const [{ value: image }, { value: thumbnail }] = res;
    dispatch(
      cloneMasterTemplate({
        presentationId,
        masterTemplateId,
        templateIndex,
        image,
        thumbnail
      })
    ).finally(() => {
      setIsCloneLoading(false);
      handleCloneClose()
    })
  };

  const renameHandle = () => {
    showRenameTemplateDialog(template)
  }
  const dropDownData = [
    // { label: "Share Presentation", icon: <IoShare />, onClick: shareHandler },
    { label: "Rename", icon: <IoPencil />, onClick: renameHandle },
    { label: "Delete", icon: <IoTrash />, onClick: deleteHandle },
  ];

  //other handler will be create here
  return (
    <>
      <Confirm
        isOpen={isOpen}
        handleClose={handleClose}
        handleConfirm={templateDelete}
        title="Are you sure"
        subtitle=" you want to delete this Master template?"
      />
      <Confirm
        isOpen={cloneDialogIsOpen}
        handleClose={handleCloneClose}
        handleConfirm={templateClone}
        variant="success"
        title="Are you sure"
        subtitle="Are you sure you make clone for this template?"
        confirmLoading={isCloneLoading}
      />
      <Grid item md={8}>
        <Stack direction="row" spacing="1">
          <Tooltip title="View" arrow>
            <IconButton
              variant="outlined"
              onClick={() =>
                navigate(`/presentation/${presentationId}/view/${masterTemplateId}/template`)
              }
            >
              <IoEye />
            </IconButton>
          </Tooltip>

          {!hideOnStatus?.[status]?.edit && (
            <Tooltip title="Edit" arrow>
              <IconButton
                variant="outlined"
                onClick={() =>
                  navigate(`/presentation/${presentationId}/edit/${masterTemplateId}/template`)
                }
              >
                <IoPencil />
              </IconButton>
            </Tooltip>
          )}

          {/* for clone template */}
          <PermissionComp permissionPath="presentation.create.clone">
            <Tooltip title="Clone" arrow>
              <IconButton variant="outlined" onClick={handleCloneOpen}>
                {isCloneLoading ? (
                  <Box sx={{ width: "100%" }}>
                    <CircularProgress size={20} />
                  </Box>
                ) : (
                  <IoCopyOutline />
                )}
              </IconButton>
            </Tooltip>
          </PermissionComp>


          {/* <IconButton variant="outlined">
            <IoDocument />
          </IconButton> */}
        </Stack>
      </Grid>
      <Grid item md={4}>
        <Box style={{ textAlign: "right" }}>
          <DropDown
            tooltip="More"
            dropIcon={<IoEllipsisVerticalSharp />}
            items={dropDownData}
          />
        </Box>
      </Grid>
    </>
  );
};
export default MasterTemplateControls;

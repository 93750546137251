import React from "react";
import "./Card.scss";
const Card = (props) => {
  const { classes = '', style, children, cardStyle = 'card2' } = props;
  const className = `${cardStyle} ${classes && classes}`;
  
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default Card;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "src/components/Button";
import { Box } from "@mui/material";
import { countries, languages, timezones } from "src/data/data";
import InputFields from "src/components/ui/formElements/InputFields";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";
import { useForm } from "react-hook-form";
import SelectFields from "src/components/ui/formElements/SelectFields";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createUser, getUser, updateUser } from "src/store/actions/users";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import Card from "src/components/ui/Card";
import "src/styles/users/form.scss";
const UsersForm = ({cancelPath, isEditProfile}) => {
  const { id } = useParams();
  const { company } = useSelector((state) => state.auth.userData);
  const companyId = company?.companyId || company?._id; 

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Setting Default Values For Form
  const defaultValues = useForm({
    defaultValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobile: "",
      lang: "",
      timezone: "",
      title: "",
      company: {
        companyId: company ? company.companyId : "",
        name: "",
        UID: "",
        assetReview: null,
      },
      role: "",
    },
  });

  // Initialization For React-Hook-Form

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const { isLoading, notification } = useSelector((state) => state.ui);
  const [doNavigate, setDoNavigate] = useState(false);


  useEffect(() => {
    if (id)
      dispatch(getUser({ id, companyId })).then((res) => {
        reset(res.payload);
      });
  }, [id]);

  useEffect(() => {
    if (notification?.status === "success" && doNavigate) {
      navigate(-1);
    }
    clearErrors();
    if (notification?.message === "User already exists") {
      setError(
        "email",
        { message: notification?.message },
        { shouldFocus: true }
      );
    }
  }, [notification]);

  const onSubmitHandler = (data) => {
    console.log(data)
    if (id) {
      dispatch(updateUser({ data, id }));
    } else {
      dispatch(createUser(data));
    }
    setDoNavigate(true);
  };

  const roles = [
    { label: "Admin", value: "admin" },
    { label: "Developer", value: "developer" },
    { label: "Reviewer", value: "reviewer" },
  ];
  const handleCancel = ()=>{
    navigate(cancelPath);
  }
  return (
    <form>
      <Card cardStyle="card" className="form-card">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <InputFields
              className="outlined"
              fieldName="firstName"
              type="text"
              label="First Name"
              control={control}
              rules={{
                required: "First Name is required",
              }}
              error={errors?.firstName}
              placeholder="Enter your first name"
              labelTag
            />
          </Grid>

          <Grid item xs={4}>
            <InputFields
              className="outlined"
              fieldName="middleName"
              type="text"
              label="Middle Name"
              control={control}
              error={errors?.middleName}
              placeholder="Enter your middle name"
              labelTag
            />
          </Grid>

          <Grid item xs={4}>
            <InputFields
              className="outlined"
              fieldName="lastName"
              type="text"
              label="Last Name"
              control={control}
              rules={{
                required: "Last Name is required",
              }}
              error={errors?.lastName}
              placeholder="Enter your last name"
              labelTag
            />
          </Grid>

          <Grid item xs={4}>
            <InputFields
              className="outlined"
              fieldName="email"
              type="email"
              label="Email"
              control={control}
              rules={{
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                  message: "Incorrect Email",
                },
                required: "Email is required",
              }}
              labelTag
              error={errors.email}
              disabled={id ? true : false}

            />
          </Grid>
          <Grid item xs={4}>
            <InputPhoneField
              fieldName="mobile"
              type="tel"
              label="Mobile"
              control={control}
              rules={{
                minLength: {
                  value: 11,
                  message: "Mobile should have at least 11 characters",
                },
                maxLength: {
                  value: 16,
                  message: "Mobile limit 16 characters",
                },
                // required: "Mobile is required",
              }}
              labelTag
              error={errors?.mobile}
              phone
            />
          </Grid>
          <Grid item xs={4}>
            <SelectFields
              fieldName="role"
              label="Role"
              control={control}
              options={roles.map((lang) => {
                return { label: lang.label, value: lang.value };
              })}
              rules={{
                required: "Role is required",
              }}
              labelTag
              error={errors?.role}
              withSearch
              disabled={isEditProfile}
            />
          </Grid>
          <Grid item xs={4}>
            <SelectFields
              fieldName="lang"
              label="Language"
              control={control}
              options={languages.map((lang) => {
                return {
                  label: lang.label,
                  value: lang.value,
                };
              })}
              labelTag
              withSearch
            />
          </Grid>
          <Grid item xs={4}>
            <SelectFields
              fieldName="timezone"
              label="Timezone"
              control={control}
              options={timezones.map(({ label, value }) => {
                return { label, value };
              })}
              labelTag
              withSearch
            />
          </Grid>
          <Grid item xs={4}>
            <InputFields
              fieldName="title"
              type="text"
              label="Title"
              control={control}
              placeholder="Enter your front end title"
              labelTag
            />
          </Grid>
        </Grid>
      </Card>

      {/* <AddressView
      control={control}
      errors={errors}
      watch={watch}
      resetField={resetField}
    /> */}
      <Stack direction="row" justifyContent="flex-end" gap={2} sx={{mt:2}}>
        <Button
          onClick={handleCancel}
          type="submit"
          variant="outlined"
          disabled={isLoading}
          startIcon={<IoCloseOutline />}
        >
          Cancel Changes
        </Button>
        <Button
          onClick={handleSubmit(onSubmitHandler)}
          type="submit"
          variant="contained"
          disabled={isLoading}
          startIcon={<IoCheckmarkOutline />}
        >
          Update Changes
        </Button>
      </Stack>
    </form>
  );
};

export default UsersForm;

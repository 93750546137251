import { getUnit } from "@mui/material/styles/cssUtils";
import React, { useEffect, useState, useCallback } from "react";
import Row from "src/components/properties/Row";
import { useDebouncedCallback } from "src/hooks/useDebouncedCallback";
const SingleProperty = ({
  elementId,
  undoRedoChange,
  updateHistory,
  heading,
  cssProperty,
  fallbackUnit = "px",
  onChangeCallback,
  onClearCallback,
  inputProps
}) => {
  const [value, setValue] = useState("");
  const [unit, setUnit] = useState();
  let element  = document.getElementById(elementId);
  // If cssProperty is not provided, render nothing
  if (!cssProperty) {
   return null;
 }
 
 const updateHistoryCall = useDebouncedCallback(() => {
  if(onChangeCallback){
    onChangeCallback()
  }
  updateHistory();
}, 500); // 500ms delay

  useEffect(() => {
    // Dynamically get the current value of the passed CSS property
    // const elementStyle = getComputedStyle(element)[cssProperty];
    const elementStyle = element?.style[cssProperty];
    // console.log('elementStyle', elementStyle)
    if(elementStyle){
      const getValue = parseInt(elementStyle);
      if(getValue){
        setValue(getValue);
        setUnit(getUnit(elementStyle));
      }else{
        setUnit(fallbackUnit)
      }
    }else{
      setUnit(fallbackUnit)
    }
  }, [elementId, undoRedoChange]);
  
  const inputHandler = (event) => {
    let { value } = event.target;
    element.style[cssProperty] = value + unit;
    setValue(value);
    updateHistoryCall(value)
  };
  
  const onClear = ()=>{
    setValue("")
    if(onClearCallback){
      onClearCallback()
    }
    updateHistory();
  }

  return (
    <Row
      label={heading || cssProperty}
      clearProperty={cssProperty}
      canvasElement={element}
      onClear={onClear}
      element={
        <input
          type="number"
          name="left"
          className="input"
          value={value}
          onChange={inputHandler}
          {...inputProps}
        />
      }
    />
  );
};
export default SingleProperty;

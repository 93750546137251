import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";

//Icons
import { IoAdd, IoCreate, IoCloudUploadOutline } from "react-icons/io5";

//APIs
import {
  fetchPresentations,
  updatePresentationSetting,
} from "src/store/actions/presentations";

//MUI Componenets
import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import DropDown from "src/components/ui/DropDown";
import Pagination from "src/components/ui/Pagination";
import PresentationCard from "src/components/presentations/PresentationCard";

//Dialogs
import MyLibraryDialogs from "src/components/dialogs/presentations/MyLibrary-Dialogs";
import Permission from "src/components/Permission";
import FolderList from "src/components/folder";
import Breadcrumbs from "src/components/folder/FolderBreadcrumbs";
import MoveDialog from "src/components/folder/MoveDialog";
import "./MyLibrary.scss";
import { clearFolder } from "src/store/reducers/folder-slice";
import { removePresentationFromUi } from "src/store/reducers/presentations-slice";
const MyLibrary = () => {
  const limit = 12;
  const dispatch = useDispatch();
  const {
    list: presentations,
    count,
    loadingMsg,
  } = useSelector((state) => state.presentations);

  const [searchParams] = useSearchParams();
  const folderId = searchParams.get("folderId") || null;
  const [details, setDetails] = useState({});
  const [presentationId, setPresentationId] = useState("");
  const [moveableItem, setMoveableItem] = useState(null);
  const [fetchPresentationsLoading, setFetchPresentationsLoading] = useState(false);

  //Dialogs State
  const [dialog, setDialog] = useState({});

  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;

    const params = {};
    searchParams.forEach((value, key) => {
      if (key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;
    setFetchPresentationsLoading(true)
    dispatch(fetchPresentations(params)).then(()=>{
      setFetchPresentationsLoading(false)
    })

    return () => {
      dispatch(clearFolder())
    }

  }, [searchParams]);

  const dropDownData = useMemo(() => {
    return [
      {
        label: "Create Folder",
        icon: <IoCreate />,
        onClick: () => {
          openDialog("", "createfolder");
        },
      },
      {
        label: "Create New",
        icon: <IoCreate />,
        permissionPath: "presentation.create.createViaTemplate",
        onClick: () => {
          openDialog("", "createNew");
        },
      },
      {
        label: "Upload From",
        icon: <IoCloudUploadOutline />,
        permissionPath: "presentation.create.uploadZipFile",
        onClick: () => {
          openDialog("", "uploadNew");
        },
      },
    ];
  }, []);

  const handleMovePresentation = (selectedFolder = null) => {
    dispatch(
      updatePresentationSetting({
        data: { folderId: selectedFolder },
        id: moveableItem?._id,
      })
    ).then(() => {
      setMoveableItem();
      // dispatch(getFoldersList({ parentId: folderId, type: moduleType }));
      dispatch(removePresentationFromUi(moveableItem?._id))
    });
  };

  //presentation info
  const showDetails = useCallback((presentation) => {
    setPresentationId(presentation._id);
    setDetails(presentation);
    setDialog({ info: true });
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name, device, presentation) => {
    setPresentationId(id);
    setDialog({ [name]: true, device: device });
    setDetails(presentation);
  }, []);

  return (
    <>
      {/* Dialogs */}
      <MyLibraryDialogs
        dialog={dialog}
        presentation={details}
        presentationId={presentationId}
        onClose={() => setDialog({})}
      />
      {/* Header */}
      <div className="headersection">
        <Breadcrumbs count={count} />
        <Permission permissionPath="presentation.create">
          <DropDown
            dropText="Add New"
            tooltip="options"
            startIcon={<IoAdd />}
            items={dropDownData}
          />
        </Permission>
      </div>
      <FolderList items={presentations.length} />

      {(!presentations.length && !fetchPresentationsLoading && !folderId) && (
        <Typography variant="h1" pl={3} pt={2}>
          No Presentaion Found
        </Typography>
      )}
      
      {presentations.length > 0 && (
        <Grid container spacing={1.5} >
          {presentations.map((presentation) => {
            if (presentation.folderId == folderId)
              return (
                <PresentationCard
                  key={presentation._id}
                  presentation={presentation}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role="owner"
                  setMoveableItem={setMoveableItem}
                />
              );
            return "";
          })}
        </Grid>
      )}
      
      {!fetchPresentationsLoading && (
        <Pagination
          count={count}
          itemsPerPage={limit}
          enableParams
          onChange={(value) => {
            if (
              !searchParams.get("search") &&
              !searchParams.get("language") &&
              !searchParams.get("country")
            ) {
              dispatch(fetchPresentations(value));
            }
          }}
        />
      )}
      <MoveDialog
        show={moveableItem}
        close={() => setMoveableItem()}
        moveableItem={moveableItem}
        onSubmit={handleMovePresentation}
      />
    </>
  );
};
export default React.memo(MyLibrary);

import React, { Navigate } from "react-router-dom";

import NotFound from "../pages/NotFound";
import MyAccount from "../pages/profile/EditProfile";

import PageContent from "../layouts/PageContent";

//My Library
import PresentationMyLibrary from "../pages/presentations/MyLibrary";
import MicrositesMyLibrary from "../pages/microsites/MyLibrary";
import EmailMyLibrary from "../pages/emailTemplates/MyLibrary";
import GlobalLibrary from "../pages/global/GlobalLibrary";

//Shared With Me
import SharedWithMe from "../pages/presentations/SharedWithMe";
import EmailSharedWithMe from "../pages/emailTemplates/SharedWithMe";
import MicrositesSharedWithMe from "../pages/microsites/SharedWithMe";

//Editors
import PresentationEditor from "../pages/presentations/PresentationEditor";
import MicrositeEditor from "../pages/microsites/MicrositeEditor";
import EmailEditor from "../pages/emailTemplates/EmailEditor";

//Preview
import PresentationPreview from "../pages/presentations/PresentationPreview";
import MicrositePreview from "../pages/microsites/MicrositePreview";
import EmailPreview from "../pages/emailTemplates/EmailPreview";

//Digital Assets
import Assets from "../pages/digitalAssets/Assets";
import Vault from "../pages/digitalAssets/Vault";

//Details Page
import PresentationDetails from "../pages/presentations/Details";
import MicrositeDetails from "../pages/microsites/Details";
import EmailDetails from "../pages/emailTemplates/Details";

//Structure Page
import Structure from "../pages/presentations/Structure";

//Publish Page
import Publish from "../pages/presentations/Publish";

//Localize Page
import Localize from "../pages/presentations/Localize";

//Review Page
import PresentationReviewDetail from "../pages/presentations/PresentationReviewDetail";
import MicrositeReviewDetail from "../pages/microsites/ReviewDetail";
import EmailReviewDetail from "../pages/emailTemplates/EmailReviewDetail";
import AssetReviewDetail from "../pages/digitalAssets/AssetReviewDetail";

//Review Tables
import PresentationReviewTable from "../pages/reviews/PresentationReview";
import LandingPageReview from "../pages/reviews/LandingPageReview";
import EmailReviewTable from "../pages/reviews/EmailReview";
import AssetsReview from "../pages/reviews/AssetsReview";

//Integration
import Integration from "../pages/integration/Integration";
import Salesforce from "../pages/integration/Salesforce";

//Settings
import PresentationSetting from "../pages/presentations/settings/Setting";
import MicrositeSetting from "../pages/microsites/Settings";
import EmailSetting from "../pages/emailTemplates/Settings";

//import tabs of all pages from _nav.js
import { nav } from "../layouts/sidebar/_nav";

//Campaign
import Campaign from "../pages/emailCampaign/campaigns/Campaign";
import CreateCampaign from "../pages/emailCampaign/campaigns/CreateCampaign";
import UpdateCampaign from "../pages/emailCampaign/campaigns/UpdateCampaign";
import Contact from "../pages/emailCampaign/contacts/Contact";
import CreateContact from "../pages/emailCampaign/contacts/CreateContact";
import UpdateContact from "../pages/emailCampaign/contacts/UpdateContact";
import Groups from "../pages/emailCampaign/groups/Groups";
import CreateGroup from "../pages/emailCampaign/groups/CreateGroup";
import UpdateGroup from "../pages/emailCampaign/groups/UpdateGroup";
import Accounts from "../pages/emailCampaign/accounts/Accounts";
import CreateAccount from "../pages/emailCampaign/accounts/CreateAccount";
import UpdateAccount from "../pages/emailCampaign/accounts/UpdateAccount";
import Analytics from "../pages/emailCampaign/campaigns/analytics/Analytics";
import Users from "src/pages/users/Users";
import CreateUser from "src/pages/users/CreateUser";
import EditUser from "src/pages/users/EditUser";
import PermissionComp from "src/components/Permission";
import ViewProfile from "../pages/profile/ViewProfile";
import EditProfile from "../pages/profile/EditProfile";
import ViewCompanyProfile from "../pages/company/ViewCompanyProfile";
import EditCompanyProfile from "../pages/company/EditCompanyProfile";
export const privateRoutes = [
  {
    path: "global",
    element: <PageContent />,
    children: [{ path: "search", element: <GlobalLibrary /> }],
  },
  {
    path: "presentations",
    element: <PageContent tabs={nav.presentation.tabs} />,
    children: [
      { index: true, element: <Navigate to="my-library" /> },
      { path: "my-library", element: <PermissionComp permissionPath="presentation" alert><PresentationMyLibrary /></PermissionComp> },
      { path: "my-library/search", element: <PermissionComp permissionPath="presentation" alert><PresentationMyLibrary /></PermissionComp> },
      { path: "shared-with-me", element: <PermissionComp permissionPath="share,presentation" alert><SharedWithMe /></PermissionComp> },
    ],
  },
  {
    path: "profile",
    element: <PageContent />,
    children:[
      { path: "view", element: <ViewProfile/> },
      { path: "edit/:id", element: <EditProfile/> },
    ]
  },
  {
    path: "company",
    element: <PageContent />,
    children:[
      { path: "view", element: <ViewCompanyProfile/> },
      { path: "edit/:id", element: <EditCompanyProfile cancelPath="/company/view"/> },
    ]
  },
  {
    path: "presentation",
    element: <PageContent />,
    children: [
      {
        path: ":id/edit/:slideId",
        element: <PermissionComp permissionPath="presentation" alert><PresentationEditor /></PermissionComp>,
      },
      {
        path: ":id/view/:slideId",
        element: <PermissionComp permissionPath="presentation" alert><PresentationPreview /></PermissionComp>,
      },
      {
        path: ":id/edit/:masterTemplateId/template",
        element: <PermissionComp permissionPath="presentation" alert><PresentationEditor isMasterTemplate/></PermissionComp>,
      },
      {
        path: ":id/view/:masterTemplateId/template",
        element: <PermissionComp permissionPath="presentation" alert><PresentationPreview /></PermissionComp>,
      },
      {
        path: ":id/details",
        element: <PermissionComp permissionPath="presentation" alert><PresentationDetails /></PermissionComp>,
      },
      {
        path: ":id/publish",
        element: <PermissionComp permissionPath="presentation" alert><Publish /></PermissionComp>,
      },
      {
        path: ":id/structure",
        element: <PermissionComp permissionPath="presentation" alert><Structure /></PermissionComp>,
      },
      {
        path: ":id/localize",
        element: <PermissionComp permissionPath="presentation" alert><Localize /></PermissionComp>,
      },
    ],
  },
  {
    path: "presentation",
    children: [
      {
        path: ":id/settings",
        element: <PageContent wrapper={false} /> ,
        children: [{ index: true, element: <PresentationSetting /> }],
      },
    ],
  },
  {
    path: "campaign",
    element: <PageContent />,
    children: [
      { index: true, element: <Navigate to="list" /> },
      {
        path: "list",
        element: <Campaign />,
      },
      {
        path: "create",
        element: <CreateCampaign />,
      },
      {
        path: "update/:id",
        element: <UpdateCampaign />,
      },
      {
        path: "analytics/:id",
        element: <PermissionComp permissionPath="campaign.analytics" alert><Analytics /></PermissionComp>,
      },
      {
        path: "groups",
        element: <Groups />,
      },
      {
        path: "groups/create",
        element: <CreateGroup />,
      },
      {
        path: "groups/update/:id",
        element: <UpdateGroup />,
      },
      {
        path: "contacts",
        element: <Contact />,
      },
      {
        path: "contacts/create",
        element: <CreateContact />,
      },
      {
        path: "contacts/update/:id",
        element: <UpdateContact />,
      },
      {
        path: "accounts",
        element: <Accounts />,
      },
      {
        path: "accounts/create",
        element: <CreateAccount />,
      },
      {
        path: "accounts/update/:id",
        element: <UpdateAccount />,
      },
    ],
  },
  {
    path: "emails",
    element: <PageContent tabs={nav.email.tabs} />,
    children: [
      { index: true, element: <Navigate to="my-library" /> },
      { path: "my-library", element: <PermissionComp permissionPath="emailTemplates" alert><EmailMyLibrary /></PermissionComp>},
      { path: "my-library/search", element: <PermissionComp permissionPath="emailTemplates" alert><EmailMyLibrary /></PermissionComp> },
      { path: "shared-with-me", element: <PermissionComp permissionPath="share,emailTemplates" alert><EmailSharedWithMe /></PermissionComp>},
    ],
  },
  {
    path: "email",
    element: <PageContent />,
    children: [
      {
        path: ":id/edit",
        element: <PermissionComp permissionPath="emailTemplates" alert><EmailEditor /></PermissionComp>,
      },
      {
        path: ":id/view",
        element: <PermissionComp permissionPath="emailTemplates" alert><EmailPreview /></PermissionComp>,
      },
      {
        path: ":id/details",
        element: <PermissionComp permissionPath="emailTemplates" alert><EmailDetails /></PermissionComp>,
      },
      {
        path: ":id/publish",
        element: <PermissionComp permissionPath="emailTemplates" alert><Publish /></PermissionComp>,
      },
      {
        path: ":id/settings",
        element: <PermissionComp permissionPath="emailTemplates" alert><EmailSetting /></PermissionComp>,
      },
    ],
  },
  {
    path: "microsites",
    element: <PageContent tabs={nav.microsites.tabs} />,
    children: [
      { index: true, element: <Navigate to="my-library" /> },
      { path: "my-library", element: <PermissionComp permissionPath="microsites" alert><MicrositesMyLibrary /></PermissionComp> },
      { path: "my-library/search", element: <PermissionComp permissionPath="microsites" alert> <MicrositesMyLibrary /></PermissionComp> },
      { path: "shared-with-me", element: <PermissionComp permissionPath="share,microsites" alert><MicrositesSharedWithMe /></PermissionComp> },
    ],
  },
  {
    path: "microsite",
    element: <PageContent />,
    children: [
      {
        path: ":id/edit",
        element: <PermissionComp permissionPath="microsites"><MicrositeEditor /></PermissionComp>,
      },
      {
        path: ":id/view",
        element: <PermissionComp permissionPath="microsites"><MicrositePreview viewType="emailView" /></PermissionComp>,
      },
      {
        path: ":id/details",
        element: <PermissionComp permissionPath="microsites"><MicrositeDetails /></PermissionComp>,
      },
      {
        path: ":id/publish",
        element: <PermissionComp permissionPath="microsites"> <Publish /></PermissionComp>,
      },
      {
        path: ":id/settings",
        element: <PermissionComp permissionPath="microsites"><MicrositeSetting /></PermissionComp>,
      },
    ],
  },
  {
    path: "assets",
    element: <Assets />,
  },
  {
    path: "reviews",
    element: <PageContent tabs={nav.reviews.tabs} />,
    children: [
      { index: true, element: <Navigate to="presentation" /> },
      { path: "email", element: <PermissionComp permissionPath="approvalProcess" alert> <EmailReviewTable /> </PermissionComp> },
      { path: "presentation", element: <PermissionComp permissionPath="approvalProcess" alert><PresentationReviewTable /></PermissionComp> },
      { path: "landingpage", element: <PermissionComp permissionPath="approvalProcess" alert><LandingPageReview /></PermissionComp>},
      { path: "assets", element: <PermissionComp permissionPath="approvalProcess" alert><AssetsReview /></PermissionComp>},
    ],
  },
  {
    path: "reviews",
    element: <PageContent />,
    children: [
      {
        path: ":id/presentation",
        element: <PermissionComp permissionPath="approvalProcess"><PresentationReviewDetail /></PermissionComp>,
      },
      { path: ":id/email", element: <PermissionComp permissionPath="approvalProcess"><EmailReviewDetail /></PermissionComp>},
      { path: ":id/landingpage", element: <PermissionComp permissionPath="approvalProcess"><MicrositeReviewDetail /></PermissionComp>},
      { path: ":id/assets", element: <PermissionComp permissionPath="approvalProcess"><AssetReviewDetail /> </PermissionComp>},
    ],
  },
  {
    path: "settings",
    element: <PageContent />,
    children: [
      {
        index: true,
        element: <Navigate to="users" />,
      },
      {
        path: "users",
        element: <Users />,
      },
      { path: "users/create", element: <CreateUser /> },
      { path: "users/edit/:id", element: <EditUser /> },
      {
        path: "integration",
        element: <Integration />,
      },
      {
        path: "integration/salesforce",
        element: <PermissionComp permissionPath="salesforceIntegration" alert><Salesforce /></PermissionComp>,
      },
    ],
  },
  { path: "*", element: <NotFound /> },
];

import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSharedWith } from "src/store/reducers/presentations-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";
import {
  clonePresentation,
  deletePresentation,
  exportPresentation,
  uploadNewVersion,
} from "src/store/actions/presentations";
import { share } from "src/store/actions/sharing";

//Dialogs
import ExportPresentation from "../ExportPresentation";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import Confirm from "../Confirm";
import ReviewDelete from "../ReviewDelete";
import { useSearchParams } from "react-router-dom";

const MyLibraryDialogs = (props) => {
  const type = "presentation";
  const dispatch = useDispatch();
  const { company, _id: userId } = useSelector((state) => state.auth.userData);
  const { onClose, dialog, presentationId, presentation } = props;
  // const sharedPresentations = useSelector((state) => state.sharing.shared);
  // const sharedPresentationsIndex = sharedPresentations.findIndex((sharedPresentation) => sharedPresentation.presentationId._id === presentationId);
  const [exportAs, setExportAs] = useState("ppt");

  const [searchParams] = useSearchParams();
  const limit = 10;
  const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
  const skip = (pageNo - 1) * limit;

  const params = {};
  searchParams.forEach((value, key) => {
    if (key !== "page") params[key] = value;
  });

  params.skip = skip;
  params.limit = limit;

  //on close function for closing dialogs

  //upload new presentation function
  const onUploadNewVersion = useCallback((data) => {
    dispatch(uploadNewVersion(data));
  }, []);

  //export presentation
  const onExport = useCallback(() => {
    dispatch(exportPresentation({ id: presentationId, exportAs }));
  }, [presentationId, exportAs]);

  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const presentationShare = useCallback(
    (sharedWith, accessLevel) => {
      console.log('sharedPresentationsIndex', sharedPresentationsIndex, sharedPresentations, presentationId)
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: presentationId,
          data: { sharedWith: userIds, accessLevel },
          type
        })
      ).then(()=>{
        // if(sharedPresentationsIndex !=-1){
        //   console.log('sharedPresentationsIndex', sharedPresentationsIndex)
        // }
      })
    },
    [presentationId]
  );

  //assign presentation fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: presentationId, data }));
    },
    [presentationId]
  );

  const presentationDelete = useCallback(async() => {
    await dispatch(deletePresentation(presentationId));
    dispatch(fetchPresentations(params));
    onClose();
  }, [presentationId]);

  const cancelPresentationReview = useCallback(() => {
    dispatch(cancelReview({ id: presentationId, type }));
    onClose();
  }, [presentationId]);

  const handleClone = useCallback(async () => {
    await dispatch(clonePresentation(presentationId));
    dispatch(fetchPresentations(params));
    onClose();
  }, [presentationId]);

  return (
    <>
      <ExportPresentation
        isOpen={dialog.export}
        exportAs={exportAs}
        handleExport={onExport}
        setExportAs={setExportAs}
        handleClose={onClose}
        id={presentationId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={presentationId}
      />
      <DetailsDrawer
        type="presentation"
        open={dialog.info}
        onClose={onClose}
        details={presentation}
        className="presentationDetailPane"
      />
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={presentationShare}
        shareType={type}
      />
      <SendForReview
        type="presentation"
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this presentation"
        handleClose={onClose}
        handleConfirm={presentationDelete}
      />
     <ReviewDelete
       isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}
        
      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel the review ?"
        handleClose={onClose}
        handleConfirm={cancelPresentationReview}
      />

      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this presentation ?"
        handleClose={onClose}
        handleConfirm={handleClone}
      />
    </>
  );
};

export default React.memo(MyLibraryDialogs);

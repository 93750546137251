import { HISTORY_ACTIONS } from "src/data/contants";
import {
  generateUniqueLetterId,
  getElementIndex,
} from "src/services/editor.service";

export const getElementData = ({
  id,
  type = HISTORY_ACTIONS.updated,
  index,
  parentId,
  getStylesOnly = false,
  styles = "",
}) => {
  try{
    const element = document.getElementById(id);
    // if (!element) return null;
  
    const _recordId = generateUniqueLetterId();
    // verifying if item is not a layer by it self and containing ancestor layer.
    const elementClosestLayer =
      !element.classList.contains("cs-layer") && element.closest(".cs-layer");
    const layer = elementClosestLayer ? elementClosestLayer : element;
    const shouldGetStyles =
      element.classList.contains("cs-slide") || getStylesOnly;
    const eleStyles = styles || $(element).attr("style"); // if not receive then get it!
  
    const commonDetails = {
      layerId: layer.id,
      changesFrom: id,
      action: type,
      eleStyles,
      _recordId,
      styleOnly: false,
      ...(styles ? { eleStyles: styles } : {}),
    };
    // Getting Elements style only
    if (shouldGetStyles) {
      const styleDetails = { ...commonDetails, eleStyles, styleOnly: true };
      return styleDetails;
    }
  
    // Getting complete Details
    const layerHtml = layer.outerHTML.replace(
      `<div class="status" style="display: block; opacity: 1;">`,
      `<div class="status" style="display: none; opacity: 0;">`
    );
  
    const layerParent = layer.parentElement;
    const isParentContainer =
      element.parentElement.classList.contains("container");
  
    const containerId = isParentContainer
      ? element.parentElement.parentElement.id
      : null;
  
    const layerParentId = parentId || layerParent.id;
    const layerIndex = index !== undefined ? index : getElementIndex(element);
  
    const layerDetails = {
      layerHtml,
      layerParentId,
      layerIndex,
      containerId,
      ...commonDetails,
    };
  
    return layerDetails;
  }catch(error){
    return `Error in getHTMLData function: ${error}`;
  }
};

// backup Details remains same for every action (internal mechanism usage)
export const getElementBackUpDetails = (action) => {
  return getElementData({
    type: "_backup",
    id: action.changesFrom? action.changesFrom : action.layerId,
  });
};


// selecting a single object undo or redo
export const populateEntry = (action, type) => {
  const populatedEntry = { ...action, ...action[type] };
  delete populatedEntry.undo;
  delete populatedEntry.redo;
  return populatedEntry;
};

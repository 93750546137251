import React from "react";
import SingleProperty from "../common/SingleProperty";
import useZoom from "../../hooks/useZoom";
import { resetMicrositeWidth } from "../../microsites/renderMicrosite";

const CanvasProperty = ({ elementId, updateHistory, undoRedoChange }) => {
  const zoomReset = ()=>{
    const zoomBtn = document.getElementById('resetZoom');
    zoomBtn.click()
  }

  const widthReset = ()=>{
    resetMicrositeWidth();
    zoomReset()
  }
  return (
    <SingleProperty
      elementId={elementId}
      updateHistory={updateHistory}
      undoRedoChange={undoRedoChange}
      cssProperty="width"
      heading="Canvas Width"
      inputProps={{min: 320, max:1920}}
      onChangeCallback={zoomReset}
      onClearCallback={widthReset}
    />
  );
};
export default CanvasProperty;

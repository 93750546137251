import React, { useState, useEffect } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import {
  MdAlignVerticalTop,
  MdAlignVerticalCenter,
  MdAlignVerticalBottom,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalRight,
} from "react-icons/md";
import "jquery-ui-dist/jquery-ui";
import { HISTORY_ACTIONS } from "src/data/contants";
import Control from "./Control";
import Row from "src/components/properties/Row";
const Alignment = ({ elementId, updateHistory, undoRedoChange, type }) => {
  const isFlex = type && type === "flex";

  const poistionHandler = (position) => {
    const layer = document.getElementById(elementId);
    const layerHeight = layer.offsetHeight; // For outer height
    const layerWidth = layer.offsetWidth; // For outer width

    const dropzone = layer.parentElement;
    const dropzoneHeight = dropzone.offsetHeight; // For outer height
    const dropzoneWidth = dropzone.offsetWidth; // For outer width

    const bottomPosition = dropzoneHeight - layer.offsetHeight + "px";
    const rightPosition = dropzoneWidth - layer.offsetWidth + "px";

    if (isFlex) {
      if (position === "top") {
        layer.style["align-items"] = "start";
      } else if (position === "bottom") {
        layer.style["align-items"] = "end";
      } else if (position === "left") {
        layer.style["justify-content"] = "start";
      } else if (position === "right") {
        layer.style["justify-content"] = "end";
      } else if (position === "vc") {
        layer.style["align-items"] = "center";
      } else if (position === "hc") {
        layer.style["justify-content"] = "center";
      }
    } else {
      if (position === "top") {
        layer.style.top = "0px";
        layer.style.bottom = "";
      } else if (position === "bottom") {
        layer.style.top = bottomPosition;
      } else if (position === "left") {
        layer.style.left = "0px";
        layer.style.right = "";
      } else if (position === "right") {
        layer.style.left = rightPosition;
      } else if (position === "vc") {
        const top = dropzoneHeight / 2 - layerHeight / 2;
        layer.style.top = top + "px";
        layer.style.bottom = "";
      } else if (position === "hc") {
        const left = dropzoneWidth / 2 - layerWidth / 2;
        layer.style.left = left + "px";
        layer.style.right = "";
      }
    }

    updateHistory({ action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
    layer.focus();
  };

  const activeButton = (elementId, properties) => {
    if (!elementId) {
      return "";
    }

    const element = document.getElementById(elementId);
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i].property;
      const value = properties[i].value;
      if (element?.style[property] === value) {
        return "active";
      } else {
        return "";
      }
    }
  };

  if (elementId) {
    activeButton(elementId, [{ property: "justifyContent", value: "center" }]);
  }

  const buttons = (
    <Box className="iconBtns">
      <Tooltip title="Top" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "alignItems", value: "start" },
          ])}`}
          onClick={() => {
            poistionHandler("top");
          }}
        >
          <MdAlignVerticalTop />
        </IconButton>
      </Tooltip>

      <Tooltip title="Vertical Center" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "alignItems", value: "center" },
          ])}`}
          onClick={() => {
            poistionHandler("vc");
          }}
        >
          <MdAlignVerticalCenter />
        </IconButton>
      </Tooltip>

      <Tooltip title="Bottom" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "alignItems", value: "end" },
          ])}`}
          onClick={() => {
            poistionHandler("bottom");
          }}
        >
          <MdAlignVerticalBottom />
        </IconButton>
      </Tooltip>

      <Tooltip title="Left" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "justifyContent", value: "start" },
          ])}`}
          onClick={() => {
            poistionHandler("left");
          }}
        >
          <MdOutlineAlignHorizontalLeft />
        </IconButton>
      </Tooltip>

      <Tooltip title="Horizontal Center" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "justifyContent", value: "center" },
          ])}`}
          onClick={() => {
            poistionHandler("hc");
          }}
        >
          <MdOutlineAlignHorizontalCenter />
        </IconButton>
      </Tooltip>

      <Tooltip title="Right" arrow>
        <IconButton
          className={`icon ${activeButton(elementId, [
            { property: "justifyContent", value: "end" },
          ])}`}
          onClick={() => {
            poistionHandler("right");
          }}
        >
          <MdOutlineAlignHorizontalRight />
        </IconButton>
      </Tooltip>
    </Box>
  );

  if (isFlex) {
    return <Box className="alignment"><Row label="Position" element={buttons} /></Box>;
  }

  return (
    <Control
      heading="Alignment"
      headingVariant="h2"
      className="alignment"
      divider={false}
      isCollapse={true}
      expanded={true}
    >
      {buttons}
    </Control>
  );
};

export default React.memo(Alignment);

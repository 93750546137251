import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Typography,
  Box,
  Autocomplete,
  TextField,
  Popper,
} from "@mui/material";

import SelectBox from "../ui/formElements/SelectBox";
import Main from "./Main";
import "../../styles/export_dialog.scss";
import { roles } from "src/config";

const SharingSettings = (props) => {
  const { onInput, userId, onShare, handleClose, editUserRole, shareType } = props;
  const { list, loading } = useSelector((state) => state.user);
  const [userInputValue, setuserInputValue] = useState("");
  const [sharedWith, setSharedWith] = useState([]);
  const [accessLevel, setAccessLevel] = useState("viewer");
  const [open, setOpen] = useState(false);
  const inputOpenHandler = () => {
    onInput();
    setOpen(true);
  };

  useEffect(() => {
    onInput();
    if (editUserRole && Object.keys(editUserRole).length !== 0) {
      setSharedWith(list.filter(e => e._id == editUserRole?.userId._id))
      setAccessLevel(editUserRole?.role)
    }else{
      setSharedWith([]);
      setAccessLevel("viewer")
    }

  }, [editUserRole])
  const inputCloseHandler = () => {
    setOpen(false);
  };

  const usersInputHandler = (userInputValue) => {
    setuserInputValue(userInputValue);
  };

  const onClose = () => {
    if (handleClose) {
      setSharedWith([]);
      handleClose();
    }
  };

  const usersChangeHanlder = (users) => {
    setSharedWith(users);
  };

  const submitHandler = () => {
    onShare(sharedWith, accessLevel);
    props.handleClose();
    setSharedWith([]);
  };

  return (
    <Main
      background="white"
      modalWidth="md"
      isOpen={props.isOpen}
      handleClose={props.handleClose}
    >
      <Box className="exports">
        <Typography variant="h2" sx={{textTransform:'capitalize'}}>Share {shareType === 'landingpage' ? 'Microsite': shareType }</Typography>
        <Box className="user-content">
          <Box className="margin-top">
            <Typography className="user-label" variant="body2">
              Users
            </Typography>
            <Autocomplete
              disablePortal
              multiple
              open={open}
              onOpen={inputOpenHandler}
              onClose={inputCloseHandler}
              loading={loading}
              onChange={(_, val) => usersChangeHanlder(val)}
              onInputChange={(_, val) => usersInputHandler(val)}
              value={sharedWith}
              options={list.filter((user) => user._id !== userId && user.role !== roles.reviewer.role)}
              isOptionEqualToValue={(option, value) => value._id === option._id}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              ListboxProps={{
                style: { maxHeight: "150px" }, // Optional: Set the maximum height of the list
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ background: "" }}
                  {...props}
                  key={option._id}
                >
                  <Box>
                    <Typography className="option-label">
                      {option.firstName} {option.lastName}
                    </Typography>
                    <Typography>{option.email}</Typography>
                  </Box>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={userInputValue}
                  variant="standard"
                  className="user-input"
                  classes={{ input: "search-input" }}
                  placeholder="Search for a user"
                />
              )}
            />
          </Box>
          <br />
          <Typography
            className="user-label"
            sx={{ marginTop: "0px" }}
            variant="body2"
          >
            Access
          </Typography>
          <SelectBox
            labelTag=""
            name="accessLevel"
            value={accessLevel}
            options={[
              { label: "Viewer", value: "viewer" },
              { label: "Editor", value: "editorRU" },
            ]}
            onChange={(e) => setAccessLevel(e.target.value)}
            variant="standard"
          />
        </Box>

        <Box className="iconBox">
          <Button className="cancelbtn" variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            onClick={submitHandler}
            className="exportbtn"
            variant="contained"
            disabled={sharedWith.length === 0}
          >
            Assign
          </Button>
        </Box>
      </Box>
    </Main>
  );
};
export default React.memo(SharingSettings);

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useElementStatus } from "../element.controller";
import { editorActions } from "src/store/reducers/editor-slice";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHeirarchy } from "../heirarchy.controller";
import { useEditorHistoryUpdater } from "./history-updater";
import { getElementBackUpDetails, populateEntry } from "../shared-logic";
import { selectLast } from "src/services/utils";
import { isEditor } from "src/services/editor.service";
import { generateAndAppendMediaQueries } from "src/components/editor/microsites/renderMicrosite";

export const useUndoRedo = ({ csRenderEditable }) => {
  const dispatch = useDispatch();
  const { activeElement, deActiveElement } = useElementStatus();
  const { updateHistory } = useEditorHistoryUpdater();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const { history, activeEditable } = useSelector((state) => state.editor);
  const { backStack, forwardStack, savedActionId } = history;

  const updateUndoRedoStack = (action) => {
    const backupDetails = getElementBackUpDetails(
      activeEditable.elementDetails
    );

    // console.log(activeEditable, backupDetails);
    const payload = { type: action.type, backupDetails };

    dispatch(editorActions.updateUndoRedo(payload));
  };

  // console.log({ backStack, forwardStack, savedActionId });

  const isBackStackEmpty = !backStack.length;
  const isSavedIdMatches = selectLast(backStack)?._recordId === savedActionId;
  const isChangedSaved = savedActionId ? isSavedIdMatches : isBackStackEmpty;

  const disabledButtons = {
    undo: !backStack.length,
    redo: !forwardStack.length,
    isChangedSaved,
  };

  useEffect(() => {
    // adding and removing listeners
    if (csRenderEditable) updateUndoRedoFromKeyChange(undo, redo);
  }, [disabledButtons]);

  const renderEditableComponent = ({ id, active }) => {
    // --MAKING FUNCTIONAL AFTER APPENDING FROM HISTORY
    const el = document.getElementById(id);
    const elmentToActive = document.getElementById(active);

    if (el) {
      csRenderEditable({
        layersContainer: el,
        updateHistory,
        activeElement,
        requestHierarchyUpdate,
      });

      activeElement(elmentToActive);
    } else {
      deActiveElement();
    }
  };

  const undo = () => {
    const type = "undo";

    if (!disabledButtons[type]) {
      try {
        // last one is always latest action
        const actionEntry = selectLast(backStack);
        const actionData = populateEntry(actionEntry, type);
        UIHistoryUpdater({ actionData });

        updateUndoRedoStack({ type });
        if(isEditor.microsite()){
          generateAndAppendMediaQueries()
        }
      } catch (e) {
        console.warn("Undo skipped due to", e);
      }
    }
  };

  const redo = () => {
    const type = "redo";

    if (!disabledButtons[type]) {
      // Next
      try {
        const actionEntry = forwardStack[0];
        const actionData = populateEntry(actionEntry, type);
        UIHistoryUpdater({ actionData });

        updateUndoRedoStack({ type });
        if(isEditor.microsite()){
          generateAndAppendMediaQueries()
        }
      } catch (e) {
        console.warn("Redo skipped due to", e);
      }
    }
  };

  // Performs UI Update according to actions
  const UIHistoryUpdater = ({ actionData }) => {
    const type = actionData.styleOnly ? "updateStyle" : actionData.action;

    const uiActs = undoRedoUIUpdaterActions;
    const actionsByTypes = {
      updateStyle: uiActs.updateStyle,
      [HISTORY_ACTIONS.appended]: uiActs.appendLayerToPosition,
      [HISTORY_ACTIONS.deleted]: uiActs.removeLayer,
      [HISTORY_ACTIONS.updated]: uiActs.updateLayer,
      [HISTORY_ACTIONS.containerChanged]: uiActs.updateContainment,
      [HISTORY_ACTIONS.gridResized]: uiActs.updateGridStyles,
      [HISTORY_ACTIONS.imageChange]: uiActs.updateLayer,
      [HISTORY_ACTIONS.sorted]: uiActs.updateContainment,
    };

    actionsByTypes[type](actionData);

    const elemntToActive = document.getElementById(actionData.changesFrom);
    if (elemntToActive) {
      activeElement(elemntToActive);
    } else {
      deActiveElement();
    }
  };

  // CORE UI Actions
  const undoRedoUIUpdaterActions = {
    updateStyle(actionData) {
      const styleId = actionData.changesFrom || actionData.id;
      const element = $("#" + styleId);
      const isDropzone = element.hasClass("cs-slide");
      const isDropzoneHasZoom = isDropzone && element.css("zoom") !== "1";

      element.addClass("smooth-transistion");
      element.attr("style", actionData.eleStyles);
      setTimeout(() => {
        element.removeClass("smooth-transistion");
      }, 210);

      if (isDropzoneHasZoom) {
        const zoomValue =
          document.documentElement.style.getPropertyValue("--zoom");
        element.css("zoom", zoomValue);
      }
    },
    updateGridStyles(actionData) {
      const { gridItemsStyles = [] } = actionData;
      gridItemsStyles.forEach(({ id, eleStyles }) => {
        undoRedoUIUpdaterActions.updateStyle({ id, eleStyles });
      });
    },
    appendLayerToPosition(actionData) {
      const {
        layerParentId: parentId,
        layerHtml,
        layerIndex,
        layerId,
      } = actionData;

      const createdLayerFromHistory = $(layerHtml).get(0);
      const alreadyExistingLayer = document.getElementById(layerId);
      const layer = alreadyExistingLayer || createdLayerFromHistory;
      const parent = document.getElementById(parentId);
      const targetLayer = parent?.children[layerIndex];

      if (targetLayer) {
        // for sorting
        if (targetLayer.previousSibling !== layer) {
          targetLayer.before(layer);
        } else {
          targetLayer.after(layer);
        }
      } else {
        parent.append(layer);
      }

      const shouldCsRerender = alreadyExistingLayer === null;
      if (shouldCsRerender) {
        renderEditableComponent({
          id: layerId,
          active: actionData.changesFrom,
        });
      }
    },
    updateLayer(actionData) {
      const layer = document.getElementById(actionData.layerId);
      layer.outerHTML = actionData.layerHtml;

      renderEditableComponent({
        id: actionData.layerId,
        active: actionData.changesFrom,
      });
    },
    updateContainment(actionData) {
      undoRedoUIUpdaterActions.updateStyle(actionData);
      undoRedoUIUpdaterActions.appendLayerToPosition(actionData);
    },
    removeLayer(actionData) {
      const layer = document.getElementById(actionData.layerId);
      deActiveElement(layer);
      layer.remove();
    },
  };

  // key listeners
  const updateUndoRedoFromKeyChange = (undo, redo) => {
    function KeyPress(e) {
      const windowActiveElement = document.activeElement;
      const canUndoOrRedo =
        document.activeElement.tagName == "BODY" ||
        windowActiveElement.classList.contains("editable");

      if (canUndoOrRedo) {
        if (e.ctrlKey) {
          // ctr + z
          if (e.keyCode == 90) {
            undo();
            // ctr + y
          } else if (e.keyCode == 89) {
            redo();
          }
        }
        // if any input or other element have focus then blur it !
        setTimeout(() => {
          document.activeElement.blur();
        }, 50);
      }
    }

    document.onkeydown = KeyPress;
  };

  return { undo, redo, disabledButtons };
};

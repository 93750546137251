import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";

import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";

import InputFields from "src/components/ui/formElements/InputFields";
import InputPhoneField from "src/components/ui/formElements/InputPhoneField";
import SelectFields from "src/components/ui/formElements/SelectFields";
import AddressView from "src/layouts/address/Address";

import Card from "src/components/ui/Card";
import Check_Box from "src/components/common/Check_Box";
import Button from "src/components/Button";

import { languages, timezones, currencies } from "src/data/data";
import { getCompanyData, updateCompany } from "src/store/actions/company";
import './EditCompanyProfile.scss'

const EditCompanyProfile = ({ cancelPath }) => {
  const { id } = useParams();
  const { company } = useSelector((state) => state.auth.userData);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Setting Default Values For Form
  const defaultValues = useForm({
    defaultValues: {
      name: "",
      phone: "",
      fax: "",
      currency: "",
      timezone: "",
      website: "",
      locale: "",
      assetReview: true,
      packageId: "",
      lang:"",
      primaryContact: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
      },
      billing: {
        type: "",
      },
      address: {
        street: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
      },
    },
  });
  // Initialization For React-Hook-Form

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    reset,
    resetField,
    formState: { errors },
  } = defaultValues;

  const { isLoading, notification } = useSelector((state) => state.ui);
  const [doNavigate, setDoNavigate] = useState(false);

  const companyId = company?._id;

  useEffect(() => {
    if (companyId){
      dispatch(getCompanyData(companyId)).then((res) => {
        reset(res.payload);
      });
    }      
  }, [id]);

  // useEffect(() => {
  //   if (notification?.status === "success" && doNavigate) {
  //     navigate(-1);
  //   }
  //   clearErrors();
  //   if (notification?.message === "User already exists") {
  //     setError(
  //       "email",
  //       { message: notification?.message },
  //       { shouldFocus: true }
  //     );
  //   }
  // }, [notification]);

  const onSubmitHandler = (data) => {
    console.log(data);
    dispatch(updateCompany({ data, id })).then(()=> navigate('/company/view'))
    // setDoNavigate(true);
  };

  const handleCancel = () => {
    navigate(cancelPath);
  };

  const disablePrimaryContacts = true
  return (
    <form className="EditCompanyProfile">
      <Card className="form-card" cardStyle="card" style={{marginBottom:'10px'}}>
        <Box className="form-heading">
          <Typography variant="h2">Info:</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <InputFields
              fieldName="name"
              type="text"
              label="Company Name"
              control={control}
              rules={{
                minLength: {
                  value: 2,
                  message: "Company name should have at least 2 characters",
                },
                maxLength: {
                  value: 50,
                  message: "Company name allowed 50 characters",
                },
                required: "Company name is required",
              }}
              error={errors?.name}
              labelTag
              placeholder="Enter company name"
              // inputProps={{ minLength: 2, maxLength: 40 }}
            />
          </Grid>
          <Grid item xs={3}>
            <InputPhoneField
              fieldName="phone"
              type="text"
              min={0}
              label="Company Phone"
              control={control}
              rules={{
                minLength: {
                  value: 11,
                  message: "Company Phone should have at least 11 characters",
                },
                maxLength: {
                  value: 16,
                  message: "Phone limit 16 characters",
                },
                required: "Company phone is required",
              }}
              error={errors?.phone}
              phone
              labelTag
            />
          </Grid>
          <Grid item xs={3}>
            <InputFields
              fieldName="fax"
              type="text"
              min={0}
              label="Company Fax"
              control={control}
              rules={{
                minLength: {
                  value: 6,
                  message: "Fax should have at least 6 characters",
                },
                maxLength: {
                  value: 20,
                  message: "Fax limit 20 characters",
                },
              }}
              error={errors?.fax}
              phone
              labelTag
              placeholder="Enter Fax Number"
            />
          </Grid>
          <Grid item xs={3}>
            <InputFields
              fieldName="website"
              type="text"
              label="Company Website"
              control={control}
              rules={{
                pattern: {
                  value:
                    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                  message: "Incorrect URL",
                },
              }}
              error={errors?.website}
              placeholder="Enter company website"
              labelTag
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFields
              fieldName="lang"
              label="Langauge"
              control={control}
              options={languages.map((lang) => {
                return { label: lang.label, value: lang.value };
              })}
              placeholder="Select langauge"
              withSearch
              labelTag
            />
          </Grid>

          <Grid item xs={3}>
            <SelectFields
              fieldName="currency"
              label="Currency"
              control={control}
              options={currencies.map(({ label, value }) => {
                return { label, value };
              })}
              placeholder="Select currency"
              withSearch
              labelTag
            />
          </Grid>
          <Grid item xs={3}>
            <SelectFields
              fieldName="timezone"
              label="Timezone"
              control={control}
              options={timezones.map(({ label, value }) => {
                return { label, value };
              })}
              placeholder="Select timezone"
              withSearch
              labelTag
            />
          </Grid>
          <Grid item xs={3}>
            <InputFields
              fieldName="locale"
              type="text"
              label="locale"
              control={control}
              labelTag
              placeholder="Enter locale"
            />
          </Grid>
        </Grid>

        {/* <Grid container spacing={2}>
          <Grid item xs={2}>
            <Check_Box
              control={control}
              fieldName="assetReview"
              label="Asset Review"
            />
          </Grid>
        </Grid> */}
      </Card>

      <Grid container spacing={2} sx={{marginBottom:'10px'}}>
        <Grid item xs={8.5}>
          <Card className="form-card" cardStyle="card">
            <Box className="form-heading">
              <Typography variant="h2">Primary Contact:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <InputFields
                  fieldName="primaryContact.firstName"
                  type="text"
                  label="First Name"
                  control={control}
                  rules={{
                    minLength: {
                      value: 2,
                      message: "First Name should have at least 3 characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "First Name allowed 50 characters",
                    },
                    required: "First Name is required",
                  }}
                  error={errors.primaryContact?.firstName}
                  disabled={disablePrimaryContacts}
                  placeholder="Enter your first name"
                  // inputProps={{ minLength: 2, maxLength: 40 }}
                />
              </Grid>
              <Grid item xs={3}>
                <InputFields
                  fieldName="primaryContact.lastName"
                  type="text"
                  label="Last Name"
                  control={control}
                  rules={{
                    minLength: {
                      value: 2,
                      message: "Last Name should have at least 3 characters",
                    },
                    maxLength: {
                      value: 50,
                      message: "Last Name allowed 50 characters",
                    },
                    required: "Last Name is required",
                  }}
                  error={errors.primaryContact?.lastName}
                  disabled={disablePrimaryContacts}
                  placeholder="Enter your last name"
                  // inputProps={{ minLength: 2, maxLength: 40 }}
                />
              </Grid>
              <Grid item xs={3}>
                <InputFields
                  fieldName="primaryContact.email"
                  type="email"
                  label="Email"
                  control={control}
                  rules={{
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/gi,
                      message: "Incorrect Email",
                    },
                    required: "Email is required",
                  }}
                  error={errors.primaryContact?.email}
                  disabled={disablePrimaryContacts}
                  placeholder="Enter your email"
                />
              </Grid>
              <Grid item xs={3}>
                <InputPhoneField
                  fieldName="primaryContact.phone"
                  type="text"
                  label="Phone"
                  control={control}
                  rules={{
                    minLength: {
                      value: 11,
                      message: "Phone should have at least 11 characters",
                    },
                    maxLength: {
                      value: 16,
                      message: "Phone limit 16 characters",
                    },
                    required: "Phone is required",
                  }}
                  error={errors.primaryContact?.phone}
                  disabled={disablePrimaryContacts}
                  phone
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={3.5}>
          <Card className="form-card" cardStyle="card">
            <Box className="form-heading">
              <Typography variant="h2">Billing:</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputFields
                  fieldName="billing.type"
                  type="text"
                  label="Type"
                  control={control}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <AddressView
        control={control}
        errors={errors}
        watch={watch}
        resetField={resetField}
      />
      
     
      <Stack direction="row" justifyContent="flex-end" gap={2} sx={{mt:2}}>
      <Button
          onClick={handleCancel}
          type="submit"
          variant="outlined"
          disabled={isLoading}
          startIcon={<IoCloseOutline />}
        >
          Cancel Changes
        </Button>
        <Button
          onClick={handleSubmit(onSubmitHandler)}
          type="submit"
          variant="contained"
          disabled={isLoading}
          startIcon={<IoCheckmarkOutline />}
        >
          Update Changes
        </Button>
      </Stack>
    </form>
  );
};

export default EditCompanyProfile;

import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { onlyPhone } from "src/utils/Utils";
import './InputFields.scss'
const InputFields = ({
  error,
  type,
  fieldName,
  control,
  rules,
  label,
  labelTag,
  phone,
  disabled,
  helperText,
  inputFieldSx,
  placeholder,
  gap,
  ...rest
}) => {
  const [isPassword, setIsPassword] = useState(true);
  const togglePassword = () => {
    setIsPassword((prev) => !prev);
  };
  
  let passwordIcon = label?.toLowerCase().includes('password') || type?.toLowerCase().includes('password');
  let endAdornment = (
    <IconButton onClick={() => togglePassword()} edge="end">
      {isPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
    </IconButton>
  );
  
  let newType = type;
  if(passwordIcon){
    if(isPassword){
      newType = 'password'
    }else{
      newType = 'text'
    }
  }


  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => {
        return (
          <Box className={`InputField ${gap? "gap":''}`} sx={inputFieldSx}>
            {labelTag && (
              <Typography
                variant="body1"
                component="label"
                className="labelTag"
              >
                {label} {rules?.required && <span className="required">*</span>}
              </Typography>
            )}

            <TextField
              className="outlined inputField"
              required={rules?.required && true}
              variant="outlined"
              error={error && true}
              helperText={
                error ? (helperText ? helperText : error.message) : null
              }
              type={newType}
              inputRef={field.ref}
              label={(labelTag && field?.value?.length) || placeholder? null : label}
              placeholder={placeholder}
              value={phone ? onlyPhone(field.value) : field.value}
              onChange={field.onChange}
              fullWidth
              disabled={disabled}
              autoComplete=""
              InputProps={{
                endAdornment: passwordIcon? endAdornment: null,
              }}
              {...rest}
            />
          </Box>
        );
      }}
    />
  );
};

export default InputFields;

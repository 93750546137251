import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useAuthShareLink from "./useAuthSharedLink";

const AuthSharedLinkRedirect = ({ children }) => {
  const navigate = useNavigate();
  const { linkId } = useParams();
  const { isSharedLinkAuthenticated, isLoggedin } = useAuthShareLink();
//   console.log('isSharedLinkAuthenticated', isSharedLinkAuthenticated(linkId))
  useEffect(() => {
    if (isSharedLinkAuthenticated(linkId) || isLoggedin) {
      navigate(`/shared/view/${linkId}`);
    } else {
      navigate(`/shared/auth/${linkId}`);
    }
  }, [isLoggedin]);

  return children;
};

export default AuthSharedLinkRedirect;

import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import UserAvatar from "../ui/UserAvatar";

const CompanyLogo = ({ ...props }) => {
  const logo = useSelector((state) => state.auth.userData.company.logo);
  return (
    <Box className="companyLogo" {...props}>
      <UserAvatar image={logo} type="default"></UserAvatar>
    </Box>
  );
};

export default CompanyLogo;

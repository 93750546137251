import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Stack, Grid, Typography, Box, Chip, IconButton } from "@mui/material";
import "../../../src/styles/profile.scss";
import Card from "src/components/ui/Card";
import Caption from "src/components/common/Caption";
import Button from "../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import UserAvatar from "../../components/ui/UserAvatar";
import Upload from "src/components/dialogs/Upload";
import {
  deleteUserProfilePic,
  updateUserProfilePic,
} from "src/store/actions/auth";
import "./ViewCompanyProfile.scss";
import { RiPencilLine } from "react-icons/ri";
import { FiTrash2 } from "react-icons/fi";
import Confirm from "src/components/dialogs/Confirm";
import { getUser } from "src/store/actions/users";
import { languages, timezones } from "src/data/data";
import {
  deleteCompanyLogo,
  getCompanyData,
  updateCompanyLogo,
} from "src/store/actions/company";
import Image from "src/components/ui/Image";
import { IoCloudUpload } from "react-icons/io5";
import { useBaseUrl } from "src/hooks/useBaseUrl";
import { setCompanyData } from "src/store/reducers/auth-slice";
const ViewCompanyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const baseUrl = useBaseUrl();
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const companyData = useSelector((state) => state.auth.userData.company);
  let companyId = companyData?._id || companyData?.companyId;
  const uploadProfileImage = (image) => {
    dispatch(updateCompanyLogo(image)).then((res) => {
      const cloneCompanyData = structuredClone(companyData);
      cloneCompanyData.logo = res.payload;
      dispatch(setCompanyData(cloneCompanyData));
    });
  };

  const deleteProfileImage = () => {
    dispatch(deleteCompanyLogo(companyId)).then(() => {
      setIsDeleteDialogOpen(false);
      const cloneCompanyData = structuredClone(companyData);
      cloneCompanyData.logo = null;
      dispatch(setCompanyData(cloneCompanyData));
    });
  };

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyData(companyId)).then((res) => {
        dispatch(setCompanyData(res.payload));
      });
    }
  }, []);

 
  let timezone = null
  
  if(companyData?.timezone){
    timezone = timezones.find(
      (timezone) => timezone.value === companyData?.timezone
    );
  }
  
  
  return (
    <>
      <Confirm
        isOpen={isDeleteDialogOpen}
        handleClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        title="Are you sure?"
        subtitle="you want to delete picture?"
        handleConfirm={deleteProfileImage}
      />

      <Upload
        title="Change User Image"
        show={isUploadDialogOpen}
        type="image"
        uploadHandler={uploadProfileImage}
        close={() => setIsUploadDialogOpen(false)}
      />

      <div className="ViewCompanyProfile">
        <Card
          className="form-card"
          cardStyle="card"
          style={{ marginBottom: "15px" }}
        >
          <Box className="form-heading">
            <Typography variant="h2">Info:</Typography>
          </Box>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <div
                className={`companyProfileImage ${
                  !companyData?.logo ? "noImage" : ""
                }`}
              >
                {companyData && (
                  <UserAvatar
                    name={companyData.name}
                    image={companyData?.logo}
                    type="profile"
                  >
                    <div className="uploadOverlay">
                      {companyData?.logo ? (
                        <IconButton
                          variant="contained"
                          onClick={() => setIsUploadDialogOpen(true)}
                        >
                          <RiPencilLine />
                        </IconButton>
                      ) : (
                        <Button variant="contained" color="secondary" size="small" startIcon={<IoCloudUpload /> } onClick={() => setIsUploadDialogOpen(true)} className="btnUpload"> Upload Logo</Button>
                      )}

                      {companyData.logo && (
                        <IconButton
                          variant="contained"
                          onClick={() => {
                            setIsDeleteDialogOpen(true);
                          }}
                        >
                          <FiTrash2></FiTrash2>
                        </IconButton>
                      )}
                    </div>
                  </UserAvatar>
                )}
              </div>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Caption label="Company name" value={companyData?.name} />
                </Grid>
                <Grid item xs={3}>
                  <Caption label="Company phone" value={companyData?.phone} />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Company Fax"
                    value={companyData?.fax || "No fax"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Company website"
                    value={companyData?.website || "No website"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption label="language" value={companyData?.lang || "No language"} />
                </Grid>
                <Grid item xs={3}>
                  <Caption label="Currency" value={companyData?.currency} />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Timezone"
                    value={timezone?.label || "No time zone"}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Locale"
                    value={companyData?.locale || "No Locale"}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <Caption
                    label=" Asset Review "
                    value={companyData?.assetReview ? "Enabled" : "Disabled"}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Card>

        <Grid container spacing={2} sx={{ marginBottom: "15px" }}>
          <Grid item xs={8.5}>
            <Card className="form-card" cardStyle="card">
              <Box className="form-heading">
                <Typography variant="h2">Primary Contact:</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Caption
                    label="First name"
                    value={companyData?.primaryContact?.firstName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Last name"
                    value={companyData?.primaryContact?.lastName}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Email"
                    value={companyData?.primaryContact?.email}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Caption
                    label="Phone"
                    value={companyData?.primaryContact?.phone || "No phone"}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={3.5}>
            <Card className="form-card" cardStyle="card">
              <Box className="form-heading">
                <Typography variant="h2">Billing:</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Caption
                    label="Type"
                    value={companyData?.billing?.type || "No Billing Type"}
                  />
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Card className="form-card" cardStyle="card">
          <Box className="form-heading">
            <Typography variant="h2">Address:</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={2.4}>
              <Caption
                label="Country"
                value={companyData?.address?.country || "No country"}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Caption
                label="State"
                value={companyData?.address?.state || "No state"}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Caption
                label="City"
                value={companyData?.address?.city || "No city"}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Caption
                label="Street"
                value={companyData?.address?.street || "No street"}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Caption
                label="Postal Code"
                value={companyData?.address?.postalCode || "No postalCode"}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
      <Stack direction="row" justifyContent="flex-end" gap={2} sx={{ mt: 2 }}>
        <Button
          onClick={() => {
            navigate(`/company/edit/${companyId}`);
          }}
          variant="contained"
        >
          Edit Profile
        </Button>
      </Stack>
    </>
  );
};

export default ViewCompanyProfile;

import {
  IoImage,
  IoTextOutline,
  IoNewspaper,
  IoCreateSharp,
  IoCheckbox,
  IoAppsSharp,
  IoRadioButtonOn,
  IoBarChartSharp,
  IoPencilOutline,
  IoVideocam,
  IoListSharp,
  IoGrid,
} from "react-icons/io5";
import { MdAudiotrack, MdSocialDistance } from "react-icons/md";
import { BsLink45Deg, BsSliders } from "react-icons/bs";
import {
  AiOutlineGroup,
  AiOutlineCreditCard,
  AiFillStar,
} from "react-icons/ai";
import { MdOutlineViewCarousel } from "react-icons/md";
import { IoMdRemove } from "react-icons/io";
import { ReactComponent as ParaIcon } from "src/assets/images/ParagraphIcon1.svg";
import { ReactComponent as TitleIcon } from "src/assets/images/Title.svg";



export const email_toolbar_items = {
  components: [
    {
      type: "cs-image",
      caption: "Image",
      icon: <IoImage />,
      permissionPath:'emailTemplates.components.image'
    },
    {
      type: "cs-text",
      caption: "Paragraph",
      icon: <ParaIcon />,
      permissionPath:'emailTemplates.components.text'
    },
    {
      type: "cs-card",
      caption: "Card",
      icon: <IoNewspaper />,
      permissionPath:'emailTemplates.components.card'
    },
    {
      type: "cs-table",
      caption: "Table",
      icon: <IoAppsSharp />,
      permissionPath:'emailTemplates.components.table'
    },
    {
      type: "cs-link",
      caption: "link",
      icon: <BsLink45Deg />,
      permissionPath:'emailTemplates.components.link'
    },
    {
      type: "cs-input",
      caption: "Title",
      icon: <TitleIcon />,
      permissionPath:'emailTemplates.components.input'
    },
    {
      type: "cs-grid",
      caption: "Grid",
      icon: <IoGrid />,
      permissionPath:'emailTemplates.components.grid'
    },
    {
      type: "cs-list",
      caption: "List",
      icon: <IoListSharp />,
      permissionPath:'emailTemplates.components.input'
    },
    {
      type: "cs-rating",
      caption: "Rating",
      icon: <AiFillStar />,
      permissionPath:'emailTemplates.components.rating'
    },
    {
      type: "cs-divider",
      caption: "Divider",
      icon: <IoMdRemove />,
      permissionPath:'emailTemplates.components.divider'
    },
    {
      type: "cs-social",
      caption: "Social",
      icon: <MdSocialDistance />,
      permissionPath:'emailTemplates.components.social'
    },
  ],
};

import {
  getHTMLFromBody,
  getLayersEditableChildren,
  insertHtmlToDropzone,
  editableActions,
  setLayerElementIds,
  deActiveElementOnOtherSideClick,
} from "src/services/editor.service";
import { csRenderFunctional, useSetLayerHeight } from "../editorLogics/components";
import { getRandomId } from "src/services/utils";
import { Sortable } from "../editorLogics/sortable";
import { HISTORY_ACTIONS } from "src/data/contants";

export const attachlisteners = ({
  element,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
  forCapture,
}) => {
  const isDropzone = element.classList.contains("cs-slide");
  // Redering Main element (can be dropzone or a layer)
  element.classList.add("editable");
  element.classList.remove("reset");

  if (isDropzone) {
    // <----------------------Dropzone------------------------>
    element.addEventListener("dblclick", (e) => {
      e.stopPropagation();
      editableActions.clickHandler({ element, activeElement });
    });
  } else {
    // <----------------------Layer------------------------>
    // Click listener
    const addEventListeners = (element, isLayer) => {
      element.addEventListener("click", (e) => {
        e.stopPropagation();

        if(document.querySelectorAll('.cs-col')){
          document.querySelectorAll('.cs-col').forEach((col)=>{
            col.classList.remove('selected')
          })
        }

        if(e.target.classList.contains('cs-col')){
          editableActions.clickHandler({ e, element: element.parentElement, activeElement, clickedEle: e.target });
          e.target.classList.add('selected')
        }else{
          editableActions.clickHandler({ e, element, activeElement });
        }
        
      });
      // mouse listeners
      element.addEventListener("mouseover", (e) => {
        e.stopPropagation();
        editableActions.mouseOverHandler({ e, element });
      });
      element.addEventListener("mouseout", (e) => {
        editableActions.mouseOutHandler({ e, element });
      });

      //Note: here the sortable code is responsible to dragging item inside dropzone,
      //while dragging from toolbar to dropzone, you can get code in Toolbar.jsx line 91 makeItemDraggable()
      if (isLayer) {
        const isColumnLayout = element.classList.contains("column-component");
        if (isColumnLayout) {
          //make layout component sortable
          new Sortable(element, {
            preventedContainers: "toolbar",
            containers: "cs-slide",
            zoomedElement: document.getElementById('dropzone'),
            onSort: (itemDetails) => {
              setTimeout(() => {
                updateHistory({
                  action: HISTORY_ACTIONS.sorted,
                  actionState,
                  id: "", // since extraInfo is passing, id isn't required
                  extraInfo: itemDetails,
                });
                requestHierarchyUpdate();
              }, 300);
            },
          });
        } else {
          // on dropzone the cs-tabContent cant accept cs-tabContent, 
          //so we prevented tab componet inside tab component
          const isTabComponent = element.classList.contains("tabGroup-component");
          new Sortable(element, {
            containers: isTabComponent ? "col-appendable" : "col-appendable,cs-tabContent",
            preventedContainers: "toolbar,cs-slide",
            zoomedElement: document.getElementById('dropzone'),
            onSort: (itemDetails) => {
              setTimeout(() => {
                updateHistory({
                  action: HISTORY_ACTIONS.sorted,
                  actionState,
                  id: "", // since extraInfo is passing, id isn't required
                  extraInfo: itemDetails,
                });
                requestHierarchyUpdate();
              }, 300);
            },
          });
        }

      }
    };
    // rendering layer 2nd param, isLayer?
    addEventListeners(element, true);

    // Redering it's nested children if they are editable if not a dropzone
    const nestedEditables = getLayersEditableChildren(element);
    nestedEditables.forEach((nestedEditable) => {
      nestedEditable.classList.add("editable");
      nestedEditable.classList.remove("reset");
      addEventListeners(nestedEditable);
      csRenderFunctional(nestedEditable, forCapture);
    });

    requestHierarchyUpdate();
  }
};

export const applyPadding = (el) => {
  const layer = el;
  const editables = el.querySelectorAll(".editable");;
  const padding = 10;
  //now apply padding class on editable element
  const setPadding = (element) => {
    // if element has not styled padding
    // 'cs-row', 'cs-col',
    if (!element?.style?.padding && !['cs-layer', 'cs-image'].some(cls => element.classList.contains(cls))) {
      element.style.padding = `${padding}px`;
    }
  };

  // set for layer and child elements
  //setPadding(layer);

  if (editables.length) {
    editables.forEach((editable)=>{
      setPadding(editable); // do not se-t for last nested editable
    })
  }
};

export const csRenderMicrositeEditable = ({
  element,
  updateHistory,
  activeElement,
  actionState,
  requestHierarchyUpdate,
  forCapture,
}) => {
  // applying unique id
  if (!element.classList.contains("cs-slide")) {
    setLayerElementIds(element);
    applyPadding(element)
    if (!element.id) {
      element.id = getRandomId();
    }
  }

  // if not have status then append it first
  if (!element.querySelector(".status")) {
    element.insertAdjacentHTML(
      "beforeend",
      "<div class='status'><span></span></div>"
    );
  }
  // Preventing default for anchor tags
  const a = element.tagName === "A" ? element : element.querySelector("a");
  if (a) {
    a.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // Preventing default for image tags
  const img =
    element.tagName === "IMG" ? element : element.querySelector("img");
  if (img) {
    img.addEventListener("click", (e) => {
      e.preventDefault();
    });
  }

  // removing unnecessary stylings
  element.style.transform = "";

  // Adding listeners
  attachlisteners({
    element,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
    forCapture,
  });
};

export const renderEditableElements = ({
  layersContainer,
  activeElement = () => { },
  updateHistory = () => { },
  actionState,
  requestHierarchyUpdate = () => { },
  forCapture = false,
}) => {
  const csLayers = layersContainer.querySelectorAll(".cs-layer");

  // Rendering layersContainer
  csRenderMicrositeEditable({
    element: layersContainer,
    actionState,
    activeElement,
    updateHistory,
    requestHierarchyUpdate,
    forCapture,
  });
  // Rendering Layers
  csLayers.forEach((layer, i) => {
    // now bnding funcationality to layers
    csRenderMicrositeEditable({
      element: layer,
      actionState,
      activeElement,
      updateHistory,
      requestHierarchyUpdate,
      forCapture,
    });
  });

  requestHierarchyUpdate();
};

export const resetMicrositeWidth = () => {
  const dropzone = document.getElementById('dropzone')
  dropzone.style.width =  '1250px'
}

export const renderMicrosite = ({
  dropzone,
  assetsBaseUrl,
  editorHtmlData,
  activeElement,
  updateHistory,
  actionState,
  requestHierarchyUpdate,
  deActiveElement,
} = {}) => {
  // Appending HTML
  insertHtmlToDropzone(dropzone, editorHtmlData);
  resetMicrositeWidth(true)
  dropzone.style.width = '1250px'
  // Rendering with functionalities
  renderEditableElements({
    layersContainer: dropzone,
    activeElement,
    updateHistory,
    actionState,
    requestHierarchyUpdate,
  });

  return deActiveElementOnOtherSideClick(deActiveElement);
};

export const micrositeBreakPoints = {
  mobile: 320
}

export const generateAndAppendMediaQueries = () => {
  // Find all sections with columns
  const sections = document.getElementById('dropzone').querySelectorAll("section");
  let desktopMediaQueryCSS = ""; // For desktop widths
  let mobileMediaQueryCSS = ""; // For mobile widths

  sections.forEach((section) => {
    const columns = section.querySelectorAll(".cs-col");

    columns.forEach((column, i) => {
      const columnId = column.id;
      const desktopWidth = column.getAttribute("data-desktop-width") || "50"; // Fallback width
      const mobileWidth = column.getAttribute("data-mobile-width") || "100"; // Assuming mobile width stored in a data attribute

      // Append CSS for desktop
      desktopMediaQueryCSS += `#${columnId} { width: ${desktopWidth}%;}`;
      // Append CSS for mobile
      mobileMediaQueryCSS += `#${columnId} { width: ${mobileWidth}%;}`;
    });
  });

  // Combine into final media queries
  const mediaQueryCSS = `
    /*for mobile*/
    @media (max-width: 768px) {
      ${mobileMediaQueryCSS}
    }
    /*for desktop*/
    @media (min-width: 769px) {
      ${desktopMediaQueryCSS}
    }
  `.trim();


  // Uncomment to append the style to the document
  const styleElement = document.createElement("style");
  styleElement.id = 'colums-style';
  styleElement.innerHTML = mediaQueryCSS;

  // // Append the style element to the drop zone or first section
  const dropZone = document.querySelector("#dropzone");
  const existingStyleElement = dropZone.querySelector("#colums-style");
  if (!existingStyleElement) {
    dropZone.prepend(styleElement)
  } else {
    existingStyleElement.innerHTML = mediaQueryCSS
  }
};
import React from "react";
import { getHTMLFromBody, setBodyinHTML } from "src/services/editor.service";
import { csRenderFunctional } from "src/components/editor/editorLogics/components";
const MicrositeIframe = (props) => {
  const {landingpageHtml} = props;
  const headerIframeTags = `
    <link rel="stylesheet" href="/css/components.css">
    <link rel="stylesheet" href="/css/animate.min.css">
    <link rel="stylesheet" href="/css/grids.css">
    <link rel="stylesheet" href="/css/jquery-ui.css">
    <script defer src="https://cdn.jsdelivr.net/npm/chart.js@3.7.1/dist/chart.min.js"></script>
    <script defer src="https://cdnjs.cloudflare.com/ajax/libs/jquery.flipster/1.1.5/jquery.flipster.min.js"></script>  
    <script src="/js/jquery-1.12.4.min.js"></script>
    <script src="/js/slider.js"></script>
     
    <style>
      @import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
      *{
        margin:0;
        padding:0;
        box-sizing:border-box;
      }
        
      html{
        height: 100%;
      }
      body{
         display: flex;
          justify-content: center;
          width:100%;
          height: 100%;
          font-family : Lato ,sans-sarif;
      }
      #loading-iframe{
        z-index : 100;
        display : flex;
        justify-content : center;
        align-items : center;
        position : absolute;
        width : 100%;
        height : 100%;
        background-color : white;
      }
      #dropzone{
        height: auto;
      }
      .item-loading{
        align-self: center;
        font-size: 20px;
        font-weight: 600;
        font-family: lato;
      }

      ::-webkit-scrollbar {
      width: 0.4em;
      height: 0.4em;
    }

    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    ::-webkit-scrollbar-thumb {
    background-color: #2cc1d6;
    }
    @media(max-width:320px){
    .cs-grid-item{
      width:100% !important;
    }
    }
    </style>
  `;

  const renderFunctionalComponents = (e) => {
    const renderFunction = `
      const layers = document.querySelectorAll('[class*="cs-"]');
      csRenderFunctional(layers);
      layers.forEach((element) => {
        // console.log(element);
        csRenderFunctional(element);
      });
      `;
    // document.getElementById("loading-iframe").remove()
    const renderScript = document.createElement("script");
    renderScript.text = renderFunction;
    const frameDoc = e.target.contentWindow.document;
    frameDoc.body.append(renderScript);
  };
  return (
    <iframe
      onLoad={renderFunctionalComponents}
      loading="lazy"
      className={"data"}
      srcDoc={setBodyinHTML(getHTMLFromBody(landingpageHtml), headerIframeTags)}
     // width={iframeWidth}
     style={{height:"100%", width:"100%", border:'0px'}}
    ></iframe>
  );
};

export default MicrositeIframe;

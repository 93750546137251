import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Content from "src/layouts/Content";
import { getUser, updateUser } from "src/store/actions/users";
import UsersForm from "./UsersForm";

const EditUser = () => {
  const { id } = useParams();
  const { company } = useSelector((state) => state.auth.userData);
  const [loading, setLoading] = useState(false);
  const companyId = company.companyId;
  const { userToEdit } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSubmitHandler = (editData) => {
    const data = { ...editData, company };
    return dispatch(updateUser({ data, id: editData._id }))
      .unwrap()
      .then(() => {
        navigate("/settings/users");
      });
  };

  useEffect(() => {
    setLoading(true);
    if (companyId) {
      dispatch(getUser({ id, companyId }))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch, companyId]);

  return (
    <>
    <Content title="Edit User">
      {loading ? (
        "Loading..."
      ) : (
        <UsersForm
          initialData={JSON.parse(JSON.stringify(userToEdit))}
          onSubmit={onSubmitHandler}
          cancelPath="/settings/users"
        />
      )}
    </Content>
    </>
  );
};

export default EditUser;

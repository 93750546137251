import { AiOutlineMinus } from "react-icons/ai";
import JSZip from 'jszip';
import store from "src/store";

export const getFormattedCell = (data) => {
  return data ? data : <AiOutlineMinus />;
};
export const getLabelFromCamelCase = (str) => {
  const words = str.split(/(?=[A-Z][a-z])/);

  const labels = {
    numberOfSlidePerPresentation: "Number Of Slides",
  };

  const label =
    labels[str] ||
    words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");

  return label;
};
// export const validPhone = /[^0-9-+]/g;
export const validPhone = /[^0-9+()-]|([+-])\1+/g
export const onlyPhone = (str) => {
  if (str && str.length > 0) {
    str = str?.replace(validPhone, '')
  }
  return str;
}


export const passwordValidator = (password) => {
  // Minimum 8 characters, at least one uppercase letter, one lowercase letter, one number and one special character
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return pattern.test(password);
};

export function reArangeFilters(filters = {}) {
  let queryString = "?";
  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      for (let item of value) {
        queryString = `${queryString}${key}=${item}&`
      }
    } else {
      queryString = `${queryString}${key}=${value}&`;
    }
  }
  return queryString != "?" ? queryString : "";
}

// Example permissions object
const permissions = {
  presentation: {
    create: {
      uploadZipFile: true,
      createViaTemplate: true,
      clone: true,
    },
    maxNumberOfSlides: 4,
    versions: true,
    commentsAndCollaboration: true,
    export: {
      downloadSourceCode: true,
      downloadBuild: true,
      downloadAsPDF: true,
      downloadAsPPT: true,
      applicationLinkForIOS: true,
      applicationLinkForAndroid: true,
    },
    components: {
      image: true,
      text: true,
      card: true,
      container: true,
      popup: true,
      radio: true,
      checkbox: true,
      table: true,
      flipCard: true,
      button: true,
      slider: true,
      audio: true,
      video: true,
      input: true,
      graph: true,
      coverFlow: true,
      carousel: true,
      extendedCard: true,
      tabs: true,
      list: true,
    },
    chapter: {
      create: true,
      hide: true,
      lock: true
    },
  },
  emailTemplates: {
    create: {
      uploadZipFile: true,
      createViaTemplate: true,
      clone: true,
    },
    versions: true,
    commentsAndCollaboration: true,
    export: {
      downloadSourceCode: true,
    },
    components: {
      image: true,
      text: true,
      card: true,
      table: true,
      input: true,
      grid: true,
      rating: true,
      divider: true,
      social: true,
      link: true,
    },
  },
  microsites: {
    create: {
      uploadZipFile: true,
      createViaTemplate: true,
      clone: true,
    },
    versions: true,
    commentsAndCollaboration: true,
    export: {
      downloadSourceCode: true,
    },
    components: {
      image: true,
      text: true,
      card: true,
      socialLinks: true,
      button: true,
      radio: true,
      checkbox: true,
      table: true,
      flipCard: true,
      video: true,
      input: true,
      graph: true,
      coverFlow: true,
      carousel: true,
      extendedCard: true,
      tabs: true,
      list: true,
      ratting: true,
      divider: true,
    },
  },
  storageCapacity: true,
  share: true,
  approvalProcess: false,
  campaign: {
    analytics: true
  },
  userSettings: {
    maxNumberOfUsers: 5
  },
  salesforceIntegration: true,
}
// presentation.create.uploadZipFile

// Recursive function to check permissions
export const hasPermission = (permissionPath, permissionsObj) => {
  if (!permissionPath) return false
  const permissionKeys = permissionPath.split('.');
  const { auth } = store.getState();

  // Check if auth.permissions.permissions exists in the store
  if (!auth || !auth.permissions) {
    // Permissions not found, return false
    return false;
  }

  if (!permissionsObj) {
    permissionsObj = auth?.permissions;
  }


  // if (permissionKeys.length == 1) {
  //   return permissionsObj[permissionPath]
  // }

  for (const key of permissionKeys) {
    if (!permissionsObj.hasOwnProperty(key)) {
      return false; // Permission path does not exist
    }
    permissionsObj = permissionsObj[key];
  }


  // Return the final permission value
  return permissionsObj;

};
export const pixelsToInches = (pixels) => {
  let pixelPerInch = 96;
  let value;
  if (typeof (pixels) === 'string' && pixels.includes('px')) {
    value = Number(pixels.replace('px', '')) / pixelPerInch
  } else {
    value = pixels / pixelPerInch;
  }

  return value.toFixed(1)
}
export const removePx = (str) => {
  if (typeof (str) === 'string' && str.includes('px')) {
    return Number(str.replace('px', ''))
  } else {
    return str
  }
}
export const getPadding = (element, noUnit = false) =>{
  if(!element) return false
  const style = getComputedStyle(element)
  return {
    inline: ()=> {
      if(noUnit){
        return removePx(style.paddingLeft) + removePx(style.paddingRight);
      }else{
        return removePx(style.paddingLeft) + removePx(style.paddingRight)+"px";
      }
      
    }
  }
}
export const countKeywordOccurrences = (str, keyword) => {
  if (typeof str !== 'string' || typeof keyword !== 'string') {
    throw new Error('Both arguments must be strings');
  }

  // Create a regular expression to match the keyword globally and case-insensitively
  const regex = new RegExp(keyword, 'gi');

  // Use match to find all occurrences and return the length of the result array
  const matches = str.match(regex);

  // If matches is null, return 0; otherwise, return the length of the matches array
  return matches ? matches.length : 0;
}

export const formatHTML = (string) => {
  return string?.replace(/^\s*$(?:\r\n?|\n)/gm, '');
}

export const validation = {
  hasSpecialChar: (str) => {
    if (str) {
      return /[!@#$%^&*(),.?":{}|<>]/.test(str)
    }
  },

  hasNumber: (str) => {
    if (str) {
      return /\d/.test(str)
    }
  },
  hasUppercase: (str) => {
    if (str) {
      return /[A-Z]/.test(str);
    }
  }
}

export const setFontSize = (selector, ratio = 0.4) => {
  const elements = document.querySelectorAll(selector);
  if (elements) {
    elements.forEach((element) => {
      const width = element.offsetWidth;
      // Example formula: font size is 10% of the element's width
      const fontSize = width * ratio; // Adjust the multiplier as needed
      element.style.fontSize = `${fontSize}px`;
    })
  }
}


export const checkZipFileFormat = async (file, type = "Presentation") => {
  if (!file) {
    return
  }
  const zip = new JSZip();

  const requiredPaths = {
    "Presentation": [
      'assets/slides/', 'assets/slides/images/', 'index.html',
      'lib/app.js', 'structure.js', 'structure.json'
    ],
    "Microsite": [
      'assets/images/', 'index.html',
      'lib/app.js', 'structure.json'
    ],
    "Email": [
      'assets/images/', 'index.html', 'structure.json'
    ]
  }

  const currentRequiredPaths = requiredPaths[type]
  const zipContent = await zip.loadAsync(file);
  const fileHierarchy = {};
  zipContent.forEach((relativePath, zipEntry) => {
    fileHierarchy[relativePath] = zipEntry.dir ? 'directory' : 'file';
  });

  const exludedPaths = currentRequiredPaths.filter((item) =>
    !(item in fileHierarchy)
  )
  console.log(fileHierarchy, 'exludedPaths=>', exludedPaths);

  if (exludedPaths?.length == 0) {
    return true
  }
  return false
};

export const getModuleType = () => {
  if (location.pathname.includes('email')) {
    return "emailTemplates"
  }
  if (location.pathname.includes('presentation')) {
    return "presentation"
  }
  if (location.pathname.includes('microsite')) {
    return "microsites"
  }
}

export const copyText = (text, onSuccess, onError) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      if(onSuccess){
        onSuccess()
      }
    })
    .catch((error) => {
      if(onError){
        onError(error)
      }
    });
};
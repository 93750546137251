import React, { useState, useEffect } from "react";
import {
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import Control from "./Control";
import Row from "src/components/properties/Row";
import "src/styles/properties.scss";
import Select_Box from "src/components/common/Select_Box";
import Button from "src/components/Button";

const positionDropDown = [
  { label: "Top", value: "top" },
  { label: "Bottom", value: "bottom" },
  { label: "Left", value: "left" },
  { label: "Right", value: "right" },
];

const vAlignmentDropDown = [
  { label: "Top", value: "top" },
  { label: "Middle", value: "middle" },
  { label: "Bottom", value: "bottom" },
];

function swapByPosition(element1, element2, position) {
  const parent = element1.parentElement;

  // If swapping by 'top', move element1 before element2
  if (position === "top" || position === "left") {
    parent.insertBefore(element1, element2);
  }

  // If swapping by 'bottom', move element1 after element2
  if (position === "bottom" || position === "right") {
    parent.insertBefore(element2, element1);
  }
}

const EmailCardSetting = ({ elementId, updateHistory, undoRedoChange }) => {
  const element = document.getElementById(elementId).querySelector('.cs-text').parentElement;

  const [positionType, setPositionType] = useState(positionDropDown[0].value);
  const [vAlignment, setVAlignment] = useState(vAlignmentDropDown[0].value);

  useEffect(() => {
    if (element && element.children[0]) {
      if (element.children[0].classList.contains("cs-text")) {
        if (
          element.querySelector(".cs-text").style.display === "inline-block"
        ) {
          setPositionType("left");
        } else {
          setPositionType("top");
        }
      } else {
        if (
          element.querySelector(".cs-text").style.display === "inline-block"
        ) {
          setPositionType("right");
        } else {
          setPositionType("bottom");
        }
      }

      if (element.querySelector(".cs-text").style.verticalAlign) {
        const getVAlign = element.querySelector(".cs-text").style.verticalAlign;
        setVAlignment(getVAlign);
      }
    }
  }, [elementId, undoRedoChange]);

  const positionHandler = (e) => {
    const { value } = e.target;
    setPositionType(value);

    const csText = element.querySelector(".cs-text");
    const csImage = element.querySelector(".cs-image");

    swapByPosition(csText, csImage, value);
    if (value === "left" || value === "right") {
      csText.style.display = "inline-block";
      csImage.style.display = "inline-block";
      csText.style.width = "50%";
      csText.style.verticalAlign = "middle";
      csImage.style.verticalAlign = "middle";
    } else {
      csText.style.display = "";
      csImage.style.display = "";
      csText.style.width = "";
      //if image is changed then set image width full, else reset 25%
      csImage.style.width = csImage.src.includes("placeholder") ? "25%" : "25%";
    }

    setTimeout(function () {
      updateHistory();
    }, 100);
  };

  const vAlignmentHandler = (e) => {
    const { value } = e.target;
    setVAlignment(value);
    console.log(value);
    const csText = element.querySelector(".cs-text");
    const csImage = element.querySelector(".cs-image");

    csText.style.verticalAlign = value;
    csImage.style.verticalAlign = value;

    setTimeout(function () {
      updateHistory();
    }, 100);
  };

  return (
    <Control
      heading="Card Setting"
      isCollapse={true}
      className="animationControl"
      expanded={true}
    >
      <Row
        label="Text Position"
        element={
          <Select_Box
            name="type"
            value={positionType}
            onChange={positionHandler}
            options={positionDropDown}
          />
        }
      />

      {(positionType === "left" || positionType === "right") && (
        <Row
          label="Text Vertical Alignment"
          leftsx={8}
          rightsx={4}
          element={
            <Select_Box
              name="type"
              value={vAlignment}
              onChange={vAlignmentHandler}
              options={vAlignmentDropDown}
            />
          }
        />
      )}
    </Control>
  );
};

export default EmailCardSetting;

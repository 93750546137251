import React from 'react'
import { Box, Typography } from '@mui/material'
import { IoInformationCircle } from 'react-icons/io5'
import TooltipWrapper from './Tooltip';
import './Info.scss';
const Info = ({ children, infoTooltip }) => {
  let icon;

  if(infoTooltip){
      icon = <TooltipWrapper title={infoTooltip} width="200"><p><IoInformationCircle /></p></TooltipWrapper>
  }else{
     icon = <IoInformationCircle />;
  }
  
  return (
   <Box className="info-wrapper">
      {icon}
      <Typography component="div"> {children} </Typography>
   </Box>
  )
}

export default Info
import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Main from "../Main";
import "../../../styles/SendTestEmail.scss";
import {
  emailValidator,
  getHTMLFromBody,
  setBodyinHTML,
} from "src/services/editor.service";
import { sendTestEmail } from "src/store/actions/emails";
import Input from "src/components/ui/formElements/Input";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";

const SendTestEmail = (props) => {
  const { isOpen, handleClose, close, templateName } = props;
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);
  const dispatch = useDispatch();

  const html = useSelector((state) => state.emails.emailData);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!disabled) {
      dispatch(
        sendTestEmail({
          to: value,
          templateName,
          html: setBodyinHTML(getHTMLFromBody(html), false, false, true, false, false),
        })
      );
      setValue("");
      setDisabled(true);
      close();
    }
  };

  const valueHandler = (e) => {
    const value = e.target.value;
    setValue(value);
    setDisabled(!emailValidator(value));
  };

  return (
    <Main
      background="white"
      modalWidth="xs"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <form className="sendTestEmail" onSubmit={submitHandler} autocomplete="on">
        <Box textAlign="center" p={3}>
          <Typography htmlFor="email" variant="h1" component="h1">
            Send Test Email
          </Typography>
          <div className="formControl"><input
            type="email"
            id="email"
            value={value}
            onChange={valueHandler}
            placeholder="Sent a test to "
            // sx={{ m: 1 }}
          /></div>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            mt={1}
          >
            <Button variant="outlined" className="sendBtn" onClick={close} startIcon={<IoCloseOutline />}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={disabled}
              className="sendBtn"
              onClick={submitHandler}
              color="success"
              endIcon={<IoCheckmarkOutline />}
            >
              Send
            </Button>
          </Stack>
        </Box>
      </form>
    </Main>
  );
};
export default React.memo(SendTestEmail);

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginForm from "src/components/login/LoginForm";
import AuthLayout from "src/layouts/AuthLayout";

const Login = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { state: locationState } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      if (locationState && locationState.visitedLocation) {
        navigate(locationState.visitedLocation);
      } else {
        navigate("/");
      }
    }
  }, [isLoggedIn]);

  return (
    <AuthLayout heading="Welcome To CreateSmart">
      <LoginForm />
    </AuthLayout>
  );
};
export default Login;

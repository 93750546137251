import React from "react";
import Control from "./Control";
import UnitBoxes from "./unitBoxes/UnitBoxes";
import "src/styles/properties.scss";
import { HISTORY_ACTIONS } from "src/data/contants";

const Padding = ({
  elementId,
  updateHistory: propsUpdateHistory,
  undoRedoChange,
  noControl
}) => {
  const updateHistory = () => {
    propsUpdateHistory({ action: HISTORY_ACTIONS.STYLED_ACTIONS.common });
  };
  
  const padding = (
    <UnitBoxes
        selector={`#${elementId}`}
        property="padding"
        updateHistory={updateHistory}
        undoRedoChange={undoRedoChange}
        elementId={elementId}
        heading="Padding"
      />
  )
  if(noControl){
    return padding;
  }
  return (
    <Control
      className="paddingProperties"
      heading="Padding"
      isCollapse={true}
      divider={false}
      expanded={false}
    >
      {padding}
    </Control>
  );
};

export default Padding;

import { Typography, TextField, Box, InputAdornment, IconButton } from "@mui/material";
import { useState } from "react";
import './Input.scss'
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
const Input = ({
  type,
  value,
  defaultValue,
  onChange,
  labelTag,
  icon,
  inputLabel,
  placeholder,
  name,
  refer,
  required,
  disabled,
  sx,
  className,
  limit,
  size,
  onBlur,
  InputProps,
  inputProps,
  ...rest // Collect any remaining props
}) => {
  let InputPropsIcon = {};
  let inputType = type;
  const [isPassword, setIsPassword] = useState(true);
  const [inputValue, setInputValue] = useState(value || defaultValue || '');

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(e);
    }
  };

  const isInputPassword = type?.toLowerCase().includes('password');
  if (limit) {
    InputPropsIcon.endAdornment = (
      <InputAdornment position="end">
        {inputValue.length} / {limit}
      </InputAdornment>
    );
  }
  
  if (isInputPassword) {
    InputPropsIcon.endAdornment = (
      <IconButton onClick={() => togglePassword()} edge="end" size="small">
        {isPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
      </IconButton>
    );
  }

  const togglePassword = () => {
    setIsPassword((prev) => !prev);
  };

  if (isInputPassword) {
    inputType = isPassword ? 'password' : 'text';
  }

  return (
    <Box className={`inputField ${icon ? 'withIcon' : ''}`}>
      {labelTag && (
        <Typography variant="body1" component="label" className="labelTag">
          {labelTag} {required && <span className="required">*</span>}
        </Typography>
      )}
      {icon && icon}
      <TextField
        fullWidth
        defaultValue={defaultValue}
        className={className}
        label={inputLabel}
        placeholder={placeholder}
        type={inputType}
        name={name}
        value={value}
        onChange={handleInputChange}
        inputRef={refer}
        required={required}
        disabled={disabled}
        sx={sx}
        InputProps={{ ...InputPropsIcon, ...InputProps }} // Combine icons with any custom InputProps
        inputProps={limit ? { maxLength: limit, ...inputProps } : inputProps} // Add maxLength if limit is provided
        size={size}
        onBlur={onBlur}
        variant="outlined"
        {...rest} // Apply remaining props here
      />
    </Box>
  );
};

export default Input;

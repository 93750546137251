import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  activeLayer,
  addActiveClassToElement,
  dragUiHelper,
  findElementType,
  isEditor,
} from "src/services/editor.service";
import { editorActions } from "src/store/reducers/editor-slice";
import { getElementData } from "./shared-logic";

export const useElementStatus = () => {
  const dispatch = useDispatch();

  //this actions passing to integrate with keyboard and mouse actions
  let dispatchEditorActions = {
    openDeleteLayerDailog: function(){
      const element = document.querySelector('.editable.active');
      if(element && !element.classList.contains('cs-slide')){
        if(!element.classList.contains('cs-layer')){
          activeElement(element.closest('.cs-layer'))
          dispatch(editorActions.setEditorDialog({dialog: 'deleteLayer', value: true}))
        }else{
          dispatch(editorActions.setEditorDialog({dialog: 'deleteLayer', value: true}))
        }
      }
    }
  }
  // To Active an Element
  const activeElement = useCallback((element, clickedEle) => {
    const id = element?.id;
    if (id) {
      const elementDetails = getElementData({ id });
      const elementType = findElementType(element.getAttribute("class"));
      const payload = {
        elementId: id,
        elementDetails,
        elementType,
      };
      
      if(clickedEle){
        payload.clickedEle = clickedEle.id
      }

      element.focus()
      addActiveClassToElement(element);
      //if user on presentation then show x,y values because they have absolute position
      if(isEditor.presentation()){
        dragUiHelper(element).position.init()
        dragUiHelper(element).position.show()
      }

      dispatch(editorActions.activeElement(payload));
      return payload;
    }
  }, []);

  // to deactive element
  const deActiveElement = useCallback(() => {
    let element = document.querySelector(".editable.active");
    if (element) {
      dragUiHelper()
      addActiveClassToElement(element, false);
      dispatch(editorActions.deActiveElement());
    }
  }, []);

  return { activeElement, deActiveElement, dispatchEditorActions };
};

import React from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";

const Select_Box = (props) => {
  const { name, value, onChange, classes, options, disabled, iconStart } = props;

  return (
    <Box className="selectBox">
      {iconStart}
      <FormControl disabled={disabled}>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          classes={{
            select: "select_box_input",
            icon: "select_box_icon",
            ...classes,
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
              disabled={option.disabled}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Select_Box;

import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import PermissionComp from "../Permission";
import './DropDown.scss';
const DropDown = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let renderButton = null;

  //only button icon
  if (props.dropText) {
    renderButton = (
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={props.dropIcon}
        startIcon={props.startIcon}
        className={`${props.className? 'Button-'+props.className: 'Button'}`}
        color={props.color}
        fullWidth={props.fullWidth ? true : false}
        disabled={props.disabled ? true : false}
      >
        <Typography sx={{ color: "#fff" }}>{props.dropText}</Typography>
      </Button>
    );
  } else {
    renderButton = (
      <Tooltip title={props.tooltip} arrow>
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="outlined"
          onClick={handleClick}
          className={`IconButton ${props.className? props.className: props.iconClass}`}
          disabled={props.disabled}
        >
          {props.dropIcon}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Box className="dropdown-wrapper">
      {renderButton}
      <Menu
        id={props.id? props.id: "basic-menu"}
        className={`dropdown ${props.className? props.className: ''}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props.items && props.items.map((item, index) => (
          <PermissionComp permissionPath={item.permissionPath}>
            <MenuItem
              key={index}
              className="cards-menu-items"
              onClick={() => {
                if (item.onClick) {
                  item.onClick(props.id, props.data);
                }
                handleClose();
              }}
              disabled={item.disabled}
            >
              {item.icon} {item.label}
            </MenuItem>
          </PermissionComp>
        ))}

        {(!props.items && props.children) && (
          <Box>{props.children}</Box>
        )}
      </Menu>
    </Box>
  );
};
export default React.memo(DropDown);

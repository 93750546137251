import { useState, useCallback, useEffect } from "react";
import { scaleDropzone } from "../editorLogics/components";

const useZoom = () => {
  const [zoom, setZoom] = useState(1); // Default zoom set to 100%
  const [scaledToFit, setScaledToFit] = useState(false);

  const handleZoomChange = (value) => {
    if (value === "scaleToFit") {
      handleScaleToFit();
    } else {
      const minZoom = 1;
      const maxZoom = 250;
      if (value < minZoom) {
        value = minZoom;
      } else if (value > maxZoom) {
        value = maxZoom;
      }
      const zoomValue = value / 100;
      document.documentElement.style.setProperty("--zoom", zoomValue);

      scaleDropzone(
        document.getElementById("mainWrapper").parentElement,
        document.getElementById("dropzone"),
        zoomValue
      );
      setZoom(+value);
      setScaledToFit(false);
    }
  };

  const handleScaleToFit = () => {
    scaleDropzone(
      document.getElementById("mainWrapper").parentElement,
      document.getElementById("dropzone"),
      "scaleToFit",
      (scale) => {
        let zoom = Math.round(scale * 100);
        document.documentElement.style.setProperty("--zoom", scale);
        setZoom(zoom);
      }
    );
    setScaledToFit(true);
  };
  
  return {
    zoom,
    scaledToFit,
    handleZoomChange,
    handleScaleToFit
  };
};

export default useZoom;

import React from 'react';
import { Home, Settings, AccountCircle, Search, Info, Close } from '@mui/icons-material';
import { BiDuplicate } from 'react-icons/bi';
import { AiOutlineDelete } from 'react-icons/ai';
import { IoAddSharp } from 'react-icons/io5';
import { IoMdAdd } from 'react-icons/io';
const IconComponent = ({ iconname, ...props }) => {
  // Object mapping icon names to imported icons
  const iconsMap = {
    add: IoMdAdd,
    addlight: IoAddSharp,
    delete: AiOutlineDelete,
    duplicate: BiDuplicate,
    // Add more icons as needed
  };

  // Get the icon based on the iconname prop
  const SelectedIcon = iconsMap[iconname.toLowerCase()];
  
  if(!SelectedIcon) return null
  // Return the selected icon component or null if not found
  return <SelectedIcon {...props} /> 
};

export default IconComponent;

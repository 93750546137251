import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, Typography, Checkbox } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";

import Content from "src/layouts/Content";
import Input from "src/components/ui/formElements/Input";
import CardItem from "src/components/common/CardItem";
import SelectBox from "../../ui/formElements/SelectBox";
import tempImage from "../../../assets/images/temp/img1.png";
import Main from "../Main";

import {
  createPresentation,
  fetchTemplates,
} from "src/store/actions/presentations";
import { uiActions } from "src/store/reducers/ui-slice";

import { setTemplates } from "src/store/reducers/presentations-slice";
import "../../presentations/slide.scss";
import { countries, languages } from "src/data/data";
import { devices } from "../../editor/editorLogics/components";
const AddNewPresentation = (props) => {
  const { show, close } = props;

  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const { templates, templatesMsg } = useSelector(
    (state) => state.presentations
  );
  const intialState = {
    name: "",
    image: "N/A",
    category: "abc",
    lang: "ENG",
    country: "GBL",
    region: "pak",
    version: "0.001",
    targetDevice: "iPad",
    templateId: "",
    startDate: "",
    endDate: "",
    ApprovalSystemExtId: "",
    originalSlides: true,
    status: "draft",
  };
  const [presentation, setPresentation] = useState(intialState);

  useEffect(() => {
    dispatch(
      fetchTemplates({
        targetDevice: intialState.targetDevice,
        type: "presentation",
      })
    );
    setPresentation(intialState);
  }, []);

  const handleChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (key === "targetDevice") {
      dispatch(setTemplates({ templates: [] }));
      setPresentation((pre) => ({ ...pre, templateId: "" }));
      dispatch(fetchTemplates({ targetDevice: value, type: "presentation" }));
    }
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    if (key === "name") {
      if (value.length > 50) {
        setError("Name cannot exceed 50 characters");
        value = value.slice(0, 50); // Enforce the character limit
      } else {
        setError("");
      }
    }
    setPresentation((pre) => ({ ...pre, [key]: value }));
  };

  const selectTemplate = (e, id) => {
    const cards = document.querySelectorAll(".templateContainer .cardItem");
    setPresentation({ ...presentation, templateId: id });
    cards.forEach((card) => card.classList.remove("success-checkmark"));
    e.currentTarget.classList.add("success-checkmark");
  };

  const addPresentation = (e) => {
    e.preventDefault();
    if (presentation.templateId !== "") {
      dispatch(createPresentation(presentation));
      close();
      setPresentation({
        ...presentation,
        templateId: "",
        name: "",
        targetDevice: "",
        lang: "",
        country: "",
      });
      dispatch(uiActions.hideNotification());
    } else {
      dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Select Template",
          message: "Please Select Template to create Presentation",
        })
      );
    }
  };

  const closeDialog = () => {
    close();
    resetForm(); // Reset the form on close
  };
  // Function to reset form state
  const resetForm = () => {
    setPresentation(intialState);
  };
  return (
    <Main className="previewDialog" isOpen={show} handleClose={closeDialog}>
      <Content title="Create New Presentation">
        <form onSubmit={addPresentation}>
          <div className="presentation-input">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <Box className="ControlWrap">
                  <Input
                    type="text"
                    // label="Presentation Name"
                    placeholder="Enter text here.."
                    name="name"
                    labelTag="Presentation Name"
                    value={presentation.name}
                    onChange={handleChange}
                    limit="50"
                    required
                    inputProps={{ maxLength: 50 }}
                  />
                  {error && (
                    <Typography color="error" variant="body2">
                      {error}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className="ControlWrap">
                  <SelectBox
                    // label="Country"
                    name="country"
                    labelTag="Country"
                    onChange={handleChange}
                    renderValue="Select Country"
                    value={presentation.country}
                    options={countries}
                    required
                    displayEmpty
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className="ControlWrap">
                  <SelectBox
                    // label="Language"
                    name="lang"
                    labelTag="Language"
                    renderValue="Select Language"
                    value={presentation.lang}
                    onChange={handleChange}
                    options={languages}
                    required
                    displayEmpty
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={3}>
                <Box className="ControlWrap">
                  <SelectBox
                    // label="Target Device"
                    name="targetDevice"
                    labelTag="Target Device"
                    renderValue="Select Device"
                    value={presentation.targetDevice}
                    onChange={handleChange}
                    defaultValue="iPad"
                    options={devices}
                    required
                    displayEmpty
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              my={0.6}
              justifyContent="space-between"
              alignItems="center"
              mt={2}
              mb={2}
            >
              <Grid item xs={6} md={6}>
                <Typography variant="h4" component="h4" className="small">
                  Choose From Template
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box textAlign="end">
                  <Typography variant="h4" component="h4" className="small">
                    Sort by:
                    <strong style={{ color: "#2E3A94" }}>Newest First</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
          <div className="templateContainer" style={{ overflowY: "scroll",minHeight:'200px' }}>
            <Grid container spacing={1}>
              {templates.map((template, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    xs={12}
                    md={2}
                    className="custom-width"
                  >
                    <CardItem
                      classes="presentationTemplateCard"
                      id={template._id}
                      image={template.image !== "" ? template.image : tempImage}
                      title={template.name}
                      onClick={(e) => selectTemplate(e, template._id)}
                    />
                  </Grid>
                );
              })}
              {templatesMsg && (
                <Grid item xs={12} md={12} className="custom-width">
                  <Typography variant="h2" component="h4" className="small">
                    {templatesMsg}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </div>

          <div className="button-div">
            <Grid container mt={2}>
              {/* <Grid item md={10}>
               This checkbox is not in use for now. 
               <Box display="flex" alignItems="center">
                <Checkbox
                  color="secondary"
                  defaultChecked
                  name="originalSlides"
                  onChange={handleChange}
                />
                <Typography textTransform="capitalize" color="#2D3748">
                  keep slides from orignal template
                </Typography>
              </Box> 
            </Grid>*/}
              {/* <Grid item  md={2} display="flex" justifyContent="space-between"> */}
              <div className="custom-btn">
                <Button
                  sx={{ mr: 2 }}
                  variant="outlined"
                  className="capitalize"
                  onClick={closeDialog}
                  startIcon={<AiOutlineClose size={13} />}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="capitalize"
                  type="submit"
                  startIcon={<BsCheck2 size={13} />}
                >
                  Create
                </Button>
              </div>
            </Grid>
          </div>
          {/* </Grid> */}
        </form>
      </Content>
    </Main>
  );
};
export default React.memo(AddNewPresentation);

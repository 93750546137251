import HttpService from "../../services/http.service";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { uiActions } from "../reducers/ui-slice";
import { api } from "../../interfaces/api.interface";

export const getFoldersList = createAsyncThunk(
  "getFoldersList",
  async (filters, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.getList(filters),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMoveableFoldersList = createAsyncThunk(
  "getMoveableFoldersList",
  async (filters, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.getList(filters),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getFoldersHierarchy = createAsyncThunk(
  "getFoldersHierarchy",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.getHierarchy(id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getMoveFoldersHierarchy = createAsyncThunk(
  "getMoveFoldersHierarchy",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.getHierarchy(id),
        null,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const createFolder = createAsyncThunk(
  "createFolder",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.create(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Folder created!",
            message: "Folder created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const updateFolder = createAsyncThunk(
  "updateFolder",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.update(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated !",
            message: "Folder Updated Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


export const deleteFolder = createAsyncThunk(
  "deleteFolder",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.folder.delete(id),
        undefined,
        thunkAPI,
        undefined,
        true
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted !",
            message: "Folder Deleted Successfully",
          })
        );
      }
      if(response){
        return id;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const { useEffect, useState } = require("react");

export const ThumbnailImage = ({ src, cache = false, classes}) => {
   const [imageSrc, setImageSrc] = useState(src);
   const [attemptedJpg, setAttemptedJpg] = useState(false);
   const [attemptedPng, setAttemptedPng] = useState(false);
   
   useEffect(() => {
     // Reset state when src changes
     setImageSrc(src);
     setAttemptedJpg(false);
     setAttemptedPng(false);
   }, [src]);
 
   const handleError = () => {
     if (src.endsWith('.jpg') && !attemptedPng) {
       setImageSrc(src.replace('.jpg', '.png'));
       setAttemptedPng(true);
     } else if (src.endsWith('.png') && !attemptedJpg) {
       setImageSrc(src.replace('.png', '.jpg'));
       setAttemptedJpg(true);
     }
   };
   
   let newSrc = !cache ? imageSrc+`?time=${Date.now()}` : imageSrc;
   
   return <img src={newSrc} onError={handleError} alt="Image" className={classes || ''} />;
 };
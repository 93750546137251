import { Grid, createTheme } from "@mui/material";
import React, { useState } from "react";
import CardItem from "src/components/common/CardItem";
import ContentAction from "src/components/ui/formElements/ContentAction";
import Content from "src/layouts/Content";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import MasterTemplateControls from "./MasterTemplateControls";
import CreateMasterTemplate from "./dialog/CreateMasterTemplate";
import { createMasterTemplate, updateMasterTemplate } from "src/store/actions/presentations";
import { useNavigate, useParams } from "react-router-dom";
import RenameMasterTemplate from "./dialog/RenameMasterTemplate";
const MasterTemplates = () => {
  const [createTemplateDialog, setCreateTemplateDialog] = useState(false);
  const [createTemplateLaoding, setCreateTemplateLaoding] = useState(false);

  const [renameTemplateDialog, setRenameTemplateDialog] = useState(false);
  const [renameTemplateLoading, setRenameTemplateLoading] = useState(false);
  const [targetTemplate, setTargetTemplate] = useState(null);
  const { presentation: { masterTemplates, bucketURL } } = useSelector((state) => state.presentations);
  const { id: presentationId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const showCeateTemplateDialog = () => {
    setCreateTemplateDialog(true);
  };
  const hideCeateTemplateDialog = () => {
    setCreateTemplateDialog(false);
  };

  const createTemplate = (templateName) => {
    setCreateTemplateLaoding(true)
    dispatch(createMasterTemplate({
      presentationId: presentationId,
      template: { name: templateName }
    })).then(() => {
      setCreateTemplateDialog(false);
      setCreateTemplateLaoding(false)
    })
  };

  //rename functions
  const showRenameTemplateDialog = (clickedTemplate) => {
    setTargetTemplate(clickedTemplate)
    setRenameTemplateDialog(true);
  };

  const hideRenameTemplateDialog = () => {
    setRenameTemplateDialog(false);
  };

  const renameTemplate = (getName) => {
    setRenameTemplateLoading(true)
    dispatch(updateMasterTemplate({
      presentationId: presentationId,
      masterTemplateId: targetTemplate?._id,
      data: { name: getName }
    })).then(() => {
      setRenameTemplateDialog(false)
      setRenameTemplateLoading(false)
    })
  };
  return (
    <Content
      title="Master Templates"
      headerAction={
        <ContentAction
          text="Create Master Template"
          variant="contained"
          icon={<IoAdd />}
          onClick={showCeateTemplateDialog}
        />
      }
    >
      <CreateMasterTemplate
        isOpen={createTemplateDialog}
        title="Create New Master Template"
        handleClose={hideCeateTemplateDialog}
        handleConfirm={createTemplate}
        loading={createTemplateLaoding}
      />

      <RenameMasterTemplate
        isOpen={renameTemplateDialog}
        title="Rename Master Template"
        handleClose={hideRenameTemplateDialog}
        handleConfirm={renameTemplate}
        templaneName={targetTemplate?.name}
        loading={renameTemplateLoading}
      />

      {masterTemplates.length > 0 ? (
        <Grid container spacing={1}>
          {masterTemplates.map((template, i) => {
            const imageURL = `${bucketURL}/${template.image}`;
            const thumbnailURL = `${bucketURL}/${template.thumbnail}`;

            return (
              <Grid
                item
                key={template._id}
                className="slide masterTemplatesCard"
                sx={{ cursor: "pointer" }}
                xs={12}
                md={1.5}
              >
                <CardItem
                  image={thumbnailURL}
                  title={template.name}
                  classes="chapterCard"
                  id={template._id}
                  onDoubleClick={(e) => {
                    if (!e.target.closest(".cardItem")) return false;
                    status != "reviewed" && status != "inReview"
                      ? navigate(`/presentation/${presentationId}/edit/${template._id}/template`)
                      : console.log(`reviewed or in-review can't open`);
                  }}
                  controls={
                    <MasterTemplateControls
                      presentationId={presentationId}
                      templateId={template._id}
                      template={template}
                      templateIndex={i}
                      showRenameTemplateDialog={showRenameTemplateDialog}
                      imageURL={imageURL}
                      thumbnailURL={thumbnailURL}
                    />
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <p>No Master template created yet</p>
      )}

    </Content>
  );
};

export default MasterTemplates;

import React, { memo, useEffect, useState } from "react";
import Check_Box from "src/components/common/Check_Box";
import Row from "src/components/properties/Row";
import { convertRgbAToHexAplha } from "src/services/editor.service";
const BackgroundOverlay = ({
  elementId,
  updateHistory,
  undoRedoChange,
}) => {
  const element = document.getElementById(elementId)
  const [overlayEnabled, setOverlayEnabled] = useState(false);
  const [color, setColor] = useState(null);
  const [opacity, setOpacity] = useState(0.6);
  
  
  const createOverlay = (section) => {
    if (section.querySelector(".overlay")) return;
    const newChild = document.createElement("div");
    newChild.classList.add("overlay");
    newChild.style.opacity = opacity;
    section.insertBefore(newChild, section.firstChild);
  };

  const overlayHandler = (e) => {
    const overlayCheck = e.target.checked;
    setOverlayEnabled(overlayCheck);
    if (overlayCheck) {
      createOverlay(element);
    } else {
      const getOverlay = element.querySelector(".overlay");
      element.removeChild(getOverlay);
    }
    updateHistory();
  };

  const colorHandler = (e) => {
    let color = e.target.value;
    console.log(color)
    const elementOverlay = element.querySelector(".overlay");
    if (elementOverlay) {
      elementOverlay.style.background = color;
      setColor(color);
      updateHistory();
    }
  };

  const opacityHandler = (e) => {
    let opacityValue = e.target.value;
    const elementOverlay = element.querySelector(".overlay");
    elementOverlay.style.opacity = opacity;
    setOpacity(opacityValue);
  };

  useEffect(() => {
    const elementOverlay = element.querySelector(".overlay");
    if(elementOverlay){
      const background = elementOverlay.style.background;
      if(background){
        const hexBackground =convertRgbAToHexAplha(background).hex;
        setColor(hexBackground)
      }

      const opacity = elementOverlay.style.opacity;
      if(opacity){
        setOpacity(opacity)
      }
    }
    
  }, [elementId, undoRedoChange]);
  
  useEffect(() => {
    const element = document.getElementById(elementId);
    if (element && element.querySelector('.overlay')) {
      setOverlayEnabled(true);  
    }
  }, [elementId]); 

  return (
    <>
      <Row
        label="Image Overlay"
        component="h4"
        element={
          <Check_Box
            name="animationCheckbox"
            color="secondary"
            size="small"
            checked={overlayEnabled}
            onClick={overlayHandler}
          />
        }
      />

      {overlayEnabled && (
        <>
          <Row
            label="Color"
            element={
              <input
                type="color"
                name="color"
                className="color"
                value={color}
                onInput={colorHandler}
              />
            }
          />

          <Row
            label="Opacity"
            clearProperty="opacity"
            canvasElement={element.querySelector(".overlay")}
            onClear={() => setOpacity(0)}
            element={
              <input
                type="number"
                name="opacity"
                className="input"
                value={opacity}
                onInput={opacityHandler}
                step="0.1"
                min="0"
                max="1"
                onBlur={updateHistory}
              />
            }
          />
        </>
      )}
    </>
  );
};
export default memo(BackgroundOverlay);

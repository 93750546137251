import React from "react";

const Centered = ({ children }) => {
  const style = {
    display: "flex",
    height: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  };
  return <div style={style}>{children}</div>;
};

export default Centered;

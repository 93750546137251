const arrowKeyNavigationHandler = (form) => {
  if (!form) {
    return null; // Return null if the form is not valid
  }

  const handleKeyDown = (event) => {
    console.log('sdasd')
    const buttons = form.querySelectorAll('button');
    const focusedIndex = Array.prototype.indexOf.call(buttons, document.activeElement);

    if (event.key === 'ArrowRight') {
      if (focusedIndex < buttons.length - 1) {
        buttons[focusedIndex + 1].focus();
      } else {
        buttons[0].focus(); // Loop to the first button
      }
      event.preventDefault();
    }

    if (event.key === 'ArrowLeft') {
      if (focusedIndex > 0) {
        buttons[focusedIndex - 1].focus();
      } else {
        buttons[buttons.length - 1].focus(); // Loop to the last button
      }
      event.preventDefault();
    }
  };

  return handleKeyDown;
};

export default arrowKeyNavigationHandler;

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { logout } from "src/store/actions/auth";
import {
  Grid,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Breadcrumbs,
  Tooltip,
  Button,
} from "@mui/material";
import {
  IoPeopleCircle,
  IoMail,
  IoNotifications,
  IoLogOutOutline,
} from "react-icons/io5";

import Guide from "src/components/dialogs/Guide";
import LangAndCountrySelect from "../../components/common/LangAndCountrySelect";
import SearchBox from "../../components/ui/formElements/SearchBox";
import personAvatart from "../../assets/images/person-avatar.jpeg";
import "./Header.scss";
import { countries, languages } from "src/data/data";
import { IoIosHelpCircle } from "react-icons/io";
import { BsMenuApp } from "react-icons/bs";
import { AiOutlineMenu } from "react-icons/ai";
import UserAvatar from "src/components/ui/UserAvatar";

const Header = ({ menuToggleHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { userData } = useSelector((state) => state.auth);
  let userName = userData.firstName +" "+ userData.lastName
  const [searchParams, setSearchParams] = useSearchParams();

  const [showGuide, setShowGuide] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [languageCountrySelect, setLanguageCountrySelect] = useState(false);

  const pathNames = location.pathname
    .split("/")
    .slice(1)
    .filter((x) => !parseInt(x));

  useEffect(() => {
    const path = location.pathname;
    if (path.includes("my-library") || path.includes("shared-with-me")) {
      setLanguageCountrySelect(true);
    } else {
      setLanguageCountrySelect(false);
    }
  }, [location]);

  useEffect(() => {
    document.addEventListener("keyup", guideKeyHandler);
    return () => {
      document.removeEventListener("keyup", guideKeyHandler);
    };
  }, [location.pathname]);

  const setParams = (data, label) => {
    console.log(label);
    const result = data.map(({ value }) => value);
    if (!result.length) {
      if (label === "Language") {
        return searchParams.set(label, []);
      } else {
        return searchParams.set(label, []);
      }
    } else {
      searchParams.set(label, result);
    }
    setSearchParams(searchParams);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const GuideHandler = () => {
    setAnchorEl(null);
    setShowGuide(true);
  };
  const closeGuideHandler = () => {
    setShowGuide(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = (path) => {
    setAnchorEl(null);
    handleMobileMenuClose();
    // navigate(path);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const logoutHandler = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    dispatch(logout());
  };

  const handleProfileNavigate = () => {
    navigate("/profile/view");
    handleMenuClose();
  };

  const hideSearchBox =
    location.pathname.includes("presentations/my-library") ||
    location.pathname.includes("emails/my-library") ||
    location.pathname.includes("/microsites/my-library") ||
    location.pathname.includes("/shared-with-me");

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      classes={{ list: "header-person-details-list" }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <Box className="header-person-details-container">
        <Box className="header-person-image">
          <UserAvatar name={userName} image={userData.profile_pic} />
        </Box>
        <Box className="header-person-details" sx={{maxWidth:'200px'}}>
          <h6>
            {userData.firstName} {userData.lastName}
          </h6>
          <p>{userData.email}</p>
          <p>{userData.company.name}</p>
          <Button variant="outlined" onClick={handleProfileNavigate}>
            Manage Your Account
          </Button>
          <Button variant="outlined" onClick={() => {
            navigate("/company/view");
            handleMenuClose();
          }}>
            Manage Company Account
          </Button>
        </Box>
      </Box>
      {/* <MenuItem onClick={GuideHandler}>
        <IoIosHelpCircle size="22" color="black" /> Help
      </MenuItem> */}
      {location.pathname.includes("edit") && (
        <MenuItem onClick={GuideHandler}>Guide (Alt + G)</MenuItem>
      )}
      {/* <Divider sx={{ my: 0.5 }} /> */}
      <MenuItem onClick={logoutHandler}>
        <IoLogOutOutline size="22" color="#2cc1d6" />
        Sign Out
      </MenuItem>
    </Menu>
  );

  const guideKeyHandler = useCallback(
    (e) => {
      if (e.altKey && e.key == "g" && location.pathname.includes("edit")) {
        setShowGuide(true);
      }
    },
    [location.pathname]
  );
  return (
    <>
      <Guide open={showGuide} closeHandler={closeGuideHandler} />
      <Box sx={{ flexGrow: 0 }} className="desktopHeader">
        <AppBar position="static">
          <Toolbar sx={{ padding: "0px !Important" }}>
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                className="breadcrumb-text"
              >
                {pathNames[0].includes("campaign") ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    {pathNames.map((item, index) => (
                      <Typography
                        variant="h6"
                        noWrap
                        component="p"
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                          },
                        }}
                        key={item + index}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                ) : (
                  <Box className="toggleButton">
                  <IconButton onClick={()=> {
                    menuToggleHandler()
                  }} size="small">
                    <AiOutlineMenu color="white"></AiOutlineMenu>
                  </IconButton>
                  <Typography
                    variant="h1"
                    
                    component="h1"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    {pathNames[0] === 'company'? 'company profile': pathNames[0]}
                  </Typography>
                  </Box>
                )}
              </Grid>
              {languageCountrySelect && (
                <Grid item sm={3} md={2.6}>
                  <Box className="ControlWrap">
                    <LangAndCountrySelect
                      onSelect={setParams}
                      labelTag={false}
                      options={languages}
                      classes=""
                      label="Language"
                      placeholder="Languages"
                    />
                  </Box>
                </Grid>
              )}
              {languageCountrySelect && (
                <Grid item sm={3} md={2.6}>
                  <Box className="ControlWrap">
                    <LangAndCountrySelect
                      onSelect={setParams}
                      labelTag={false}
                      options={countries}
                      classes="transparent"
                      label="Country"
                      placeholder="Countries"
                    />
                  </Box>
                </Grid>
              )}
              {/* {languageCountrySelect && (
                <Grid item md={3} justifySelf="flex-end">
                  <Box className="ControlWrap">
                    <SearchBox />
                  </Box>
                </Grid>
              )} */}
            </Grid>

            <Box sx={{ display: { md: "flex" } }} marginLeft="10px">
              {hideSearchBox && (
                <Box className="ControlWrap search-box-header" sx={{ display: { xs: "none", md: "block" } }} >
                  <SearchBox />
                </Box>
              )}
              
              <Tooltip title={userData.email} arrow>
                <IconButton
                  size="medium"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  className="user-icon-header"
                >
                  {/* <IoPeopleCircle /> */}
                  <UserAvatar name={userName} image={userData.profile_pic} />
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </AppBar>

        {renderMenu}
      </Box>
    </>
  );
};
export default Header;

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthPasswordForm from "src/components/login/AuthPasswordForm";
import Centered from "src/layouts/Centered";

const Login = () => {
  return (
    <Centered>
      <AuthPasswordForm />
    </Centered>
  );
};
export default Login;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, Typography, Checkbox } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";

import Content from "src/layouts/Content";
import Input from "src/components/ui/formElements/Input";
import CardItem from "src/components/common/CardItem";
import SelectBox from "../../ui/formElements/SelectBox";
import tempImage from "../../../assets/images/temp/img1.png";
import Main from "../Main";

import { updatePresentationSetting } from "src/store/actions/presentations";
import { uiActions } from "src/store/reducers/ui-slice";

import { countries, languages } from "src/data/data";
import { devices } from "../../editor/editorLogics/components";
import { setPresentation } from "src/store/reducers/presentations-slice";

const UpdatePresentationDetails = (props) => {
  const { show, close, presentation, presentationIndex } = props;
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(presentation);
  const [updateSettings, setUpdateSettings] = useState({});
  const [error, setError] = useState("");
  const { _id: id } = presentation;

  const handleChange = (e) => {
    const key = e.target.name;
    let val = e.target.value;

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    }
    if (key === "name") {
      if (val.length > 50) {
        setError("Name cannot exceed 50 characters");
        val = val.slice(0, 50); // Enforce the character limit
      } else {
        setError("");
      }
    }
    setSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
    setUpdateSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  useEffect(() => {
    setSettings(presentation);
    setUpdateSettings(presentation);
  }, [presentation]);

  const updateSetting = (e) => {
    e.preventDefault();
    if (!updateSettings?.name?.trim()?.length) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid Name",
          message: "Presentation name can't be empty!",
        })
      );
    }
    dispatch(updatePresentationSetting({ id, data: updateSettings })).finally(
      () => {
        dispatch(
          setPresentation({
            presentationIndex: presentationIndex,
            data: {
              name: settings.name,
              country: settings.country,
              lang: settings.lang,
            },
          })
        );
        close();
      }
    );
  };

  return (
    <Main
      className="previewDialog"
      isOpen={show}
      handleClose={close}
      modalWidth="xs"
    >
      <Content title="Update Presentation">
        <form onSubmit={updateSetting} className="vform">
          <Input
            onChange={handleChange}
            value={settings["name"]}
            name="name"
            className="bordered"
            labelTag="Name"
            disabled={presentation.isLock}
            inputProps={{ maxLength: 50 }}
            limit="50"
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}

          <SelectBox
            onChange={handleChange}
            value={settings["country"]}
            labelTag="Country"
            name="country"
            defaultValue="pakistan"
            classes="bordered"
            options={countries}
            disabled={presentation.isLock}
          />

          <SelectBox
            onChange={handleChange}
            value={settings["lang"]}
            labelTag="Language"
            defaultValue="en"
            classes="bordered"
            name="lang"
            options={languages}
            disabled={presentation.isLock}
          />

          <SelectBox
            onChange={handleChange}
            value={settings["targetDevice"]}
            labelTag="Target Device"
            defaultValue="ipad"
            name="targetDevice"
            classes="bordered"
            options={devices}
            // disabled={presentation.isLock}
            disabled
          />

          {!presentation.isLock && (
            <Box sx={{display:"flex", gap:'10px', justifyContent:'end'}}>
              <Button
                onClick={close}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={updateSetting}
                type="submit"
              >
                Update
              </Button>

              
            </Box>
          )}
        </form>
      </Content>
    </Main>
  );
};
export default React.memo(UpdatePresentationDetails);

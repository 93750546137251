import React, { useState } from 'react';

const Image = ({ src, fallbackSrc, alt, noCache, ...props }) => {
  const cacheBuster = new Date().getTime();
  const [imageSrc, setImageSrc] = useState(src);
  const handleImageError = () => {
    // If the image fails to load, set the source to the fallback image
    setImageSrc(fallbackSrc);
  };


  return <img src={noCache? imageSrc+`?${cacheBuster}` : imageSrc} onError={handleImageError} alt={alt} {...props} />;
};

export default Image;

import React, { useState, useCallback } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Grid from "@mui/material/Grid2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IoPencil, IoTrash } from "react-icons/io5";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { useSearchParams } from "react-router-dom";
import Folder from "./Folder";
import DropDown from "../ui/DropDown";
const FolderItem = ({ folder, setDeletingId, setEditing, setMoveableItem }) => {
  const { name, _id } = folder;
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const open = Boolean(anchorEl);
  // Function to open the menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const openFolder = () => {
    searchParams.set("folderId", _id);
    setSearchParams(searchParams);
  };

  // Create dropdown data using useCallback
  const foldercardItemDropData = [
    {
      label: "Open",
      icon: <FolderOpenIcon />,
      onClick: () => {
        handleMenuClose();
        openFolder();
      },
    },
    {
      label: "Rename",
      icon: <IoPencil />,
      onClick: () => {
        handleMenuClose();
        setEditing(folder);
      },
    },
    {
      label: "Move to",
      icon: <OpenWithIcon />,
      onClick: () => {
        handleMenuClose();
        setMoveableItem(folder);
      },
    },
    {
      label: "Delete",
      icon: <IoTrash />,
      onClick: () => {
        handleMenuClose();
        setDeletingId(_id);
      },
    },
  ];

  return (
    <Folder
      name={name}
      onClick={openFolder}
      controls={
        <DropDown dropIcon={<MoreVertIcon />} items={foldercardItemDropData} />
      }
    />
  );
};

export default FolderItem;

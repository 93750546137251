import cookie from "react-cookies";
import axios from "axios";
import { uiActions } from "src/store/reducers/ui-slice";
import { removeUser } from "src/store/reducers/auth-slice";

const getHeaders = (file) => {
  const token = cookie.load("user") ? cookie.load("user").jwt : "";
  const sfConfig = cookie.load("sf-config");
  axios.defaults.headers.common["authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["sf-token"] = `Bearer ${sfConfig?.token}`;
  if (file) {
    return;
  }
  const headers = {
    "Content-Type": "application/json",
  };

  return headers;
};

const call = (api, data, thunk, file, showError, timeout) => {
  let headers = getHeaders(file);
  let errorDisplay = showError;

   // Check for internet connection
  if (!navigator.onLine) {
    if (thunk) {
      thunk.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Network error!",
          message: "No internet access",
        })
      );
    }
    return Promise.reject(new Error("network-error"));
  }

  if (thunk) {
    thunk.dispatch(uiActions.showLoading());
  }

  return axios({
    method: api.method,
    url: api.url,
    data,
    headers,
    timeout: timeout || 60000,
  }).then((response) => {
      if (thunk) {
        thunk.dispatch(uiActions.hideLoading());
      }

      return response;
    })
    .catch((error) => {
      console.log(error)
      if (error?.response?.status == 401) {
        thunk.dispatch(removeUser())
      }
      if (thunk) {
        thunk.dispatch(uiActions.hideLoading());
      }
      if(error.response){
        if (errorDisplay) {
          console.log('display error', error)
          if (typeof error.response?.data == 'string') {
            console.log(error.response.data, "error.response.data")
            thunk.dispatch(
              uiActions.showNotification({
                status: "error",
                title: "Error!",
                message: error.response.data,
              })
            );
            return Promise.reject([{ message: error.response.data }]);
          }
  
          if (typeof error.response.data.errors[0].message == 'string') {
            if (error?.response?.data?.errors) {
              let message = "An Error Occured";
              if (
                Array.isArray(error.response.data.errors) &&
                error.response.data.errors?.[0]?.message
              ) {
                message = error.response.data.errors[0].message;
              } else if (error.response.data.errors?.message) {
                message = error.response.data.errors.message;
              }
              thunk.dispatch(
                uiActions.showNotification({
                  status: "error",
                  title: "Error!",
                  message: message,
                })
              );
  
              return Promise.reject([{ message }]);
            } else if (error.response) {
              // Response Error
              thunk.dispatch(
                uiActions.showNotification({
                  status: "error",
                  title: "Error!",
                  message: error.message,
                })
              );
              return Promise.reject(error.response.data.errors);
            } else if (error.request) {
              // Request Error
              thunk.dispatch(
                uiActions.showNotification({
                  status: "error",
                  title: "Request Error!",
                  message: error.message,
                })
              );
              return Promise.reject([{ message: error.message }]);
            } else {
              // Error
              thunk.dispatch(
                uiActions.showNotification({
                  status: "error",
                  title: "Error!",
                  message: error.message,
                })
              );
              return Promise.reject([{ message: error.message }]);
            }
          } else {
            thunk.dispatch(
              uiActions.showNotification({
                status: "error",
                title: "Error!",
                message: "Internal server error",
              })
            );
            return Promise.reject([{ message: "Internal server error" }]);
          }
        }
      }else if(error.request){
        thunk.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Server error!",
            message: "No Server Respond",
          })
        );
      }
    });
};

export default {
  call,
};

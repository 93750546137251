import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { MdShare } from "react-icons/md"; 
import { ContentCopy, Delete } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
} from "@mui/material";

import DropDown from "src/components/ui/DropDown";
import { getShareLinkList, deleteShareLink } from "src/store/actions/shareLink";
import { resetShareLinkList } from "src/store/reducers/sharelink-slice";
import { dateFormat } from "src/services/utils";

const ShareLinkTableView = (props) => {
  const { type, id} = props;
  const dispatch = useDispatch();
  const {shareLinkList} = useSelector((state) => state.shareLink);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("version");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {

    dispatch(getShareLinkList({type,id}));
  
    return () => {
      dispatch(resetShareLinkList());
    };
  }, [dispatch]);
 

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = [...shareLinkList].sort((a, b) => {
    if (orderBy) {
      if (order === "asc") {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    }
    return 0;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleShareLink = () => {
    // Add functionality for each action here
  };
  
  const handleDeleteShareLink = (_id) => {
    dispatch(deleteShareLink(_id));
   
  };

  return (
    <TableContainer>
      <Table aria-labelledby="tableTitle">
        {/* Table Header */}
        <TableHead>
          <TableRow>
            {shareLinkHeaders.map((headCell) => (
              <TableCell
                key={headCell.id}
                align="left"
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {sortedData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((shareLinkItem) => {
              return (
              <TableRow hover tabIndex={-1} key={shareLinkItem.id}>
                <TableCell >{shareLinkItem.version}</TableCell>
                <TableCell >{dateFormat(shareLinkItem.createdAt)}</TableCell>
               
                <TableCell align="right">
                  <DropDown
                    dropIcon={<IoEllipsisVerticalSharp size={16} />}
                    tooltip="options"
                    items={[
                      {
                        label: "Copy Link",
                        icon: <ContentCopy />,
                        onClick: () => handleShareLink(),
                      },
                      {
                        label: "Edit",
                        icon: <MdShare />,
                        onClick: () => handleShareLink(),
                      },
                      {
                        label: "Delete",
                        icon: <Delete />,
                        onClick: () => handleDeleteShareLink(shareLinkItem._id),
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
              );
})}
        </TableBody>
      </Table>

      {/* Table Pagination */}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: shareLinkList.length }]}
        component="div"
        count={shareLinkList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default React.memo(ShareLinkTableView);

ShareLinkTableView.defaultProps = {
  initialRows: 10,
};
const shareLinkHeaders = [
  {
    id: "version",
    numeric: false,
    sort: true,
    label: "VERSION",
  },
  {
    id: "createdAt",
    numeric: true,
    sort: true,
    label: "DATE",
  },
];

import { createSlice } from "@reduxjs/toolkit";
import { getSettings, fetchShared, share, removeSharingUser } from "../actions/sharing";

const initialState = {
  shared: [],
  sharedWith: [],
  sharingSettings: [],
  count: 0,
  loadingMsg: "Loading...",
};

const sharingSlice = createSlice({
  name: "sharingSlice",
  initialState,
  reducers: {
    setSharedWith(state, action) {
      state.sharedWith = action.payload;
    },
    setSharedStatus(state, action) {
      try {
        let key = null;
        let index = null;

        //getting key and index of shared presentation
        const allShared = JSON.parse(JSON.stringify(state.shared));
        allShared.forEach((myShared, ind) => {
          if (myShared.presentationId?._id === action.payload.id) {
            key = "presentationId";
            index = ind;
            if (state.shared?.[index]?.[key].status) {
              state.shared[index][key].status = action.payload.status;
              if (action.payload.options) {
                state.shared[index][key].isLock = action.payload.options.isLock;
              }
            }
          } else if (myShared?.emailId?._id === action.payload.id) {
            key = "emailId";
            index = ind;
            if (state.shared?.[index]?.[key].status) {
              state.shared[index][key].status = action.payload.status;
              if (action.payload.options) {
                state.shared[index][key].isLock = action.payload.options.isLock;
              }
            }
          } else if (myShared?.landingPageId?._id === action.payload.id) {
            key = "landingPageId";
            index = ind;
            if (state.shared?.[index]?.[key].status) {
              state.shared[index][key].status = action.payload.status;
              if (action.payload.options) {
                state.shared[index][key].isLock = action.payload.options.isLock;
              }
            }
          }
        });
      } catch (error) {}
    },
    setSharedVersion(state, action) {
      try {
        let key = null;
        let index = null;

        //getting key and index of shared presentation
        const allShared = JSON.parse(JSON.stringify(state.shared));
        allShared.forEach((myShared, ind) => {
          if (myShared.presentationId?._id === action.payload.id) {
            key = "presentationId";
            index = ind;
            if (state.shared?.[index]?.[key].version) {
              let version = Number(state.shared[index][key].version);
              console.log(version,version + 0.1);
              version = version + 0.1;
              state.shared[index][key].version = version.toFixed(1)
            }
          } else if (myShared?.emailId?._id === action.payload.id) {
            key = "emailId";
            index = ind;
            if (state.shared?.[index]?.[key].status) {
              let version = Number(state.shared[index][key].version);
              version = version + 0.1;
              state.shared[index][key].version = version.toFixed(1)
            }
          } else if (myShared?.landingPageId?._id === action.payload.id) {
            key = "landingPageId";
            index = ind;
            if (state.shared?.[index]?.[key].status) {
              let version = Number(state.shared[index][key].version);
              version = version + 0.1;
              state.shared[index][key].version = version.toFixed(1)
            }
          }
        });
      } catch (error) {
        console.log(error)
      }
    },
  },
  extraReducers: (builder) => {
    // Get Sharing Settings : Pending
    builder.addCase(getSettings.pending, (state, action) => {
      state.sharingSettings = [];
    });

    // Get Sharing Settings : Fulfilld
    builder.addCase(getSettings.fulfilled, (state, action) => {
      state.sharingSettings = action.payload;
    });

    // Fetch Shared : Pending
    builder.addCase(fetchShared.pending, (state, action) => {
      state.shared = [];
      state.count = 0;
      state.loadingMsg = "Loading....";
    });

    // Fetch Shared : Fulfilled
    builder.addCase(fetchShared.fulfilled, (state, action) => {
      const { data, type } = action.payload;
      const { response, count } = data;
      state.shared = response;
      state.count = count;
      if (response.length === 0) {
        if (type === "presentation") {
          state.loadingMsg = "No Shared Presentations Found !";
        } else if (type === "email") {
          state.loadingMsg = "No Shared Email Templates Found !";
        } else if (type === "landingpage") {
          state.loadingMsg = "No Shared Microsites Found !";
        }
      } else {
        state.loadingMsg = "";
      }
    });

    // Fetch Shared : Rejected
    builder.addCase(fetchShared.rejected, (state, action) => {
      state.shared = [];
      state.count = 0;
      state.loadingMsg = "An Error Occured";
    });

    // Remove Shared User : Fulfilled
    builder.addCase(removeSharingUser.fulfilled, (state, action) => {
      state.sharedWith = action.payload[0].sharedWith;
    });

    // Get Sharing Settings
    builder.addCase(share.fulfilled, (state, action) => {
      if(action.payload){
        state.sharedWith = action.payload.sharedWith;
        state.sharedWith.forEach((user) => {
          let userFound = false;
          state.sharingSettings.forEach((newUser, index) => {
            if (newUser.userId._id === user.userId._id) {
              state.sharingSettings[index] = user;
              userFound = true;
            }
          });
          if (!userFound) {
            state.sharingSettings.push(user);
          }
        });
      }
    });
  },
});
export const { setSharedWith, setSharedStatus, setSharedVersion } =
  sharingSlice.actions;
export default sharingSlice.reducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "src/store/actions/auth";
import { uiActions } from "src/store/reducers/ui-slice";
import EmailForm from "src/components/login/EmailForm";
import AuthLayout from "src/layouts/AuthLayout";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const isUser = useSelector((state) => state.auth.isUser);

  const resetHandler = (e) => {
    // e.preventDefault();
    let email = e.email;
    dispatch(forgotPassword(email));
  };

  useEffect(() => {
    if (isUser) {
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Token Sent",
          message: "A reset email has been sent to you.",
        })
      );
    }
  }, [isUser]);

  return (
    <AuthLayout heading="Forgot Your Password?">
      <EmailForm btnText="Send Link" backLink={true} submitHandler={resetHandler} />
    </AuthLayout>
  );
};
export default ForgotPassword;

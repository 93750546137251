import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

//APIs
import { fetchShared } from "src/store/actions/sharing";

//MUI Componenets
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Content from "src/layouts/Content";
import Pagination from "src/components/ui/Pagination";
import PresentationCard from "src/components/presentations/PresentationCard";

//Dialogs
import SharedWithMeDialogs from "src/components/dialogs/presentations/SharedWithMe-Dialogs";

const SharedWithMe = () => {
  const type = "presentation";
  const dispatch = useDispatch();
  const { shared: sharedPresentations, count , loadingMsg} = useSelector(
    (state) => state.sharing
  );

  const { _id: userId } = useSelector((state) => state.auth.userData);
  const [searchParams] = useSearchParams();

  const [details, setDetails] = useState({});
  const [presentationId, setPresentationId] = useState("");
  const [dialog, setDialog] = useState({});

  const limit = 10;
  //Dialogs State
  useEffect(() => {
    const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
    const skip = (pageNo - 1) * limit;
    const params = {};
    searchParams.forEach((value, key) => {
      if(key !== "page") params[key] = value;
    });

    params.skip = skip;
    params.limit = limit;
    params.type = type;
    dispatch(fetchShared(params));
  }, [searchParams]);

  //presentation info
  const showDetails = useCallback((presenetation) => {
    setDialog({ info: true });
    setDetails(presenetation);
  }, []);

  //open dialogs function
  const openDialog = useCallback((id, name) => {
    setPresentationId(id);
    setDialog({ [name]: true });
  }, []);

  return (
    <>
      {/* Dialogs */}
      <SharedWithMeDialogs
        dialog={dialog}
        presentation={details}
        presentationId={presentationId}
        onClose={() => setDialog({})}
      />
      {/* Dialogs */}
      <Content>
        <Grid container spacing={1.5} mb={8}>
          {!loadingMsg && userId && sharedPresentations ? (
            sharedPresentations.map((shared) => {
              const presentation = shared.presentationId;
              const role = shared.sharedWith.find(
                (user) => user.userId === userId
              );
              if (!role || !presentation) {
                return <></>;
              }
              return (
                <PresentationCard
                  key={presentation._id}
                  presentation={presentation}
                  showDetails={showDetails}
                  openDialog={openDialog}
                  role={role.role}
                />
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={4}>
              {loadingMsg}
            </Typography>
          )}
        </Grid>
        <Pagination
          count={count}
          enableParams
          itemsPerPage={limit}
          onChange={(value) => {
            dispatch(fetchShared({ ...value, type }));
          }}
        />
      </Content>
    </>
  );
};
export default React.memo(SharedWithMe);

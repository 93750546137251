import React from "react";
import Grid from '@mui/material/Grid2';
import CardItem from "src/components/common/CardItem";
import Column from "./Column";

const ProjectCard = (props) => {
  const {
    Controls,
    targetDevice,
    image,
    masterTemplateThumbnailURL,
    title,
    subtitle,
    description,
    status,
    id,
    onDoubleClick,
    targetSystem,
    isShared,
    classes
  } = props;

  return (
    <Column>
      {/* we need div as a wrapper (MUI requirement) */}
      <div>
        <CardItem
          id={id}
          image={image}
          masterTemplateThumbnailURL={masterTemplateThumbnailURL}
          title={title}
          subtitle={subtitle}
          description={description}
          classes={classes}
          status={status}
          controls={Controls}
          targetDevice={targetDevice}
          targetSystem={targetSystem}
          onDoubleClick={onDoubleClick}
          isShared={isShared}
        />
      </div>
    </Column>
  );
};

export default React.memo(ProjectCard);

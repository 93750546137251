import { Navigate } from "react-router-dom";
import BlankLayout from "src/layouts/BlankLayout";
import ActivateAccount from "src/pages/ActivateAccount";
import ForgotPassword from "src/pages/ForgotPassword";
import GenerateToken from "src/pages/GenerateToken";
import Login from "src/pages/Login";
import AuthPasswordLogin from "src/pages/sharedLink/AuthPasswordLogin";

import PresentationPreviewPublic from "src/pages/presentations/PresentationPreviewPublic";
import SharedLink from "src/pages/sharedLink/SharedLink";
import ExpiredLink from "src/pages/sharedLink/ExpiredLink";
import { View } from "src/pages/sharedLink/view/View";
import AuthSharedLinkRedirect from "src/pages/sharedLink/AuthSharedLinkRedirect";

export const publicRoutes = [
  
  {
    path: "/shared/:hash",
    element: <SharedLink />,
  },
  {
    path: "/shared/auth/:linkId",
    element: <AuthSharedLinkRedirect><AuthPasswordLogin /></AuthSharedLinkRedirect>,
  },
  {
    path: "/shared/expired",
    element: <ExpiredLink></ExpiredLink>,
  },
  {
    path: "/shared/view/:linkId",
    element: <AuthSharedLinkRedirect><View /></AuthSharedLinkRedirect>,
  },
  {
    path: "/preview/",
    element: <PresentationPreviewPublic />,
  },
  { path: "/login", element: <Login /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/set-password", element: <ActivateAccount /> },
  { path: "/generate-token", element: <GenerateToken /> },
];

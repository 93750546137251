import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthenticationData } from "src/store/reducers/sharelink-slice";
const useAuthShareLink = () => {
  const dispatch = useDispatch();
  const [authData, setAuthData] = useState(null);
  const {authention: sharedAuthFromRedux, isLoggedin} = useSelector((state) => state.shareLink);
  
  // Fetch data from localStorage and set it in Redux
  useEffect(() => {
    const storedData = localStorage.getItem("sharedAuth");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setAuthData(parsedData);
    }
  }, [dispatch]);
   
  const addShareLinkId = (shareLinkObject) => {
   // Fetch existing data from localStorage
   let storageIds = localStorage.getItem("sharedAuth");
   storageIds = storageIds ? JSON.parse(storageIds) : [];
  
   // Check if the shareLinkId already exists
   const idExists = storageIds.some((item) => item.id === shareLinkObject.id);
   if (!idExists) {
     storageIds.push(shareLinkObject);
     localStorage.setItem("sharedAuth", JSON.stringify(storageIds));
     dispatch(updateAuthenticationData(storageIds));
     localStorage.removeItem('sharedLinkAuthTempObj') //remove temp object
   }
 };
 
 //use when user reload page
 const isSharedLinkAuthenticated = (shareLinkId)=>{
   let storageIds = localStorage.getItem("sharedAuth");
   storageIds = storageIds ? JSON.parse(storageIds) : [];
   // console.log('shareLinkId', shareLinkId)
   //storage condition if user reload page, 
   return (
      storageIds.some((item) => item.SharedLinkId === shareLinkId)
   );
 }

//  const isAuthStorageEmpty = ()=>{
//    const storageIds = JSON.parse(localStorage.getItem("sharedAuth") || "[]");
//    return storageIds.length === 0;
//  }
  
  return { authData, addShareLinkId, isSharedLinkAuthenticated, isLoggedin };
};

export default useAuthShareLink;

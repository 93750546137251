import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateChapterSetting } from "src/store/actions/presentations";
import { Grid, Box, Button } from "@mui/material";
import Input from "src/components/ui/formElements/Input";
import Card from "src/components/ui/Card";

//import stylesheet
import "../../../styles/presentations/settings.scss";
import { uiActions } from "src/store/reducers/ui-slice";

const Chapters = () => {
  const dispatch = useDispatch();
  //state for chapters
  const handleChange = (e, index) => {
    const newList = JSON.parse(JSON.stringify(chapters));
    newList[index].name = e.target.value;
    setChapters(newList);
  };

  const { id } = useParams();
  const {chapters:chaptersList , isLock} = useSelector((state) => state.presentations.presentation);
  const [chapters, setChapters] = useState(chaptersList);

  useEffect(() => {
    setChapters(chaptersList);
  }, [chaptersList]);

  const updateSettings = (chapter, index) => {
    if(!chapter?.name?.trim()?.length){
      return dispatch(uiActions.showNotification({
        status: "error",
        title: "Invalid Name",
        message: "Chapter name can't be empty!",
      }))
    }
    dispatch(
      updateChapterSetting({
        presentationId: id,
        chapterIndex: index,
        data: { name: chapter.name },
      })
    );
  };

  return (
    <Grid container gap={3}>
       {chapters.map((chapter, index) => {
          return (
            <Grid item md={12} key={index}>
              <Card classes="box-style" style={{ minHeight: "175px" }}>
                <Grid container spacing={4}>
                  <Grid item md={6}>
                    <Input
                      className="bordered"
                      labelTag="Id"
                      name="_id"
                      disabled
                      value={chapter._id}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Input
                      className="bordered"
                      labelTag="Name"
                      name="name"
                      value={chapter.name}
                      onChange={(e) => handleChange(e, index)}
                      disabled={isLock}
                      inputProps={{ maxLength: 50 }}
                      limit="50"
                    />
                  </Grid>
                  <Grid item md={12} className="d-flex btn-align">
                    {!isLock &&<Box>
                      <Button
                        variant="contained"
                        className="add-new-field"
                        onClick={() => updateSettings(chapter, index)}
                        
                      >
                        Update
                      </Button>
                    </Box>}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })}
      </Grid>
  );
};
export default Chapters;

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CountryPhoneCode } from 'src/data/countryPhoneCode';
import { Controller } from 'react-hook-form';
import { onlyPhone } from 'src/utils/Utils';
import { Typography } from '@mui/material';
import './InputFields.scss'
const InputPhoneField = ({ error, type, fieldName, control, rules, label, phone, disabled, labelTag, gap, inputFieldSx, ...rest }) => {
    return (
        <Box className={`InputField ${gap? "gap":''}`} sx={inputFieldSx}>
            {labelTag && (
                <Typography variant="body1" component="label" className="labelTag">
                    {label} {rules?.required && <span className="required">*</span>}
                </Typography>
            )}
            <Controller
                name={fieldName}
                control={control}
                rules={rules}
                render={({ field: { onChange, value, ref } }) => {
                    return (
                        <>
                            {value?.length > 0 ?    // this TextField show when you select code, If country code is selected then you can write phone number else you have to select country code first.
                                <TextField
                                    className='outlined'
                                    required={(rules?.required) && true}
                                    variant="outlined"
                                    error={error && true}
                                    helperText={error ? error.message : null}
                                    type={type}
                                    inputRef={ref}
                                    label={labelTag && value.length ? null : label}
                                    value={phone ? onlyPhone(value) : value}
                                    onChange={(e) => { onChange(e.target.value) }}
                                    fullWidth
                                    autoComplete=""
                                    disabled={disabled}
                                    {...rest}
                                />
                                :
                                <Autocomplete
                                    id="country-select-demo"
                                    options={CountryPhoneCode}
                                    autoHighlight
                                    onChange={(_, data) => { onChange(data ? "+" + data.phone : '') }}
                                    value={value}
                                    style={{ borderWidth: '0px' }}

                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                alt=""
                                            />
                                            {option.label} ({option.code}) +{option.phone}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            className='outlined'
                                            required={(rules?.required) && true}
                                            {...params}
                                            inputRef={ref}
                                            label={label}
                                            error={error && true}
                                            helperText={error ? error.message : null}
                                            //   label="Choose a country"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            }
                        </>

                    )
                }}
            />
        </Box>

    )
}
export default InputPhoneField;

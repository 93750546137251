import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePresentationSetting } from "src/store/actions/presentations";
import Content from "src/layouts/Content";
import Card from "src/components/ui/Card";
import Input from "src/components/ui/formElements/Input";
import SelectBox from "src/components/ui/formElements/SelectBox";
import { Checkbox, Grid, Button, Box ,Typography} from "@mui/material";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import "../../../styles/presentations/settings.scss";
import { uiActions } from "src/store/reducers/ui-slice";
import { countries, languages } from "src/data/data";
import { devices } from "src/components/editor/editorLogics/components";

const Details = () => {
  const dispatch = useDispatch();
  const { presentation } = useSelector((state) => state.presentations);
  const [settings, setSettings] = useState(presentation);
  const [updateSettings, setUpdateSettings] = useState({});
  const [error, setError] = useState('');
  const { id } = useParams();

  const handleChange = (e) => {
    const key = e.target.name;
    let val = e.target.value;

    if (e.target.type === "checkbox") {
      val = e.target.checked;
    }
    if (key === "name") {
      if (val.length > 50) {
        setError("Name cannot exceed 50 characters");
        val = val.slice(0, 50); // Enforce the character limit
      } else {
        setError("");
      }
    }
    setSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
    setUpdateSettings((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  useEffect(() => {
    setSettings(presentation);
    setUpdateSettings(presentation);
  }, [presentation]);

  
  const updateSetting = () => {
    if (!updateSettings?.name?.trim()?.length) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid Name",
          message: "Presentation name can't be empty!",
        })
      );
    }
    dispatch(updatePresentationSetting({ id, data: updateSettings }));
  };
  return (
    <Grid container gap={4}>
    <Grid item xs={12}>
      <Content title="General Settings">
        <Card classes="box-style">
          <Grid container spacing={3} className="margin-bottom">
            <Grid item xs={6} md={4}>
              <Input
                onChange={handleChange}
                value={settings["name"]}
                name="name"
                className="bordered"
                labelTag="Name"
                disabled={presentation.isLock}
                inputProps={{ maxLength: 50 }}
                limit="50"
              />
               {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
            </Grid>
            <Grid item xs={6} md={4}>
              <SelectBox
                onChange={handleChange}
                value={settings["country"]}
                labelTag="Country"
                name="country"
                defaultValue="pakistan"
                classes="bordered"
                options={countries}
                disabled={presentation.isLock}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <SelectBox
                onChange={handleChange}
                value={settings["lang"]}
                labelTag="Language"
                defaultValue="en"
                classes="bordered"
                name="lang"
                options={languages}
                disabled={presentation.isLock}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <SelectBox
                onChange={handleChange}
                value={settings["targetDevice"]}
                labelTag="Target Device"
                defaultValue="ipad"
                name="targetDevice"
                classes="bordered"
                options={devices}
                // disabled={presentation.isLock}
                disabled
              />
            </Grid>

            {/* <Grid item xs={6} md={4}>
              <Grid container className="grid-txt">
                <Grid item md={10}>
                  Cross-Chapter Swipe
                </Grid>
                <Grid item md={2} className="align-end">
                  <Checkbox
                    onChange={handleChange}
                    name="chapterSwipe"
                    defaultChecked
                    checkedIcon={<BiCheckboxChecked />}
                    icon={<BiCheckbox />}
                    size="26px"
                    color="secondary"
                    sx={{ mr: "-3px" }}
                    disabled={presentation.isLock}
                  />
                </Grid>
              </Grid>

              <Grid container className="grid-txt">
                <Grid item md={10}>
                  Turn on default touch and move behaviour
                </Grid>
                <Grid item md={2} className="align-end">
                  <Checkbox
                    name="touchBehaviour"
                    onChange={handleChange}
                    defaultChecked
                    size="small"
                    color="secondary"
                    disabled={presentation.isLock}
                  />
                </Grid>
              </Grid>
            </Grid> */}
            <Grid item md={8} display="flex" className="d-flex btn-align">
              {!presentation.isLock && (
                <Box>
                  <Button
                    variant="contained"
                    onClick={updateSetting}
                    sx={{ textTransform: "capitalize" }}
                    disabled={presentation.isLock}
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </Card>
      </Content>
    </Grid>
  </Grid>
  );
};
export default Details;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { checkSFAuth } from "../reducers/auth-slice";
import { uiActions } from "../reducers/ui-slice";

export const salesforceLogin = createAsyncThunk(
  "salesforceLogin",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.salesForce.login(),
        data,
        thunkAPI
      );
      if (response.data && response.data.access_token) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Authorized!",
            message: "Login Successfull",
          })
        );
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Authorization Failed!",
            message: "Invalid Email/Password",
          })
        );
      }
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Authorization Failed!",
          message: "Invalid Email/Password",
        })
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const connectPresentation = createAsyncThunk(
  "connectPresentation",
  async ({ csPresentation, ...Sf_presentation }, thunkAPI) => {
    try {
      thunkAPI.dispatch(checkSFAuth());
      const response = await HttpService.call(
        api.salesForce.sharePresentation(csPresentation),
        Sf_presentation,
        thunkAPI,
        "",
        true,
        50000000
      );
      if (response.data) {
        const status = response?.data?.status;
        const message = response?.data?.message;
        if (status === 400) {
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "error",
              title: "Connect Failed !",
              message: "Something Went Wrong!",
            })
          );
        } else if (status === 500) {
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "error",
              title: "Connect Failed !",
              message: "Something Went Wrong!",
            })
          );
        } else if (message == "Data Saved Successfully") {
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Connected!",
              message: "Presentation Connected Successfully",
            })
          );
        }
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getReviewedPre = createAsyncThunk(
  "getReviewedPre",
  async (page, thunkAPI) => {
    try {
      thunkAPI.dispatch(checkSFAuth());
      const response = await HttpService.call(
        api.salesForce.getReviewed(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSFPresentations = createAsyncThunk(
  "getSFPresentations",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(checkSFAuth());
      const response = await HttpService.call(
        api.salesForce.getSFPresentations(),
        data,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

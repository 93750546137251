import React,{ useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "./header/Header";
import { Box, Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "./sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import { nav, navData } from "./sidebar/_nav";
import smallLogo from "../assets/images/smallLogo.png";
import largeLogo from "../assets/images/largeLogo.png";
import { hideOnStatus } from "src/services/utils";
import { roles as usersRoles } from "src/config";

const MainLayout = (props) => {
  
  let type = "";
  const location = useLocation().pathname;
  const { presentation } = useSelector((state) => state.presentations);
  const { email } = useSelector((state) => state.emails);
  const { landingPage } = useSelector((state) => state.microsites);
  const { roles } = useSelector((state) => state.roles);
  const { userData } = useSelector((state) => state.auth);
  const [menuToggle, setMenuToggle] = useState(false);

  const loginUserRole = userData.role;

  const getNav = () => {
    const permitNavs = usersRoles[loginUserRole]?.allowedUrls || [];
    const rolesNav = navData.filter((route) => {
      return permitNavs.some((r) => {
        return r.indexOf(route.url.replace("/", "")) == 0;
      });
    });
    return rolesNav;
  };
  let sideBarItems = getNav();
  let sideBarWidth = 1.8;
  let mainBarWidth = 10.2;
  let sidebarClass = "";
  let sidebarLogo = largeLogo;
  //setting column width
  if (location.includes("presentation/") || location.includes("email/") || location.includes("microsite/") || location.includes("campaign/") || location.includes("settings/")  ) {
    sideBarWidth = 1;
    mainBarWidth = 11;
    sidebarClass = "collapse";
    sidebarLogo = smallLogo;
  }  
  // filter menu items
  if (location.includes("presentation/")) {
    type = "presentations";
    sideBarItems = nav.presentation.subNav.filter((navItem) => {
      const role = presentation.role;
      const status = presentation.status;
      const item = navItem?.label.toLowerCase();
      if (roles?.[role]?.[item] && !hideOnStatus?.[status]?.[item]) {
        return navItem;
      }
    });
  } else if (location.includes("email/")) {
    type = "emails";
    sideBarItems = nav.email.subNav.filter((navItem) => {
      const role = email.role;
      const status = email.status;
      const item = navItem?.label.toLowerCase();
      if (roles?.[role]?.[item] && !hideOnStatus?.[status]?.[item]) {
        return navItem;
      }
    });
  } else if (location.includes("microsite/")) {
    type = "microsites";
    sideBarItems = nav.microsites.subNav.filter((navItem) => {
      const role = landingPage.role;
      const status = landingPage.status;
      const item = navItem?.label.toLowerCase();
      if (roles?.[role]?.[item] && !hideOnStatus?.[status]?.[item]) {
        return navItem;
      }
    });
  } else if (location.includes("campaign/")) {
    sideBarItems = nav.campaign.subNav;
  } else if (location.includes("settings/")) {
    sideBarItems = nav.settings.subNav;
  }

  const menuToggleHandler = ()=>{
    setMenuToggle((prev) => !prev);
  }

  useEffect(() => {
    setMenuToggle(false);
  }, [location])
  
  return (
    <>
      <Box className="bgImge2"></Box>
      <Grid container>
        <Grid item md={sideBarWidth} lg={sideBarWidth} xlg={sideBarWidth} className={`mainCol ${menuToggle? 'show': ''}`}>
          <Sidebar
            type={type}
            items={sideBarItems}
            classes={sidebarClass}
            logo={sidebarLogo}
            menuToggleHandler={menuToggleHandler}
          />
        </Grid>
        <Grid item md={mainBarWidth} lg={mainBarWidth} xlg={mainBarWidth} className="mainCol">
          <Header menuToggleHandler={menuToggleHandler}/>
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayout;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import IconComponent from "../common/IconComponent";
import "./CustomAccordion.scss";
import TooltipWrapper from "./Tooltip";

const CustomAccordion = ({
  onChange,
  expanded,
  name,
  children,
  removeHandler,
  duplicateHandler,
  itemsLength,
  duplicateTooltip="Duplicate",
  deleteTooltip="Delete"
}) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className="custom-accordion"
    >
      <AccordionSummary classes={{ content: "column_head" }}>
        <Typography>{name}</Typography>
        {duplicateHandler && (
          <TooltipWrapper title={duplicateTooltip}>
            <IconButton
            size="small"
            className="duplicateicon"
            onClick={(e) => {
              e.stopPropagation();
              duplicateHandler();
            }}
          >
            <IconComponent iconname="duplicate" size={14} />
          </IconButton>
          </TooltipWrapper>
        )}

        {removeHandler && (
          <TooltipWrapper title={deleteTooltip}>
            <IconButton
            size="small"
            className="removeIcon"
             disabled={itemsLength === 1}
            onClick={(e) => {
              e.stopPropagation();
              removeHandler();
            }}
          >
            <IconComponent iconname="delete" size={14} />
          </IconButton>
          </TooltipWrapper>
        )}
      </AccordionSummary>

      <AccordionDetails className="column_body">{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;

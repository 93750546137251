import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./reducers/auth-slice";
import uiSlice from "./reducers/ui-slice";
import presentationsSlice from "./reducers/presentations-slice";
import editorSlice from "./reducers/editor-slice";
import assetsSlice from "./reducers/assets-slice";
import usersSlice from "./reducers/users-slice";
import rolesSlice from "./reducers/roles-slice";
import reviewsSlice from "./reducers/reviews-slice";
import commentsSlice from "./reducers/comments-slice";
import salesforceSlice from "./reducers/salesforce-slice";
import emailsSlice from "./reducers/emails-slice";
import sharingSlice from "./reducers/sharing-slice";
import campaignSlice from "./reducers/campaign-slice";
import micrositeSlice from "./reducers/microsite-slice";
import versionSlice from "./reducers/versions-slice";
import globalSearchSlice from "./reducers/global-search-slice";
import countrySlice from "./reducers/country-slice";
import folderSlice from "./reducers/folder-slice";
import shareLinkSlice from "./reducers/sharelink-slice"

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    presentations: presentationsSlice,
    editor: editorSlice,
    assets: assetsSlice,
    user: usersSlice,
    comments: commentsSlice,
    reviews: reviewsSlice,
    roles: rolesSlice,
    salesforce: salesforceSlice,
    emails: emailsSlice,
    sharing: sharingSlice,
    campaign: campaignSlice,
    microsites: micrositeSlice,
    versions: versionSlice,
    globalSearch: globalSearchSlice,
    country: countrySlice,
    folders: folderSlice,
    shareLink: shareLinkSlice,
  },
});

export default store;

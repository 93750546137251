import { HISTORY_ACTIONS } from "src/data/contants";
import { getElementData } from "../shared-logic";

export const createEntry = ({ data, activeEditable } = {}) => {
  const { id, action: type = HISTORY_ACTIONS.updated, extraInfo } = data;
  const elementId = id || activeEditable.elementId;
  let prevDetails = { ...activeEditable.elementDetails };

  const isStyleOnly = isStyleOnlyAction(type, elementId);

  if (isStyleOnly) {
    console.log('its style only action')
    // overriding type since we don't know what user will do after selection for object
    // updating initial action with styles
    prevDetails.action = type;

    const getElementPayload = {
      id: elementId,
      getStylesOnly: true,
      type,
    };

    if (extraInfo) {
      // overriding payload if recives extrainfo
      const { styleAttr, initialStyleAttr, itemId } = extraInfo;

      getElementPayload.styles = initialStyleAttr;
      prevDetails = getElementData(getElementPayload);

      getElementPayload.styles = styleAttr;
      getElementPayload.id = itemId;
    }
    
    const stylesEleData = getElementData(getElementPayload);
    const undoRedoObj = createUndoRedoObject({
      oldData: prevDetails,
      newData: stylesEleData,
    });
    return undoRedoObj;
  }

  delete activeEditable.eleStyles;

  if (type === HISTORY_ACTIONS.containerChanged) {
    // fetching it's initial history for update
    const { startingDetails, itemId, updatedStyleAttr } = extraInfo;

    const endedDetails = getElementData({
      type,
      id: itemId,
      styles: updatedStyleAttr,
    });

    const prevDetails = startingDetails;
    const newDetails = endedDetails;

    const undoRedoObj = createUndoRedoObject({
      oldData: prevDetails,
      newData: newDetails,
    });

    return undoRedoObj;
  }

  if (type === HISTORY_ACTIONS.gridResized) {
    // fetching it's initial history for update
    const { startingDetails, startingStyles, endingStyles } = extraInfo;

    const endedDetails = getElementData({
      type,
      id: startingDetails.layerId,
    });

    const prevDetails = {
      ...startingDetails,
      gridItemsStyles: startingStyles,
    };

    const newDetails = {
      ...endedDetails,
      gridItemsStyles: endingStyles,
      changesFrom: prevDetails.changesFrom,
    };

    const undoRedoObj = createUndoRedoObject({
      oldData: prevDetails,
      newData: newDetails,
    });

    return undoRedoObj;
  }

  if (type === HISTORY_ACTIONS.sorted) {
    // fetching it's initial history for update
    const { startedFrom, endedOn } = extraInfo;
    const { element, index, parent } = startedFrom;
    const { index: endIndex, parent: endParent } = endedOn;

    // giving information and getting formated data to push
    const elementDetails = getElementData({
      type: HISTORY_ACTIONS.sorted,
      id: element.id,
      parentId: parent.id,
      index,
    });

    const prevDetails = {
      ...elementDetails,
      layerIndex: index,
      layerParentId: parent.id,
    };

    const newDetails = {
      ...elementDetails,
      layerIndex: endIndex,
      layerParentId: endParent.id,
    };

    const undoRedoObj = createUndoRedoObject({
      oldData: prevDetails,
      newData: newDetails,
    });

    return undoRedoObj;
  }

  const defaultHistoryAction = getElementData({ id: elementId, type });
  const undoRedoObj = createUndoRedoObject({
    oldData: prevDetails,
    newData: defaultHistoryAction,
  });

  return undoRedoObj;
};

// <-----------------------HELPERS------------------>
const isStyleOnlyAction = (action, id) => {
  const StyleOnlyActions = Object.values(HISTORY_ACTIONS.STYLED_ACTIONS);
  const isDropzone = id === "dropzone";
  return StyleOnlyActions.includes(action) || isDropzone;
};

const reverseAction = (action) => {
  const reversedActions = {
    [HISTORY_ACTIONS.appended]: HISTORY_ACTIONS.deleted,
    [HISTORY_ACTIONS.deleted]: HISTORY_ACTIONS.appended,
  };

  return reversedActions[action] || action;
};

const createUndoRedoObject = ({ newData, oldData }) => {
  console.log({ newData, oldData });
  const action = newData.action;
  const reversedAction = reverseAction(action);

  const undoRedoEntry = {
    action,
    layerId: newData.layerId,
    changesFrom: newData.changesFrom,
    _recordId: newData._recordId,
    styleOnly: newData.styleOnly,

    undo: null,
    redo: null,
  };

  // details structure is different for styles
  if (newData.styleOnly) {
    const undoStyle = { action: oldData.action, eleStyles: oldData.eleStyles };
    const redoStyle = { action: newData.action, eleStyles: newData.eleStyles };

    undoRedoEntry.undo = undoStyle;
    undoRedoEntry.redo = redoStyle;
    return undoRedoEntry;
  }

  if (action === HISTORY_ACTIONS.gridResized) {
    const undoStyle = {
      action: oldData.action,
      gridItemsStyles: oldData.gridItemsStyles,
    };
    const redoStyle = {
      action: newData.action,
      gridItemsStyles: newData.gridItemsStyles,
    };

    undoRedoEntry.undo = undoStyle;
    undoRedoEntry.redo = redoStyle;
    return undoRedoEntry;
  }

  // details structure is different for styles
  if (action === HISTORY_ACTIONS.containerChanged) {
    const undoStyle = {
      action: oldData.action,
      layerParentId: oldData.layerParentId,
      layerIndex: oldData.layerIndex,
      eleStyles: oldData.eleStyles,
    };
    const redoStyle = {
      action: newData.action,
      layerParentId: newData.layerParentId,
      layerIndex: newData.layerIndex,
      eleStyles: newData.eleStyles,
    };

    undoRedoEntry.undo = undoStyle;
    undoRedoEntry.redo = redoStyle;
    return undoRedoEntry;
  }

  // Normal flow
  undoRedoEntry.undo = {
    action: reversedAction,
    layerHtml: oldData.layerHtml,
    layerParentId: oldData.layerParentId,
    layerIndex: oldData.layerIndex,
    containerId: oldData.containerId,
  };

  undoRedoEntry.redo = {
    action,
    layerHtml: newData.layerHtml,
    layerParentId: newData.layerParentId,
    layerIndex: newData.layerIndex,
    containerId: newData.containerId,
  };

  return undoRedoEntry;
};

export const convertMBToBytes = (mb) => {
   return mb * 1048576;
};

export const getUnitBasedOnMB = (mb) => {
   const bytes = mb * 1048576; // Convert MB to bytes
   const units = ["bytes", "KB", "MB", "GB", "TB"];
   let size = bytes;
   let unitIndex = 0;

   while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
   }

   return `${size.toFixed()} ${units[unitIndex]}`;
};
import React, { useEffect, useState } from "react";
import {
  IconButton,
  Typography,
  Box,
  Button,
  FormControl,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { IoMdAdd, IoMdRemoveCircleOutline } from "react-icons/io";
import Control from "../../Control";
import {
  attachlisteners,
  generateAndAppendMediaQueries,
} from "../../../microsites/renderMicrosite";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";
import ColumnProperty from "./Column";
import { AiOutlineColumnWidth, AiOutlineDelete } from "react-icons/ai";
import IconComponent from "src/components/common/IconComponent";
import { getRandomId } from "src/services/utils";
import TooltipWrapper from "src/components/ui/Tooltip";
import Row from "src/components/properties/Row";
import SingleProperty from "../../common/SingleProperty";
import Alignment from "../../Alignment";
import Padding from "../../Padding";
import { useSelector } from "react-redux";
import CustomAccordion from "src/components/ui/CustomAccordion";
import "./RowColumnProperties.scss";

const RowColumnProperties = (props) => {
  const { type, elementId, updateHistory, undoRedoChange } = props;
  const {clickedEle, history} = useSelector((state) => state.editor);
  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const [expanded, setExpanded] = useState(0);
  const [columns, setcolumns] = useState([]);
  const [isItemEqual, setIsItemEqual] = useState(false);
  const element = document.getElementById(elementId);
  const dataDesktop = "data-desktop-width";
  const dataMobile = "data-mobile-width";
  const returnCols = (element) => {
    return element?.querySelectorAll(":scope > .cs-col");
  };
  // Getting Element's data
  useEffect(() => {
    const columns = [];
    const gridElements = returnCols(element);
    const selectedCol = element.querySelector(".cs-col.selected");
    // console.log(gridElements, element);
    //make colums collaps and active if user click on cols
    if (selectedCol) {
      const index = Array.from(gridElements).indexOf(selectedCol);
      setExpanded(index);
    } else {
      setExpanded(0);
    }

    gridElements.forEach((el, i) => {
      columns.push({ name: `Column`, id: el.getAttribute("id") });
    });
    setcolumns(columns);
  }, [elementId, undoRedoChange, clickedEle]);

  const setColumnWidth = (colIndex, width) => {
    console.log(colIndex, width)
    let items = element.querySelectorAll(".cs-col");
    let itemsLength = items.length;
    itemsLength = itemsLength - 1;
    items.forEach((el, i) => {
      let newItemWidth = (100 - width) / itemsLength;
      newItemWidth = newItemWidth.toFixed(2)
      el.setAttribute(dataDesktop, newItemWidth);
      //for last column
      if (i === colIndex) {
        let newItemWidth = width;
        el.setAttribute(dataDesktop, newItemWidth);
      }
    });
    generateAndAppendMediaQueries();
  };

  const adjustColumsWidth = (isItemEqual = false) => {
    let items = element.querySelectorAll(".cs-col");
    let itemsLength = items.length;
    if (!isItemEqual) {
      const lastItemIndex = itemsLength - 1
      setColumnWidth(lastItemIndex, 5);
    } else {
      let newItemWidth = 100 / itemsLength;
      newItemWidth = newItemWidth.toFixed(2)
      items.forEach((el) => {
        el.setAttribute(dataDesktop, newItemWidth);
      });
      generateAndAppendMediaQueries();
    }
  };

  // To delete item
  const removeItem = (index) => {
    const availableItems = JSON.parse(JSON.stringify(columns));
    const updatedItems = availableItems.filter((_, i) => i !== index);
    const items = element.querySelectorAll(".cs-col");
    const elementToRemove = items[index];

    element.removeChild(elementToRemove);
    adjustColumsWidth(true);
    setcolumns(updatedItems);
    updateHistory();
  };

  const addColumn = () => {
    const id = getRandomId();
    const layer = element.closest(".cs-layer");

    const lastColumn = element.querySelector(".cs-col:last-child");
    const newColumn = lastColumn.cloneNode(true); // true to deep clone with children
    newColumn.innerHTML = "";
    newColumn.id = id;
    element.appendChild(newColumn);

    adjustColumsWidth(isItemEqual);
    attachlisteners({
      actionState: 0,
      activeElement,
      requestHierarchyUpdate,
      element: layer,
      updateHistory,
    });
    requestHierarchyUpdate();
    updateHistory();
    const items = element.querySelectorAll(".cs-col");
    setcolumns((prev) => [...prev, { name: `Column` }]);
  };

  const duplicateColumn = (index) => {
    const id = getRandomId();
    const column = getColumnFromIndex(index);
    const newColumn = column.cloneNode(true);
    newColumn.id = id;
    element.appendChild(newColumn);
    column.insertAdjacentElement("afterend", newColumn);
    const items = element.querySelectorAll(".cs-col");
    setcolumns((prev) => [...prev, { name: `Column` }]);
    adjustColumsWidth(true);
    attachlisteners({
      actionState: 0,
      activeElement,
      requestHierarchyUpdate,
      element: layer,
      updateHistory,
    });
    requestHierarchyUpdate();
    updateHistory();
  };

  const getColumnFromIndex = (index) => {
    const column = element.querySelectorAll(".cs-col")[index];
    return column;
  };
  //   to navigate on clicked column property
  const activeColumnProperty = (index) => {
    const clickedButtonColumn = getColumnFromIndex(index);
    deHighlightElement();
    clickedButtonColumn?.click();
  };
  //   For highliting
  const highlightElement = (index) => {
    deHighlightElement();
    const hoveringColumn = getColumnFromIndex(index);
    hoveringColumn?.classList?.add("selected");
  };
  const deHighlightElement = () => {
    const highlightedColumn = element.querySelector(".cs-col.selected");
    highlightedColumn?.classList.remove("selected");
  };

  // when collapse change
  const listItemChange = (panel) => (event, isExpanded) => {
    console.log(panel);
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (typeof expanded === "number") {
      highlightElement(expanded);
    }
  }, [expanded]);

  // useEffect(() => {
  //   if (isItemEqual) {
  //     adjustColumsWidth(true);
  //   }
  // }, [isItemEqual]);

  const equalDivideColumns = (e) => {
    setIsItemEqual(!isItemEqual);
    e.stopPropagation();
    adjustColumsWidth(true);
  }

  const columnActions = (
    <>
      <TooltipWrapper title="Set all coulumns equal">
        <IconButton
          size="small"
          onClick={equalDivideColumns}
          className={isItemEqual ? "active" : ""}
        >
          <AiOutlineColumnWidth iconname="add" size={20} />
        </IconButton>
      </TooltipWrapper>

      <TooltipWrapper title="Add new column">
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            addColumn();
          }}
        >
          <IconComponent iconname="add" size={20} />
        </IconButton>
      </TooltipWrapper>
    </>
  );
  return (
    <Box className="column-property">
      <Control
        heading={"Columns"}
        className="bgControl"
        headingSize={"h1"}
        divider={false}
        isCollapse={true}
        expanded={true}
        actionsButtons={columnActions}
      >
        {columns.map((item, i) => {
          return (
            <CustomAccordion
              name={`${item.name} ${i+1}`}
              key={i}
              expanded={expanded === i}
              onChange={(event, isExpanded) =>
                listItemChange(i)(event, isExpanded)
              }
              removeHandler={() => removeItem(i)}
              duplicateHandler={() => duplicateColumn(i)}
              itemsLength={columns.length}
              duplicateTooltip="Duplicate Column"
              deleteTooltip="Delete Column"
            >
              <ColumnProperty
                key={i}
                elementId={item.id}
                updateHistory={updateHistory}
                undoRedoChange={undoRedoChange}
                setColumnWidth={setColumnWidth}
                history={history}
                index={i}
              />
            </CustomAccordion>
          );
        })}
      </Control>

      <Control
        heading={"Layout Settings"}
        className="bgControl"
        headingSize={"h1"}
        divider={false}
        isCollapse={true}
        expanded={true}
      >
        <Alignment
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          type="flex"
        />

        <SingleProperty
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          cssProperty="gap"
          heading="Spacing"
          fallbackUnit="%"
          inputProps={{ min: 0, max: 50, step: 0.1 }}
        />

        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          noControl
        />
      </Control>
    </Box>
  );
};

export default RowColumnProperties;

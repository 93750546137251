import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setPresentation, setSharedWith } from "src/store/reducers/presentations-slice";
import { getAllCompanyUsers } from "src/store/actions/users";
import { cancelReview, sendForReview } from "src/store/actions/reviews";
import {
  clonePresentation,
  deletePresentation,
  exportPresentation,
  importPresentation,
  uploadNewVersion,
  fetchPresentations,
  updatePresentationSetting
} from "src/store/actions/presentations";
import { share } from "src/store/actions/sharing";

//Dialogs
import AddNewPresentation from "./AddNewPresentation";
import ExportPresentation from "../ExportPresentation";
import UploadNewVersion from "../UploadNewVersion";
import DetailsDrawer from "../DetailsDrawer";
import SharingSettings from "../SharingSettings";
import SendForReview from "../SendForReview";
import UploadNew from "../UploadNew";
import Confirm from "../Confirm";
import ReviewDelete from "../ReviewDelete";
import { useSearchParams } from "react-router-dom";
import { devices } from "src/components/editor/editorLogics/components";
import { pixelsToInches } from "src/utils/Utils";
import CreateFolder from "../../folder/CreateFolder";
import RenameDialog from "../RenameDialog";
import UpdatePresentationDetails from "./UpdatePresentationDetails";

const MyLibraryDialogs = (props) => {
  const [searchParams] = useSearchParams();
  const limit = 10;
  const pageNo = Math.round(Number(searchParams.get("page"))) || 1;
  const skip = (pageNo - 1) * limit;

  const params = {};
  searchParams.forEach((value, key) => {
    if (key !== "page") params[key] = value;
  });

  params.skip = skip;
  params.limit = limit;

  const dispatch = useDispatch();
  const type = "presentation";
  const { company, _id: userId } = useSelector((state) => state.auth.userData);
  const { onClose, dialog, presentationId } = props;
  const presentations = useSelector((state) => state.presentations.list);
  const presentationIndex = presentations.findIndex((presentation) => presentation._id === presentationId);
  const presentation = presentations.find((presentation) => presentation._id === presentationId);

  const [exportAs, setExportAs] = useState(null);
  //on close function for closing dialogs

  //upload new presentation function
  const onUploadNewVersion = useCallback((data) => {
    dispatch(uploadNewVersion(data));
  }, []);

  //import new presentation
  const onUploadNew = useCallback((data) => {
    dispatch(importPresentation(data));
  }, []);

  //export presentation
  const onExport = useCallback(() => {
    const { width, height } = dialog.device && devices.find((device) => device.label === dialog.device)
    let resolution = {
      width: pixelsToInches(width),
      height: pixelsToInches(height)
    };
    dispatch(exportPresentation({ id: presentationId, exportAs, resolution }));
  }, [presentationId, exportAs, dialog.device]);

  //get all company users
  const getCompanyUsers = useCallback(() => {
    dispatch(getAllCompanyUsers(company.companyId));
  }, [company]);

  const handleClone = useCallback(async () => {
    await dispatch(clonePresentation(presentationId));
    dispatch(fetchPresentations(params));
    onClose();
  }, [presentationId]);


  const presentationShare = useCallback(
    (sharedWith, accessLevel) => {
      const userIds = sharedWith.map((user) => {
        return { userId: user._id, role: accessLevel };
      });

      const shared = sharedWith.map((user) => ({
        userId: user,
        role: accessLevel,
      }));

      dispatch(setSharedWith(shared));
      dispatch(
        share({
          id: presentationId,
          data: { sharedWith: userIds, accessLevel },
          type,
        })
      ).then((res)=> {
        if(res.payload) {
          dispatch(setPresentation({ presentationIndex: presentationIndex, data: { isShared: res.payload.sharedWith.length || userIds.length } }));
        }
      })
      
    },
    [presentationId]
  );

  //assign presentation fro review
  const assignForReview = useCallback(
    (data) => {
      dispatch(sendForReview({ id: presentationId, data }));
    },
    [presentationId]
  );

  //presentation delete
  const presentationDelete = useCallback(async () => {
    await dispatch(deletePresentation(presentationId))
    dispatch(fetchPresentations(params));
    onClose();
  }, [presentationId]);

  const cancelPresentationReview = useCallback(() => {
    dispatch(cancelReview({ id: presentationId, type }));
    onClose();
  }, [presentationId]);

  //rename presentation dialog
  const [renameLoading, setRenameLoading] = useState(false)
  const handleRenamePresentation = (name) => {
    setRenameLoading(true)
    dispatch(updatePresentationSetting({ id: presentationId, data: { name: name } })).finally(() => {
      setRenameLoading(false);
      if (presentationIndex > -1) {
        dispatch(setPresentation({ presentationIndex: presentationIndex, data: { name: name } }));
      }
      onClose();
    })
  }

  return (
    <>
      <CreateFolder show={dialog.createfolder} close={onClose} />
      <AddNewPresentation show={dialog.createNew} close={onClose} />
      <UpdatePresentationDetails show={dialog.updatePresentationDetails} close={onClose} presentation={presentation || {}} presentationIndex={presentationIndex}/>
      <ExportPresentation
        isOpen={dialog.export}
        exportAs={exportAs}
        handleExport={onExport}
        setExportAs={setExportAs}
        handleClose={onClose}
        id={presentationId}
      />
      <UploadNewVersion
        title="Upload New Version"
        close={onClose}
        show={dialog.uploadNewVersion}
        onUpload={onUploadNewVersion}
        id={presentationId}
      />
      
      <DetailsDrawer
        type="presentation"
        open={props.dialog.info}
        onClose={onClose}
        details={presentation}
        className="presentationDetailPane"
      />
      
      <SharingSettings
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.share}
        handleClose={onClose}
        onShare={presentationShare}
        shareType={type}
      />
      <SendForReview
        type="presentation"
        userId={userId}
        onInput={getCompanyUsers}
        isOpen={dialog.review}
        handleClose={onClose}
        onSend={assignForReview}
      />
      <UploadNew
        show={dialog.uploadNew}
        close={onClose}
        title="Upload From"
        onUpload={onUploadNew}
        type="Presentation"
        targetDevice
      />
      <Confirm
        isOpen={dialog.delete}
        subtitle="Are you sure you want to delete this presentation ? "
        handleClose={onClose}
        handleConfirm={presentationDelete}
      />
      <ReviewDelete
        isOpen={dialog.reviewdelete}
        subtitle="Content under review or already reviewed cannot be deleted. Thank you for your understanding!"
        handleClose={onClose}

      />
      <Confirm
        isOpen={dialog.cancelReview}
        subtitle="Are you sure you want to cancel the review ?"
        handleClose={onClose}
        handleConfirm={cancelPresentationReview}
      />

      <Confirm
        isOpen={dialog.clone}
        subtitle="Are you sure you want to clone this presentation ?"
        handleClose={onClose}
        handleConfirm={handleClone}
        variant="success"
      />

      <RenameDialog
        isOpen={dialog.renamePresentation}
        title="Presentation"
        handleClose={onClose}
        handleConfirm={handleRenamePresentation}
        name={presentation && presentation.name}
        loading={renameLoading}
      />

    </>
  );
};

export default React.memo(MyLibraryDialogs);

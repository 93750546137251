import React, { useState, useEffect, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { useDispatch, useSelector } from "react-redux";
import { editorActions } from "src/store/reducers/editor-slice";
import { removeFontSizeFromStr } from "src/services/editor.service";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Control from "./Control";
import { useSetLayerHeight } from "../editorLogics/components";
const JoditEditorr = (props) => {
  const dispatch = useDispatch();
  const { elementId, undoRedoChange } = props;
  const editorHistory = useSelector(
    (state) => state.editor.history.backStack
  );
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    const element = document.getElementById(elementId)
    if(element){
      setContent(removeFontSizeFromStr(element.innerHTML));
    }
  }, [elementId, undoRedoChange]);

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    useNativeTooltip: false,
    enableDragAndDropFileToEditor: true,
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    askBeforePasteFromWord: false,
    askBeforePasteHTML: false,
    buttons: [
      "bold",
      "italic",
      "underline",
      "align",
      "subscript",
      "superscript",
      "font",
      "fontsize",
      "lineHeight",
      "paragraph",
      "brush",
      "source",
      "link",
      "eraser",
      "undo",
      "redo",
    ],
    controls: {
      font: {
        list: {
          "Roboto Medium,Arial,sans-serif": "Roboto",
        },
      },
      fontsize: {
        list: {
          10: "10",
          12: "12",
          14: "14",
          16: "16",
          18: "18",
          20: "20",
          22: "22",
          24: "24",
          26: "26",
          28: "28",
          30: "30",
          32: "32",
          34: "34",
          36: "36",
          38: "38",
          40: "40",
        },
      },
    },
  };
  const handleUpdate = (event) => {
    let joditPopupContainer = document.querySelector(".jodit-popup-container");
    const elementHtml = document.getElementById(elementId).innerHTML;
    //check element in history that last updated text is already in history or not
    const isRepeated = editorHistory.filter(
      (x) => x.elementId === elementId && x.elementText == elementHtml
    );
    //if element text not in history then add to history, its use to avoid text duplication
    if (isRepeated.length == 0) {
      if (
        joditPopupContainer == null ||
        joditPopupContainer?.childNodes.length == 0
      ) {
        props.updateHistory({
          id: elementId,
        });
      }
    }
  };

  const onChangeHandler = (newContent) => {
    const element = $(".editable.active");
    const elementLayer = element.closest(".cs-layer");
    element.html(removeFontSizeFromStr(newContent));
    useSetLayerHeight(elementLayer);
  };

  return (
    <Control
      heading="Text Editor"
      isCollapse={true}
      divider={false}
      expanded={true}
    >
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1}
        onBlur={handleUpdate}
        onChange={onChangeHandler}
      />
    </Control>
  );
};

export default JoditEditorr;

import React, { useEffect, useCallback } from "react";
import Main from "./Main";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import Button from "../Button";
import arrowKeyNavigationHandler from "src/hooks/useArrowKeyNavigation";

const Confirm = (props) => {
  const notAlert = !Boolean(props.alert);

  useEffect(() => {
    if (props.isOpen) {
      setTimeout(() => {
        const formElement = document.getElementById("myForm");
        if (formElement) {
          const handler = arrowKeyNavigationHandler(formElement);
          formElement.addEventListener('keydown', handler);
  
          return () => {
            formElement.removeEventListener('keydown', handler);
          };
        }
      }, 0);
    }
  }, [props.isOpen]);

  const handConfirm = () => {
    if (props.handleConfirm()) {
      props.handleClose();
    }
  };

  return (
    <Main
      isOpen={props.isOpen}
      handleClose={props.handleClose}
      background="#fff"
      modalWidth={notAlert ? "xs" : "sm"}
      zIndex={1305}
    >
      <Box textAlign="center" p={3}>
        <Typography variant="h1" component="h1">
          {notAlert && props.title}
        </Typography>
        <Typography
          variant="h3"
          component="h3"
          sx={{ fontSize: "16px", color: "#444", fontWeight: "normal" }}
          mb={2}
        >
          {props.subtitle}
        </Typography>
        <form id="myForm">
          <Stack
            direction="row"
            justifyContent={notAlert ? "center" : "flex-end"}
            alignItems="center"
            spacing={2}
            mt={1}
          >
            {notAlert && (
              <Button
                variant="outlined"
                startIcon={<IoCloseOutline />}
                onClick={() => {
                  props.handleClose();
                }}
              >
                {props.cancelText}
              </Button>
            )}
            <Button
              variant="contained"
              id="confirmDeleteButton"
              color={props.variant || (notAlert ? "warning" : "success")}
              endIcon={<IoCheckmarkOutline />}
              sx={{ mt: notAlert ? 1 : 5 }}
              onClick={notAlert ? handConfirm : props.handleClose}
              loading={props.confirmLoading}
              autoFocus
            >
              {notAlert ? props.confirmText : "Okay"}
            </Button>
          </Stack>
        </form>
      </Box>
    </Main>
  );
};

export default React.memo(Confirm);

Confirm.defaultProps = {
  title: "Are You Sure?",
  confirmText: "Yes",
  cancelText: "No",
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";
import { setSharedVersion } from "../reducers/sharing-slice";
import { uiActions } from "../reducers/ui-slice";
export const fetchPresentations = createAsyncThunk(
  "fetchPresentations",
  async (page, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.fetch(page),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPresentaionById = createAsyncThunk(
  "getPresentaionById",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.getPresentaionById(id),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getPresentaionSettings = createAsyncThunk(
  "getPresentaionSettings",
  async ({ id, dataType }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.getPresentaionSettings(id, dataType),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchTemplates = createAsyncThunk(
  "fetchTemplates",
  async ({ targetDevice, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchTemplates(targetDevice, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createPresentation = createAsyncThunk(
  "createPresentation",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.create(),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Presentation created!",
            message: "Presentation created successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const clonePresentation = createAsyncThunk(
  "clonePresentation",
  async (presentationId, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.clone(presentationId),
        undefined,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Presentation Cloned!",
            message: "Presentation cloned successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getReviewedPresentations = createAsyncThunk(
  "getReviewedPresentations",
  async (page, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.getReviewed(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateStructure = createAsyncThunk(
  "updateStructure",
  async ({ presentationId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.updateStructure(presentationId),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated!",
            message: "Chapter Updated Successfully",
          })
        );
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updatePresentationSetting = createAsyncThunk(
  "updatePresentationSetting",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.updatePresentationSetting(id),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated!",
            message: "Presentation Updated Successfully",
          })
        );
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

// Create Chapter
export const createChapter = createAsyncThunk(
  "createChapter",
  async ({ presentationId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.createChapter(presentationId),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Created!",
          message: "New Chapter Created!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Update Chapter
export const updateChapterSetting = createAsyncThunk(
  "updateChapterSetting",
  async ({ presentationId, chapterIndex, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.updateChapterSetting(presentationId, chapterIndex),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated!",
            message: "Structure Updated Successfully",
          })
        );
        return response.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

//Delete Chapter
export const deleteChapter = createAsyncThunk(
  "deleteChapter",
  async ({ presentationId, chapterId, chapterIndex }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.deleteChapter(presentationId, chapterId),
        undefined,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted!",
            message: "Chapter Delete Successfully",
          })
        );
        return { chapterIndex, data: response.data };
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

// Create Slide
export const createSlide = createAsyncThunk(
  "createSlide",
  async ({ presentationId, slide }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.createSlide(presentationId),
        slide,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Created!",
            message: "Slide created successfully",
          })
        );
        return {
          data: response.data,
          index: slide.chapterIndex,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSlideSetting = createAsyncThunk(
  "updateSlideSetting",
  async ({ id, data, connectMasterTemplate }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.updateSlideSetting(id),
        data,
        thunkAPI
      );
      if (response.data) {
        if (connectMasterTemplate) {
          if (connectMasterTemplate === 'connectTamplate') {
            thunkAPI.dispatch(
              uiActions.showNotification({
                status: "success",
                title: "Updated!",
                message: "Master template connected successfully",
              })
            );
          } else {
            thunkAPI.dispatch(
              uiActions.showNotification({
                status: "info",
                title: "Updated!",
                message: "Remove Master template successfully",
              })
            );
          }
        } else {
          thunkAPI.dispatch(
            uiActions.showNotification({
              status: "success",
              title: "Updated!",
              message: "Slide Updated Successfully",
            })
          );
        }

        return response.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const slideEnable = createAsyncThunk(
  "slideEnable",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.updateSlideSetting(id),
        data,
        thunkAPI
      );
      if (response.data) {
        console.log(data)
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated!",
            message: data.isEnabled ? "Slide enabled successfully" : "Slide disable successfully",
          })
        );

        return response.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const fetchSlideTemplates = createAsyncThunk(
  "fetchSlideTemplates",
  async ({ targetDevice }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.fetchSlideTemplates(targetDevice),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadSource = createAsyncThunk(
  "downloadSource",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.downloadSource(id, type),
        undefined,
        thunkAPI,
        "",
        true,
        50000000
      );

      return response.data;
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadLink = createAsyncThunk(
  "downloadLink",
  async ({ id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.downloadSource(id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const downloadVersionSource = createAsyncThunk(
  "downloadVersionSource",
  async ({ presentationId, versionId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.downloadVersionSource(presentationId, versionId),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// save slide
export const saveSlide = createAsyncThunk(
  "saveSlide",
  async ({ presentationId, slideId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.saveSlide(presentationId, slideId),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Created!",
          message: "Slide Saved",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//get Slide
export const getSlide = createAsyncThunk(
  "getSlide",
  async ({ presentationId, slideId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.getSlide(presentationId, slideId),
        undefined,
        thunkAPI,
        undefined,
        undefined,
        10000
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//upload new version
export const uploadNewVersion = createAsyncThunk(
  "uploadNewVersion",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.uploadNewVersion(id),
        data,
        thunkAPI
      );
      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Uploaded!",
            message: "New Version Uploaded!",
          })
        );

        thunkAPI.dispatch(setSharedVersion({ id }));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// export presentation
export const exportPresentation = createAsyncThunk(
  "exportPresentation",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.exportPresentation(data),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const importPresentation = createAsyncThunk(
  "importPresentation",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.importPresentation(),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Imported!",
          message: "Presentation Imported!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//Delete Presentation
export const deletePresentation = createAsyncThunk(
  "deletePresentation",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.deletePresentation(id),
        undefined,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted!",
            message: "Presentation Delete Successfully",
          })
        );
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteSlide = createAsyncThunk(
  "deleteSlide",
  async ({ presentationId, slideId, chapterId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.deleteSlide(presentationId, slideId, chapterId),
        undefined,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Deleted!",
          message: "Slide Deleted Successfully!",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cloneSlide = createAsyncThunk(
  "cloneSlide",
  async (
    { presentationId, slideId, chapterIndex, slideIndex, slideName, image, thumbnail },
    thunkAPI
  ) => {
    try {
      const response = await HttpService.call(
        api.presentations.cloneSlide(presentationId, slideId),
        {
          slideName,
          chapterIndex,
          slideIndex,
          image,
          thumbnail,
        },
        thunkAPI
      );

      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Cloned!",
          message: "Slide Cloned Successfully!",
        })
      );
      return {
        data: response.data,
        chapterIndex,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const previewPresentation = createAsyncThunk(
  "previewPresentation",
  async (token, thunkAPI) => {
    try {
      axios.defaults.headers.common["preview-token"] = `Bearer ${token}`;
      const response = await HttpService.call(
        api.presentations.preview(),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const previewPresentationHTML = createAsyncThunk(
  "previewPresentationHTML",
  async ({ token, slideId }, thunkAPI) => {
    try {
      axios.defaults.headers.common["preview-token"] = `Bearer ${token}`;
      const response = await HttpService.call(
        api.presentations.previewHTML(slideId),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendAnalytics = createAsyncThunk(
  "sendAnalytics",
  async (data, thunkAPI) => {
    try {
      const response = await navigator.sendBeacon(
        api.presentations.sendAnalytics().url,
        JSON.stringify(data)
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// Create master template
export const createMasterTemplate = createAsyncThunk(
  "createMasterTemplate",
  async ({ presentationId, template }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.create(presentationId),
        template,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Created!",
            message: "Master template created successfully",
          })
        );
        return response.data
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

// rename master template
export const updateMasterTemplate = createAsyncThunk(
  "updateMasterTemplate",
  async ({ presentationId, masterTemplateId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.update(presentationId, masterTemplateId),
        data,
        thunkAPI
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Updated!",
            message: "Master template updated successfully",
          })
        );
        return response.data
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);


//get master template
export const getMasterTemplate = createAsyncThunk(
  "getMasterTemplate",
  async ({ presentationId, masterTemplateId }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.getMasterTemplate(presentationId, masterTemplateId),
        undefined,
        thunkAPI
      );
      if (!response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: "Could not get master template!",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//save master template
export const saveMasterTemplate = createAsyncThunk(
  "saveMasterTemplate",
  async ({ presentationId, masterTemplateId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.saveMasterTemplate(presentationId, masterTemplateId),
        data,
        thunkAPI
      );
      thunkAPI.dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Save!",
          message: "Master Template Saved",
        })
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//delete master template
export const deleteMasterTemplate = createAsyncThunk(
  "deleteMasterTemplate",
  async ({ presentationId, masterTemplateId, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.deleteMasterTemplate(presentationId, masterTemplateId),
        undefined,
        thunkAPI
      );

      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Delete!",
            message: "Master Template Deleted",
          })
        );
        return response.data;
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: "Master Template Could not Deleted",
          })
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

//delete master template
export const cloneMasterTemplate = createAsyncThunk(
  "cloneMasterTemplate",
  async ({ presentationId, masterTemplateId, templateIndex,
    image,
    thumbnail }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.presentations.masterTemplates.clone(presentationId, masterTemplateId),
        {
          presentationId,
          masterTemplateId,
          masterTemplateIndex: templateIndex,
          image,
          thumbnail
        },
        thunkAPI
      );

      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Cloned",
            message: "Master Template Cloned Successfully",
          })
        );
        return response.data;
      } else {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "error",
            title: "Error!",
            message: "Master Template Could not clone",
          })
        );
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
import React from 'react'
import { Box, Typography } from '@mui/material'
import styles from './caption.module.scss';
const Caption = ({label,value}) => {
  return (
    <Box className={`caption ${styles.caption}`}>
      <Typography variant="body2">{label}</Typography>
      <Typography variant="h4">{value}</Typography>
    </Box>
  )
}

export default Caption
import React from "react";
import { Link } from "react-router-dom";
import { Grid, Box, Stack, IconButton, Tooltip } from "@mui/material";
import {
  IoEye,
  IoPencil,
  IoDocument,
  IoEllipsisVerticalSharp,
  IoCopyOutline,
} from "react-icons/io5";
import DropDown from "src/components/ui/DropDown";
import AccessController from "../common/AccessController";
import PermissionComp from "../Permission";

const CardControls = (props) => {
  const { dropDownData, data } = props;

  //other handler will be create here
  return (
    <>
      <Grid item md={8}>
        <Stack direction="row" spacing="1">
          <AccessController role={props.role} component="view">
            <Tooltip title="View" arrow> 
              <IconButton variant="outlined" onClick={props.preview}>
                <IoEye />
              </IconButton>
            </Tooltip>
          </AccessController>
          <AccessController
            role={props.role}
            component="edit"
            status={props.status}
          >
            <Tooltip title="Edit" arrow>
              <span>
                <Link to={`/email/${props.id}/edit`}>
                  <IconButton variant="outlined">
                    <IoPencil />
                  </IconButton>
                </Link>
              </span>
            </Tooltip>
          </AccessController>

          <AccessController role={props.role} component="clone">
            <PermissionComp permissionPath="emailTemplates.create.clone">
              <Tooltip title="Clone Email Template" arrow>
                <IconButton onClick={props.cloneEmail} variant="outlined">
                  <IoCopyOutline />
                </IconButton>
              </Tooltip>
            </PermissionComp>
          </AccessController>

          {/* <AccessController role={props.role} component="detail"> */}
            <Tooltip title="Details" arrow>
              <IconButton onClick={props.toggleDetails} variant="outlined">
                <IoDocument />
              </IconButton>
            </Tooltip>
          {/* </AccessController> */}
        </Stack>
      </Grid>

      <AccessController role={props.role} component="more">
        <Grid item md={4}>
          <Box style={{ textAlign: "right" }}>
            <DropDown
              id={props.id}
              tooltip="More" 
              dropIcon={<IoEllipsisVerticalSharp />}
              items={dropDownData}
              data={data}
            />
          </Box>
        </Grid>
      </AccessController>

      {/* Dialogs */}
    </>
  );
};
export default React.memo(CardControls);

import { Box, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import Main from "src/components/dialogs/Main";
import Input from "src/components/ui/formElements/Input";
import Button from "src/components/Button";
const RenameMasterTemplate = (props) => {
  const templateName = useRef()
  
  const save = () => {
    props.handleConfirm(templateName.current.value)
  };

  const onClose = () => {
    props.handleClose();
  };

  return (
    <Main
      isOpen={props.isOpen}
      handleClose={onClose}
      background="#fff"
      modalWidth="xs"
      zIndex={1305}
    >
      <Box textAlign="center" p={3}>
        <Typography variant="h1" component="h1">
          {props.title}
        </Typography>

        <Input placeholder="Enter template name" defaultValue={props?.templaneName} sx={{ m: 1 }} refer={templateName} className="border" limit="50"/>

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={1}
        >
          <Button
            variant="outlined"
            startIcon={<IoCloseOutline />}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            id="confirmDeleteButton"
            color="success"
            endIcon={<IoCheckmarkOutline />}
            sx={{ mt: 5 }}
            type="button"
            onClick={save}
            loading={props.loading}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Main>
  );
};

export default RenameMasterTemplate;

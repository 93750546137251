import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ToolItem from "./ToolItem";
import "jquery-ui-dist/jquery-ui";
import { getNewLayerZIndex } from "src/services/editor.service";
import { components } from "../../../elements/Components";
import { getRandomId } from "src/services/utils";
import { applyPadding, csRenderMicrositeEditable, generateAndAppendMediaQueries } from "../../renderMicrosite";
import {
  getSnippet,
  getDimenssions,
  useSetLayerHeight,
} from "src/components/editor/editorLogics/components";
import { Sortable } from "src/components/editor/editorLogics/sortable";
import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHistory } from "src/hooks/editor/history";
import { useElementStatus } from "src/hooks/editor/element.controller";
import PermissionComp from "src/components/Permission";
import { hasPermission } from "src/utils/Utils";

const Toolbar = (props) => {
  const itemsDetails = useRef([]);
  const dropzone = props.dropzoneRef.current;
  const requestHierarchyUpdate = props.requestHierarchyUpdate;
  const { targetDevice } = useSelector(
    (state) => state.presentations.presentation
  );

  const deviceDimensions = getDimenssions(props.component, targetDevice);
  const { updateHistory } = useEditorHistory();
  const { activeElement } = useElementStatus();

  const getLayerSnippet = (type, id, columnLayout) => {
    const layerSnippet = components[type](id, props.component, columnLayout);
    let layerElement = getSnippet(layerSnippet);

    const appendableClass = "cs-col";
    const newAppendableClass = "col-appendable";

    layerElement.querySelectorAll("*").forEach((el) => {
      // For Email or other editor
      const csClass = [...el.classList].find((c) => c.includes("cs-"));
      if (csClass === "cs-button" || csClass === "cs-image") {
        el.style.width = "70px";
        el.style.minWidth = "50px";
        el.style.margin = "0px auto";
        layerElement.style.textAlign = "center";
      }

      if (el.classList.contains(appendableClass)) {
        el.classList.add(newAppendableClass);
      }

      if (csClass || el.classList.contains(appendableClass)) {
        // we need padding for cs-col last child too
        applyPadding(el, deviceDimensions);
      }
    });
    layerElement.style.position = "relative";
    layerElement.style.width = "100%";
    layerElement.style.height = "auto";
    return layerElement;
  };

  const renderAppendedItem = ({ endedOn }) => {
    const appendedItem = endedOn.element;

    if (appendedItem) {
      const layerId = getRandomId();
      appendedItem.id = layerId;
      // replacing layer with updated dimensions according to device
      // appendedItem.style.zIndex = getNewLayerZIndex(dropzone);

      // rendering it's functionality
      csRenderMicrositeEditable({
        activeElement,
        updateHistory,
        element: appendedItem,
        requestHierarchyUpdate,
      });
      // generateAndAppendMediaQueries
      if(appendedItem.classList.contains('column-component')){
        generateAndAppendMediaQueries()
      }
      // doing active after append
      const activeEditable = activeElement(appendedItem);
      // Applying padding to all elements
      applyPadding(appendedItem);
      // now element is completely rendered and it can be push to history
      updateHistory({
        action: HISTORY_ACTIONS.appended,
        id: appendedItem.id,
        activeEditable,
      });

      requestHierarchyUpdate();
    }
  };

  const makeItemDraggable = ({ ref, componentType, columnLayout }) => {
    if (ref) {
      const layerId = getRandomId();
      const layer = getLayerSnippet(componentType, layerId, columnLayout);

      if (columnLayout) {
        new Sortable(ref, {
          containers: "cs-slide",
          preventedContainers: "toolbar",
          fallBackElement: layer,
          onDrop: renderAppendedItem,
        });
      } else {
        const isTabComponent = ref.innerText.includes("Tab");
        new Sortable(ref, {
          containers: "col-appendable",
          preventedContainers: isTabComponent
            ? "toolbar,cs-slide,cs-tabContent"
            : "toolbar,cs-slide",
          fallBackElement: layer,
          onDrop: renderAppendedItem,
          onStart: props.onItemDragStart,
        });
      }
    }
  };

  useEffect(() => {
    if (dropzone) {
      itemsDetails.current.forEach(makeItemDraggable);
    }
  }, [itemsDetails.current, dropzone]);

  return (
    <>
      {props.info && <Typography className="info">{props.info}</Typography>}
      <Box className={`toolbar ${props.className ? props.className : ""}`}>
        {props.items.map((item, index) => {
          const toolItemPermission = hasPermission(item?.permissionPath);
          const toolItem = (
            <ToolItem
              itemRef={toolItemPermission || item.type == 'cs-column' ? (ref, componentType, columnLayout) => {
                itemsDetails.current[index] = {
                  ref: ref,
                  componentType,
                  columnLayout,
                };
              } :null}
              type={item.type}
              caption={item.caption}
              icon={item.icon}
              layout={item.layout}
              onItemClick={() => { }}
              className="drag"
              key={index}
              disable={item.type == "cs-audio" ? true : false}
            />
          );

          if (item.type == "cs-audio") {
            return toolItem;
          } else {
            return (
              <PermissionComp permissionPath={item.permissionPath} key={index}>
                {toolItem}
              </PermissionComp>
            );
          }
        })}
      </Box>
    </>
  );
};
export default Toolbar;

Toolbar.defaultProps = {
  onItemDragStart: () => { },
};

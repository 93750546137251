import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSlideSetting } from "src/store/actions/presentations";
import { Grid, Typography, Box, Button } from "@mui/material";
import Input from "src/components/ui/formElements/Input";
import Card from "src/components/ui/Card";

//import stylesheet
import "../../../styles/presentations/settings.scss";
import { uiActions } from "src/store/reducers/ui-slice";
import TextLimit from "src/components/ui/TextLimit";

const Slide = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const list = useSelector(
    (state) => state.presentations.presentation.chapters
  );
  const {
    presentation: { bucketURL, isLock },
  } = useSelector((state) => state.presentations);

  //state for slides
  const [slideList, setSlideList] = useState(list);

  const handleChange = (e, index, i) => {
    const list = JSON.parse(JSON.stringify(slideList));
    list[index].slides[i].name = e.target.value;
    setSlideList(list);
  };

  const updateSettings = (slide, chapterIndex, slideIndex) => {
    if (!slide?.name?.trim().length) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Invalid Name",
          message: "Slide name can't be empty!",
        })
      );
    }
    dispatch(
      updateSlideSetting({
        id: { presentationId: id, chapterIndex, slideIndex },
        data: { name: slide.name },
      })
    );
  };

  useEffect(() => {
    setSlideList(list);
  }, [list]);

  return (
    <Grid container gap={3}>
        {slideList.map(({ slides }, index) => {
          return slides.map((slide, i) => {
            return (
              <Grid item md={12} key={i}>
                <Card classes="box-style">
                  <Grid container>
                    <Grid item md={3} display="flex">
                      <Box>
                        <Typography variant="h1" className="title">
                          <TextLimit width="160px" text={slide.name} />
                        </Typography>
                        <img src={`${bucketURL}/${slide.image}`} width="200" />
                      </Box>
                      <Box className="borderLine"></Box>
                    </Grid>
                    <Grid item md={7}>
                      <Grid container spacing={3}>
                        <Grid item md={6}>
                          <Input
                            className="bordered"
                            labelTag="Id"
                            name="_id"
                            key={100 + i}
                            value={slide._id}
                            disabled
                          />
                        </Grid>
                        <Grid item md={6}>
                          <Input
                            className="bordered"
                            labelTag="Name"
                            name="name"
                            key={i}
                            onChange={(e) => handleChange(e, index, i)}
                            value={slide.name}
                            disabled={isLock}
                            inputProps={{ maxLength: 50 }}
                            limit="50"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={2} className="d-flex btn-align">
                      {!isLock && (
                        <Box>
                          <Button
                            variant="contained"
                            sx={{ textTransform: "capitalize" }}
                            onClick={() => updateSettings(slide, index, i)}
                          >
                            Update
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          });
        })}
      </Grid>
  );
};
export default Slide;

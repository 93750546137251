import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getHashDetails } from "src/store/actions/shareLink";
import { formatIsoDate, isDateExpire } from "src/hooks/useDateFormat";
import "./SharedLink.scss";
import CircleLoader from "src/components/ui/loader/CircleLoader";
import Centered from "src/layouts/Centered";
const SharedLink = () => {
  const navigate = useNavigate();
  const { hash } = useParams();
  const [hashDetails, setHashDetails] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getHashDetails(hash)).then((res) => {
      if (res.payload) {
        setHashDetails(res.payload);
      }
    });
  }, [dispatch, hash]);

  useEffect(() => {
    if (hashDetails) {
      const expireDate = hashDetails.expiryDate;
      if (expireDate) {
        const expireDateTime = formatIsoDate(expireDate);
        if (expireDateTime && isDateExpire(expireDateTime)) {
          navigate("/shared/expired");
          return; // Stop further processing if expired
        }
      }
      // if link is not expire then save in storage for other pages
      const storageObj = {
        SharedLinkId: hashDetails._id,
        type: hashDetails.type,
        id: hashDetails.landingPageId,
        version: hashDetails.version,
        UID: hashDetails.UID
      };

      localStorage.setItem("sharedLinkAuthTempObj", JSON.stringify(storageObj));
      // If not expired, check for password requirement
      if (hashDetails.hasPassword) {
        navigate(`/shared/auth/${hashDetails._id}`);
        return;
      }

      navigate(`/shared/view/${hashDetails._id}`);
    }
  }, [hashDetails, navigate]);

  return <Centered><CircleLoader></CircleLoader></Centered>;
};

export default SharedLink;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Tabs, Tab } from "@mui/material";
import { useParams } from "react-router-dom";

import {
  downloadSource,
  downloadVersionSource,
} from "src/store/actions/presentations";
import { getPresentaionById } from "src/store/actions/presentations";
import { setPreiewPresentation } from "src/store/reducers/presentations-slice";
import { canSendRequest } from "src/services/utils";

import AccessControl from "../../components/presentations/AccessControl";
import VersionsDetail from "src/components/common/VersionsDetail";
import SharingDetails from "src/components/common/SharingDetails";
import PresentationDetail from "src/components/common/Detail";

import "../../styles/presentations/details.scss";
import PermissionComp from "src/components/Permission";
import ExportDetails from "src/components/common/ExportDetails";
import ShareLinkDetails from "src/components/common/ShareLinkDetails";

const Action = ({ id }) => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(downloadSource({ id }));
  };

  return (
    <PermissionComp permissionPath="presentation.export.downloadSourceCode">
      <Button className="actionBtn" onClick={handleDownload}>
        Download Source
      </Button>
    </PermissionComp>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const Details = () => {
  const type = "presentation";
  const dispatch = useDispatch();

  const { id } = useParams();

  const { presentation, config } = useSelector((state) => state.presentations);

  const [presentationDetail, setPresentationDetail] = useState({});

  useEffect(() => {
    if (canSendRequest(config, id)) {
      dispatch(getPresentaionById(id)).then((res) => {
        setPresentationDetail(res.payload);
      });
    }
  }, [dispatch]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const downloadVersion = (versionId) => {
    dispatch(downloadVersionSource({ presentationId: id, versionId }));
  };

  const previewVersion = (versionId) => {
    dispatch(
      setPreiewPresentation({ isOpen: true, presentationId: id, versionId })
    );
  };

  return (
    <AccessControl component="details">
      <Box className="details">
          <PresentationDetail
            detail={presentation}
            Action={<Action id={id} />}
          />
          <Box className="sub-details">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="wrapped label tabs example"
              visibleScrollbar={false}
              variant="scrollable"
              sx={{ marginBottom: "10px" }}
              
            >
              <Tab className="detailsTabs" value={0} label="Versions" />
              <Tab className="detailsTabs" value={1} label="Share Settings" />
               <Tab className="detailsTabs" value={2} label="Exports" />
               <Tab className="detailsTabs" value={3} label="Share Links" />
            </Tabs>

            <TabPanel value={value} index={0}>
              <PermissionComp permissionPath="presentation.versions" alert>
                <VersionsDetail
                  id={id}
                  type={type}
                  downloadVersion={downloadVersion}
                  previewVersion={previewVersion}
                />
              </PermissionComp>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PermissionComp permissionPath="share" alert>
                <SharingDetails
                  id={id}
                  type={type}
                  Detail={presentationDetail}
                />
              </PermissionComp>
            </TabPanel>
            <TabPanel value={value} index={2}>
            <ExportDetails/>
              </TabPanel> 
              <TabPanel value={value} index={3}>
            <ShareLinkDetails/>
              </TabPanel> 
          </Box>
        </Box>
    </AccessControl>
  );
};
export default Details;

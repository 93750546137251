import React from "react";
import { useSelector } from "react-redux";
import Alignment from "../properties/Alignment";
import Styles from "../properties/Styles";
import JoditEditorr from "../properties/JoditEditor";
import AssetUpload from "../properties/AssetUpload";
import CheckBox from "../properties/CheckBox";
import RadioProperty from "../properties/RadioProperty";
import Border from "../properties/Border";
import FlipCard from "../properties/FlipCard";
import Table from "../properties/Table";
import RangeSlider from "../properties/RangeSlider";
import Audio from "../properties/Audio";
import Video from "../properties/Video";
import Graph from "../properties/Graph";
import LayerActions from "../properties/LayerActions";
import Flipster from "../properties/Flipster";
import Input from "../properties/Input";
import Background from "../properties/Background";
import BoxShadow from "../properties/BoxShadow";
import TabGroup from "../properties/TabGroup";
import Animation from "../properties/Animation";
import Popup from "../properties/Popup";
import Actions from "../properties/Actions";
import Grid from "../properties/Grid";
import Padding from "../properties/Padding";
import List from "../properties/List";
import Rating from "../properties/Rating";
import DividerBorder from "../properties/DividerBorder";
import Typography from "../properties/Typography";
import { useEditorHistory } from "src/hooks/editor/history";
import { isActiveComponent } from "src/services/editor.service";

export const properties = {
  ["cs-slide"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-text"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <JoditEditorr
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Actions
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-image"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <AssetUpload elementId={elementId} updateHistory={updateHistory} />
        <Border
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {document.getElementById(elementId)?.closest(".flipCard-component") && (
          <Padding
            elementId={elementId}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        )}
        <Actions
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-layer"]: ({ elementId, updateHistory, undoRedoChange }) => {
    const checkActiveImageComponent = isActiveComponent("image-component");
    const checkActiveAudioComponent = isActiveComponent("audio-component");
    const checkActiveVideoComponent = isActiveComponent("video-component");
    const checkActiveButtonComponent = isActiveComponent("button-component");
    const NoNeedBackground = !checkActiveImageComponent && !checkActiveAudioComponent && !checkActiveVideoComponent && !checkActiveButtonComponent;
    return (
      <>
        <Alignment
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Styles
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          component="presentation"
        />
        {!checkActiveButtonComponent && (
          <BoxShadow
            elementId={elementId}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        )}

        {NoNeedBackground && (
          <Background
            elementId={elementId}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        )}

        {!checkActiveButtonComponent && (
          <Border
            elementId={elementId}
            updateHistory={updateHistory}
            undoRedoChange={undoRedoChange}
          />
        )}
        
        <Animation
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <LayerActions
          elementId={elementId}
          buttonActive={true}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          component="presentation"
        />
      </>
    );
  },
  ["cs-container"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-popup"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Popup
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {/* <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-button"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Input
          widthComponent
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        ></Input>
        <Actions
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Typography
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Border
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <BoxShadow
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-link"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Input
          widthComponent
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        ></Input>

        <Typography
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Border
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-table"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Table
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Border
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {/* <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-checkboxes"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <CheckBox
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-flipCard"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <FlipCard
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-cardBody"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-extendedCard"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-tabGroup"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <TabGroup
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {/* <Background
          color={true}
          opacity={true}
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-tabContent"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Background
          color={true}
          opacity={true}
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-radio-container"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <RadioProperty
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-slider"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <RangeSlider
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          componentType="presentation"
        />
        {/* <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-audio"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Audio
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-video"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Video
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-input"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Input
          widthComponent
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        ></Input>
        <Typography
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Actions
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-graph"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Graph
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {/* <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-grid"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Grid
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-grid-item"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-coverflow"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Flipster
          heading="coverflow"
          elementId={elementId}
          component="presentation"
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Typography
          elementId={elementId}
          targetClass="text"
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        {/* <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        /> */}
      </>
    );
  },
  ["cs-list"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <List
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Typography
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          child
        />
        <Padding
          elementId={`${elementId} li`}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Border
          elementId={elementId}
          borderRadius={true}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          selector="li"
        />
      </>
    );
  },
  ["cs-list-item"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Background
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-carousel"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Flipster
          heading="carousel"
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Typography
          heading="Heading Styles"
          elementId={elementId}
          targetClass="heading"
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Typography
          elementId={elementId}
          targetClass="text"
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-rating"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <>
        <Rating
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
        <Padding
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
        />
      </>
    );
  },
  ["cs-divider"]: ({ elementId, updateHistory, undoRedoChange }) => {
    return (
      <DividerBorder
        elementId={elementId}
        updateHistory={updateHistory}
        undoRedoChange={undoRedoChange}
      />
    );
  },
  
 
  
};
const RighatbarProperties = (props) => {
  const { updateHistory: updateEditorHistory } = useEditorHistory();

  const editorState = useSelector((state) => state.editor);
  const {
    activeEditable,
    history: { backStack },
  } = editorState;
  // changes on every undo/redo
  const undoRedoChange = backStack.length;

  let render;
  let timeout;
  const elementId = activeEditable.elementId;
  const elementType = activeEditable.elementType;
  const property = properties[elementType];

  const updateHistory = ({ action } = {}) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      updateEditorHistory({ action });
    }, 200);
  };

  if (elementType) {
    if (elementId != "") {
      if (property) {
        render = (
          <>
            {/* <ActiveDropDown /> */}
            {property({
              elementId,
              updateHistory,
              undoRedoChange,
            })}
          </>
        );
      } else {
        render = (
          <p className="notFound-message">
            There is no Property was found for Selected Object
          </p>
        );
      }
    }
  } else {
    render = (
      <p className="notFound-message">Select an object to see its property</p>
    );
  }

  //if activeElement is image then ckedior will be hide.
  if (elementType == "cs-image") {
    const editor = document.querySelector(".ck-editor");
    if (editor !== null) {
      editor.style.display = "none";
    }
  }
  return render;
};
export default RighatbarProperties;

import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Checkbox,
  Radio,
  Box,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import { IoInformationCircle, IoCloseSharp } from "react-icons/io5";
import { RiShareBoxFill } from "react-icons/ri";
import CustomAlert from "src/components/ui/CustomAlert";
import { downloadLink } from "src/store/actions/presentations";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { uiActions } from "src/store/reducers/ui-slice";
import { clearDownloadLink } from "src/store/reducers/presentations-slice";
import Main from "./Main";
import "../../styles/export_dialog.scss";
import Permission from "src/components/Permission"
import { hasPermission } from "src/utils/Utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const ExportPresentation = (props) => {
  const { isOpen, handleClose, exportAs, setExportAs, handleExport, id } = props;
  const dispatch = useDispatch();
  const link = useSelector((state) => state.presentations.downloadLink);

  const [showTab, setShowTab] = useState(0);
  const [checked, setChecked] = useState(false);

  const defaultSelected = {
    0: "ppt",
    1: "pdf",
    2: "application",
  };
  const hasExportPermission = hasPermission("presentation.export.downloadAsPPT") ||
  hasPermission("presentation.export.downloadAsPDF") ||
  hasPermission("presentation.export.applicationLinkForAndroid");
  useEffect(() => {
    if (!isOpen) {
      if (hasPermission("presentation.export.downloadAsPPT")) {
        setExportAs(defaultSelected[0]); // Set exportAs to the value at key 1 ("pdf")
      }
      else if (hasPermission("presentation.export.downloadAsPDF")) {
        setExportAs(defaultSelected[1]); // Set exportAs to the value at key 0 ("ppt")
      }
      else if (hasPermission("presentation.export.applicationLinkForAndroid")) {
        setExportAs(defaultSelected[2]); // Set exportAs to the value at key 0 ("ppt")
      }
    }
  }, [isOpen]);

  const tabshandleChange = (event, newValue) => {
    setShowTab(newValue);
    setExportAs(defaultSelected[newValue]);
    setChecked(false);
  };

  const closeModal = () => {
    dispatch(clearDownloadLink())
    handleClose()
  }

  const handleChange = (e) => {
    setExportAs(e.target.name);
  };

  const exportPresentation = () => {
    if (handleExport) {
      handleExport();
    }
    closeModal();
  };

  const generateLinkHandler = () => {
    if (link) {
      navigator.clipboard.writeText(link);
      dispatch(
        uiActions.showNotification({
          status: "success",
          title: "Copied",
          message: "Copy to Clipboard",
        })
      );
    } else {
      dispatch(downloadLink({ id, type: "build" }));
    }
  };

  return (
    <Main
      background="white"
      modalWidth="md"
      isOpen={isOpen}
      handleClose={closeModal}
    >
      <Box className="exports">
        <Typography variant="h1">Export New E-Detailer</Typography>
        <Box className="horizontal-line"></Box>
        {/* <Tabs
          value={showTab}
          onChange={tabshandleChange}
          aria-label="wrapped label tabs example"
          variant="scrollable"
          sx={{ marginBottom: "20px" }}
        >
          <Tab value={0} label="Document" />
          <Tab value={1} label="CLM" />
          <Tab value={2} label="Application" />
        </Tabs> */}

        {/* <TabPanel value={showTab} index={0}>
          <RadioGroup value={exportAs} onChange={handleChange}>
            <FormControlLabel
              className="radiobtn"
              value="clm"
              name="clm"
              control={<Radio color="secondary" />}
              label={
                <>
                  <Typography variant="h2" className="radiolabel">
                    CLM TOOL
                  </Typography>
                  <Typography>
                    Select this option to export your content as an archive, if
                    you are using Abbott RU CLM Tool.
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              className="radiobtn"
              value="mobile"
              name="mobile"
              control={<Radio color="secondary" />}
              label={
                <>
                  <Typography variant="h2" className="radiolabel">
                    Phonegap Mobile Client
                  </Typography>
                  <Typography>
                    Here, you can export your presentation as an archive, if you
                    work with Phonegap Mobile Client.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={exportAs !== "mobile"}
                        checked={checked}
                        onClick={() => setChecked((pre) => !pre)}
                        color="secondary"
                      />
                    }
                    label="Generate slide snapshots"
                  />
                </>
              }
            />
          </RadioGroup>
        </TabPanel> */}
        <TabPanel value={showTab} index={0}>
          <RadioGroup value={exportAs} onChange={handleChange}>
            <Permission permissionPath="presentation.export.downloadAsPPT">
              <FormControlLabel
                className="radiobtn"
                value="ppt"
                name="ppt"
                control={<Radio color="secondary" />}
                label={
                  <>
                    <Typography variant="h2" className="radiolabel">
                      Microsoft PowerPoint
                    </Typography>
                    <Typography>
                      You can download your presentation as PPT file, with
                      detailed slide screenshots, reflecting each animation state.
                    </Typography>
                  </>
                }
              />
            </Permission>
            <Permission permissionPath="presentation.export.downloadAsPDF">
              <FormControlLabel
                className="radiobtn"
                value="pdf"
                name="pdf"
                control={<Radio color="secondary" />}
                label={
                  <>
                    <Typography variant="h2" className="radiolabel">
                      Portable Document Format (PDF)
                    </Typography>
                    <Typography>
                      Select this option to export only the presentation itself
                      into a PDF file. The generated file will not contain any
                      comments.
                    </Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={exportAs !== "pdf"}
                          checked={checked}
                          onClick={() => setChecked((pre) => !pre)}
                          color="secondary"
                        />
                      }
                      label="Display all interactive component states"
                    />
                  </>
                }
              />
            </Permission>
            <Permission permissionPath="presentation.export.applicationLinkForAndroid,presentation.export.downloadBuild">
              <FormControlLabel
                className="radiobtn"
                value="application"
                name="application"
                control={<Radio color="secondary" />}
                label={
                  <>
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h2" className="radiolabel">
                        Application
                      </Typography>
                      <Typography>
                        Select this option to genrate a link for both IOS and
                        Android devices.
                      </Typography>
                      <input
                        type="text"
                        className="genrate-link-input"
                        disabled
                        placeholder="Click on Generate Link"
                        value={link}
                      />
                      <Button
                        variant="contained"
                        color={link ? "success" : "primary"}
                        onClick={generateLinkHandler}
                        disabled={exportAs !== "application"}
                      >
                        {link ? "Copy" : "Generate Link"}
                      </Button>
                    </Box>
                  </>
                }
              />
            </Permission>
          </RadioGroup>
        </TabPanel>
        {/* <TabPanel value={showTab} index={2}>
          <RadioGroup value={exportAs} onChange={handleChange}>
            <FormControlLabel
              className="radiobtn"
              value="ios"
              name="ios"
              control={<Radio color="secondary" />}
              label={
                <>
                  <Typography variant="h2" className="radiolabel">
                    iOS Application
                  </Typography>
                  <Typography>
                    Here you can generate an iOS application based on your
                    presentation. Right after export you can install it on the
                    iPad.
                  </Typography>
                </>
              }
            />
            <FormControlLabel
              className="radiobtn"
              value="android"
              name="android"
              control={<Radio color="secondary" />}
              label={
                <>
                  <Typography variant="h2" className="radiolabel">
                    Android Application
                  </Typography>
                  <Typography>
                    You can now have your presentation exported as an app ready
                    for use on Android tablets.
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={exportAs !== "android"}
                        checked={checked}
                        onClick={() => setChecked((pre) => !pre)}
                        color="secondary"
                      />
                    }
                    label="Generate slide snapshots"
                  />
                </>
              }
            />
          </RadioGroup>
        </TabPanel> */}
        <Box className="alertbox">
          <CustomAlert
            action={
              <Button
                variant="contained"
                color="secondary"
                className="alert-btn"
                style={{ textTransform: "none" }}
              >
                View Guide
              </Button>
            }
            icon={<IoInformationCircle color="#2CC1D6" />}
          >
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
            sint. Velit officia consequat.
          </CustomAlert>
        </Box>

        <Box className="iconBox">
          <Button
            startIcon={<IoCloseSharp />}
            className="cancelbtn"
            variant="outlined"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            startIcon={<RiShareBoxFill />}
            className="exportbtn"
            variant="contained"
            onClick={exportPresentation}
            disabled={exportAs === "!hasExportPermission"}
          >
            Export
          </Button>
        </Box>
      </Box>
    </Main>
  );
};
export default React.memo(ExportPresentation);

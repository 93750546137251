import React from "react";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
const Check_Box = (props) => {
  const inputProps = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { name, color, size, checked, onChange, className, disabled, onClick, toolTipText, label, ...rest } = props;
  let checkbox = <Checkbox
  name={name}
  color={color}
  size={size}
  checked={checked}
  onChange={onChange}
  onClick={onClick}
  className={`heading_check_box ${className}`}
  disabled={disabled}
  {...rest}
  {...inputProps}
/>

  if(toolTipText){
    return <Tooltip title={toolTipText} size="small" arrow>{checkbox}</Tooltip>;
  }

  if(label){
    return <FormControlLabel control={checkbox} label={label}></FormControlLabel>;
  }
  return checkbox
};

export default Check_Box;

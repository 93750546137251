import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import jsonwebtoken from "jsonwebtoken";
import { getRolesEntry, getRolesPermission } from "src/services/utils";
import { setUserData } from "src/store/reducers/auth-slice";
import MainLayout from "./MainLayout";

const RequireAuth = () => {
  const {
    isLoggedIn,
    userData: { role },
    user,
  } = useSelector((state) => state.auth);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getModuleUrl = (url) => {
    return url.split("/").at(1);
  };

  useEffect(() => {
    if (user) {
      const jwt = user.jwt;
      const userData = jsonwebtoken.decode(jwt);
      console.log('userData', userData)
      dispatch(setUserData(userData));
    }
  }, [user]);

  useEffect(() => {
    if (role) {
      const url = getModuleUrl(location.pathname);
      const isHaveAccess = getRolesPermission(role, url);

      if (!isHaveAccess) {
        const entry = getRolesEntry(role);
        navigate(`/${entry}`);
      }
    }
  }, [role, location]);

  if (!isLoggedIn) {
    return (
      <Navigate
        to="/login"
        state={{
          visitedLocation: location.pathname,
        }}
        replace
      />
    );
  }

  if (!role) {
    return "Loading...";
  }

  return <MainLayout />;
};

export default RequireAuth;

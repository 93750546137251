import React, { useEffect, useState } from "react";
import FolderItem from "./FolderItem";
import Grid from "@mui/material/Grid2";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteFolder,
  getFoldersList,
  getFoldersHierarchy,
  updateFolder,
} from "src/store/actions/folder";
import { useSearchParams } from "react-router-dom";
import { getModuleType } from "src/utils/Utils";
import Confirm from "../dialogs/Confirm";
import CreateFolder from "./CreateFolder";
import MoveDialog from "./MoveDialog";
import EmptyPage from "./Empty";
import Column from "../common/Column";
import { resetFolderbreadcrumbs } from "src/store/reducers/folder-slice";
import { uiActions } from "src/store/reducers/ui-slice";

const FolderList = (props) => {
  const dispatch = useDispatch();
  const [deletingId, setDeletingId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [moveableItem, setMoveableItem] = useState(null);
  const {
    folders: { foldersList },
    ui: { isLoading },
  } = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const moduleType = getModuleType();

  const folderId = searchParams.get("folderId");
  const [folderPath, setFolderPath] = useState([]); // State to hold folder path

  // Fetch folder list based on the current folderId (parent folder)
  useEffect(() => {
    dispatch(getFoldersList({ parentId: folderId, type: moduleType }));
    if (folderId) {
      dispatch(getFoldersHierarchy(folderId));
    }
  }, [folderId, moduleType]);

  useEffect(() => {
    dispatch(resetFolderbreadcrumbs());
  }, [moduleType]);



  function onConfirmClose() {
    setDeletingId(null);
  }

  function onEditingClose() {
    setEditing(null);
  }

  function onMoveableItemClose() {
    setMoveableItem(null);
  }

  function onConfirmDelete() {
    dispatch(deleteFolder(deletingId));
    setDeletingId(null);
  }

  const handleMove = (selectedFolder = null) => {
    dispatch(
      updateFolder({
        data: { parentId: selectedFolder || null },
        id: moveableItem?._id,
      })
    ).then((response) => {
      if (response?.type === 'updateFolder/fulfilled') {
        dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Moved!",
            message: "Folder moved successfully.",
          })
        );
      }
      setMoveableItem();
      dispatch(getFoldersList({ parentId: folderId, type: moduleType }));
    });
  };
  return (
    <div className={`folder-container ${(folderId && !foldersList.length && !props.items) ? "vCenter" : ""}`}>
      <Grid container spacing={1.5}>
        {foldersList?.map((folder) => (
          <Column>
            <FolderItem
              folder={folder}
              setDeletingId={setDeletingId}
              setEditing={setEditing}
              setMoveableItem={setMoveableItem}
            />
          </Column>
        ))}
      </Grid>

      {!foldersList.length && !props.items && !isLoading && folderId && (
        <EmptyPage />
      )}

      <Confirm
        isOpen={deletingId}
        subtitle="Are you sure you want to delete this Folder?"
        handleClose={onConfirmClose}
        handleConfirm={onConfirmDelete}
      />
      <CreateFolder show={editing} close={onEditingClose} editing={editing} />
      {moveableItem && (
        <MoveDialog
          show={moveableItem}
          close={onMoveableItemClose}
          moveableItem={moveableItem}
          onSubmit={handleMove}
        />
      )}
    </div>
  );
};

export default FolderList;

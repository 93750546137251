import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";

import store from "./store/index";
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";

import App from "./App";

render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <style>
          {`
            :root {
              --mui-primary-main: ${theme.palette.primary.main};
              --mui-secondary-main: ${theme.palette.secondary.main};
            }
          `}
        </style>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

import { createSlice } from "@reduxjs/toolkit";
import {
  login,
  logout,
  forgotPassword,
  activateAccount,
  newActivationToken,
  updateUserData,
  updateUserProfilePic,
  deleteUserProfilePic
} from "../actions/auth";
import cookie from "react-cookies";
import { salesforceLogin, connectPresentation } from "../actions/sf";
import { userVerify } from "../actions/users";

import jsonwebtoken from "jsonwebtoken";
const user = cookie.load("user");

const initialState = user
  ? {
    isLoggedIn: true,
    user,
    userData: { company: {}, companyDetail:{} },
    isSfIntegrated: false,
    accountActive: { status: false, tokenStatus: null },
    permissions: {},
  }
  : {
    isLoggedIn: false,
    user: null,
    userData: {},
    isSfIntegrated: false,
    accountActive: { status: false, tokenStatus: null },
    isUser: null,
    permissions: {},
  };

const authSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
      // storing usre cookies
      const isRemember = cookie.load("rememberMe")
      const permissions = jsonwebtoken.decode(action.payload?.user?.packageData)
      state.permissions = permissions?.package?.permissions || {}
      let userData = {
        ...isRemember,
        ...action.payload.user
      }
      localStorage.setItem("user", JSON.stringify(userData));
      cookie.save("user", JSON.stringify(userData), { path: "/" });
      cookie.remove("rememberMe")
    });

    builder.addCase(login.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.permissions = {}
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.isSfIntegrated = false;

      // removing user cookies
      cookie.remove("user", { path: "/" });
      cookie.remove("sf-config", { path: "/" });
      localStorage.removeItem("user");
    });

    builder.addCase(userVerify.fulfilled, (state, action) => {
      const isRemember = JSON.parse(localStorage.getItem("user"))
      const permissions = jsonwebtoken.decode(action.payload.packageData)
      state.permissions = permissions?.package.permissions
      let obj = {
        rememberMe: isRemember?.rememberMe,
        ...action.payload
      }

      cookie.save("user", JSON.stringify(obj), { path: "/" });
      localStorage.setItem("user", JSON.stringify(obj));
    });
    builder.addCase(userVerify.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.permissions = {}
      state.isSfIntegrated = false;

      // removing user cookies
      cookie.remove("user", { path: "/" });
      cookie.remove("rememberMe")
      cookie.remove("sf-config", { path: "/" });
      localStorage.removeItem("user");
    });

    builder.addCase(salesforceLogin.fulfilled, (state, action) => {
      const token = action.payload.access_token;
      const instanceUrl = action.payload.instance_url;
      const expiry = Date.now() + 5400000; //1.5 Hours

      if (token) {
        cookie.save(
          "sf-config",
          {
            token,
            instanceUrl,
            expiry,
          },
          {
            path: "/",
          }
        );
        state.isSfIntegrated = true;
      }
    });

    builder.addCase(connectPresentation.fulfilled, (state, action) => {
      if (action.payload.status === 401) {
        state.isSfIntegrated = false;
        cookie.remove("sf-token", { path: "/" });
      }
    });
    builder.addCase(activateAccount.pending, (state, action) => {
      state.accountActive.status = false;
      state.accountActive.tokenStatus = null;
    });
    builder.addCase(activateAccount.rejected, (state, action) => {
      state.accountActive.tokenStatus = null;
    });
    builder.addCase(activateAccount.fulfilled, (state, action) => {
      state.accountActive.status = action.payload == "activated" ? true : false;
    });
    builder.addCase(newActivationToken.pending, (state, action) => {
      state.isUser = null;
    });
    builder.addCase(newActivationToken.rejected, (state, action) => {
      state.isUser = null;
    });
    builder.addCase(newActivationToken.fulfilled, (state, action) => {
      state.isUser = action.payload;
    });
    builder.addCase(forgotPassword.pending, (state, action) => {
      state.isUser = null;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isUser = action.payload;
    });
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.userData.firstName = action.payload.firstName
      state.userData.lastName = action.payload.lastName
    });
    builder.addCase(updateUserProfilePic.fulfilled, (state, action) => {
      state.userData.profile_pic = action.payload
    });
    builder.addCase(deleteUserProfilePic.fulfilled, (state, action) => {
      state.userData.profile_pic = null
    });
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setCompanyData: (state, action) => {
      state.userData.company = action.payload;
    },
    saveUserData: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    removeUser: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.permissions = {}
      state.isSfIntegrated = false;

      // removing user cookies
      cookie.remove("user", { path: "/" });
      cookie.remove("rememberMe")
      cookie.remove("sf-config", { path: "/" });
      localStorage.removeItem("user");
    },
    checkSFAuth: (state, action) => {
      const sfConfig = cookie.load("sf-config");
      if (sfConfig?.token && sfConfig?.expiry > Date.now()) {
        state.isSfIntegrated = true;
      } else {
        cookie.remove("sf-config", { path: "/" });
        state.isSfIntegrated = false;
      }
    },
  },
});
export default authSlice.reducer;
export const { setUserData, setCompanyData, saveUserData, removeUser, checkSFAuth } = authSlice.actions;

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { AiOutlineClose } from "react-icons/ai";
import { BsCheck2 } from "react-icons/bs";

//MUI Components
import {
  Box,
  Button,
  Grid,
  Typography,
  Checkbox,
  Tabs,
  Tab,
} from "@mui/material";

//API and Actions
import { createEmail } from "src/store/actions/emails";
import { uiActions } from "src/store/reducers/ui-slice";

//Componnets
import SelectBox from "src/components/ui/formElements/SelectBox";
import Input from "src/components/ui/formElements/Input";
import TabPanel from "src/components/ui/TabPanel";
import Content from "src/layouts/Content";
import Main from "../../Main";

import SharedWithMe from "./SharedWithMe";
import MyTemplates from "./MyTemplates";
import Templates from "./Templates";
import Layouts from "./Layouts";
import { countries, languages } from "src/data/data";
import { targetSystem } from "src/components/editor/editorLogics/components";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const intialState = {
  name: "",
  image: "N/A",
  category: "abc",
  lang: "",
  country: "",
  region: "pak",
  version: "0.001",
  targetSystem: "",
  templateId: "",
  startDate: "",
  endDate: "",
  ApprovalSystemExtId: "",
  originalSlides: true,
  status: "draft",
};

const AddNew = (props) => {
  const { show, close } = props;
  const dispatch = useDispatch();
  const [email, setEmail] = useState(intialState);
  const [templatesTab, setTemplatesTab] = useState(0);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const key = e.target.name;
    let value = e.target.value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    if (key === "name") {
      if (value.length > 50) {
        setError("Name cannot exceed 50 characters");
        value = value.slice(0, 50); // Enforce the character limit
      } else {
        setError("");
      }
    }
    setEmail((pre) => ({ ...pre, [key]: value }));
  };

  const handleTabChange = (event, newValue) => {
    setTemplatesTab(newValue);
  };

  const selectTemplate = (e, id) => {
    const cards = document.querySelectorAll(".templateContainer .cardItem");
    setEmail({ ...email, templateId: id });
    cards.forEach((card) => card.classList.remove("success-checkmark"));
    e.currentTarget.classList.add("success-checkmark");
  };

  const addEmail = (e) => {
    e.preventDefault();
    if (!email.templateId) {
      return dispatch(
        uiActions.showNotification({
          status: "error",
          title: "Select Template",
          message: "Please Select Template to Create Email Template",
        })
      );
    }
    dispatch(createEmail(email));
    close();
    setEmail(intialState);
  };

  const closeDialog = () => {
    close();
    resetForm(); // Reset the form on close
  };
   // Function to reset form state
   const resetForm = () => {
    setEmail(intialState);
  };
  return (
    <Main className="previewDialog" isOpen={show} handleClose={closeDialog}>
      <Content title="Create New Email Template">
        <form onSubmit={addEmail}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Box className="ControlWrap">
                <Input
                  type="text"
                  // label="Template Name"
                  placeholder="Enter text here.."
                  name="name"
                  labelTag="Template Name"
                  value={email.name}
                  onChange={handleChange}
                  required
                  inputProps={{ maxLength: 50 }}
                />
                     {error && (
                  <Typography color="error" variant="body2">
                    {error}
                  </Typography>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box className="ControlWrap">
                <SelectBox
                  // label="Target System"
                  name="targetSystem"
                  labelTag="Target System"
                  renderValue="Select System"
                  value={email.targetSystem}
                  onChange={handleChange}
                  options={targetSystem}
                  required
                  displayEmpty
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={3}>
              <Box className="ControlWrap">
                <SelectBox
                  // label="Country"
                  name="country"
                  labelTag="Country"
                  onChange={handleChange}
                  renderValue="Select Country"
                  value={email.country}
                  options={countries}
                  required
                  displayEmpty
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box className="ControlWrap">
                <SelectBox
                  // label="Language"
                  name="lang"
                  labelTag="Language"
                  renderValue="Select Language"
                  value={email.lang}
                  onChange={handleChange}
                  options={languages}
                  required
                  displayEmpty
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={0}
            my={0.6}
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mb={2}
          >
            <Grid item xs={12}>
              <Templates selectTemplate={selectTemplate} setEmail={setEmail} />
            </Grid>
          </Grid>

          <div className=" button-div">
          <Grid container mt={2} >
             {/*<Grid item md={10}>
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="secondary"
                  defaultChecked
                  name="originalSlides"
                  onChange={handleChange}
                />
                <Typography textTransform="capitalize" color="#2D3748">
                  keep slides from orignal template
                </Typography>
              </Box> 
            </Grid>*/}
            {/* <Grid item md={2} display="flex" justifyContent="space-around"> */}
            <div className="custom-btn">
              <Button
              sx={{ mr: 2 }}
                variant="outlined"
                className="capitalize"
                onClick={closeDialog}
                startIcon={<AiOutlineClose size={13} />}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="capitalize"
                type="submit"
                startIcon={<BsCheck2 size={13} />}
              >
                Create
              </Button>
              </div>
            {/* </Grid> */}
          </Grid>
          </div>
        </form>
      </Content>
    </Main>
  );
};
export default React.memo(AddNew);

import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import PresentationStatus from "./Status";
import { makeStyles } from "@mui/styles";
import { FaCheckSquare, FaUsers } from "react-icons/fa";
import { ThumbnailImage } from "./ThumbnailImage";
import Check_Box from "./Check_Box";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { HiUsers } from "react-icons/hi";
const useStyles = makeStyles((theme) => ({
  shapedPaper: {
    borderRadius: theme.shape?.borderRadius,
    padding: theme.spacing && theme.spacing(1), 
  },
}));

const CardItem = (props) => {
  const classes = useStyles();
  return (
    <Box
      id={props.id}
      className={`cardItem ${props.classes} ${classes.shapedPaper} ${props.isShared? 'shared':""}`}
      onClick={props.onClick}
      onDoubleClick={props.onDoubleClick}
    >
      <Box className="cardItemHeader">
        <Box className={`image-wrap ${props.masterTemplateThumbnailURL && 'masterTemplateLinked'}`}>
          {props.masterTemplateThumbnailURL && <ThumbnailImage src={props.masterTemplateThumbnailURL} />}
          {!props.dontShowSlideThumbnail && <ThumbnailImage src={props.image} classes="slideThumbnail"/>}
          {props.checkHandler &&
          <Check_Box
            name="animationCheckbox"
            color="primary"
            size="medium"
            checked={props.checkValue}
            onClick={props.checkHandler}
            className="isEnableCheck"
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: '#1360ef',
              },
              '&:hover': {
                color: 'gray',
              },
            }}
            checkedIcon={<ImCheckboxChecked />}
            icon={<ImCheckboxUnchecked />}
          />
          
          }
        </Box>
      </Box>
      <Box className="cardItemBody">
        <Grid container>
          <Grid item md={12}>
            <div className="titleWrap">
            <Typography component="h2" className="title">
              <span>{props?.title || 'No Name'}</span>
            </Typography>
            {props.isShared > 0 && <span className="sharedIcon"><HiUsers/></span>}
            </div>
            
          </Grid>
          <Grid item md={12} className="cardItemSubContainer">
            <Typography component="p" className="subtitle">
              {props.subtitle}
            </Typography>
            <PresentationStatus status={props.status} onCard />
          </Grid>
        </Grid>
        {props.controls && (
        <Box className="controls">
          {props.controls}
        </Box>
        )}
      </Box>
      
    </Box>
  );
};
export default React.memo(CardItem);

import { Box, Stack, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { IoCheckmarkOutline, IoCloseOutline } from "react-icons/io5";
import Main from "src/components/dialogs/Main";
import Input from "src/components/ui/formElements/Input";
import Button from "src/components/Button";

const RenameDialog = (props) => {
  const inputName = useRef();
  const [error, setError] = useState("");

  const save = () => {
    const inputValue = inputName.current?.value.trim();

    if (!inputValue) {
      setError(`${props.title} name is required`);
      return;
    }

    setError(""); 
    props.handleConfirm(inputValue);
  };

  const onClose = () => {
    setError(""); 
    props.handleClose();
  };

  const handleChange = () => {
    if (error) setError(""); 
  };

  return (
    <Main
      isOpen={props.isOpen}
      handleClose={onClose}
      background="#fff"
      modalWidth="xs"
      zIndex={1305}
    >
      <Box textAlign="center" p={3}>
        <Typography variant="h1" component="h1">
          Rename {props.title}
        </Typography>

        <Input
          placeholder={`Enter ${props.title} Name`}
          defaultValue={props?.name}
          sx={{ m: 1 }}
          refer={inputName}
          className="border"
          limit="50"
          onChange={handleChange} 
        />
        
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}

        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          mt={1}
        >
          <Button
            variant="outlined"
            startIcon={<IoCloseOutline />}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            id="confirmDeleteButton"
            color="success"
            endIcon={<IoCheckmarkOutline />}
            sx={{ mt: 5 }}
            type="button"
            onClick={save}
            loading={props.loading}
          >
            Update
          </Button>
        </Stack>
      </Box>
    </Main>
  );
};

export default RenameDialog;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";
import {
  IoTrash,
  IoDuplicateSharp,
  IoLockClosed,
  IoLockOpen,
} from "react-icons/io5";
import Control from "./Control";
import { showLayers } from "src/services/editor.service";
import "src/styles/properties.scss";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Confirm from "src/components/dialogs/Confirm";
import { editorActions } from "src/store/reducers/editor-slice";
import { setDuplicateLayerId } from "src/services/editor.service";

import { renderEditableElements as csRenderPresentationLayer } from "../presentation/renderPresentation";
import { renderEditableElements as csRenderEmailLayer, generateAndAppendMediaQueries } from "../microsites/renderMicrosite";
import { renderEditableElements as csRenderMicrositeLayer } from "../email/renderEmail";

import { HISTORY_ACTIONS } from "src/data/contants";
import { useEditorHistory } from "src/hooks/editor/history";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { useEditorHeirarchy } from "src/hooks/editor/heirarchy.controller";

const isPresentationEditor = window.location.href.includes("presentation");
const isEmailEditor = window.location.href.includes("email");
const isMicrositeEditor = window.location.href.includes("microsite");

const LayerActions = (props) => {
  const dispatch = useDispatch();
  const { elementId, undoRedoChange } = props;
  const editorLayers = useSelector((state) => state.editor.layers);
  const [lock, setLock] = useState(false);
  const [lockEnabled, setLockEnabled] = useState(false);

  const { activeElement } = useElementStatus();
  const { requestHierarchyUpdate } = useEditorHeirarchy();
  const { updateHistory } = useEditorHistory();

  //delete layer code start
  const deleteButton = (
    <Tooltip title="Delete" arrow>
      <IconButton
        variant="outlined"
        color="info"
        size="small"
        id="layerDeleteBtn"
        onClick={() => {
          // setIsOpen(true);
          dispatch(
            editorActions.setEditorDialog({
              dialog: "deleteLayer",
              value: true,
            })
          );
        }}
        disabled={!lock ? false : true}
      >
        <IoTrash />
      </IconButton>
    </Tooltip>
  );
  //delete layer code end
  ///////////////////////////////////////////  duplicate layer code start
  const duplicateLayerHandler = () => {
    const Ele = $("#" + elementId);
    const cloneEle = $("#" + elementId).clone();

    // As popup has a sibling overlay
    // So duplicating it conditionally
    if (
      $("#" + elementId)
        .attr("class")
        .includes("popup")
    ) {
      const overlay = $("#" + elementId).next(".overlay");
      const overlayClone = overlay.clone();
      //cloneEle.appendTo("#dropzone");
      $(cloneEle).insertAfter(Ele);
      overlay.after(cloneEle);
    } else {
      //change duplicated element of position only for presentation, becuase its absolute
      if (isPresentationEditor) {
        let EleTop = parseInt(Ele.css("top")) + 20 + "px";
        let EleLeft = parseInt(Ele.css("left")) + 20 + "px";
        cloneEle.css({
          top: EleTop,
          left: EleLeft,
        });
      }

      $(cloneEle).insertAfter(Ele);
    }

    // Applying id for duplicated element and their children
    setDuplicateLayerId($(cloneEle));
    const clonedId = cloneEle.attr("id");
    const clonedObj = cloneEle.get(0);
    //conditionally render layer based on editor type presentation
    if (isPresentationEditor) {
      csRenderPresentationLayer({
        layersContainer: clonedObj,
        activeElement,
        updateHistory,
        requestHierarchyUpdate,
      });
    }
    //conditionally render layer based on editor type microsite
    if (isMicrositeEditor) {
      generateAndAppendMediaQueries()
      csRenderMicrositeLayer({
        layersContainer: clonedObj,
        activeElement,
        updateHistory,
        requestHierarchyUpdate,
      });
    }
    //conditionally render layer based on editor type email
    if (isEmailEditor) {
      csRenderEmailLayer({
        layersContainer: clonedObj,
        activeElement,
        updateHistory,
        requestHierarchyUpdate,
      });
    }
    // putting in settimeoute to run in after setting height
    setTimeout(() => {
      // to active an element
      const activeEditable = activeElement(cloneEle.get(0));
      // then update it's history
      // updateHistory({ action: HISTORY_ACTIONS.appended });
      updateHistory({
        action: HISTORY_ACTIONS.appended,
        id: cloneEle.get(0).id,
        activeEditable,
      });
      requestHierarchyUpdate();
    }, 300);
  };
  
  let duplicateButton = (
    <IconButton
      variant="outlined"
      color="info"
      size="small"
      onClick={duplicateLayerHandler}
      disabled
    >
      <IoDuplicateSharp />
    </IconButton>
  );
  ///////////////////////////////////////////  duplicate layer code end

  if (!lock) {
    duplicateButton = (
      <Tooltip title="Duplicate" arrow>
        <IconButton
          variant="outlined"
          color="info"
          size="small"
          onClick={duplicateLayerHandler}
        >
          <IoDuplicateSharp />
        </IconButton>
      </Tooltip>
    );
  }
  //duplicate layer code end

  //lock layer code start
  const toggleLock = () => {
    const element = $("#" + elementId);
    if (element.hasClass("locked")) {
      //lock element
      element.draggable("disable");
      element.addClass("locked");
      setLock(true);
    } else {
      //unlock element
      element.draggable("enable");
      element.removeClass("locked");
      setLock(false);
    }
    setLockEnabled(true);
  };

  useEffect(() => {
    if (props.component === "presentation") {
      toggleLock();
    }
  }, [elementId, undoRedoChange]);

  const lockLayer = () => {
    const element = $("#" + elementId);
    element.toggleClass("locked");
    toggleLock();
    updateHistory({ action: "updated" });
  };

  let lockButton = (
    <Tooltip title="Lock" arrow>
      <IconButton
        variant="outlined"
        color="info"
        size="small"
        onClick={lockLayer}
      >
        {lock ? <IoLockClosed /> : <IoLockOpen />}
      </IconButton>
    </Tooltip>
  );
  if (editorLayers.length > 0) {
    lockButton = (
      <Tooltip title="Lock Layer" arrow>
        <IconButton
          variant="outlined"
          color="info"
          size="small"
          onClick={lockLayer}
        >
          {lock ? <IoLockClosed /> : <IoLockOpen />}
        </IconButton>
      </Tooltip>
    );
  }
  //lock layer code end
  return (
    <div className="layer_footer">
      <Control heading="" className="alignment" divider={false}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid>
            {/* <Confirm
              isOpen={isOpen}
              handleClose={toggleDialog}
              handleConfirm={deleteLayer}
              title="Are You Sure?"
              subtitle="You want to delete this layer"
            ></Confirm> */}
            {deleteButton}
          </Grid>
          <Grid>{duplicateButton}</Grid>
          {lockEnabled && <Grid>{lockButton}</Grid>}
        </Grid>
      </Control>
    </div>
  );
};

export default LayerActions;

import React, { useState, forwardRef, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactSortable } from "react-sortablejs";
import { useNavigate, useParams } from "react-router-dom";

import {
  IconButton,
  Tooltip,
  Box,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { IoEye, IoEyeOff, IoTrash, IoAdd } from "react-icons/io5";
import { BsLockFill, BsUnlockFill } from "react-icons/bs";

import {
  updateChapterSetting,
  deleteChapter,
  createChapter,
  updateStructure,
  slideEnable,
  getPresentaionById,
} from "src/store/actions/presentations";
import { setUpdatedStruture } from "src/store/reducers/presentations-slice";

import Content from "src/layouts/Content";
import CardItem from "src/components/common/CardItem";
import ConfirmDialouge from "src/components/dialogs/Confirm";
import AddSlidePanel from "src/components/dialogs/AddSlidePanel";
import AccessControl from "../../components/presentations/AccessControl";
import ContentAction from "src/components/ui/formElements/ContentAction";
import SlideControls from "src/components/presentations/structure/SlideControls";
import MasterTemplates from "src/components/presentations/structure/masterTemplates/MasterTemplates";

import addIcon from "../../assets/images/icon.webp";

import "../../styles/presentations/Structure.scss";
import PermissionComp from "src/components/Permission";
import { hasPermission } from "src/utils/Utils";
import TextLimit from "src/components/ui/TextLimit";


const SlideWrapper = forwardRef(({ children }, ref) => (
  <Grid container spacing={2} ref={ref}>
    {children}
  </Grid>
));

const Structure = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    presentation: { chapters, bucketURL, masterTemplates,  isLock, status },
    updatedStructure,
  } = useSelector((state) => state.presentations);

  const [updateAction, setUpdateAction] = useState();
  const [isDragging, setisDragging] = useState(false);
  const [chapterIndex, setChapterIndex] = useState(0);
  const [chapterUpdate, setChapterUpdate] = useState({});
  const [drawer, setDrawer] = useState(false);
  //state to manage message on confirm dialog
  const [confirmMessage, setConfirmMessage] = useState("");
  const [isOpen, setisOpen] = useState(false);

  const isLockPermission = hasPermission("presentation.chapter.lock");

  const showDetailPanel = (chapterId, order, chapterIndex) => {
    setDrawer(true);
    setChapterIndex(chapterIndex);
  };

  const toggleDialog = (updateData, index, action = "update") => {
    setisOpen(!isOpen);
    setChapterIndex(index);
    setChapterUpdate(updateData);
    setUpdateAction(action);
  };

  const addChapter = () => {
    dispatch(
      createChapter({
        presentationId: id,
        data: {
          name: "New Chapter",
          presentationId: id,
          isLock: false,
          isDeleted: false,
          status: true,
        },
      })
    );
  };

  const updateSettings = (update) => {
    dispatch(
      updateChapterSetting({
        presentationId: id,
        chapterIndex: chapterIndex,
        data: update,
      })
    );
    setisOpen(false);
  };

  const chapterDelete = (update) => {
    dispatch(
      deleteChapter({
        presentationId: id,
        chapterId: update.delete,
        chapterIndex,
      })
    );
    setisOpen(false);
  };

  const onSlideDragStart = () => {
    setisDragging(true);
  };

  const updateChapter = (updatedChapters) => {
    dispatch(setUpdatedStruture(updatedChapters));
    dispatch(updateStructure({ presentationId: id, data: updatedChapters }));
  };

  const onSlideDragEnd = (event) => {
    setisDragging(false);
    let {
      newDraggableIndex: newSlideIndex,
      oldDraggableIndex: oldSlideIndex,
      from,
      to,
    } = event;

    const oldChapterIndex =
      from.parentNode.parentNode.getAttribute("data-index");
    const newChapterIndex = to.parentNode.parentNode.getAttribute("data-index");

    if (
      oldChapterIndex === newChapterIndex &&
      oldSlideIndex === newSlideIndex
    ) {
      return;
    }

    const updatedChapters = JSON.parse(JSON.stringify(chapters));
    const dragItem = {
      ...updatedChapters[oldChapterIndex].slides[oldSlideIndex],
    };
    updatedChapters[oldChapterIndex].slides.splice(oldSlideIndex, 1);
    updatedChapters[newChapterIndex].slides.splice(newSlideIndex, 0, dragItem);

    updateChapter(updatedChapters);
  };

  const onChapterDragEnd = (event) => {
    const { newIndex: newChapterIndex, oldIndex: oldChapterIndex } = event;

    if (oldChapterIndex === newChapterIndex) {
      return;
    }

    const updatedChapters = JSON.parse(JSON.stringify(chapters));
    const dragItem = JSON.parse(
      JSON.stringify(updatedChapters[oldChapterIndex])
    );
    updatedChapters.splice(oldChapterIndex, 1);
    updatedChapters.splice(newChapterIndex, 0, dragItem);

    updateChapter(updatedChapters);
  };

  const setList = () => {
    return;
  };

  let slidesCount = chapters?.reduce(
    (total, chapter) => total + chapter.slides.length,
    0
  );

  const slideEnableHandler = (e, chapterIndex, slideIndex)=>{
    console.log(e.target.checked, chapterIndex, slideIndex);
    const value = e.target.checked
    dispatch(slideEnable({
      id: { presentationId: id, chapterIndex, slideIndex },
      data: { isEnabled: value }
    }));
  }

  useLayoutEffect(() => {
    if (id) {
      dispatch(getPresentaionById(id));
    }
  },[]);

  return (
    <AccessControl component="structure">
      <MasterTemplates></MasterTemplates>
      <br></br>
      <Content
        title="Chapters"
        contentClass="sorter"
        classes="chapter-sortable"
        headerAction={
          !isLock && (
            <ContentAction
              text="Create chapter"
              variant="contained"
              icon={<IoAdd />}
              onClick={addChapter}
              permissionPath="presentation.chapter.createNew"
            />
          )
        }
      >
        <ConfirmDialouge
          isOpen={isOpen}
          handleClose={toggleDialog}
          subtitle={confirmMessage}
          handleConfirm={() => {
            if (updateAction == "deleteChapter") {
              chapterDelete(chapterUpdate);
            } else {
              updateSettings(chapterUpdate);
            }
            return true;
          }}
          variant={updateAction != "deleteChapter" && 'success'}
        />
        {drawer && (
          <AddSlidePanel
            open={drawer}
            chapterIndex={chapterIndex}
            onClose={() => setDrawer(false)}
            presentationId={id}
            slidesCount={slidesCount}
          />
        )}
        <ReactSortable
          disabled={isLock || !isLockPermission}
          draggable=".draggable"
          setList={setList}
          onEnd={onChapterDragEnd}
          ghostClass="op-1"
          dragClass="op-1"
          forceFallback
          scrollSpeed={100}
          handle=".chapterHead"
          list={JSON.parse(JSON.stringify(chapters))}
          animation={150}
        >
          {chapters.length > 0 ? (
            chapters.map((chapter, index) => {
              return (
                <Box
                  className={`chapter ${chapter.status ? "" : "hideChapter"} ${
                    !chapter.isLock && "draggable"
                  }`}
                  data-index={index}
                  key={chapter._id}
                >
                  <Box className="chapterHead">
                    <Grid container spacing={2}>
                      <Grid item xs={10} md={10}>
                        <Typography component="p" className="chapterTitle">
                          <TextLimit width="250px" text={chapter.name} />
                        </Typography>
                      </Grid>
                      {!isLock && (
                        <Grid item xs={2} md={2}>
                          <Typography component="p" className="chapterButtons">
                            <PermissionComp permissionPath="presentation.chapter.hide">
                              <Tooltip
                                title={
                                  chapter.status
                                    ? "Hide Chapter"
                                    : "Unhide Chapter"
                                }
                                arrow
                              >
                                <IconButton
                                  onClick={() => {
                                    toggleDialog(
                                      { status: !chapter.status },
                                      index
                                    );
                                    setConfirmMessage(
                                      `Are you sure you want to ${
                                        chapter.status ? "hide" : "unhide"
                                      } this chapter?`
                                    );
                                  }}
                                  color="primary"
                                  size="small"
                                  className="btn-hide"
                                >
                                  {chapter.status ? <IoEye /> : <IoEyeOff />}
                                </IconButton>
                              </Tooltip>
                            </PermissionComp>
                            <PermissionComp permissionPath="presentation.chapter.lock">
                              <Tooltip
                                title={chapter.isLock ? "Unlock" : "Lock"}
                                arrow
                              >
                                <IconButton
                                  disabled={
                                    !chapter.status || !isLockPermission
                                  }
                                  onClick={() => {
                                    toggleDialog(
                                      { isLock: !chapter.isLock },
                                      index
                                    );
                                    setConfirmMessage(
                                      `Are you sure you want to ${
                                        chapter.isLock ? "unlock" : "lock"
                                      } this chapter?`
                                    );
                                  }}
                                  color="primary"
                                  size="small"
                                >
                                  {chapter.isLock ? (
                                    <BsLockFill className="close" />
                                  ) : (
                                    <BsUnlockFill className="open" />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </PermissionComp>
                            <Tooltip title="Delete" arrow>
                              <IconButton
                                disabled={chapter.isLock}
                                onClick={() => {
                                  // console.log(chapter)
                                  toggleDialog(
                                    { delete: chapter._id },
                                    index,
                                    "deleteChapter"
                                  );
                                  setConfirmMessage(
                                    "Are you sure you want to delete this chapter?"
                                  );
                                }}
                                color="primary"
                                size="small"
                                className="delete"
                              >
                                <IoTrash color={chapter.isLock ? "#ddd" : ""} />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Box className="chapterBody" style={{ minHeight: "260px" }}>
                    <ReactSortable
                      draggable=".slideCard"
                      disabled={chapter.isLock || isLock || !isLockPermission}
                      setList={setList}
                      onStart={onSlideDragStart}
                      onEnd={onSlideDragEnd}
                      tag={SlideWrapper}
                      chapterIndex={index}
                      dragClass="op-1"
                      ghostClass="op-1"
                      forceFallback
                      scrollSpeed={100}
                      list={JSON.parse(JSON.stringify(chapter.slides))}
                      group="default"
                      animation={150}
                      children={
                        <>
                          {chapter.slides.map((slide, i) => {
                            
                            const imageURL = `${bucketURL}/${slide.image}`;
                            const thumbnailURL = `${bucketURL}/${slide.thumbnail}`;
                            const masterTemplate = slide.masterTemplateFileId? masterTemplates.find((temp)=> temp.fileId === slide.masterTemplateFileId) : null;
                            const masterTemplateThumbnailURL = masterTemplate ? `${bucketURL}/${masterTemplate?.thumbnail}` : null;
                            const dontShowSlideThumbnail = slide.masterTemplateFileId && !slide.isHTMLModified
                            return (
                              <Grid
                                key={slide._id}
                                className="slide slideCard"
                                sx={{ cursor: "pointer" }}
                                item
                                xs={12}
                                md={2}
                              >
                                <CardItem
                                  dontShowSlideThumbnail={dontShowSlideThumbnail}
                                  masterTemplateThumbnailURL={masterTemplateThumbnailURL}
                                  checkHandler={(e)=>{slideEnableHandler(e, index, i)}}
                                  checkValue={slide.isEnabled}
                                  image={thumbnailURL}
                                  title={slide.name}
                                  classes={`chapterCard ${!slide.isEnabled && 'disabledSlide'}`}
                                  id={slide._id}
                                  onDoubleClick={(e) => {
                                    if (!e.target.closest(".cardItem"))
                                      return false;
                                    status != "reviewed" && status != "inReview"
                                      ? navigate(
                                          `/presentation/${id}/edit/${slide._id}`
                                        )
                                      : console.log(
                                          `reviewed or in-review can't open`
                                        );
                                  }}
                                  controls={
                                    !isLock && (
                                      <SlideControls
                                        chapterIndex={index}
                                        status={status}
                                        chapterId={chapter._id}
                                        presentationId={id}
                                        slide={slide}
                                        slideIndex={i}
                                        imageURL={imageURL}
                                        thumbnailURL={thumbnailURL}
                                        isLock={chapter.isLock}
                                        slidesCount={slidesCount}
                                      />
                                    )
                                  }
                                />
                              </Grid>
                            );
                          })}

                          {!isLock && (
                            <Grid
                              item
                              xs={12}
                              md={2}
                              className="sort-cancel"
                              style={{ opacity: !isDragging ? "1" : "0" }}
                            >
                              <CardItem
                                classes="chapterCard addSlide"
                                image={addIcon}
                                onClick={() =>
                                  showDetailPanel(
                                    chapter._id,
                                    chapter.slides.length + 1,
                                    index
                                  )
                                }
                              />
                            </Grid>
                          )}
                        </>
                      }
                    />
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography variant="h1" pl={3} pt={2}>
              No Record !
            </Typography>
          )}
        </ReactSortable>
      </Content>
    </AccessControl>
  );
};
export default Structure;






import { Avatar, Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { setFontSize } from "src/utils/Utils";
import { useBaseUrl } from "src/hooks/useBaseUrl";
import "./UserAvatar.scss";

const UserAvatar = ({ name, image, type = "icon", children }) => {
  const [newName, setNewName] = useState(null)
  const [newImage, setNewImage] = useState(null)
  const baseUrl = useBaseUrl();
  const cacheBuster = new Date().getTime();
  // console.log('image', image)
  useEffect(() => {
    if (typeof image === 'string' && image) {
      if (!image.includes("http")) {
        setNewImage(baseUrl + image);
      } else {
        setNewImage(image);
      }
    } else {
      setNewImage(null); 
    }
  
    if (name) {
      if (name.includes(" ")) {
        setNewName(name.split(" ")[0][0] + name.split(" ")[1][0]);
      } else {
        setNewName(name[0])
      }
      setFontSize(".userAvatar");
    }
    // console.log(newImage, newName)
    
  }, [name, image, baseUrl])
  

  return (
    <div className={`userAvatar ${type}`}>
      {newImage && (
        <Avatar alt="Profile Photo" src={newImage + `?${cacheBuster}`} id="profileImageView" />
      )}

      {(newName && newImage === null) && (
         <Avatar id="userName" className="userName">
         {newName}
       </Avatar>
      )}
      {children}
    </div>
  );
};

export default UserAvatar;

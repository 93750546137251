export const devices = [
  {
    label: "iPad",
    type: "iPad",
    value: "iPad",
    width: "1024px",
    height: "768px",
  },
  {
    label: "iPad Pro",
    type: "iPad",
    value: "iPad Pro",
    width: "1024px",
    height: "768px",
  },
  {
    label: "iPad air 3rd generation",
    type: "iPad",
    value: "iPad air 3rd generation",
    width: "1668px",
    height: "2224px",
  },
  {
    label: "Samsung galaxy tab a 10.1",
    type: "tab",
    value: "Samsung galaxy tab a 10.1",
    width: "1200px",
    height: "1920px",
  },
  {
    label: "Microsoft surface pro 3",
    type: "tab",
    value: "Microsoft surface pro 3",
    width: "2160px",
    height: "1440px",
  },
  {
    label: "iPhone 6,7,8",
    type: "phone",
    value: "iPhone 6,7,8",
    width: "750px",
    height: "1334px",
  },
  {
    label: "iPhone 6,7,8 plus",
    type: "phone",
    value: "iPhone 6,7,8 plus",
    width: "1080px",
    height: "1920px",
  },
  {
    label: "Android Hd Screens",
    type: "phone",
    value: "Android Hd Screens",
    width: "720px",
    height: "1080px",
  },
];
export const targetSystem = [
  { value: "Salesforce", label: "Salesforce" },
  { value: "Salesforce Marketing Cloud", label: "Salesforce Marketing Cloud" },
];
export const getDimenssions = (otherDevice, targetDevice) => {
  const otherDevices = {
    email: {
      width: "600px",
      minHeight: "100%",
    },
    microsite: {
      width: "100%",
      minHeight: "100%",
    },
  };
  if (otherDevice && otherDevices[otherDevice]) {
    return otherDevices[otherDevice];
  }
  if (targetDevice) {
    return devices.find(
      (obj) => obj.label.toLowerCase() == targetDevice.toLowerCase()
    );
  }
};
export function scaleDropzone(
  wrapperElement,
  dropzone,
  zoom = 1,
  callback = () => { },
  deviceWidth,
  deviceHeight
) {
  const isOnEditor = dropzone?.closest("#mainWrapper") !== null;
  const isEmailOrMicrosite = window.location.href.includes('microsite') || window.location.href.includes('email');
  let wrapperHeight = wrapperElement.clientHeight;
  let wrapperWidth = wrapperElement.clientWidth;
  let width = dropzone ? parseInt(dropzone.style.width) : parseInt(deviceWidth) ;
  let height = dropzone ? parseInt(dropzone.style.height) : parseInt(deviceHeight);
  // Scalling to Fit
  if (zoom === "scaleToFit") {    
    let widthMargin = isOnEditor ? 6.5 : 1;
    let heightMargin = isOnEditor ? 10 : 1;
    
    //if user on editor and microsite or email then increase margin
    if(isEmailOrMicrosite){
      widthMargin = 50
    }

    let scaleX = (wrapperWidth - widthMargin) / width;
    let scaleY = (wrapperHeight - heightMargin) / height;
    zoom = Math.min(scaleX, scaleY);
    // console.log({height, width, wrapperHeight, wrapperWidth,scaleX, scaleY, zoom})

    //only for email and microsite
    if(isEmailOrMicrosite){
      if(width <= wrapperWidth){
        zoom = 1 //if microsite container width is less then wrapper then no scale to fit
      }else{
        zoom = scaleX //scale to fit according to width
      }   
    }
  }
  
  if (dropzone) {
    dropzone.style.zoom = zoom;
    if(document.getElementById('sitemap')){
      document.getElementById('sitemap').style.zoom = zoom;
    }
  }

  callback(zoom);
}
export const shortCodeSnippets = (type) => {
  switch (type) {
    case "cs-checkboxes":
    case "cs-radio-container":
      return (element, elType) => {
        const { color = "#0096ff" } = element.dataset;
        const isRadio = elType.includes("radio");
        const checkboxClass = isRadio ? "radio" : "checkbox";
        let generatedHtml = "";
        let rowHtml = "";
        const rows = element.querySelectorAll("div");
        let uniqueChecked = false;
        rows.forEach((el) => {
          const { label = "Label", checked } = el.dataset;
          // checked class and keeping unique checkbox for radio even user adds multiple
          const checkedClass =
            checked && checked !== "false" && !uniqueChecked ? "checked" : "";
          // Keeping unique check for radio
          if (isRadio && checked) {
            uniqueChecked = true;
          }
          if (isRadio) {
            rowHtml = ` 
                <div class="${checkboxClass}-row"> 
                  <div class="radio ${checkedClass}"> 
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" style="fill: ${color};"> 
                      <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" fill="inherit"></path> 
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="inherit"></path> 
                    </svg> 
                  </div> 
                  <span class="label">${label}</span> 
                </div> 
              `;
          } else {
            rowHtml = ` 
              <div class="${checkboxClass}-row"> 
                <div class="checkbox ${checkedClass}"> 
                  <svg fill="#0096ff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path fill="inherit" d="M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z"/> </g> </svg> 
                </div> 
                <span class="label">${label}</span> 
              </div> 
              `;
          }
          generatedHtml += rowHtml;
        });
        delete element.dataset.shortcode;
        delete element.dataset.color;
        element.innerHTML = generatedHtml;
        return element;
      };
    case "cs-slider":
      return (element) => {
        const {
          trackColor = "#057EFF",
          thumbColor = "#00BFFF",
          value,
        } = element.dataset;
        const width = `${(value / 100) * 24}px`;
        const sliderdata = `<div class="track" style="background: ${trackColor}"></div> 
              <div class="thumb" style="left: calc(${value}% - ${width}); background: ${thumbColor}"></div>`;
        delete element.dataset.shortcode;
        element.innerHTML = sliderdata;
        return element;
      };
    case "cs-coverflow":
    case "cs-carousel":
      return (flipster, elType) => {
        const container = document.createElement("ul");
        const items = flipster.querySelectorAll("div");
        const isCarousel = elType.includes("carousel");
        if (isCarousel) {
          items.forEach((el) => {
            const { heading = "heading", text = "text", image } = el.dataset;
            const imageDiv = `<img src="${image}" data-src="${image}" />`;
            const subtitle = ` 
                <div class="subtitle"> 
                  <p class="heading">${heading}</p> 
                  <pre class="text">${text}</pre> 
                </div>`;
            const item = `<li>${imageDiv + subtitle}</li>`;
            container.innerHTML += item;
            el.remove();
          });
        } else {
          items.forEach((el) => {
            const { text = "text", image } = el.dataset;
            const imageDiv = `<img src="${image}" data-src="${image}" />`;
            const subtitle = `<p class="text">${text}</p>`;
            const item = `<li>${subtitle + imageDiv}</li>`;
            container.innerHTML += item;
            el.remove();
          });
        }
        flipster.append(container);
        delete flipster.dataset.shortcode;
        return flipster;
      };
    case "cs-audio":
      return (element) => {
        element.innerHTML = `<audio></audio> 
            <button class="play" style="color:rgba(38, 67, 181, 1);"> 
            <svg stroke="currentColor" 
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
            xmlns="http://www.w3.org/2000/svg"> 
            <path d="M96 448l320-192L96 64v384z"></path></svg> 
            </button> 
            <input  class="timeline" style="background: rgba(38, 67, 181, 0.6);" type="range" value='0' name="timeline" min="0" max="100" disabled> 
            <button class="mute" style="color: rgba(38, 67, 181, 1);"> 
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" 
            stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
             d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
            <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
            </button> 
            <div class="time"> 
                  <span class="currentTime" style="color:rgba(38, 67, 181, 1);">00:00</span> 
                  <span style="color: rgba(38, 67, 181, 1);">/</span> 
                  <span class="duration" style="color: rgba(38, 67, 181, 1);">00:00</span> 
             </div>`;
        return element;
      };
    case "cs-video":
      return (element) => {
        if (location.pathname.includes("email")) {
          element.innerHTML = ` 
              <video src="..." controls style="width:100%;"></video> 
              `;
        } else {
          element.innerHTML = ` 
              <video src="..."  ></video> 
              <div class="videoControls" style="background: #84c7ff;"> 
                 <button class="play" style="color:rgba(38, 67, 181, 1);"> 
                 <svg stroke="currentColor" 
                 fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                 xmlns="http://www.w3.org/2000/svg"> 
                 <path d="M96 448l320-192L96 64v384z"></path></svg> 
                 </button> 
                 <input  class="timeline" style="background: rgba(38, 67, 181, 0.6);" type="range" value='0' name="timeline" min="0" max="100" disabled> 
                 <button class="mute" style="color: rgba(38, 67, 181, 1);"> 
                 <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
                 height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" 
                 stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
                  d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
                 <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
                 </button> 
                  <div class="time"> 
                       <span class="currentTime" style="color:rgba(38, 67, 181, 1);">00:00</span> 
                       <span style="color: rgba(38, 67, 181, 1);">/</span> 
                       <span class="duration" style="color: rgba(38, 67, 181, 1);">00:00</span> 
                  </div> 
     
                  <button class="fullScreen" style="color:rgba(38, 67, 181, 1);"> 
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path></svg> 
                  </button> 
              </div>`;
        }
        return element;
      };
    case "cs-tabGroup":
      return (element) => {
        element.innerHTML = ` 
            <div class="tabs editable" style="background-color: #ffffff;"> 
            <button class="tabLinks activeBtn" style="background-color: #2980b9;color: #ffffff;" data-target="tab-1">Tab 1</button> 
            <button class="tabLinks" data-target="tab-2">Tab 2</button> 
            <button class="tabLinks" data-target="tab-3">Tab 3</button> 
           </div> 
           <div id="tab-1" class="cs-tabContent activeTab editable"> 
            
           </div> 
           <div id="tab-2" class="cs-tabContent editable"> 
             
           </div> 
           <div id="tab-3" class="cs-tabContent editable"> 
             
           </div> 
         `;
        return element;
      };
    case "cs-extendedCard":
      return (element) => {
        const defaultImage = element.dataset.image;
        element.innerHTML = ` 
            <div class="head"> 
              <div  class="cs-text editable" 
              style="background:rgba(0,0,0,0.1);text-align: center;"><p>Front</p></div> 
            </div> 
            <div class="cs-cardBody cardBody editable"> 
              <div  class="cs-text editable" 
              style="align-self: center; width: 55%;"><p>This is a textarea .. type here</p></div> 
              <img src="${defaultImage}" class="cs-image editable" style="text-align:center" draggable="false"/> 
            </div> 
            </div> 
          `;
        return element;
      };
    case "cs-flipCard":
      return (element) => {
        const frontImage = element.getAttribute("data-front-image");
        const backImage = element.getAttribute("data-back-image");
        element.innerHTML = ` 
                <div class="flipCardInner" style="transform: rotateX(0deg)"> 
              <div class="flipCardFront"> 
                <div class="head"> 
                  <div 
                    class="cs-text editable" 
                    style="background: rgba(0, 0, 0, 0.1); text-align: center" 
                  > 
                  Front 
                  </div> 
                  <button class="flipButton">&#10554;</button> 
                </div> 
                <div class="cardBody"> 
                  <div class="cs-text editable" style="align-self: center; width: 55%"> 
                      Type a note here
                  </div> 
                  <img 
                    src="${frontImage}" 
                    class="cs-image editable" 
                    draggable="false" 
                  /> 
                </div> 
              </div> 
              <div class="flipCardBack"> 
                <div class="head"> 
                  <div 
                    class="cs-text editable" 
                    style="background: rgba(0, 0, 0, 0.1); text-align: center" 
                  > 
                  Back
                  </div> 
                  <button class="flipButton">&#10554;</button> 
                </div> 
                <div class="cardBody"> 
                  <div class="cs-text editable" style="align-self: center; width: 55%"> 
                  Type a note here
                  </div> 
                  <img 
                    src="${backImage}" 
                    class="cs-image editable" 
                   
                    draggable="false" 
                  /> 
                </div> 
              </div> 
            </div>`;
        return element;
      };
    case "cs-table":
      return (element) => {
        //NOTE!!: In FUTURE if anything changes in the CS-INPUT component the input below will be changed as CS-INPUT at THAT TIME
        const input = `<div class="cs-input" style="word-break:break-all;color:#000000;font-family:Arial;font-weight:normal;font-size:16px;font-style:normal;text-transform:none;text-align: center;"> Type here </div>`;
        element.innerHTML = ` 
                      <thead> 
                  <tr> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                  </tr> 
                </thead> 
                <tbody> 
                  <tr> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                  </tr> 
                  <tr> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                    <td>${input}</td> 
                  </tr> 
                </tbody>`;
        return element;
      };
    default:
      break;
  }
};

export const getSnippet = (html) => {
  const layer = $(html);
  const layerDomElement = layer[0];
  const oldChild = layer.children();
  const isShortCode = oldChild.attr("data-shortcode") === "true";
  if (isShortCode) {
    const type = oldChild.attr("class");
    const snippetGenerator = shortCodeSnippets(type);
    if (snippetGenerator) {
      const newSnippet = snippetGenerator(oldChild[0], type);
      layer.empty();
      layer.append(newSnippet);
      oldChild.removeAttr("data-shortcode");
    } else console.error("sorry snippet is not available for " + type);
  }
  return layerDomElement;
};


export const useSetLayerHeight = (layerEl) => {   

  function getTotalHeightOfChildren(parentElement) {
    // Get the parent div element
    if (!parentElement) {
      console.error("Parent div not found");
      return 0;
    }
  
    // Get all children of the parent div
    const children = parentElement.children;
  
    // Calculate the total height of children
    let totalHeight = 0;
  
    for (let i = 0; i < children.length; i++) {
      // Add the height of each child to the total height
      totalHeight += children[i].offsetHeight;
    }
  
    return totalHeight;
  }

  let layer = $(layerEl);

  if (!layer.hasClass("cs-layer")) {
    layer = layer.closest(".cs-layer");
  }

  if (layer.css("position") === "absolute" || layer.hasClass("flipCard-component")) {
    const layerBorder = parseInt(layer.css("borderWidth").replace("px", "") * 2);
    const layerPadding = parseInt(layer.css("padding").replace("px", "") * 2);
    const layerHeight = layer.outerHeight().toFixed(2);
    // Setting default height for popup component
    if (layer.hasClass("popup-component")) {
      if (layer[0].style.height == "") {
        layer[0].style.height = layer[0].style.width;
      }
    } else if (layer.hasClass("flipCard-component")) {
      //inner sizing because inner element is absolute element so need to ajust size of inner
      let frontHeight =  getTotalHeightOfChildren(layer[0].querySelector(".flipCardFront"))
      let backHeight =  getTotalHeightOfChildren(layer[0].querySelector(".flipCardBack"))
      let filCardInner = layer[0].querySelector(".flipCardInner");
      
      if(frontHeight>backHeight){
        filCardInner.style.height = frontHeight+"px"
      }else{
        filCardInner.style.height = backHeight+"px"
      }
      
      let csFlipCardHeight = layer[0].querySelector(".cs-flipCard").clientHeight; 
      let setLayerHeight = csFlipCardHeight + layerBorder + layerPadding;

      //finally layer size
      layer.css({ height: setLayerHeight });

    } else if (layer.hasClass("extendedCard-component")) {
      const headHeight = layer[0].querySelector(".head").clientHeight;
      const bodyHeight = layer[0].querySelector(".cardBody").clientHeight;
      layer.css("height", headHeight + bodyHeight + layerPadding * 2);
    } else {
      //dont set height for container-component
      if (
        !layer.hasClass("container-component") &&
        !layer.hasClass("carousel-component") &&
        !layer.hasClass("graph-component") &&
        !layer.hasClass("tabGroup-component")
      ) {
        // layer.css({ height: layerHeight });
        //step 2: set layer height on the based of its children height
        let csLayerElementsHeight = layerPadding;
        layer.children().each(function () {
          const csElement = $(this);
          if (
            csElement.attr("class")?.includes("cs-") ||
            csElement.is("table") ||
            csElement.hasClass("checkbox-row") ||
            csElement.hasClass("cs-tabGroup") ||
            csElement.hasClass("radio-row")
          ) {
            const csElementHeight = csElement.outerHeight().toFixed(2);
            // console.log(csElement, csElementHeight)
            csLayerElementsHeight =
              csLayerElementsHeight + parseFloat(csElementHeight);
          }
        });
        // console.log('height is set for layer', csLayerElementsHeight)
        layer.css({ height: csLayerElementsHeight+layerBorder });
      }
    }
  }
};
export const csAudio = (element) => {
  if (!element.classList.contains("cs-audio")) {
    element = element.querySelector(".cs-audio");
  }
  // Dom Elements variables
  let audio = element.querySelector("audio");
  let playBtn = element.querySelector(".play");
  let muteBtn = element.querySelector(".mute");
  let timeline = element.querySelector(".timeline");
  let duration = element.querySelector(".duration");
  let currentTime = element.querySelector(".currentTime");
  // timer update function
  const timer = () => {
    audio = element.querySelector("audio");
    currentTime = element.querySelector(".currentTime");
    timeline = element.querySelector(".timeline");
    duration = element.querySelector(".duration");
    if (audio.currentTime != 0) {
      element.dataset.time = Math.trunc(audio.currentTime);
    }
    timeline.max = Math.trunc(audio.duration);
    timeline.value = element.dataset.time;
    currentTime.textContent = new Date(audio.currentTime * 1000)
      .toISOString()
      .slice(14, 19);
    audio.addEventListener("loadedmetadata", function () {
      duration.textContent = new Date(audio.duration * 1000)
        .toISOString()
        .slice(14, 19);
    });
  };
  return {
    render() {
      timer();
    },
    setData() {
      console.log(element);
      // Getting Elements
      audio = element.querySelector("audio");
      playBtn = element.querySelector(".play");
      muteBtn = element.querySelector(".mute");
      // Seting Data coming from data attributes
      const { src, time, play, mute } = element.dataset;
      console.log(element.dataset);
      if (src && !src.includes("cs-tenant")) {
        audio.dataset.src = src;
        audio.src = src;
      } else {
        audio.dataset.src = element.getAttribute("src");
        audio.src = element.getAttribute("src");
      }
      audio.currentTime = Number(time);
      if (play == "true") {
        audio.play();
      } else {
        audio.pause();
      }
      if (mute == "true") {
        console.log("muting", audio);
        audio.muted = true;
      } else {
        audio.muted = false;
      }
      if (play == "true") {
        playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor" 
            stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
            <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
      } else {
        playBtn.innerHTML = `<svg stroke="currentColor" 
            fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
            xmlns="http://www.w3.org/2000/svg"> 
            <path d="M96 448l320-192L96 64v384z"></path></svg>`;
      }
      if (mute == "true") {
        muteBtn.innerHTML = ` 
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
                  height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square" 
                  stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path> 
                  <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path> 
                  <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path> 
                  </svg> 
                  `;
      } else {
        muteBtn.innerHTML = ` 
                  <svg stroke="currentColor" fill="currentColor" stroke-width="0" 
                  viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
                  <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
                  d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
                  <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
                  `;
      }
    },
    updateTimeLine() {
      audio = element.querySelector("audio");
      timeline = element.querySelector(".timeline");
      if (!element.closest(`#mainWrapper`)) {
        timeline.disabled = false;
        setTimeout(() => {
          timeline.max = Math.trunc(audio.duration);
        }, 100);
      }
      timeline.addEventListener("input", function () {
        audio.currentTime = this.value;
        timer();
      });
    },
    play() {
      duration = element.querySelector(".duration");
      currentTime = element.querySelector(".currentTime");
      audio = element.querySelector("audio");
      playBtn = element.querySelector(".play");
      playBtn.addEventListener("click", function (e) {
        timeline.max = Math.trunc(audio.duration);
        if (audio.paused) {
          const csAudios = document.querySelectorAll(".cs-audio");
          csAudios.forEach((csAudio) => {
            csAudio.querySelector("audio").pause();
            csAudio.querySelector(
              ".play"
            ).innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
            e.currentTarget.previousElementSibling.play();
          });
          playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor" 
            stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
            <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
          element.dataset.play = true;
        } else {
          audio.pause();
          playBtn.innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
          element.dataset.play = false;
        }
      });
    },
    mute() {
      audio = element.querySelector("audio");
      muteBtn = element.querySelector(".mute");
      muteBtn.addEventListener("click", function () {
        if (!audio.muted) {
          audio.muted = true;
          muteBtn.innerHTML = ` 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square" 
                stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path> 
                <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path> 
                <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path> 
                </svg> 
                `;
          element.dataset.mute = true;
        } else {
          audio.muted = false;
          muteBtn.innerHTML = ` 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" 
                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
                <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
                d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
                <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
                `;
          element.dataset.mute = false;
        }
      });
    },
    onAudioEnd() {
      audio = element.querySelector("audio");
      duration = element.querySelector(".duration");
      currentTime = element.querySelector(".currentTime");
      timeline = element.querySelector(".timeline");
      playBtn = element.querySelector(".play");
      audio.addEventListener("ended", function () {
        this.currentTime = 0;
        element.dataset.time = 0;
        element.dataset.play = false;
        currentTime.textContent = `00:00`;
        timeline.value = 0;
        playBtn.innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
      });
    },
    audioTimeUpdate() {
      audio = element.querySelector("audio");
      audio.addEventListener("timeupdate", function (e) {
        timer();
      });
    },
  };
};
export const imageLinkPrevent = (domElmnt) => {
  const a = domElmnt.closest(".link");
  if (a) {
    a.onclick = function (e) {
      e.preventDefault();
    };
  }
};
export const csVideo = (element) => {
  if (!element.classList.contains("cs-video")) {
    element = element.querySelector(".cs-video");
  }
  // Dom Elements variables
  let video = element.querySelector("video");
  let videoControls = element.querySelector(".videoControls");
  let playBtn = element.querySelector(".play");
  let fullScreenBtn = element.querySelector(".fullScreen");
  let muteBtn = element.querySelector(".mute");
  let timeline = element.querySelector(".timeline");
  let duration = element.querySelector(".duration");
  let currentTime = element.querySelector(".currentTime");
  // timer update function
  const timer = () => {
    video = element.querySelector("video");
    timeline = element.querySelector(".timeline");
    duration = element.querySelector(".duration");
    currentTime = element.querySelector(".currentTime");
    if (video.currentTime != 0) {
      element.dataset.time = Math.trunc(video.currentTime);
    }
    timeline.max = Math.trunc(video.duration);
    timeline.value = element.dataset.time;
    currentTime.textContent = new Date(video.currentTime * 1000)
      .toISOString()
      .slice(14, 19);
    video.addEventListener("loadedmetadata", function () {
      if (video.duration === Infinity) {
        video.currentTime = 24 * 60 * 60;
        video.addEventListener("timeupdate", getDuration);
      } else {
        duration.textContent = new Date(video.duration * 1000)
          .toISOString()
          .slice(14, 19);
      }
    });
  };
  const getDuration = () => {
    video.currentTime = element.dataset.time;
    video.removeEventListener("timeupdate", getDuration);
    duration.textContent = new Date(video.duration * 1000)
      .toISOString()
      .slice(14, 19);
  };
  // interval stopping function
  // Checking video full screen and appling classes
  const toggleFullScreen = () => {
    if (
      element.getAttribute("data-full-screen-play") == "true" &&
      element.getAttribute("data-play") == "true"
    ) {
      element.parentElement.classList.add("fullScreenLayer");
      element.classList.add("fullScreenVideo");
      fullScreenBtn.innerHTML = ` 
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" 
              height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 
              0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 
              0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 
               0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 
               0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"></path></svg> 
              `;
      videoControls.style.display = "flex";
    } else {
      element.parentElement.classList.remove("fullScreenLayer");
      element.classList.remove("fullScreenVideo");
      fullScreenBtn.innerHTML = ` 
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" 
            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 
            0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 
            0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 
            1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 
            0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path></svg>`;
      if (element.dataset.controls == "false") {
        videoControls.style.display = "none";
      }
    }
  };

  return {
    setControls() {
      if(window.location.href.includes('presentation')){
        video.controls = false;
        console.log('hide video controls for' ,video)
      }else{
        video.controls = true;
        console.log('show video controls for' ,video)
      }
      
    },
    render() {
      timer();
    },
    setData() {
      // Getting elements
      console.log('set Data')
      video = element.querySelector("video");
      videoControls = element.querySelector(".videoControls");
      playBtn = element.querySelector(".play");
      muteBtn = element.querySelector(".mute");
      // Setting Data coming from data attributes
      const { poster, time, play, mute, controls, loop, src } = element.dataset;
      if (src) {
        video.src = src;
        video.setAttribute('data-src', src)
        element.removeAttribute('data-src')
      }
      
      if(poster){
        video.poster = poster;
        element.removeAttribute('data-poster')
      }
      
      video.currentTime = Number(time);
      if (!location.pathname.includes("email")) {
        if (play == "true") {
          video.play();
        } else {
          video.pause();
        }
        if (mute == "true") {
          video.mute = true;
        } else {
          video.mute = false;
        }
        if (loop == "true") {
          video.loop = true;
        } else {
          video.loop = false;
        }
        if (controls == "true") {
          videoControls.style.display = "flex";
        } else {
          videoControls.style.display = "none";
        }
        if (element.dataset.play == "true") {
          playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor" 
                  stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
                  <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
        } else {
          playBtn.innerHTML = `<svg stroke="currentColor" 
                    fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                    xmlns="http://www.w3.org/2000/svg"> 
                    <path d="M96 448l320-192L96 64v384z"></path></svg>`;
        }
        if (element.dataset.mute == "true") {
          muteBtn.innerHTML = ` 
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
              height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square" 
              stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path> 
              <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 185l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path> 
              <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path> 
              </svg> 
              `;
        } else {
          muteBtn.innerHTML = ` 
                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" 
                    viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
                    <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
                    d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
                    <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
                    `;
        }
      }
    },
    updateTimeLine() {
      video = element.querySelector("video");
      timeline = element.querySelector(".timeline");
      if (!element.closest(`#mainWrapper`)) {
        timeline.disabled = false;
        setTimeout(() => {
          timeline.max = Math.trunc(video.duration);
        }, 150);
      }
      timeline.addEventListener("input", function () {
        video.currentTime = this.value;
        timer();
      });
    },
    play() {
      fullScreenBtn = element.querySelector(".fullScreen");
      duration = element.querySelector(".duration");
      currentTime = element.querySelector(".currentTime");
      playBtn = element.querySelector(".play");
      playBtn.addEventListener("click", function (e) {
        timeline.max = Math.trunc(video.duration);
        if (video.paused) {
          const csVideos = document.querySelectorAll(".cs-video");
          csVideos.forEach((csVideo) => {
            csVideo.querySelector("video").pause();
            csVideo.querySelector(
              ".play"
            ).innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
            video.play();
          });
          playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor" 
            stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
            <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
          element.dataset.play = true;
        } else {
          video.pause();
          playBtn.innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
          element.dataset.play = false;
        }
        if (element.getAttribute("data-full-screen-play") == "true") {
          toggleFullScreen();
        }
      });
    },
    mute() {
      muteBtn = element.querySelector(".mute");
      muteBtn.addEventListener("click", function () {
        if (!video.muted) {
          video.muted = true;
          muteBtn.innerHTML = ` 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" 
                height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="square" 
                stroke-miterlimit="10" stroke-width="32" d="M416 432L64 80"></path> 
                <path d="M352 256c0-24.56-5.81-47.88-17.75-71.27L327 170.47 298.48 1              85l7.27 14.25C315.34 218.06 320 236.62 320 256a112.91 112.91 0 01-.63 11.74l27.32 27.32A148.8 148.8 0 00352 256zm64 0c0-51.19-13.08-83.89-34.18-120.06l-8.06-13.82-27.64 16.12 8.06 13.82C373.07 184.44 384 211.83 384 256c0 25.93-3.89 46.21-11 65.33l24.5 24.51C409.19 319.68 416 292.42 416 256z"></path> 
                <path d="M480 256c0-74.26-20.19-121.11-50.51-168.61l-8.61-13.49-27 17.22 8.61 13.49C429.82 147.38 448 189.5 448 256c0 48.76-9.4 84-24.82 115.55l23.7 23.7C470.16 351.39 480 309 480 256zM256 72l-73.6 58.78 73.6 73.59V72zM32 176.1v159.8h93.65L256 440V339.63L92.47 176.1H32z"></path> 
                </svg> 
                `;
          element.dataset.mute = true;
        } else {
          video.muted = false;
          muteBtn.innerHTML = ` 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" 
                viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
                <path fill="none" stroke-linecap="square" stroke-miterlimit="10" stroke-width="32" 
                d="M320 320c9.74-19.38 16-40.84 16-64 0-23.48-6-44.42-16-64m48 176c19.48-33.92 32-64.06 32-112s-12-77.74-32-112m48 272c30-46 48-91.43 48-160s-18-113-48-160"></path> 
                <path d="M125.65 176.1H32v159.8h93.65L256 440V72L125.65 176.1z"></path></svg> 
                `;
          element.dataset.mute = false;
        }
      });
    },
    onVideoEnd() {
      video = element.querySelector("video");
      duration = element.querySelector(".duration");
      currentTime = element.querySelector(".currentTime");
      timeline = element.querySelector(".timeline");
      playBtn = element.querySelector(".play");
      video.addEventListener("ended", function () {
        this.currentTime = 0;
        element.dataset.time = 0;
        element.dataset.play = false;
        currentTime.textContent = `00:00`;
        timeline.value = 0;
        playBtn.innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
      });
    },
    videoTimeUpdate() {
      video = element.querySelector("video");
      video.addEventListener("timeupdate", function () {
        timer();
      });
    },
    videoPlay() {
      videoControls = element.querySelector(".videoControls");
      video.addEventListener("click", function () {
        if (video.paused) {
          const csVideos = document.querySelectorAll(".cs-video");
          csVideos.forEach((csVideo) => {
            csVideo.querySelector("video").pause();
            csVideo.querySelector(
              ".play"
            ).innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
            video.play();
          });
          playBtn.innerHTML = `<svg stroke="currentColor" fill="currentColor" 
            stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
            <path d="M224 432h-80V80h80zm144 0h-80V80h80z"></path></svg>`;
          element.dataset.play = true;
        } else {
          video.pause();
          playBtn.innerHTML = `<svg stroke="currentColor" 
                fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" 
                xmlns="http://www.w3.org/2000/svg"> 
                <path d="M96 448l320-192L96 64v384z"></path></svg>`;
          element.dataset.play = false;
        }
        if (element.getAttribute("data-full-screen-play") == "true") {
          toggleFullScreen();
        }
      });
      if (element.dataset.controls == "true") {
        videoControls.style.display = "flex";
      }
    },
    playOnSlideEnter() {
      if (!window.location.href.includes("edit")) {
        if (element.getAttribute("data-play-slide-enter") == "true") {
          video.play();
        }
      }
    },
    fullScreen() {
      fullScreenBtn = element.querySelector(".fullScreen");
      fullScreenBtn.addEventListener("click", function () {
        element.parentElement.classList.toggle("fullScreenLayer");
        element.classList.toggle("fullScreenVideo");
        if (element.classList.contains("fullScreenVideo")) {
          fullScreenBtn.innerHTML = ` 
              <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" 
              height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"> 
              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 
              0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 
              0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 
               0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 
               0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"></path></svg> 
              `;
        } else {
          fullScreenBtn.innerHTML = ` 
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" 
            height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 
            0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 
            0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 
            1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 
            0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"></path></svg> 
              `;
        }
      });
    },
    toggleScreen() {
      toggleFullScreen();
    },
  };
};
export const csFlipCard = (element) => {
  return {
    render() { },
    flip() {
      if (element.classList.contains("cs-layer")) {
        const flipButton = element.querySelectorAll(".flipButton");
        flipButton.forEach((btn) => {
          btn.addEventListener("click", function (e) {
            const direction = element
              .querySelector(".cs-flipCard")
              .getAttribute("data-flip-direction");
            console.log(direction);
            const flipCardInner = element.querySelector(".flipCardInner").style;
            const flipCardBack = element.querySelector(".flipCardBack").style;
            if (direction == "horizontal") {
              if (flipCardInner.transform.includes("180")) {
                flipCardInner.transform = "rotateX(0deg)";
              } else {
                flipCardInner.transform = "rotateX(180deg)";
                flipCardBack.transform = "rotateX(180deg)";
              }
            } else {
              if (flipCardInner.transform.includes("180")) {
                flipCardInner.transform = "rotateY(0deg)";
              } else {
                flipCardInner.transform = "rotateY(180deg)";
                flipCardBack.transform = "";
              }
            }
          });
        });
      }
    },
  };
};
export const drawGraph = (graph, forCapture) => {
  // checking if graph already drawed then destroy it and reDraw !
  let transparency = 0.8;
 
  if (!graph.dataset.graph) {
    //default graph data
    const dataset = {
      xValues: ["January", "February", "March", "April", "May"],
      type: "bar",
      datasets: [{
        backgroundColor: [
          "rgba(255, 0, 0, " + transparency + ")",      // Red
          "rgba(0, 128, 0, " + transparency + ")",      // Green
          "rgba(128, 128, 128, " + transparency + ")",  // Gray
          "rgba(255, 165, 0, " + transparency + ")",    // Orange
          "rgba(165, 42, 42, " + transparency + ")"     // Brown
        ],
        data: [10, 20, 30, 40, 50]
      }]
    };
    
    const datasetJSON = JSON.stringify(dataset);
    graph.dataset.graph = datasetJSON;
  }
  
  if (!graph.id) {
    let r = (Math.random() + 1).toString(36).substring(7);
    graph.id = `graph-${r}`;
  }

  const graphConfig = JSON.parse(graph.dataset.graph);
  const { type, xValues, datasets, animation = 2000 } = graphConfig;
  const chartStatus = Chart.getChart(graph.id);
  if (chartStatus) {
    chartStatus.destroy();
  }
  // creating a graph
  Chart.defaults.font.size = 17;
  let myChart = new Chart(graph.id, {
    type, //bar , pie , polarArea , radar , line
    data: {
      labels: xValues,
      datasets,
    },
    options: {
      plugins: {
        legend: { display: false },
      },
      layout: {
        padding:10,
      },
      animation: {
        duration: forCapture ? 0 : animation,
      },
    },
  });
 
  return myChart;
};

export const makeFlipster = (flipster, elType) => {
  // Check if Flipster is already initialized
  if ($(flipster).data("flipster")) {
    // If Flipster is already initialized, destroy it
    $(flipster).flipster("destroy");
  }

  flipster.classList.remove("flipster--active");

  // Remove existing side buttons
  // const sideButtons = flipster.querySelectorAll(".flipster__button");
  flipster.querySelectorAll(".flipster__button").forEach((el) => el.remove());

  // getting data
  const { buttons, nav, buttonStyles: inputButtonStyles } = flipster.dataset;
  let start = "center";
  const activeElement = flipster.querySelector(".flipster__item--current");
  const ul = flipster.querySelector("ul");
  const li = flipster.querySelectorAll("li");
  if (ul && !ul.style.height) ul.style.height = "auto";
  if (li.length) {
    li.forEach((item) => {
      if (!item.style.height) item.style.height = "auto";
    });
  }
  let sideButtons = flipster.querySelectorAll(".flipster__button");
  if (activeElement) {
    start = Array.from(activeElement.parentNode.children).indexOf(
      activeElement
    );
  }
  const isCarousel = elType.includes("carousel");
  if (!buttons) {
    sideButtons.forEach((el) => el.remove());
  }
  const config = {
    style: isCarousel ? "flat" : "coverflow",
    spacing: isCarousel ? 0 : -0.5,
    buttons: buttons == "true",
    nav: nav == "true",
    start,
    fadeIn: 0,
  };
  // call flipster 
  $(flipster).flipster(config);

  if (sideButtons.length) {
    let buttonStyles = '{"color":"#ffffff","width":"16px","strokeWidth":"3px","buttonPosition":"calc(50% - 63.5px)"}';
    if (inputButtonStyles) {
      buttonStyles = JSON.parse(inputButtonStyles);
    }
    // Create a new array to iterate over instead of NodeList
    const buttonsArray = Array.from(sideButtons);

    buttonsArray.forEach((el) => {
      el.onclick = (e) => e.stopPropagation();
      const svg = el.querySelector("svg");
      el.style.top = buttonStyles.buttonPosition;
      svg.style.color = buttonStyles.color;
      svg.style.width = buttonStyles.width;
      svg.style.strokeWidth = buttonStyles.strokeWidth;
    });

    // To remove listeners, append cloned elements
    if (isOnEditor) {
      const withoutListenersButtons = buttonsArray.map((button) => {
        const clonedButton = button.cloneNode(true);
        clonedButton.querySelector("title").innerHTML = "Actions Will work on Preview";
        return clonedButton;
      });

      buttonsArray.forEach((button, index) => {
        button.replaceWith(withoutListenersButtons[index]);
      });
    }
  }
};

export const csTabGroup = (element) => {
  let active;
  if (element && element.classList.contains("cs-tabGroup")) {
    active = element.querySelector(".activeBtn").dataset.target;
    const activeTab = document.getElementById(active);
    !activeTab.classList.contains("activeTab") &&
      activeTab.classList.add("activeTab");
  }
  return {
    render() { },
    tabSwitch() {
      const tabBtns = element.querySelectorAll(".tabLinks");
      const tabContents = element.querySelectorAll(".cs-tabContent");
      tabBtns.forEach((btn) => {
        btn.addEventListener("click", function (e) {
          tabContents.forEach((content) => (content.style.display = "none"));
          if (element && element.classList.contains("cs-tabGroup")) {
            console.log(element);
            element.querySelector(
              `#${e.currentTarget.dataset.target}`
            ).style.display = "block";
            element.querySelectorAll(".tabLinks").forEach((btn) => {
              btn.classList.remove("activeBtn");
              btn.style.backgroundColor = "";
              btn.style.color = "";
            });
            e.currentTarget.classList.add("activeBtn");
            e.currentTarget.style.backgroundColor = element.getAttribute(
              "data-active-btn-backcolor"
            );
            e.currentTarget.style.color = element.getAttribute(
              "data-active-btn-color"
            );
          }
        });
        btn.addEventListener("mouseover", function (e) {
          if (!this.classList.contains("activeBtn")) {
            this.classList.add("activeBtnHover");
          }
        });
        btn.addEventListener("mouseout", function (e) {
          this.classList.remove("activeBtnHover");
        });
      });
    },
  };
};
export const renderWithAnimation = (element) => {
  if (element && element.closest("#mainWrapper") == null) {
    const {
      animation,
      animationType,
      animationDuration,
      animationDelay,
      infinite,
      event,
    } = element.dataset;
    const animationActive = () => {
      element.classList.add("animate__animated");
      element.classList.add(animationType);
      element.style.setProperty("--animate-duration", animationDuration);
      element.style.setProperty("animation-delay", animationDelay);
      if (infinite != null) {
        if (infinite === "true") {
          element.classList.add("animate__infinite");
        } else {
          element.classList.remove("animate__infinite");
        }
      }
      element.addEventListener("animationend", () => {
        element.classList.remove("animate__animated");
        element.classList.remove(animationType);
        element.style.removeProperty("--animate-duration");
        element.style.removeProperty("animation-delay");
        element.classList.remove("animate__infinite");
      });
    };
    if (animation === "true") {
      if (event === "slideeneter") {
        animationActive();
      }
      if (event === "tap") {
        $(element).on("click", animationActive);
      }
    }
  }
};
const setPopupIndex = (layer, overlay)=>{
  const layerLength = document.querySelectorAll('.cs-slide .cs-layer').length;
  overlay.style.zIndex = layerLength + 100
  layer.style.zIndex = layerLength + 101
}
// POPUP Funcionality
export const hidePopup = (element) => {
  const layer = element.closest(".cs-layer");
  const closeBtn = element.querySelector(".closePop");
  const overlay = layer.nextSibling;
  if (closeBtn) {
    closeBtn.addEventListener("click", function (e) {
      e.stopPropagation();
      if (!layer.closest("#mainWrapper")) {
        $(overlay).fadeOut(300);
        $(layer).fadeOut(300);
      }
    });
  }
  // Hiding Popup when not being edit
  overlay.addEventListener("click", function () {
    if (element.getAttribute("data-close-out-click") == "true") {
      $(this).fadeOut(300);
      $(layer).fadeOut(300);
    }
  });
};
// Showing popup on layer click
export const showPopup = (element) => {
  const layer = element.closest(".cs-layer");
  const overlay = layer.nextSibling;
  layer.addEventListener("click", function () {
    $(this).fadeIn(300);
    $(overlay).fadeIn(300);
  });
};
// Showing popup on Button click
export const showPopupOnButtonClick = (popup) => {
  const element = popup.get(0);
  const layer = element.closest(".cs-layer");
  const overlay = layer.nextSibling;

  setPopupIndex(layer, overlay)
  $(layer).fadeIn(300);
  $(overlay).fadeIn(300);
};
export const slideEnterPopup = (element) => {
  const layer = element.closest(".cs-layer");
  const overlay = layer.nextSibling;
  if (!window.location.href.includes("edit")) {
    if (element.getAttribute("data-show-slide-enter") == "true") {
      setPopupIndex(layer, overlay)
      $(layer).fadeIn(300);
      $(overlay).fadeIn(300);
    } else {
      $(layer).fadeOut(300);
      $(overlay).fadeOut(300);
    }
  }
};
export const popupOverlay = (elment) => {
  const layer = elment.closest(".cs-layer");
  if (layer.nextSibling && layer.nextSibling.nodeName !== "#text") {
    if (!layer.nextSibling.classList.contains("overlay")) {
      layer.insertAdjacentHTML("afterend", `<div class="overlay"></div>`);
    }
  } else {
    layer.insertAdjacentHTML("afterend", `<div class="overlay"></div>`);
  }
  // Overlay styling for other than edit screen
  const overlay = layer.nextSibling;
  if (!layer.closest("#mainWrapper")) {
    layer.style.zIndex = 1000;
    overlay.style.zIndex = 999;
  } else {
    overlay.style.zIndex = layer.style.zIndex - 1;
  }
};
export const siteMapActions = {
  slides: [],
  switchSlide: () => { },
  openModal: (element) => {
    console.log($(element).closest('.cs-slide'))
    // $(element).closest('.cs-slide').find('.sitemap').fadeIn(300);
    $("#sitemap").fadeIn(300);
    $(document.body).css({ overflow: "hidden" });
  },
  closeModal: () => {
    $(".sitemap").fadeOut(300);
    $(document.body).css({ overflow: "" });
  },
};
export const renderSitemap = (
  activeClass = ".slide.selected",
  activeIndex,
  selectedIndex,
  append = 'body'
) => {
  const siteMapModal = ` 
      <div id="sitemap" class="sitemap"> 
        <div class="sitemapModal close"> 
          <div class="modal"> 
            <div class="close close-button">x</div> 
            <div class="content"> 
              <p class="heading">Select Slide</p> 
              <div class="contentBody"><div class="slidesList"></div> </div> 
            </div> 
          </div> 
        </div> 
      </div> 
      `;
  if (!$("#sitemap").length) {
    $(append).append(siteMapModal);
    const slide = document.querySelector('.cs-slide')
    if(slide){
      const rect = slide.getBoundingClientRect();
      const setSiteMapPosition = {
        width: rect.width+"px",
        height: rect.height+"px",
        // top: rect.y+"px",
      }
      $('#sitemap').css(setSiteMapPosition)
    }
    
  }

  const slideContainer = $(".slidesList");
  slideContainer.html("");
  const activeSlide = $(activeClass).index();
  siteMapActions.slides.forEach((slide, i) => {
    const slideBox = document.createElement("div");
    const isActive = i == activeSlide || i == activeIndex;
    if (isActive) {
      slideBox.classList.add("active");
    }
    if (selectedIndex == i) {
      slideBox.classList.add("selected");
    }
    const itemHtml = ` 
              <img class="image" src="${slide.thumbnail}" /> 
              <p class="title">${slide.name}</p> 
            `;
    $(slideBox)
      .on("click", () => {
        // calling switch function
        siteMapActions.switchSlide(i);
        // closing modal
        siteMapActions.closeModal();
      })
      .addClass("slide-box")
      .html(itemHtml);
    slideContainer.append(slideBox);
    // To show image on localhost
    const img = $(slideBox).find("img");
    if (img) {
      const imgSrc = img.attr("src");
      if (imgSrc.substr(imgSrc.length - 1) === "/") {
        img.attr("src", imgSrc.substr(0, imgSrc.length - 1));
      }
    }
  });
  // Adding Close modal listeners to CLose Buttons
  $("#sitemap .close").on("click", (e) => {
    if ($(e.target).hasClass("close")) {
      siteMapActions.closeModal();
    }
  });
  // Changing slide
  $(".slide-box").on("click", siteMapActions.closeModal);
};
export const renderWithActions = (element) => {
  if (element) {
    const { action, actionEvent, src, slideIndex, openPopup } = element.dataset;
    const notOnEditor = element.closest("#mainWrapper") == null;
    if (action && notOnEditor) {
      const actionFunction = (action) => {
        switch (action) {
          case "navigation":
            return () => {
              setTimeout(() => {
                siteMapActions.switchSlide(+slideIndex);
              }, 100);
            };
          case "openPdf":
            return () => {
              if (element.src) {
                window.open(element.dataset.pdf, "_blank");
              }
            };
          case "siteMap":
            return () => {
              // To Open Modal
              siteMapActions.openModal(element);
            };
          case "openPopup":
            return () => {
              //  finding correct element id by it's reference
              const popupToShow = $(element).closest('.cs-slide').find(`.popup-component [data-popup-ref=${openPopup}]`);
              if (popupToShow.length)
              {
                console.log($(element).closest('.cs-slide'))
                showPopupOnButtonClick(popupToShow);
              }
                
            };
          default:
            break;
        }
      };
      // Adding Listeners
      if (!element.closest("#mainWrapper")) {
        if (actionEvent == "tap") {
          element.addEventListener("click", actionFunction(action));
        } else if (actionEvent == "hold") {
          let timeout;
          element.addEventListener("mousedown", function (e) {
            // calling returning function
            timeout = setTimeout(() => actionFunction(action)(), 500);
          });
          element.addEventListener("mouseup", function (e) {
            clearTimeout(timeout);
          });
        }
      }
    }
  }
};
export const searchEditableEle = (element) => {
  return;
  if (element.hasClass("cs-layer")) {
    element.addClass("padding");
  }
  $(element)
    .children()
    .each(function () {
      const editable = $(this);
      if (editable.hasClass("editable")) {
        if (editable.find(".editable").length > 0) {
          editable.addClass("padding");
        }
      }
      searchEditableEle($(this));
    });
};
export const applyPadding = (
  el,
  deviceDimensions = {
    width: "600px",
    height: "600px",
  }
) => {
  const isPresentationEditor = el.closest("#mainWrapper");
  if (isPresentationEditor) {
    const editable = $(el);
    if (!editable.hasClass("container-component")) {
      // setting padding following devices
      const width = parseInt(deviceDimensions.width);
      const minWidth = 720;
      const diff = width >= minWidth ? width / minWidth : 1;
      const padding = (10 * diff) / 2;
      //now apply padding class on editable element
      const setPadding = (element) => {
        // if element has not styled padding
        if (
          !element.attr("style") ||
          !element.attr("style").includes("padding")
        ) {
          element.css("padding", padding);
        }
      };
      // set for layer and child elements
      const layer = editable.closest(".cs-layer");
      setPadding(layer);
      if (editable.find(".editable").length || editable.hasClass("cs-col")) {
        setPadding(editable); // do not set for last nested editable
      }
      setTimeout(() => {
        useSetLayerHeight(layer);
        console.log("settting");
      }, 250);
    }
  }
};
export const csList = (element) => {
  const notOnEditor = element.closest("#mainWrapper") === null;
  if (!notOnEditor) {
    // element.querySelectorAll("a").forEach((elem) => {
    //   elem.addEventListener("click", (event) => {
    //     event.preventDefault();
    //   });
    // });
  }
  if (notOnEditor) {
    // const li = element.querySelectorAll("li");
    // li.forEach((element) => {
    //   if (element.getAttribute("data-href")) {
    //     const hrefValue = element.getAttribute("data-href");
    //     const anchor = `<a target="_blank" href="${
    //       hrefValue.indexOf("http") === 0 ? hrefValue : "//" + hrefValue
    //     }">${element.innerText}</a>`;
    //     // element.innerHTML = anchor;
    //     // const html = element
    //     // element.querySelector("a").innerHTML = anchor
    //     if (element.querySelector("a")) {
    //       element.querySelector("a").innerHTML = anchor;
    //     }
    //   }
    // });
  }
};
export const csSocialIcons = (element) => {
  const notOnEditor = element.closest("#mainWrapper") === null;
  if (!notOnEditor) {
    element.querySelectorAll("a").forEach((elem) => {
      elem.addEventListener("click", (event) => {
        event.preventDefault();
      });
    });
  }
};
export const csLink = (element) => {
  const notOnEditor = element.closest("#mainWrapper") === null;
  if (!notOnEditor) {
    element.addEventListener("click", (event) => event.preventDefault());
  }
};
export function csRenderFunctional(el, forCapture = false) {
  let element = $(el);
  const domElmnt = $(el).get(0);
  const editableCsLayer = element.hasClass("cs-layer")
    ? element
    : element.closest(".cs-layer");
  const editableCsLayerDomElement = editableCsLayer.get(0);

  if (editableCsLayer.length) {
    const classArr = editableCsLayer.attr("class").split(" ");
    const elType = classArr.find((cls) => cls.includes("component"));
    // RENDERING COMPONENTS
    if (elType.includes("coverflow") || elType.includes("carousel")) {
      makeFlipster(domElmnt, elType);
    } else if (elType.includes("graph")) {
      drawGraph(domElmnt, forCapture);
    } else if (elType.includes("audio")) {
      const audio = csAudio(domElmnt);
      audio.render();
      audio.setData();
      audio.onAudioEnd();
      audio.updateTimeLine();
      audio.audioTimeUpdate();
      if (!domElmnt.closest("#mainWrapper")) {
        audio.mute();
        audio.play();
      }
    } else if (elType.includes("flipCard")) {
      const flipCard = csFlipCard(domElmnt);
      flipCard.render();
      flipCard.flip();
    } else if (elType.includes("video")) {
      const video = csVideo(domElmnt);
      video.setData();
      video.setControls()
      if (!location.pathname.includes("email")) {
        video.render();
        video.updateTimeLine();
        video.videoTimeUpdate();
        video.onVideoEnd();
        video.playOnSlideEnter();
        if (!domElmnt.closest("#mainWrapper")) {
          video.fullScreen();
          video.play();
          video.mute();
          video.toggleScreen();
          video.videoPlay();
        }
      } else {
        video.setControls();
      }
    } else if (elType.includes("tabGroup")) {
      const tabGroup = csTabGroup(domElmnt);
      tabGroup.render();
      tabGroup.tabSwitch();
    } else if (elType.includes("popup")) {
      popupOverlay(domElmnt);
      showPopup(domElmnt);
      hidePopup(domElmnt);
      slideEnterPopup(domElmnt);
    } else if (elType.includes("input")) {
      if (domElmnt.getAttribute("data-value")) {
        const inputValue = domElmnt.getAttribute("data-value");
        domElmnt.value = inputValue;
      }
    } else if (elType.includes("list")) {
      csList(domElmnt);
    } else if (elType.includes("social")) {
      csSocialIcons(domElmnt);
    } else if (elType.includes("link")) {
      csLink(domElmnt);
    } else if (elType.includes("image")) {
      imageLinkPrevent(domElmnt);
    }
    
    if (!forCapture) {
      renderWithAnimation(editableCsLayerDomElement);
      renderWithActions(domElmnt);
    }
    
    useSetLayerHeight(editableCsLayer);

    if (domElmnt.querySelector("img") || domElmnt.nodeName === "IMG") {
      domElmnt.onload = () => {
        useSetLayerHeight(editableCsLayer);
      };
    }
    
    if(domElmnt.getAttribute('class').includes('cs-video')){
      domElmnt.querySelector('video').addEventListener('loadeddata', () => {
        useSetLayerHeight(editableCsLayer);
      });
    }
  }
}
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../interfaces/api.interface";
import HttpService from "../../services/http.service";

import { uiActions } from "../reducers/ui-slice";

// Sharing Settings
export const getLinkDetails = createAsyncThunk(
  "getLinkDetails",
  async ({ type, id, version }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.getLinkDetails(type, id, version),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getShareLinkList = createAsyncThunk(
  "getShareLinkList",
  async ({ type, id, version }, thunkAPI) => {
    try {
      console.log(type, id, "abcd");
      const response = await HttpService.call(
        api.shareLink.sharelinkList({ type, id, version }),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const linkSharedToUsers = createAsyncThunk(
  "linkSharedToUsers",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.linkSharedToUsers(id),
        data,
        thunkAPI,
        undefined,
        true
      );
      if (response.data) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Shared!",
            message: 'Shared Successfully',
          })
        );
        return response.data;
      }
    } catch (error) {
      console.log(error)
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteShareLink = createAsyncThunk(
  "deleteShareLink",
  async (id, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.deleteShareLink(id),
        undefined,
        thunkAPI,
        undefined,
        true
      );

      if (response) {
        thunkAPI.dispatch(
          uiActions.showNotification({
            status: "success",
            title: "Deleted !",
            message: "Share Link Deleted Successfully",
          })
        );
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);



export const getHashDetails = createAsyncThunk(
  "getHashDetails",
  async (hash, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.hashDetails(hash),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getMicrosite = createAsyncThunk(
  "getMicrosite",
  async ({ UID, id, type }, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.getMicrosite(UID, id, type),
        undefined,
        thunkAPI
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sharedAuthenticate = createAsyncThunk(
  "sharedAuthenticate",
  async (data, thunkAPI) => {
    try {
      const response = await HttpService.call(
        api.shareLink.authenticate(),
        data,
        thunkAPI,
        undefined,
        true,
        10000
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

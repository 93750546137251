import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, Tooltip } from "@mui/material";
import { IoBrush, IoSettingsOutline, IoOptionsOutline } from "react-icons/io5";
import { BiCommentDetail } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { editorActions } from "src/store/reducers/editor-slice";
import TabsComments from "src/components/presentations/comments/TabsComments";
import Comments from "src/components/common/Comments";
import "src/styles/Rightbar.scss";
import { useElementStatus } from "src/hooks/editor/element.controller";
import { fetchComments } from "src/store/actions/comments";
import { useLocation } from "react-router-dom";
import { hasPermission } from "src/utils/Utils";
import PermissionComp from "src/components/Permission";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`propertise-tabpanel-${index}`}
      aria-labelledby={`propertise-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `propertise-tab-${index}`,
    "aria-controls": `propertise-tabpanel-${index}`,
  };
}

const Rightbar = (props) => {
  const { id, slideId, component, type, ComponentProperties } = props;

  const location = useLocation()
  const activeEditable = useSelector((state) => state.editor.activeEditable);
  const element = document.getElementById(activeEditable.elementId);
  const isLayer = element && element.classList.contains("cs-layer");
  const isSlide = element && element.classList.contains("cs-slide");
  const isHaveEditable = element && element.querySelector(".editable") !== null;
  const closestLayer = element && element.closest(".cs-layer");

  //Tabs Js Start
  let isContainerComponent = closestLayer?.classList.contains(
    "container-component"
  );
  const [value, setValue] = useState(1);
  const [render, setRender] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const { activeElement } = useElementStatus();

  const getTabIndexToActive = () => {
    const currentActiveEle = document.querySelector(".editable.active");
    const isLayerActive = currentActiveEle?.classList.contains("cs-layer");
    return isLayerActive ? 0 : 1;
  };

  const activeLayerOrLayerElement = (tabValue) => {
    //if there is any layer found
    if (document.querySelector(".cs-layer") !== null) {
      //if there is any editable and active element found then check that element type
      if (document.querySelector(".editable.active") !== null) {
        let currentActiveEle = document.querySelector(".editable.active");
        let isLayerActive = currentActiveEle.classList.contains("cs-layer");
        let className = "active";

        if (tabValue === 0 && !isLayerActive) {
          activeElement(currentActiveEle.closest(".cs-layer"));
          console.log("make layer active");
        } else if (tabValue === 1 && isLayerActive) {
          activeElement(currentActiveEle.querySelector(".editable"));
          console.log("make layer elements active");
        }
        console.log(tabValue);
      }
    }
  };

  useEffect(() => {
    activeLayerOrLayerElement(value);
    if (value === 2 && hasPermission(`${[getPathName()]}.commentsAndCollaboration`)) {
      dispatch(fetchComments({ id, type }));
      console.log("comments");
    }
  }, [value]);

  useEffect(() => {
    // deActive old element for every render
    dispatch(editorActions.deActiveElement());
    return setTimeout(() => setRender(true));
  }, []);

  useEffect(() => {
    // To show properties if user is on comment box
    if (value === 2) {
      handleChange(null, getTabIndexToActive());
    } else {
      setValue(getTabIndexToActive());
    }
  }, [activeEditable]);


  const getPathName = () => {
    if (location.pathname.includes('email')) {
      return "emailTemplates"
    }
    if (location.pathname.includes('presentation')) {
      return "presentation"
    }
    if (location.pathname.includes('microsite')) {
      return "microsites"
    }
  }
  //Tabs Js End
  return (
    <Box sx={{ width: "100%", paddingX: "0px" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="fullWidth"
        >
          {!isLayer && !closestLayer ? (
            <Tab
              label={<IoSettingsOutline />}
              {...a11yProps(0)}
              className="btnTab"
              disabled={isSlide ? true : false}
            />
          ) : (
            <Tooltip title="Layer Properties" size="small" arrow>
              <Tab
                // disabled={!isLayer && !closestLayer}
                label={<IoSettingsOutline />}
                {...a11yProps(0)}
                className="btnTab"
              />
            </Tooltip>
          )}

          {!isContainerComponent ? (
            <Tooltip title="Layer Elements" size="small" arrow>
              <Tab
                // disabled={!isHaveEditable && value !== 1}
                label={<IoOptionsOutline />}
                {...a11yProps(1)}
                className="btnTab"
              />
            </Tooltip>
          ) : null}
          <Tooltip title="Comments" size="small" arrow>
            <Tab
              label={<BiCommentDetail />}
              {...a11yProps(2)}
              className="btnTab"
            />
          </Tooltip>
          {/* <Tab icon={<IoBrush />} {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="tabPanel">
        <div id="propertise_container">
          {render && isLayer && (
            <ComponentProperties component={component} onlyLayerPropertise />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className="tabPanel">
        <div id="propertise_container">
          <ComponentProperties
            component={component}
            onlyLayerPropertise={false}
          />
        </div>
      </TabPanel>
      <TabPanel value={value} index={2} className="tabPanel">
        <PermissionComp permissionPath={`${[getPathName()]}.commentsAndCollaboration`} alert>
          {slideId ? (
            <TabsComments id={id} slideId={slideId} size={15} type={type} />
          ) : (
            <Comments id={id} type={type} />
          )}
        </PermissionComp>
      </TabPanel>
    </Box>
  );
};
export default React.memo(Rightbar);

import {
  IoImage,
  IoTextOutline,
  IoNewspaper,
  IoCreateSharp,
  IoCheckbox,
  IoAppsSharp,
  IoRadioButtonOn,
  IoBarChartSharp,
  IoPencilOutline,
  IoVideocam,
  IoListSharp,
} from "react-icons/io5";
import { MdAudiotrack } from "react-icons/md";
import { BsSliders } from "react-icons/bs";
import { AiOutlineGroup, AiOutlineCreditCard } from "react-icons/ai";
import { MdOutlineViewCarousel } from "react-icons/md";
import { ReactComponent as ParaIcon } from "src/assets/images/ParagraphIcon1.svg";
import { ReactComponent as TitleIcon } from "src/assets/images/Title.svg";


export const presentation_toolbar_items = {
  components: [
    {
      type: "cs-image",
      caption: "Image",
      icon: <IoImage />,
      permissionPath:'presentation.components.image'
    },
    {
      type: "cs-text",
      caption: "Paragraph",
      icon: <ParaIcon />,
      permissionPath:'presentation.components.text'
    },
    {
      type: "cs-card",
      caption: "Card",
      icon: <IoNewspaper />,
      permissionPath:'presentation.components.card'
    },
    {
      type: "cs-container",
      caption: "Container",
      icon: <IoCreateSharp />,
      permissionPath:'presentation.components.container'
    },
    {
      type: "cs-popup",
      caption: "Popup",
      icon: <IoCreateSharp />,
      permissionPath:'presentation.components.popup'
    },
    {
      type: "cs-radio",
      caption: "Radio",
      icon: <IoRadioButtonOn />,
      permissionPath:'presentation.components.radio'
    },
    {
      type: "cs-checkboxes",
      caption: "Checkbox",
      icon: <IoCheckbox />,
      permissionPath:'presentation.components.checkbox'
    },
    {
      type: "cs-table",
      caption: "Table",
      icon: <IoAppsSharp />,
      permissionPath:'presentation.components.table'
    },
    {
      type: "cs-flipCard",
      caption: "FlipCard",
      icon: <IoNewspaper />,
      permissionPath:'presentation.components.flipCard'
    },
    {
      type: "cs-button",
      caption: "Button",
      icon: <IoRadioButtonOn />,
      permissionPath:'presentation.components.button'
    },

    {
      type: "cs-slider",
      caption: "Slider",
      icon: <BsSliders />,
      permissionPath:'presentation.components.slider'
    },
    {
      type: "cs-audio",
      caption: "Audio",
      icon: <MdAudiotrack />,
      permissionPath:'presentation.components.audio'
    },
    {
      type: "cs-video",
      caption: "Video",
      icon: <IoVideocam />,
      permissionPath:'presentation.components.video'
    },
    {
      type: "cs-input",
      caption: "Title",
      icon: <TitleIcon />,
      permissionPath:'presentation.components.input'
    },
    {
      type: "cs-graph",
      caption: "Graph",
      icon: <IoBarChartSharp />,
      permissionPath:'presentation.components.graph'
    },
    {
      type: "cs-coverflow",
      caption: "Coverflow",
      icon: <MdOutlineViewCarousel />,
      permissionPath:'presentation.components.coverFlow'
    },
    {
      type: "cs-carousel",
      caption: "Carousel",
      icon: <MdOutlineViewCarousel />,
      permissionPath:'presentation.components.carousel'
    },
    {
      type: "cs-extendedCard",
      caption: "Extended Card",
      icon: <AiOutlineCreditCard />,
      permissionPath:'presentation.components.extendedCard'
    },
    {
      type: "cs-tabGroup",
      caption: "Tab Group",
      icon: <AiOutlineGroup />,
      permissionPath:'presentation.components.tabs'
    },
    {
      type: "cs-list",
      caption: "List",
      icon: <IoListSharp />,
      permissionPath:'presentation.components.list'
    },
  ],
};

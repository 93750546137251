import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Control from "../../Control";
import SelectBox from "src/components/ui/formElements/SelectBox";
import Row from "src/components/properties/Row";
import Select_Box from "src/components/common/Select_Box";
import { IoPhoneLandscape } from "react-icons/io5";
import { IoIosLaptop } from "react-icons/io";
import { BsPhone } from "react-icons/bs";
import Input from "src/components/ui/formElements/Input";
import { generateAndAppendMediaQueries } from "src/components/editor/microsites/renderMicrosite";
import Padding from "../../Padding";

const ColumnProperty = (props) => {
  const [desktopWidth, setDesktopWidth] = useState(0);
  const [mobileWidth, setMobileWidth] = useState(100);
  const { elementId, updateHistory, undoRedoChange, history} = props;
  const element = document.getElementById(elementId);
  useEffect(() => {
    const element = document.getElementById(elementId);
    if(element){
      let getDesktopWidth = Number(element.getAttribute('data-desktop-width'));
      let getMobileWidth = Number(element.getAttribute('data-mobile-width'));
      setDesktopWidth(getDesktopWidth);
      setMobileWidth(getMobileWidth);
    }
  });

  // Column width
  const handleChangeDesktopWidth = (e) => {
    let width = e.target.value;
    setDesktopWidth(width);
    element.style.width = ""
    props.setColumnWidth(props.index, width);
    // element.setAttribute("data-desktop-width", width);
    // generateAndAppendMediaQueries();
  };

  const handleChangeMobileWidth = (e) => {
    let width = e.target.value;
    setMobileWidth(width);
    element.setAttribute("data-mobile-width", width);
    generateAndAppendMediaQueries();
  };

  return (
    <Box className="column-property">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid size={{ md: 6 }}>
          <Input
            className="border"
            onChange={handleChangeDesktopWidth}
            value={desktopWidth}
            size="small"
            type="number"
            inputProps={{ step:0.1 }}
            icon={<IoIosLaptop />}
            onBlur={()=>{
              props.updateHistory()
            }}
          />
        </Grid>
        <Grid size={{ md: 6 }}>
          <Input
            className="border"
            onChange={handleChangeMobileWidth}
            value={mobileWidth}
            size="small"
            type="number"
            inputProps={{ step:0.1 }}
            icon={<BsPhone />}
            onBlur={()=>{
              props.updateHistory()
            }}
          />
        </Grid>
      </Grid>
      <Padding 
          elementId={elementId}
          updateHistory={updateHistory}
          undoRedoChange={undoRedoChange}
          noControl
        />
    </Box>
  );
};

export default ColumnProperty;
